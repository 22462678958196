import axios from 'axios';
import FileSaver from 'file-saver';
import { isEmpty } from 'lodash';
import { fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { paymentReportsActions } from '../../actions';

function* getSummarizedReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/payment-transactions-report',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentReportsActions.GET_PAYMENTS_REPORTS_SUCCESS,
      data: response.paymentTransactionsData,
      reportsContext: actions.data,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_PAYMENTS_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getDetailedReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/detailed-report',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentReportsActions.GET_DETAILED_REPORTS_SUCCESS,
      data: response.EnrolledStudentsData.enrolledStudents,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_DETAILED_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getAcademicUnitReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/academic-unit-report',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentReportsActions.GET_ACADEMIC_UNIT_REPORTS_SUCCESS,
      data: response.result,
      reportsContext: actions.data,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_ACADEMIC_UNIT_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getCampusReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/campuses-report',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentReportsActions.GET_CAMPUS_REPORT_SUCCESS,
      data: response.data,
      reportsContext: actions.data,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_CAMPUS_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getFeesDepositReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/fees-deposits-report',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentReportsActions.GET_FEES_DEPOSIT_REPORT_SUCCESS,
      data: response.data,
      reportsContext: actions.data,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_FEES_DEPOSIT_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getFinancialYearReports(actions) {
  try {
    const { reportCategory, data } = actions;

    const response = yield axios({
      url: `/bi/financial-reports/${reportCategory}`,
      method: 'GET',
      params: data,
      timeout: 30000000,
    });
    yield put({
      type: paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_SUCCESS,
      data: response.result,
      reportCategory,
      reportsContext: data,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getProgrammeFinancialYearReports(actions) {
  try {
    const { programmeId, data } = actions;

    const response = yield axios({
      url: `/bi/financial-reports/by-programme/${programmeId}`,
      method: 'GET',
      params: data,
      timeout: 30000000,
    });
    yield put({
      type: paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_BY_PROG_SUCCESS,
      data: response.result,
      programmeId,
      reportsContext: data,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_BY_PROG_ERROR,
      error: error.data,
    });
  }
}

function* getDetailedDepositReports(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/programme-deposit-report',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentReportsActions.GET_DETAILED_PREPAYMENT_REPORT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_DETAILED_PREPAYMENT_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getStudentsReportsRecords(actions) {
  try {
    const response = yield axios({
      url: '/registration/enrollment-registration/payment-transactions-records',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentReportsActions.GET_STUDENTS_PAYMENT_RECORDS_SUCCESS,
      data: response.data.studentTransactions,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_STUDENTS_PAYMENT_RECORDS_ERROR,
      error: error.data,
    });
  }
}

function* getDetailedPerProgrammeReport(actions) {
  try {
    const response = yield axios({
      url: '/bi/financial-reports/student-details',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: paymentReportsActions.GET_DETAILED_PER_PROGRAMME_REPORT_SUCCESS,
      data: response.result.result,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GET_DETAILED_PER_PROGRAMME_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* downloadDetailedPaymentReports(actions) {
  try {
    yield axios({
      url: '/registration/enrollment-registration/download-payments',
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PAYMENT-TRANSACTIONS-REPORT.xlsx`
      );
    });
    yield put({
      type: paymentReportsActions.DOWNLOAD_DETAILED_PAYMENT_REPORT_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.DOWNLOAD_DETAILED_PAYMENT_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* downloadFacultyPaymentReports(actions) {
  try {
    yield axios({
      url: '/ronald-to-send-endpoint',
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-FACULTY-PAYMENT-TRANSACTIONS-REPORT.xlsx`
      );
    });
    yield put({
      type: paymentReportsActions.DOWNLOAD_FACULTY_PAYMENT_REPORT_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.DOWNLOAD_FACULTY_PAYMENT_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* downloadPrepaymentReport(actions) {
  try {
    yield axios({
      url: '/registration/enrollment-registration/download-prepayments',
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PREPAYMENT-REPORT.xlsx`
      );
    });
    yield put({
      type: paymentReportsActions.DOWNLOAD_PREPAYMENT_REPORT_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.DOWNLOAD_PREPAYMENT_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* downloadFinancialYearReport(actions) {
  try {
    const response = yield axios({
      url: '/bi/financial-reports/details',
      method: 'POST',
      data: actions.data,
      responseType: actions?.data?.unit_id !== 'all' ? 'json' : 'blob',
      timeout: 30000000,
    }).then((res) => {
      if (actions?.data?.unit_id !== 'all') {
        return FileSaver.saveAs(
          new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          `${process.env.REACT_APP_INSTITUTION_NAME}-FINANCIAL-YEAR-REPORT.xlsx`
        );
      }
      return res;
    });
    yield put({
      type: paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_SUCCESS,
      data:
        actions?.data?.unit_id === 'all'
          ? response.data
          : { message: 'File Downloaded successfully' },
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_ERROR,
      error: error?.data || error?.error?.message,
    });
  }
}

function* streamFinancialYearReports(actions) {
  try {
    const response = yield axios({
      url: '/bi/financial-reports/stream',
      method: 'GET',
      params: actions.data,
      responseType: 'stream',
      timeout: 30000000,
    });

    const jsonRes = JSON.parse(JSON.stringify(response.data));

    if (!isEmpty(jsonRes)) {
      yield put({
        type: paymentReportsActions.STREAM_FINANCIAL_YEAR_REPORT_SUCCESS,
        data: jsonRes,
        context: actions.data,
      });
    }
  } catch (error) {
    yield put({
      type: paymentReportsActions.STREAM_FINANCIAL__YEAR_REPORT_ERROR,
      error: 'Unable to complete this process',
    });
  }
}

function* generateSummaryReport(actions) {
  try {
    const response = yield axios({
      url: '/bi/financial-reports/generate-report',
      method: 'POST',
      data: actions.data,
      timeout: 30000000,
    });

    yield put({
      type: paymentReportsActions.GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_ERROR,
      error: error?.data || error?.error?.message,
    });
  }
}

function* downloadFinancialYearFile(actions) {
  try {
    const { fileName } = actions;

    yield axios({
      url: `/bi/financial-reports/download-files/${fileName}`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
      timeout: 30000000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-FINANCIAL-YEAR-REPORT.xlsx`
      );
    });
    yield put({
      type: paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_FILE_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_FILE_ERROR,
      error: error?.data || error?.error?.message,
    });
  }
}

function* deleteFinancialYearFile(actions) {
  try {
    const { fileName, financialYearContext } = actions;

    yield axios({
      url: `/bi/financial-reports/delete-file/${fileName}`,
      method: 'DELETE',
      data: actions.data,
      responseType: 'blob',
      timeout: 30000000,
    });
    yield put({
      type: paymentReportsActions.DELETE_FINANCIAL_YEAR_FILE_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_REQUEST,
      data: financialYearContext,
      reportCategory: 'summary',
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.DELETE_FINANCIAL_YEAR_FILE_ERROR,
      error: error?.data || error?.error?.message,
    });
  }
}

function* downloadStudentPaymentReports(actions) {
  try {
    yield axios({
      url: '/registration/enrollment-registration/download-student-payments',
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
      timeout: 30000000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-STUDENT-PAYMENT-REPORT.xlsx`
      );
    });
    yield put({
      type: paymentReportsActions.DOWNLOAD_PAYMENT_REPORT_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: paymentReportsActions.DOWNLOAD_PAYMENT_REPORT_ERROR,
      error: error.error,
    });
  }
}

function* downloadPerProgrammePaymentReport(actions) {
  try {
    yield axios({
      url: '/bi/financial-reports/student-details',
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
      timeout: 30000000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PROGRAMME-PAYMENT-REPORT.xlsx`
      );
    });
    yield put({
      type: paymentReportsActions.DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_SUCCESS,
      data: { server: { message: 'Report downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: paymentReportsActions.DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_ERROR,
      error: error.error,
    });
  }
}

function* watchGetSummarizedReports() {
  yield takeLatest(
    paymentReportsActions.GET_PAYMENTS_REPORTS_REQUEST,
    getSummarizedReports
  );
}

function* watchGetDetailedReports() {
  yield takeLatest(
    paymentReportsActions.GET_DETAILED_REPORTS_REQUEST,
    getDetailedReports
  );
}

function* watchGetAcademicUnitReports() {
  yield takeLatest(
    paymentReportsActions.GET_ACADEMIC_UNIT_REPORTS_REQUEST,
    getAcademicUnitReports
  );
}

function* watchGetCampusReports() {
  yield takeLatest(
    paymentReportsActions.GET_CAMPUS_REPORT_REQUEST,
    getCampusReports
  );
}

function* watchGetFeesDepositReports() {
  yield takeLatest(
    paymentReportsActions.GET_FEES_DEPOSIT_REPORT_REQUEST,
    getFeesDepositReports
  );
}

function* watchGetFinancialYearReports() {
  yield takeLatest(
    paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_REQUEST,
    getFinancialYearReports
  );
}

function* watchGetProgrammeFinancialYearReports() {
  yield takeLatest(
    paymentReportsActions.GET_FINANCIAL_YEAR_REPORT_BY_PROG_REQUEST,
    getProgrammeFinancialYearReports
  );
}

function* watchGetDetailedDepositReports() {
  yield takeLatest(
    paymentReportsActions.GET_DETAILED_PREPAYMENT_REPORT_REQUEST,
    getDetailedDepositReports
  );
}

function* watchGetStudentsReportsRecords() {
  yield takeLatest(
    paymentReportsActions.GET_STUDENTS_PAYMENT_RECORDS_REQUEST,
    getStudentsReportsRecords
  );
}

function* watchGetDetailedPerProgrammeReport() {
  yield takeLatest(
    paymentReportsActions.GET_DETAILED_PER_PROGRAMME_REPORT_REQUEST,
    getDetailedPerProgrammeReport
  );
}

function* watchDownloadDetailedPaymentReports() {
  yield takeLatest(
    paymentReportsActions.DOWNLOAD_DETAILED_PAYMENT_REPORT_REQUEST,
    downloadDetailedPaymentReports
  );
}

function* watchDownloadFacultyPaymentReports() {
  yield takeLatest(
    paymentReportsActions.DOWNLOAD_FACULTY_PAYMENT_REPORT_REQUEST,
    downloadFacultyPaymentReports
  );
}

function* watchDownloadPrepaymentReport() {
  yield takeLatest(
    paymentReportsActions.DOWNLOAD_PREPAYMENT_REPORT_REQUEST,
    downloadPrepaymentReport
  );
}

function* watchDownloadFinancialYearReport() {
  yield takeLatest(
    paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_REPORT_REQUEST,
    downloadFinancialYearReport
  );
}

function* watchStreamFinancialYearReport() {
  yield takeEvery(
    paymentReportsActions.STREAM_FINANCIAL_YEAR_REPORT_REQUEST,
    streamFinancialYearReports
  );
}

function* watchGenerateSummaryReport() {
  yield takeLatest(
    paymentReportsActions.GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_REQUEST,
    generateSummaryReport
  );
}

function* watchDownloadFinancialYearFile() {
  yield takeLatest(
    paymentReportsActions.DOWNLOAD_FINANCIAL_YEAR_FILE_REQUEST,
    downloadFinancialYearFile
  );
}

function* watchDeleteFinancialYearFile() {
  yield takeLatest(
    paymentReportsActions.DELETE_FINANCIAL_YEAR_FILE_REQUEST,
    deleteFinancialYearFile
  );
}

function* watchDownloadStudentPaymentReports() {
  yield takeLatest(
    paymentReportsActions.DOWNLOAD_PAYMENT_REPORT_REQUEST,
    downloadStudentPaymentReports
  );
}

function* watchDownloadPerProgrammePaymentReport() {
  yield takeLatest(
    paymentReportsActions.DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_REQUEST,
    downloadPerProgrammePaymentReport
  );
}

export default [
  fork(watchGetSummarizedReports),
  fork(watchGetDetailedReports),
  fork(watchGetCampusReports),
  fork(watchGetFeesDepositReports),
  fork(watchGetFinancialYearReports),
  fork(watchGetProgrammeFinancialYearReports),
  fork(watchGetAcademicUnitReports),
  fork(watchGetStudentsReportsRecords),
  fork(watchGetDetailedDepositReports),
  fork(watchGetDetailedPerProgrammeReport),
  fork(watchDownloadDetailedPaymentReports),
  fork(watchDownloadFinancialYearReport),
  fork(watchDownloadFacultyPaymentReports),
  fork(watchDownloadPrepaymentReport),
  fork(watchDownloadStudentPaymentReports),
  fork(watchDownloadPerProgrammePaymentReport),
  fork(watchDownloadFinancialYearFile),
  fork(watchDeleteFinancialYearFile),
  fork(watchStreamFinancialYearReport),
  fork(watchGenerateSummaryReport),
];
