import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { academicYearActions, tabActions } from '../../actions';

function* getAcademicYears(actions) {
  try {
    const response = yield axios({
      url: '/events-mgt/academic-years',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_ERROR,
      error: error.data,
    });
  }
}

function* getAcademicYearsByCampus(actions) {
  try {
    const response = yield axios({
      url: '/events-mgt/academic-years/by-campus',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_ERROR,
      error: error.data,
    });
  }
}

function* createAcademicYear(actions) {
  try {
    const response = yield axios({
      url: '/events-mgt/academic-years',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: academicYearActions.CREATE_ACADEMIC_YEAR_SUCCESS,
      data: response,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_REQUEST,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST,
    });
    yield put({
      type: tabActions.ACADEMIC_YEAR_TAB,
      activeTab: 'view-table',
    });
  } catch (error) {
    yield put({
      type: academicYearActions.CREATE_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* createSemester(actions) {
  try {
    const response = yield axios({
      url: '/events-mgt/semesters',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: academicYearActions.CREATE_NEW_SEMESTER_SUCCESS,
      data: response,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_REQUEST,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
    yield put({ type: tabActions.ACADEMIC_YEAR_TAB, activeTab: 'view-table' });
  } catch (error) {
    yield put({
      type: academicYearActions.CREATE_NEW_SEMESTER_ERROR,
      error: error.data,
    });
  }
}

function* updateAcademicYear(actions) {
  try {
    const response = yield axios({
      url: `/events-mgt/academic-years/${actions.contextId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: academicYearActions.UPDATE_ACADEMIC_YEAR_SUCCESS,
      data: response,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: academicYearActions.UPDATE_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* deleteAcademicYear(actions) {
  try {
    const response = yield axios({
      url: `/events-mgt/academic-years/${actions.contextId}`,
      method: 'DELETE',
    });
    yield put({
      type: academicYearActions.DELETE_ACADEMIC_YEAR_SUCCESS,
      data: response,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_REQUEST,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: academicYearActions.DELETE_ACADEMIC_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* updateSemesterData(actions) {
  try {
    const response = yield axios({
      url: `/events-mgt/semesters/${actions.semesterId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: academicYearActions.UPDATE_SEMESTER_DATA_SUCCESS,
      data: response,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: academicYearActions.UPDATE_SEMESTER_DATA_ERROR,
      error: error.data,
    });
  }
}

function* deleteSemesterData(actions) {
  try {
    const response = yield axios({
      url: `/events-mgt/semesters/${actions.semesterId}`,
      method: 'DELETE',
      data: actions.data,
    });
    yield put({
      type: academicYearActions.DELETE_SEMESTER_SUCCESS,
      data: response,
      semesterId: actions.semesterId,
    });
    yield put({
      type: academicYearActions.GET_ACADEMIC_YEARS_REQUEST,
    });
    yield put({ type: 'SHOW_DELETE_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: academicYearActions.DELETE_SEMESTER_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcademicYears() {
  yield takeLatest(
    academicYearActions.GET_ACADEMIC_YEARS_REQUEST,
    getAcademicYears
  );
}

function* watchGetAcademicYearsByCampus() {
  yield takeLatest(
    academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST,
    getAcademicYearsByCampus
  );
}

function* watchCreateAcademicYear() {
  yield takeLatest(
    academicYearActions.CREATE_ACADEMIC_YEAR_REQUEST,
    createAcademicYear
  );
}

function* watchCreateNewSemesterYear() {
  yield takeLatest(
    academicYearActions.CREATE_NEW_SEMESTER_REQUEST,
    createSemester
  );
}

function* watchUpdateAcademicYear() {
  yield takeLatest(
    academicYearActions.UPDATE_ACADEMIC_YEAR_REQUEST,
    updateAcademicYear
  );
}

function* watchDeleteAcademicYear() {
  yield takeLatest(
    academicYearActions.DELETE_ACADEMIC_YEAR_REQUEST,
    deleteAcademicYear
  );
}

function* watchUpdateSemesterData() {
  yield takeLatest(
    academicYearActions.UPDATE_SEMESTER_DATA_REQUEST,
    updateSemesterData
  );
}

function* watchDeleteSemesterData() {
  yield takeLatest(
    academicYearActions.DELETE_SEMESTER_REQUEST,
    deleteSemesterData
  );
}

export default [
  fork(watchGetAcademicYears),
  fork(watchGetAcademicYearsByCampus),
  fork(watchUpdateAcademicYear),
  fork(watchCreateAcademicYear),
  fork(watchCreateNewSemesterYear),
  fork(watchUpdateSemesterData),
  fork(watchDeleteSemesterData),
  fork(watchDeleteAcademicYear),
];
