import React from 'react';
import { FaSyncAlt } from 'react-icons/fa';
import SubmitButton from './SubmitButton';

function ReloadButton({ ...props }) {
  return (
    <SubmitButton
      iconBefore={<FaSyncAlt />}
      type="dashed"
      text="REFRESH"
      danger
      loadingText="Loading..."
      className="text-sm fw-bold"
      {...props}
    />
  );
}

export default ReloadButton;
