import update from 'immutability-helper';
import { transactionActions } from '../../actions';
import initialState from '../../initialState';

function searchTransaction(state = initialState.transaction, actions) {
  switch (actions.type) {
    case transactionActions.SEARCH_UNIVERSAL_TRANSACTION_REQUEST:
      return {
        ...state,
        searchUniversalError: {},
        searchUniversalSuccess: { ...actions.data, data: [] },
        searchingUniversal: true,
      };
    case transactionActions.SEARCH_UNIVERSAL_TRANSACTION_SUCCESS:
      return {
        ...state,
        searchUniversalSuccess: actions.data,
        searchingUniversal: false,
      };
    case transactionActions.SEARCH_UNIVERSAL_TRANSACTION_ERROR:
      return {
        ...state,
        searchUniversalError: actions.error,
        searchUniversalSuccess: { ...actions.payload, data: [] },
        searchingUniversal: false,
      };

    case transactionActions.GET_TRANSACTION_REPORTS_REQUEST:
      return {
        ...state,
        transactionReportError: {},
        gettingTransactionReports: true,
      };
    case transactionActions.GET_TRANSACTION_REPORTS_SUCCESS: {
      let { transactionReports } = state;
      const findContextIndex = transactionReports.findIndex(
        (context) =>
          context.payments_from === actions.context.payments_from &&
          context.payments_to === actions.context.payments_to &&
          context.transaction_category ===
            actions.context.transaction_category &&
          context.report_category === actions.context.report_category
      );

      if (findContextIndex === -1) {
        transactionReports = [
          ...transactionReports,
          { ...actions.context, data: actions.data },
        ];
      } else {
        transactionReports[findContextIndex] = {
          ...actions.context,
          data: actions.data,
        };
      }
      return {
        ...state,
        transactionReports,
        gettingTransactionReports: false,
      };
    }
    case transactionActions.GET_TRANSACTION_REPORTS_ERROR:
      return {
        ...state,
        transactionReportError: actions.error,
        gettingTransactionReports: false,
      };

    case transactionActions.GET_SPONSOR_PAYMENTS_REPORT_REQUEST:
      return {
        ...state,
        gettingSponsorReports: true,
        sponsorReportError: {},
      };
    case transactionActions.GET_SPONSOR_PAYMENTS_REPORT_SUCCESS: {
      let { sponsorReports } = state;
      const findContextIndex = sponsorReports.findIndex(
        (context) => context.contextYear === actions.context.contextYear
      );

      if (findContextIndex === -1) {
        sponsorReports = [
          ...sponsorReports,
          { ...actions.context, data: actions.data },
        ];
      } else {
        sponsorReports[findContextIndex] = {
          ...actions.context,
          data: actions.data,
        };
      }
      return {
        ...state,
        sponsorReports,
        gettingSponsorReports: false,
      };
    }
    case transactionActions.GET_SPONSOR_PAYMENTS_REPORT_ERROR:
      return {
        ...state,
        sponsorReportError: actions.error,
        gettingSponsorReports: false,
      };

    case transactionActions.GET_SPONSOR_ALLOCATIONS_UNIVERSAL_REQUEST:
      return {
        ...state,
        gettingSponsorAllocation: true,
        sponsorAllocationError: {},
      };
    case transactionActions.GET_SPONSOR_ALLOCATIONS_UNIVERSAL_SUCCESS:
      return {
        ...state,
        sponsorAllocation: actions.data,
        gettingSponsorAllocation: false,
      };

    case transactionActions.GET_SPONSOR_ALLOCATIONS_UNIVERSAL_ERROR:
      return {
        ...state,
        sponsorAllocationError: actions.error,
        gettingSponsorAllocation: false,
      };

    case transactionActions.GET_COLLECTION_REPORTS_REQUEST:
      return {
        ...state,
        getCollectionReportError: {},
        gettingCollectionReports: true,
      };
    case transactionActions.GET_COLLECTION_REPORTS_SUCCESS: {
      let { collectionReports } = state;

      const { reportCategory } = actions;

      const findIndex = collectionReports.findIndex(
        (report) => report.category === reportCategory
      );
      if (findIndex === -1) {
        collectionReports = [
          ...collectionReports,
          { category: reportCategory, data: actions.data },
        ];
      } else {
        collectionReports[findIndex] = {
          category: reportCategory,
          data: actions.data,
        };
      }
      return {
        ...state,
        collectionReports,
        gettingCollectionReports: false,
      };
    }
    case transactionActions.GET_COLLECTION_REPORTS_ERROR:
      return {
        ...state,
        getCollectionReportError: actions.error,
        gettingCollectionReports: false,
      };

    case transactionActions.GET_STUDENT_REVENUE_REPORTS_REQUEST:
      return {
        ...state,
        studentRevenueReportError: {},
        gettingRevenueReports: true,
      };
    case transactionActions.GET_STUDENT_REVENUE_REPORTS_SUCCESS: {
      const { studentRevenueReport } = state;

      const { context, data } = actions;

      const findIndex = studentRevenueReport.findIndex(
        (report) =>
          report.payments_to === context.payments_to &&
          report.payments_from === context.payments_from
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findIndex === -1) {
        newState = update(newState, {
          studentRevenueReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          studentRevenueReport: { [findIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingRevenueReports: false,
      };
    }
    case transactionActions.GET_STUDENT_REVENUE_REPORTS_ERROR:
      return {
        ...state,
        studentRevenueReportError: actions.error,
        gettingRevenueReports: false,
      };

    case transactionActions.GET_GLOBAL_REVENUE_REPORTS_REQUEST:
      return {
        ...state,
        globalRevenueReportError: {},
        gettingRevenueReports: true,
      };
    case transactionActions.GET_GLOBAL_REVENUE_REPORTS_SUCCESS: {
      const { globalRevenueReport } = state;

      const { context, data } = actions;

      const findIndex = globalRevenueReport.findIndex(
        (report) =>
          report.payments_to === context.payments_to &&
          report.payments_from === context.payments_from
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findIndex === -1) {
        newState = update(newState, {
          globalRevenueReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          globalRevenueReport: { [findIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingRevenueReports: false,
      };
    }
    case transactionActions.GET_GLOBAL_REVENUE_REPORTS_ERROR:
      return {
        ...state,
        globalRevenueReportError: actions.error,
        gettingRevenueReports: false,
      };

    case transactionActions.GET_DETAILED_GLOBAL_REVENUE_REPORTS_REQUEST:
      return {
        ...state,
        gettingRevenueReports: true,
        globalDetailsError: {},
      };
    case transactionActions.GET_DETAILED_GLOBAL_REVENUE_REPORTS_SUCCESS:
      return {
        ...state,
        globalDetailsReport: actions.data,
        gettingRevenueReports: false,
      };

    case transactionActions.GET_DETAILED_GLOBAL_REVENUE_REPORTS_ERROR:
      return {
        ...state,
        globalDetailsError: actions.error,
        gettingRevenueReports: false,
      };

    case transactionActions.DOWNLOAD_GLOBAL_REVENUE_REPORTS_REQUEST:
      return {
        ...state,
        downloadingRevenueReport: true,
        downloadError: {},
        downloadSuccess: {},
      };
    case transactionActions.DOWNLOAD_GLOBAL_REVENUE_REPORTS_SUCCESS: {
      return {
        ...state,
        downloadSuccess: actions.data,
        downloadingRevenueReport: false,
      };
    }
    case transactionActions.DOWNLOAD_GLOBAL_REVENUE_REPORTS_ERROR:
      return {
        ...state,
        downloadError: actions.error,
        downloadingRevenueReport: false,
      };

    case transactionActions.GET_MONTHLY_COLLECTIONS_REPORTS_REQUEST:
      return {
        ...state,
        gettingMonthlyCollections: true,
        monthlyCollectionError: {},
      };
    case transactionActions.GET_MONTHLY_COLLECTIONS_REPORTS_SUCCESS:
      return {
        ...state,
        monthlyCollections: actions.data,
        gettingMonthlyCollections: false,
      };

    case transactionActions.GET_MONTHLY_COLLECTIONS_REPORTS_ERROR:
      return {
        ...state,
        monthlyCollectionError: actions.error,
        gettingMonthlyCollections: false,
      };

    case transactionActions.GET_UNIVERSAL_PAYMENTS_REPORTS_REQUEST:
      return {
        ...state,
        getUniversalReportError: {},
        gettingUniversalReports: true,
      };
    case transactionActions.GET_UNIVERSAL_PAYMENTS_REPORTS_SUCCESS: {
      return {
        ...state,
        universalReports: actions.data,
        gettingUniversalReports: false,
      };
    }
    case transactionActions.GET_UNIVERSAL_PAYMENTS_REPORTS_ERROR:
      return {
        ...state,
        getUniversalReportError: actions.error,
        gettingUniversalReports: false,
      };

    case transactionActions.GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST: {
      if (actions.context.type === 'detailed') {
        return {
          ...state,
          getFinancialYrReportError: {},
          gettingFinancialYearReportDetails: true,
        };
      }
      return {
        ...state,
        getFinancialYrReportError: {},
        gettingFinancialYrReports: true,
      };
    }
    case transactionActions.GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_SUCCESS: {
      const { context, data } = actions;
      if (context.type === 'detailed') {
        return {
          ...state,
          financialYearReportDetails: data.data,
          gettingFinancialYearReportDetails: false,
        };
      }
      return {
        ...state,
        financialYearReports: data,
        gettingFinancialYrReports: false,
      };
    }
    case transactionActions.GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_ERROR:
      return {
        ...state,
        getFinancialYrReportError: actions.error,
        gettingFinancialYrReports: false,
        gettingFinancialYearReportDetails: false,
      };

    case transactionActions.GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST:
      return {
        ...state,
        generateFyReportError: {},
        generateFyReport: true,
      };
    case transactionActions.GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_SUCCESS: {
      return {
        ...state,
        generateFyReportSuccess: actions.data,
        generateFyReport: false,
      };
    }
    case transactionActions.GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_ERROR:
      return {
        ...state,
        generateFyReportError: actions.error,
        generateFyReport: false,
      };

    case transactionActions.GENERATE_SELECTED_QUARTER_REPORT_REQUEST:
      return {
        ...state,
        generateSelectedQuarterError: {},
        generatingSelectedQuarter: true,
      };
    case transactionActions.GENERATE_SELECTED_QUARTER_REPORT_SUCCESS: {
      return {
        ...state,
        generateQuarterReportSuccess: actions.data,
        generatingSelectedQuarter: false,
      };
    }
    case transactionActions.GENERATE_SELECTED_QUARTER_REPORT_ERROR:
      return {
        ...state,
        generateSelectedQuarterError: actions.error,
        generatingSelectedQuarter: false,
      };

    case transactionActions.GET_SELECTED_QUARTER_REPORT_REQUEST:
      return {
        ...state,
        getQuarterlyReportsError: {},
        gettingQuarterReport: true,
      };
    case transactionActions.GET_SELECTED_QUARTER_REPORT_SUCCESS: {
      const { quarterlyReports } = state;

      const { context, data } = actions;

      const findIndex = quarterlyReports.findIndex(
        (report) =>
          report.quarter === context.quarter &&
          report.financialYear === context.financialYear &&
          report.type === context.type
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findIndex === -1) {
        newState = update(newState, {
          quarterlyReports: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          quarterlyReports: { [findIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingQuarterReport: false,
      };
    }
    case transactionActions.GET_SELECTED_QUARTER_REPORT_ERROR:
      return {
        ...state,
        getQuarterlyReportsError: actions.error,
        gettingQuarterReport: false,
      };

    case transactionActions.GET_UNIVERSAL_REPORTS_DETAILS_REQUEST:
      return {
        ...state,
        getUniversalReportDetailError: {},
        gettingUniversalReportDetails: true,
      };
    case transactionActions.GET_UNIVERSAL_REPORTS_DETAILS_SUCCESS: {
      return {
        ...state,
        universalReportDetails: actions.data,
        gettingUniversalReportDetails: false,
      };
    }
    case transactionActions.GET_UNIVERSAL_REPORTS_DETAILS_ERROR:
      return {
        ...state,
        getUniversalReportDetailError: actions.error,
        gettingUniversalReportDetails: false,
      };

    case transactionActions.GET_DETAILED_PERIOD_REPORTS_REQUEST:
      return {
        ...state,
        getPeriodicReportError: {},
        gettingPeriodicReportDetails: true,
      };
    case transactionActions.GET_DETAILED_PERIOD_REPORTS_SUCCESS:
      return {
        ...state,
        periodicReportDetails: actions.data,
        gettingPeriodicReportDetails: false,
      };
    case transactionActions.GET_DETAILED_PERIOD_REPORTS_ERROR:
      return {
        ...state,
        getPeriodicReportError: actions.error,
        gettingPeriodicReportDetails: false,
      };

    case transactionActions.GET_FINANCIAL_YR_UNALLOCATED_REPORTS_REQUEST:
      return {
        ...state,
        getUnallocatedReportsError: {},
        gettingUnallocatedQuarterReport: true,
      };
    case transactionActions.GET_FINANCIAL_YR_UNALLOCATED_REPORTS_SUCCESS: {
      return {
        ...state,
        unAllocatedQuarterlyReports: actions.data,
        gettingUnallocatedQuarterReport: false,
      };
    }
    case transactionActions.GET_FINANCIAL_YR_UNALLOCATED_REPORTS_ERROR:
      return {
        ...state,
        getUnallocatedReportsError: actions.error,
        gettingUnallocatedQuarterReport: false,
      };

    case transactionActions.GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_REQUEST:
      return {
        ...state,
        getOpeningReceivableReportsError: {},
        gettingOpeningReceivablesReport: true,
      };
    case transactionActions.GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_SUCCESS: {
      return {
        ...state,
        openingReceivableReport: actions.data,
        gettingOpeningReceivablesReport: false,
      };
    }
    case transactionActions.GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_ERROR:
      return {
        ...state,
        getOpeningReceivableReportsError: actions.error,
        gettingOpeningReceivablesReport: false,
      };

    case transactionActions.GET_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST:
      return {
        ...state,
        getQuarterlyBillingError: {},
        gettingQuarterlyBillingReport: true,
      };
    case transactionActions.GET_QUARTERLY_BILLING_DETAILS_REPORTS_SUCCESS: {
      return {
        ...state,
        quarterlyBilling: actions.data,
        gettingQuarterlyBillingReport: false,
      };
    }
    case transactionActions.GET_QUARTERLY_BILLING_DETAILS_REPORTS_ERROR:
      return {
        ...state,
        getQuarterlyBillingError: actions.error,
        gettingQuarterlyBillingReport: false,
      };

    case transactionActions.GET_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST:
      return {
        ...state,
        getQuarterlyStudentReportError: {},
        gettingQuarterlyStudentReport: true,
      };
    case transactionActions.GET_QUARTERLY_STUDENT_DETAILS_REPORTS_SUCCESS: {
      return {
        ...state,
        quarterlyStudentReport: actions.data,
        gettingQuarterlyStudentReport: false,
      };
    }
    case transactionActions.GET_QUARTERLY_STUDENT_DETAILS_REPORTS_ERROR:
      return {
        ...state,
        getQuarterlyStudentReportError: actions.error,
        gettingQuarterlyStudentReport: false,
      };

    case transactionActions.DOWNLOAD_UNIVERSAL_REPORT_DETAIL_REQUEST:
      return {
        ...state,
        downloadedUniversalReport: {},
        downloadUniversalReportDetailError: {},
        downloadingperiodicReportDetails: true,
      };
    case transactionActions.DOWNLOAD_UNIVERSAL_REPORT_DETAIL_SUCCESS: {
      return {
        ...state,
        downloadedUniversalReport: actions.data,
        downloadingUniversalReportDetails: false,
      };
    }
    case transactionActions.DOWNLOAD_UNIVERSAL_REPORT_DETAIL_ERROR:
      return {
        ...state,
        downloadUniversalReportDetailError: actions.error,
        downloadingUniversalReportDetails: false,
      };

    case transactionActions.DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_REQUEST:
      return {
        ...state,
        downloadReportSummeryResponse: {},
        downloadReportSummeryError: {},
        downloadingReportSummeryDetails: true,
      };
    case transactionActions.DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_SUCCESS: {
      return {
        ...state,
        downloadReportSummeryResponse: actions.data,
        downloadingReportSummeryDetails: false,
      };
    }
    case transactionActions.DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_ERROR:
      return {
        ...state,
        downloadReportSummeryError: actions.error,
        downloadingReportSummeryDetails: false,
      };

    case transactionActions.DOWNLOAD_TRANSACTION_REPORT_DETAIL_REQUEST:
      return {
        ...state,
        downloadedTransactionReport: {},
        downloadTransactionReportDetailError: {},
        downloadingTransactionReportDetails: true,
      };
    case transactionActions.DOWNLOAD_TRANSACTION_REPORT_DETAIL_SUCCESS: {
      return {
        ...state,
        downloadedTransactionReport: actions.data,
        downloadingTransactionReportDetails: false,
      };
    }
    case transactionActions.DOWNLOAD_TRANSACTION_REPORT_DETAIL_ERROR:
      return {
        ...state,
        downloadTransactionReportDetailError: actions.error,
        downloadingTransactionReportDetails: false,
      };

    case transactionActions.DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_REQUEST:
      return {
        ...state,
        downloadedAccountTransactions: {},
        downloadAllAccountTransactionsError: {},
        downloadingAllAccountTransactions: true,
      };
    case transactionActions.DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_SUCCESS: {
      return {
        ...state,
        downloadedAccountTransactions: actions.data,
        downloadingAllAccountTransactions: false,
      };
    }
    case transactionActions.DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_ERROR:
      return {
        ...state,
        downloadAllAccountTransactionsError: actions.error,
        downloadingAllAccountTransactions: false,
      };

    case transactionActions.DOWNLOAD_DETAILED_PERIOD_REPORTS_REQUEST:
      return {
        ...state,
        downloadedPeriodicReport: {},
        downloadPeriodicReportError: {},
        downloadingPeriodicReport: true,
      };
    case transactionActions.DOWNLOAD_DETAILED_PERIOD_REPORTS_SUCCESS: {
      return {
        ...state,
        downloadedPeriodicReport: actions.data,
        downloadingPeriodicReport: false,
      };
    }
    case transactionActions.DOWNLOAD_DETAILED_PERIOD_REPORTS_ERROR:
      return {
        ...state,
        downloadPeriodicReportError: actions.error,
        downloadingPeriodicReport: false,
      };
    case transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_REQUEST:
      return {
        ...state,
        downloadedPeriodicReport: {},
        downloadPeriodicReportError: {},
        downloadingPeriodicReport: true,
      };
    case transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_SUCCESS: {
      return {
        ...state,
        downloadedPeriodicReport: actions.data,
        downloadingPeriodicReport: false,
      };
    }
    case transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_ERROR:
      return {
        ...state,
        downloadPeriodicReportError: actions.error,
        downloadingPeriodicReport: false,
      };
    case transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_REQUEST:
      return {
        ...state,
        downloadedPeriodicReport: {},
        downloadPeriodicReportError: {},
        downloadingPeriodicReport: true,
      };
    case transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_SUCCESS: {
      return {
        ...state,
        downloadedPeriodicReport: actions.data,
        downloadingPeriodicReport: false,
      };
    }
    case transactionActions.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_ERROR:
      return {
        ...state,
        downloadPeriodicReportError: actions.error,
        downloadingPeriodicReport: false,
      };
    case transactionActions.DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST:
      return {
        ...state,
        downloadedPeriodicReport: {},
        downloadPeriodicReportError: {},
        downloadingPeriodicReport: true,
      };
    case transactionActions.DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_SUCCESS: {
      return {
        ...state,
        downloadedPeriodicReport: actions.data,
        downloadingPeriodicReport: false,
      };
    }
    case transactionActions.DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_ERROR:
      return {
        ...state,
        downloadPeriodicReportError: actions.error,
        downloadingPeriodicReport: false,
      };

    case transactionActions.DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST:
      return {
        ...state,
        downloadedPeriodicReport: {},
        downloadPeriodicReportError: {},
        downloadingPeriodicReport: true,
      };
    case transactionActions.DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_SUCCESS: {
      return {
        ...state,
        downloadedPeriodicReport: actions.data,
        downloadingPeriodicReport: false,
      };
    }
    case transactionActions.DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_ERROR:
      return {
        ...state,
        downloadPeriodicReportError: actions.error,
        downloadingPeriodicReport: false,
      };

    case transactionActions.DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_REQUEST:
      return {
        ...state,
        downloadedPeriodicReport: {},
        downloadPeriodicReportError: {},
        downloadingPeriodicReport: true,
      };
    case transactionActions.DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_SUCCESS: {
      return {
        ...state,
        downloadedPeriodicReport: actions.data,
        downloadingPeriodicReport: false,
      };
    }
    case transactionActions.DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_ERROR:
      return {
        ...state,
        downloadPeriodicReportError: actions.error,
        downloadingPeriodicReport: false,
      };

    case transactionActions.DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_REQUEST:
      return {
        ...state,
        downloadedPeriodicReport: {},
        downloadPeriodicReportError: {},
        downloadingPeriodicReport: true,
      };
    case transactionActions.DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_SUCCESS: {
      return {
        ...state,
        downloadedPeriodicReport: actions.data,
        downloadingPeriodicReport: false,
      };
    }
    case transactionActions.DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_ERROR:
      return {
        ...state,
        downloadPeriodicReportError: actions.error,
        downloadingPeriodicReport: false,
      };

    case transactionActions.SELECTED_REPORT_CONTEXT:
      return {
        ...state,
        selectedReportContext: actions.context,
      };

    case transactionActions.SET_UNIVERSAL_REPORTS_CONTEXT:
      return {
        ...state,
        universalReportsContext: actions.context,
      };

    case transactionActions.SET_CURRENT_UNIVERSAL_REPORT:
      return {
        ...state,
        currentReport: actions.report,
      };

    case transactionActions.SET_SELECTED_FINANCIAL_YEAR_CONTEXT:
      return {
        ...state,
        selectedFinancialYear: actions.data,
      };

    default:
      return state;
  }
}

export default searchTransaction;
