import { uniqBy } from 'lodash';
import { studentEnrollmentActions } from '../../actions';
import initialState from '../../initialState';

const studentEnrollment = (state = initialState.studentEnrollment, actions) => {
  switch (actions.type) {
    case studentEnrollmentActions.ENROLL_STUDENT_REQUEST:
      return {
        ...state,
        enrollError: {},
        enrolling: true,
      };
    case studentEnrollmentActions.ENROLL_STUDENT_SUCCESS:
      return {
        ...state,
        enrolling: false,
        enrollSuccess: actions.data,
      };
    case studentEnrollmentActions.ENROLL_STUDENT_ERROR:
      return {
        ...state,
        enrollError: actions.error,
        enrolling: false,
      };

    case studentEnrollmentActions.DE_ENROLL_STUDENT_REQUEST:
      return {
        ...state,
        deEnrollError: {},
        deEnrolling: true,
      };
    case studentEnrollmentActions.DE_ENROLL_STUDENT_SUCCESS:
      return {
        ...state,
        deEnrolling: false,
        deEnrollSuccess: actions.data,
      };
    case studentEnrollmentActions.DE_ENROLL_STUDENT_ERROR:
      return {
        ...state,
        deEnrollError: actions.error,
        deEnrolling: false,
      };

    case studentEnrollmentActions.ADD_LATE_ENROLLMENT_REQUEST:
      return {
        ...state,
        enrollmentHistoryError: {},
        enrolling: true,
      };
    case studentEnrollmentActions.ADD_LATE_ENROLLMENT_SUCCESS:
      return {
        ...state,
        enrolling: false,
        enrollSuccess: actions.data,
      };
    case studentEnrollmentActions.ADD_LATE_ENROLLMENT_ERROR:
      return {
        ...state,
        enrollmentHistoryError: actions.error,
        enrolling: false,
      };

    case studentEnrollmentActions.GET_ENROLL_HISTORY_REQUEST:
      return {
        ...state,
        enrollmentHistoryError: {},
        fetching: true,
      };
    case studentEnrollmentActions.GET_ENROLL_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        allEnrollmentHistory: uniqBy(
          [
            {
              studentId: actions.studentId,
              studentProgrammeId: actions.studentProgrammeId,
              enrollmentHistory: actions.data,
            },
            ...state.allEnrollmentHistory,
          ],
          'studentProgrammeId'
        ),
      };
    case studentEnrollmentActions.GET_ENROLL_HISTORY_ERROR:
      return {
        ...state,
        enrollmentHistoryError: actions.error,
        fetching: false,
      };
    default:
      return state;
  }
};
export default studentEnrollment;
