import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { courseAssignmentActions } from '../../actions';

function* getDepartmentProgrammes(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/department-programmes',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getContextAssignments(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment/course-assignments',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_ERROR,
      error: error.data,
    });
  }
}

function* assignLecturersToCourse(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
      context: actions.context,
    });
    yield put({
      type: courseAssignmentActions.SET_SHOW_ADD_LECTURER_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_ERROR,
      error: error.data,
    });
  }
}

function* getAssignmentCourses(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/course-assignment/courses',
      method: 'GET',
      params: context,
    });
    yield put({
      type: courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_ERROR,
      error: error.data,
    });
  }
}

function* editCourseAssignmentResultCategories(actions) {
  try {
    const { context, data } = actions;

    const response = yield axios({
      url: `/course-assignment/edit-result-categories`,
      method: 'PUT',
      data,
    });

    yield put({
      type: courseAssignmentActions.EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_SUCCESS,
      data: response.data,
    });
    yield put(courseAssignmentActions.getContextAssignments(context));
    yield put(courseAssignmentActions.setShowEditResultCategoriesModal(false));
  } catch (error) {
    yield put({
      type: courseAssignmentActions.EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_ERROR,
      error: error.data,
    });
  }
}

function* addLecturersToAssignment(actions) {
  try {
    const { context, data, assignmentId } = actions;
    const response = yield axios({
      url: `/course-assignment/add-lecturer/${assignmentId}`,
      method: 'POST',
      data,
    });
    yield put({
      type: courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
      context,
    });
    yield put({
      type: courseAssignmentActions.SET_SHOW_ADD_LECTURER_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* editAssignmentLecturer(actions) {
  try {
    const { context, data, assignmentLectureId } = actions;
    const response = yield axios({
      url: `/course-assignment/edit-lecturer/${assignmentLectureId}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
      context,
    });
    yield put({
      type: courseAssignmentActions.SET_SHOW_ADD_LECTURER_MODAL,
      data: false,
    });
    yield put({
      type: courseAssignmentActions.SET_EDIT_ASSIGNMENT_LECTURER,
      editing: false,
      dataToEdit: {},
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_ERROR,
      error: error.data,
    });
  }
}

function* removeLecturersFromAssignment(actions) {
  try {
    const { context, data } = actions;
    const response = yield axios({
      url: `/course-assignment/remove-lecturers`,
      method: 'DELETE',
      data,
      params: context,
    });
    yield put({
      type: courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
      context,
    });
    yield put({
      type: courseAssignmentActions.SET_SHOW_DELETE_MODAL,
      data: false,
    });
  } catch (error) {
    yield put({
      type: courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_ERROR,
      error: error.data,
    });
  }
}

function* watchgetDepartmentProgrammes() {
  yield takeLatest(
    courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST,
    getDepartmentProgrammes
  );
}

function* watchGetContextAssignments() {
  yield takeLatest(
    courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST,
    getContextAssignments
  );
}

function* watchAssignLecturersToCourse() {
  yield takeLatest(
    courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_REQUEST,
    assignLecturersToCourse
  );
}

function* watchAddLecturersToAssignment() {
  yield takeLatest(
    courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST,
    addLecturersToAssignment
  );
}

function* watchEditAssignmentLecturer() {
  yield takeLatest(
    courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST,
    editAssignmentLecturer
  );
}

function* watchRemoveLecturersFromAssignment() {
  yield takeLatest(
    courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST,
    removeLecturersFromAssignment
  );
}

function* watchGetAssignmentCourses() {
  yield takeLatest(
    courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_REQUEST,
    getAssignmentCourses
  );
}

function* watchAddEditCourseAssignmentResultCategory() {
  yield takeLatest(
    courseAssignmentActions.EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST,
    editCourseAssignmentResultCategories
  );
}

export default [
  fork(watchgetDepartmentProgrammes),
  fork(watchAssignLecturersToCourse),
  fork(watchGetContextAssignments),
  fork(watchAddLecturersToAssignment),
  fork(watchEditAssignmentLecturer),
  fork(watchGetAssignmentCourses),
  fork(watchRemoveLecturersFromAssignment),
  fork(watchAddEditCourseAssignmentResultCategory),
];
