import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { lecturerCourseActions } from '../../actions';

function* getLecturerCourses(actions) {
  try {
    const response = yield axios({
      url: '/course-assignment/lecturer/courses',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_LECTURER_COURSES_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_LECTURER_COURSES_ERROR,
      error: error.data,
    });
  }
}

function* getCourseRegisteredStudents(actions) {
  try {
    const { courseAssignmentId } = actions;
    const response = yield axios({
      url: '/course-assignment/lecturer/students',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_STUDENTS_SUCCESS,
      data: response,
      courseAssignmentId,
    });
  } catch (error) {
    yield put({
      type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* getLecturerResultCategoryAliases(actions) {
  try {
    const { courseAssignmentId } = actions;

    const response = yield axios({
      url: `/course-assignment/lecturer/result-category-aliases/${courseAssignmentId}`,
      method: 'GET',
    });

    yield put({
      type: lecturerCourseActions.GET_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS,
      courseAssignmentId,
      data: response.data,
    });
    yield put({
      type: lecturerCourseActions.SET_SHOW_RESULT_CATEGORY_ALIAS,
      payload: false,
      aliasData: {},
    });
  } catch (error) {
    yield put({
      type: lecturerCourseActions.GET_LECTURER_RESULT_CATEGORY_ALIAS_ERROR,
      data: error.data,
    });
  }
}

function* computeResults(actions) {
  try {
    const { courseAssignmentId, data } = actions;

    const response = yield axios({
      url: `/course-assignment/lecturer/compute-results/${courseAssignmentId}`,
      method: 'POST',
      data,
    });

    yield put({
      type: lecturerCourseActions.COMPUTE_STUDENTS_RESULT_SUCCESS,
      courseAssignmentId,
      data: response.data,
    });
    yield put({
      type: lecturerCourseActions.SET_SHOW_RESULT_COMPUTE_FINAL_MARKS,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: lecturerCourseActions.COMPUTE_STUDENTS_RESULT_ERROR,
      data: error.data,
    });
  }
}

function* createLecturerResultCategoryAlias(actions) {
  try {
    const { courseAssignmentId, data } = actions;

    const response = yield axios({
      url: '/course-assignment/lecturer/result-category-aliases',
      method: 'POST',
      data,
    });

    yield put({
      type: lecturerCourseActions.CREATE_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS,
      courseAssignmentId,
      data: response.data,
    });
    yield put(
      lecturerCourseActions.getLecturerResultCategoryAliases(courseAssignmentId)
    );
  } catch (error) {
    yield put({
      type: lecturerCourseActions.CREATE_LECTURER_RESULT_CATEGORY_ALIAS_ERROR,
      data: error.data,
    });
  }
}

function* deleteLecturerResultCategoryAlias(actions) {
  try {
    const { courseAssignmentId, resultCategoryAliasIds } = actions;

    const response = yield axios({
      url: `/course-assignment/lecturer/result-category-aliases/delete-aliases`,
      method: 'DELETE',
      data: resultCategoryAliasIds,
    });

    yield put({
      type: lecturerCourseActions.DELETE_RESULT_CATEGORY_ALIAS_SUCCESS,
      courseAssignmentId,
      data: response.data,
    });
    yield put(
      lecturerCourseActions.getLecturerResultCategoryAliases(courseAssignmentId)
    );
    yield put(lecturerCourseActions.setSelectedResultAlias({}));
    yield put(
      lecturerCourseActions.setShowDeleteResultCategoryAliasModal(false)
    );
  } catch (error) {
    yield put({
      type: lecturerCourseActions.DELETE_RESULT_CATEGORY_ALIAS_ERROR,
      data: error.data,
    });
  }
}

function* watchDeleteLecturerResultCategoryAlias() {
  yield takeLatest(
    lecturerCourseActions.DELETE_RESULT_CATEGORY_ALIAS_REQUEST,
    deleteLecturerResultCategoryAlias
  );
}

function* watchCreateLecturerResultCategoryAlias() {
  yield takeLatest(
    lecturerCourseActions.CREATE_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST,
    createLecturerResultCategoryAlias
  );
}

function* editResultCategoryAlias(actions) {
  try {
    const { courseAssignmentId, data, resultCategoryAliasId } = actions;

    const response = yield axios({
      url: `/course-assignment/lecturer/result-category-aliases/edit-aliases/${resultCategoryAliasId}`,
      method: 'PUT',
      data,
    });

    yield put({
      type: lecturerCourseActions.EDIT_RESULT_CATEGORY_ALIAS_SUCCESS,
      courseAssignmentId,
      data: response.data,
    });
    yield put(
      lecturerCourseActions.getLecturerResultCategoryAliases(courseAssignmentId)
    );
  } catch (error) {
    yield put({
      type: lecturerCourseActions.EDIT_RESULT_CATEGORY_ALIAS_ERROR,
      data: error.data,
    });
  }
}

function* watchEditResultCategoryAlias() {
  yield takeLatest(
    lecturerCourseActions.EDIT_RESULT_CATEGORY_ALIAS_REQUEST,
    editResultCategoryAlias
  );
}

function* watchGetLecturerResultCategoryAliases() {
  yield takeLatest(
    lecturerCourseActions.GET_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST,
    getLecturerResultCategoryAliases
  );
}

function* watchComputeResults() {
  yield takeLatest(
    lecturerCourseActions.COMPUTE_STUDENTS_RESULT_REQUEST,
    computeResults
  );
}

function* getRawLecturerCourseAssignmentResults(actions) {
  try {
    const { courseAssignmentId, params } = actions;
    const response = yield axios({
      url: `/course-assignment/lecturer/results/${courseAssignmentId}`,
      method: 'GET',
      params,
    });
    yield put({
      type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_SUCCESS,
      data: response.data,
      courseAssignmentId,
      resultCategory: params.result_category,
    });
  } catch (error) {
    yield put({
      type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRawLecturerCourseAssignmentResults() {
  yield takeLatest(
    lecturerCourseActions.GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_REQUEST,
    getRawLecturerCourseAssignmentResults
  );
}

function* watchGetLecturerCourses() {
  yield takeLatest(
    lecturerCourseActions.GET_COURSE_ASSIGNMENT_LECTURER_COURSES_REQUEST,
    getLecturerCourses
  );
}

function* watchGetCourseRegisteredStudents() {
  yield takeLatest(
    lecturerCourseActions.GET_COURSE_ASSIGNMENT_STUDENTS_REQUEST,
    getCourseRegisteredStudents
  );
}

export default [
  fork(watchGetLecturerCourses),
  fork(watchGetCourseRegisteredStudents),
  fork(watchGetRawLecturerCourseAssignmentResults),
  fork(watchGetLecturerResultCategoryAliases),
  fork(watchCreateLecturerResultCategoryAlias),
  fork(watchEditResultCategoryAlias),
  fork(watchDeleteLecturerResultCategoryAlias),
  fork(watchComputeResults),
];
