const paymentReports = {
  GET_PAYMENTS_REPORTS_REQUEST: 'GET_PAYMENTS_REPORTS_REQUEST',
  GET_PAYMENTS_REPORTS_SUCCESS: 'GET_PAYMENTS_REPORTS_SUCCESS',
  GET_PAYMENTS_REPORTS_ERROR: 'GET_PAYMENTS_REPORTS_ERROR',

  GET_DETAILED_REPORTS_REQUEST: 'GET_DETAILED_PAYMENTS_REPORTS_REQUEST',
  GET_DETAILED_REPORTS_SUCCESS: 'GET_DETAILED_PAYMENTS_REPORTS_SUCCESS',
  GET_DETAILED_REPORTS_ERROR: 'GET_DETAILED_PAYMENTS_REPORTS_ERROR',

  GET_ACADEMIC_UNIT_REPORTS_REQUEST: 'GET_ACADEMIC_UNIT_REPORTS_REQUEST',
  GET_ACADEMIC_UNIT_REPORTS_SUCCESS: 'GET_ACADEMIC_UNIT_REPORTS_SUCCESS',
  GET_ACADEMIC_UNIT_REPORTS_ERROR: 'GET_ACADEMIC_UNIT_REPORTS_ERROR',

  GET_CAMPUS_REPORT_REQUEST: 'GET_CAMPUS_REPORT_REQUEST',
  GET_CAMPUS_REPORT_SUCCESS: 'GET_CAMPUS_REPORT_SUCCESS',
  GET_CAMPUS_REPORT_ERROR: 'GET_CAMPUS_REPORT_ERROR',

  GET_FEES_DEPOSIT_REPORT_REQUEST: 'GET_FEES_DEPOSIT_REPORT_REQUEST',
  GET_FEES_DEPOSIT_REPORT_SUCCESS: 'GET_FEES_DEPOSIT_REPORT_SUCCESS',
  GET_FEES_DEPOSIT_REPORT_ERROR: 'GET_FEES_DEPOSIT_REPORT_ERROR',

  GET_FINANCIAL_YEAR_REPORT_REQUEST: 'GET_FINANCIAL_YEAR_REPORT_REQUEST',
  GET_FINANCIAL_YEAR_REPORT_SUCCESS: 'GET_FINANCIAL_YEAR_REPORT_SUCCESS',
  GET_FINANCIAL_YEAR_REPORT_ERROR: 'GET_FINANCIAL_YEAR_REPORT_ERROR',

  GET_FINANCIAL_YEAR_REPORT_BY_PROG_REQUEST:
    'GET_FINANCIAL_YEAR_REPORT_BY_PROG_REQUEST',
  GET_FINANCIAL_YEAR_REPORT_BY_PROG_SUCCESS:
    'GET_FINANCIAL_YEAR_REPORT_BY_PROG_SUCCESS',
  GET_FINANCIAL_YEAR_REPORT_BY_PROG_ERROR:
    'GET_FINANCIAL_YEAR_REPORT_BY_PROG_ERROR',

  GET_STUDENTS_PAYMENT_RECORDS_REQUEST: 'GET_STUDENTS_PAYMENT_RECORDS_REQUEST',
  GET_STUDENTS_PAYMENT_RECORDS_SUCCESS: 'GET_STUDENTS_PAYMENT_RECORDS_SUCCESS',
  GET_STUDENTS_PAYMENT_RECORDS_ERROR: 'GET_STUDENTS_PAYMENT_RECORDS_ERROR',

  GET_DETAILED_PREPAYMENT_REPORT_REQUEST:
    'GET_DETAILED_PREPAYMENT_REPORT_REQUEST',
  GET_DETAILED_PREPAYMENT_REPORT_SUCCESS:
    'GET_DETAILED_PREPAYMENT_REPORT_SUCCESS',
  GET_DETAILED_PREPAYMENT_REPORT_ERROR: 'GET_DETAILED_PREPAYMENT_REPORT_ERROR',

  DOWNLOAD_PREPAYMENT_REPORT_REQUEST: 'DOWNLOAD_PREPAYMENT_REPORT_REQUEST',
  DOWNLOAD_PREPAYMENT_REPORT_SUCCESS: 'DOWNLOAD_PREPAYMENT_REPORT_SUCCESS',
  DOWNLOAD_PREPAYMENT_REPORT_ERROR: 'DOWNLOAD_PREPAYMENT_REPORT_ERROR',

  DOWNLOAD_FINANCIAL_YEAR_REPORT_REQUEST:
    'DOWNLOAD_FINANCIAL_YEAR_REPORT_REQUEST',
  DOWNLOAD_FINANCIAL_YEAR_SUCCESS: 'DOWNLOAD_FINANCIAL_YEAR_SUCCESS',
  DOWNLOAD_FINANCIAL_YEAR_ERROR: 'DOWNLOAD_FINANCIAL_YEAR_ERROR',

  STREAM_FINANCIAL_YEAR_REPORT_REQUEST: 'STREAM_FINANCIAL_YEAR_REPORT_REQUEST',
  STREAM_FINANCIAL_YEAR_REPORT_SUCCESS: 'STREAM_FINANCIAL_YEAR_REPORT_SUCCESS',
  STREAM_FINANCIAL__YEAR_REPORT_ERROR: 'STREAM_FINANCIAL__YEAR_REPORT_ERROR',

  GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_REQUEST:
    'GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_REQUEST',
  GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_SUCCESS:
    'GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_SUCCESS',
  GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_ERROR:
    'GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_ERROR',

  DOWNLOAD_FINANCIAL_YEAR_FILE_REQUEST: 'DOWNLOAD_FINANCIAL_YEAR_FILE_REQUEST',
  DOWNLOAD_FINANCIAL_YEAR_FILE_SUCCESS: 'DOWNLOAD_FINANCIAL_YEAR_FILE_SUCCESS',
  DOWNLOAD_FINANCIAL_YEAR_FILE_ERROR: 'DOWNLOAD_FINANCIAL_YEAR_FILE_ERROR',

  DELETE_FINANCIAL_YEAR_FILE_REQUEST: 'DELETE_FINANCIAL_YEAR_FILE_REQUEST',
  DELETE_FINANCIAL_YEAR_FILE_SUCCESS: 'DELETE_FINANCIAL_YEAR_FILE_SUCCESS',
  DELETE_FINANCIAL_YEAR_FILE_ERROR: 'DELETE_FINANCIAL_YEAR_FILE_ERROR',

  DOWNLOAD_DETAILED_PAYMENT_REPORT_REQUEST:
    'DOWNLOAD_DETAILED_PAYMENT_REPORT_REQUEST',
  DOWNLOAD_DETAILED_PAYMENT_REPORT_SUCCESS:
    'DOWNLOAD_DETAILED_PAYMENT_REPORT_SUCCESS',
  DOWNLOAD_DETAILED_PAYMENT_REPORT_ERROR:
    'DOWNLOAD_DETAILED_PAYMENT_REPORT_ERROR',

  DOWNLOAD_PAYMENT_REPORT_REQUEST: 'DOWNLOAD_PAYMENT_REPORT_REQUEST',
  DOWNLOAD_PAYMENT_REPORT_SUCCESS: 'DOWNLOAD_PAYMENT_REPORT_SUCCESS',
  DOWNLOAD_PAYMENT_REPORT_ERROR: 'DOWNLOAD_PAYMENT_REPORT_ERROR',

  DOWNLOAD_FACULTY_PAYMENT_REPORT_REQUEST:
    'DOWNLOAD_FACULTY_PAYMENT_REPORT_REQUEST',
  DOWNLOAD_FACULTY_PAYMENT_REPORT_SUCCESS:
    'DOWNLOAD_FACULTY_PAYMENT_REPORT_SUCCESS',
  DOWNLOAD_FACULTY_PAYMENT_REPORT_ERROR:
    'DOWNLOAD_FACULTY_PAYMENT_REPORT_ERROR',

  GET_DETAILED_PER_PROGRAMME_REPORT_REQUEST:
    'GET_DETAILED_PAYMENTS_PER_PROGRAMME_REPORT_REQUEST',
  GET_DETAILED_PER_PROGRAMME_REPORT_SUCCESS:
    'GET_DETAILED_PAYMENTS_PER_PROGRAMME_REPORT_SUCCESS',
  GET_DETAILED_PER_PROGRAMME_REPORT_ERROR:
    'GET_DETAILED_PAYMENTS_PER_PROGRAMME_REPORT_ERROR',

  DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_REQUEST:
    'DOWNLOAD_DETAILED_PAYMENTS_PER_PROGRAMME_REPORT_REQUEST',
  DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_SUCCESS:
    'DOWNLOAD_DETAILED_PAYMENTS_PER_PROGRAMME_REPORT_SUCCESS',
  DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_ERROR:
    'DOWNLOAD_DETAILED_PAYMENTS_PER_PROGRAMME_REPORT_ERROR',

  SET_REPORTS_CONTEXT: 'SET_REPORTS_CONTEXT',
  SET_ACADEMIC_UNIT_REPORT_CONTEXT: 'SET_ACADEMIC_UNIT_REPORT_CONTEXT',
  SET_CAMPUS_REPORT_CONTEXT: 'SET_CAMPUS_REPORT_CONTEXT',
  SET_FEES_DEPOSIT_REPORT_CONTEXT: 'SET_FEES_DEPOSIT_REPORT_CONTEXT',

  setReportsContext: (data) => ({
    type: paymentReports.SET_REPORTS_CONTEXT,
    data,
  }),

  setAcademicUnitContext: (data) => ({
    type: paymentReports.SET_ACADEMIC_UNIT_REPORT_CONTEXT,
    data,
  }),

  setCampusReportContext: (data) => ({
    type: paymentReports.SET_CAMPUS_REPORT_CONTEXT,
    data,
  }),

  setFeesDepositReportContext: (data) => ({
    type: paymentReports.SET_FEES_DEPOSIT_REPORT_CONTEXT,
    data,
  }),

  getSummarizedReports: (data) => ({
    type: paymentReports.GET_PAYMENTS_REPORTS_REQUEST,
    data,
  }),

  getAcademicUnitReports: (data) => ({
    type: paymentReports.GET_ACADEMIC_UNIT_REPORTS_REQUEST,
    data,
  }),

  getCampusReports: (data) => ({
    type: paymentReports.GET_CAMPUS_REPORT_REQUEST,
    data,
  }),

  getFeesDepositReports: (data) => ({
    type: paymentReports.GET_FEES_DEPOSIT_REPORT_REQUEST,
    data,
  }),

  getFinancialYearReports: (reportCategory, data) => ({
    type: paymentReports.GET_FINANCIAL_YEAR_REPORT_REQUEST,
    reportCategory,
    data,
  }),

  getProgrammeFinancialYearReport: (data, programmeId) => ({
    type: paymentReports.GET_FINANCIAL_YEAR_REPORT_BY_PROG_REQUEST,
    data,
    programmeId,
  }),

  getDetailedReports: (data) => ({
    type: paymentReports.GET_DETAILED_REPORTS_REQUEST,
    data,
  }),

  getStudentsReportsRecords: (data) => ({
    type: paymentReports.GET_STUDENTS_PAYMENT_RECORDS_REQUEST,
    data,
  }),

  getDetailedPrepaymentReport: (data) => ({
    type: paymentReports.GET_DETAILED_PREPAYMENT_REPORT_REQUEST,
    data,
  }),

  getDetailedPerProgrammeReport: (data) => ({
    type: paymentReports.GET_DETAILED_PER_PROGRAMME_REPORT_REQUEST,
    data,
  }),

  downloadPerProgrammeReport: (data) => ({
    type: paymentReports.DOWNLOAD_DETAILED_PER_PROGRAMME_REPORT_REQUEST,
    data,
  }),

  downloadPrepaymentReports: (data) => ({
    type: paymentReports.DOWNLOAD_PREPAYMENT_REPORT_REQUEST,
    data,
  }),

  downloadFinancialYearReports: (data) => ({
    type: paymentReports.DOWNLOAD_FINANCIAL_YEAR_REPORT_REQUEST,
    data,
  }),

  streamFinancialYearReports: (data) => ({
    type: paymentReports.STREAM_FINANCIAL_YEAR_REPORT_REQUEST,
    data,
  }),

  generateSummaryReport: (data) => ({
    type: paymentReports.GENERATE_FINANCIAL_STATEMENT_SUMMARY_REPORT_REQUEST,
    data,
  }),

  downloadFinancialYearFile: (fileName) => ({
    type: paymentReports.DOWNLOAD_FINANCIAL_YEAR_FILE_REQUEST,
    fileName,
  }),

  deleteFinancialYearFile: (fileName, financialYearContext) => ({
    type: paymentReports.DELETE_FINANCIAL_YEAR_FILE_REQUEST,
    fileName,
    financialYearContext,
  }),

  downloadDetailedPaymentReports: (data) => ({
    type: paymentReports.DOWNLOAD_DETAILED_PAYMENT_REPORT_REQUEST,
    data,
  }),

  downloadFacultyPaymentReports: (data) => ({
    type: paymentReports.DOWNLOAD_FACULTY_PAYMENT_REPORT_REQUEST,
    data,
  }),

  downloadStudentPaymentReports: (data) => ({
    type: paymentReports.DOWNLOAD_PAYMENT_REPORT_REQUEST,
    data,
  }),
};

export default paymentReports;
