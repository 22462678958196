const financialTransaction = {
  GET_FINANCIAL_TRANSACTIONS_REQUEST: 'GET_FINANCIAL_TRANSACTIONS_REQUEST',
  GET_FINANCIAL_TRANSACTIONS_SUCCESS: 'GET_FINANCIAL_TRANSACTIONS_SUCCESS',
  GET_FINANCIAL_TRANSACTIONS_ERROR: 'GET_FINANCIAL_TRANSACTIONS_ERROR',

  CREATE_DIRECT_POST_REQUEST: 'CREATE_DIRECT_POST_REQUEST',
  CREATE_DIRECT_POST_SUCCESS: 'CREATE_DIRECT_POST_SUCCESS',
  CREATE_DIRECT_POST_ERROR: 'CREATE_DIRECT_POST_ERROR',

  UPDATE_DIRECT_POST_REQUEST: 'UPDATE_DIRECT_POST_REQUEST',
  UPDATE_DIRECT_POST_SUCCESS: 'UPDATE_DIRECT_POST_SUCCESS',
  UPDATE_DIRECT_POST_ERROR: 'UPDATE_DIRECT_POST_ERROR',

  UPLOAD_DIRECT_POST_TEMPLATE_REQUEST: 'UPLOAD_DIRECT_POST_TEMPLATE_REQUEST',
  UPLOAD_DIRECT_POST_TEMPLATE_SUCCESS: 'UPLOAD_DIRECT_POST_TEMPLATE_SUCCESS',
  UPLOAD_DIRECT_POST_TEMPLATE_ERROR: 'UPLOAD_DIRECT_POST_TEMPLATE_ERROR',

  DOWNLOAD_DIRECT_POST_TEMPLATE_REQUEST:
    'DOWNLOAD_DIRECT_POST_TEMPLATE_REQUEST',
  DOWNLOAD_DIRECT_POST_TEMPLATE_SUCCESS:
    'DOWNLOAD_DIRECT_POST_TEMPLATE_SUCCESS',
  DOWNLOAD_DIRECT_POST_TEMPLATE_ERROR: 'DOWNLOAD_DIRECT_POST_TEMPLATE_ERROR',

  UPLOAD_BULK_BILLING_TEMPLATE_REQUEST: 'UPLOAD_BULK_BILLING_TEMPLATE_REQUEST',
  UPLOAD_BULK_BILLING_TEMPLATE_SUCCESS: 'UPLOAD_BULK_BILLING_TEMPLATE_SUCCESS',
  UPLOAD_BULK_BILLING_TEMPLATE_ERROR: 'UPLOAD_BULK_BILLING_TEMPLATE_ERROR',

  DOWNLOAD_BULK_BILLING_TEMPLATE_REQUEST:
    'DOWNLOAD_BULK_BILLING_TEMPLATE_REQUEST',
  DOWNLOAD_BULK_BILLING_TEMPLATE_SUCCESS:
    'DOWNLOAD_BULK_BILLING_TEMPLATE_SUCCESS',
  DOWNLOAD_BULK_BILLING_TEMPLATE_ERROR: 'DOWNLOAD_BULK_BILLING_TEMPLATE_ERROR',

  ALLOCATE_TRANSACTION_REQUEST: 'ALLOCATE_TRANSACTION_REQUEST',
  ALLOCATE_TRANSACTION_SUCCESS: 'ALLOCATE_TRANSACTION_SUCCESS',
  ALLOCATE_TRANSACTION_ERROR: 'ALLOCATE_TRANSACTION_ERROR',

  TRANSFER_FUNDS_REQUEST: 'TRANSFER_FUNDS_REQUEST',
  TRANSFER_FUNDS_SUCCESS: 'TRANSFER_FUNDS_SUCCESS',
  TRANSFER_FUNDS_ERROR: 'TRANSFER_FUNDS_ERROR',

  GET_PENDING_FUND_TRANSFERS_REQUEST: 'GET_PENDING_FUND_TRANSFERS_REQUEST',
  GET_PENDING_FUND_TRANSFERS_SUCCESS: 'GET_PENDING_FUND_TRANSFERS_SUCCESS',
  GET_PENDING_FUND_TRANSFERS_ERROR: 'GET_PENDING_FUND_TRANSFERS_ERROR',

  DELETE_FUNDS_TRANSFER_REQUEST: 'DELETE_FUNDS_TRANSFER_REQUEST',
  DELETE_FUNDS_TRANSFER_SUCCESS: 'DELETE_FUNDS_TRANSFER_SUCCESS',
  DELETE_FUNDS_TRANSFER_ERROR: 'DELETE_FUNDS_TRANSFER_ERROR',

  APPROVE_FUNDS_TRANSFER_REQUEST: 'APPROVE_FUNDS_TRANSFER_REQUEST',
  APPROVE_FUNDS_TRANSFER_SUCCESS: 'APPROVE_FUNDS_TRANSFER_SUCCESS',
  APPROVE_FUNDS_TRANSFER_ERROR: 'APPROVE_FUNDS_TRANSFER_ERROR',

  getFinancialTransactions: (studentId, params) => ({
    type: financialTransaction.GET_FINANCIAL_TRANSACTIONS_REQUEST,
    studentId,
    params,
  }),

  createDirectPost: (data) => ({
    type: financialTransaction.CREATE_DIRECT_POST_REQUEST,
    data,
  }),

  updateDirectPost: (directDepositId, data) => ({
    type: financialTransaction.UPDATE_DIRECT_POST_REQUEST,
    directDepositId,
    data,
  }),

  uploadDirectPostTemplate: (data) => ({
    type: financialTransaction.UPLOAD_DIRECT_POST_TEMPLATE_REQUEST,
    data,
  }),

  downloadDirectPostTemplate: () => ({
    type: financialTransaction.DOWNLOAD_DIRECT_POST_TEMPLATE_REQUEST,
  }),

  uploadBulkBillingTemplate: (data) => ({
    type: financialTransaction.UPLOAD_BULK_BILLING_TEMPLATE_REQUEST,
    data,
  }),

  downloadBulkBillingTemplate: () => ({
    type: financialTransaction.DOWNLOAD_BULK_BILLING_TEMPLATE_REQUEST,
  }),

  allocateTransaction: (transactionId, data) => ({
    type: financialTransaction.ALLOCATE_TRANSACTION_REQUEST,
    transactionId,
    data,
  }),

  transferFunds: (data) => ({
    type: financialTransaction.TRANSFER_FUNDS_REQUEST,
    data,
  }),

  getPendingFundTransfers: () => ({
    type: financialTransaction.GET_PENDING_FUND_TRANSFERS_REQUEST,
  }),

  approvePendingFundTransfers: (data) => ({
    type: financialTransaction.APPROVE_FUNDS_TRANSFER_REQUEST,
    data,
  }),

  deletePendingFundTransfer: (id) => ({
    type: financialTransaction.DELETE_FUNDS_TRANSFER_REQUEST,
    id,
  }),
};

export default financialTransaction;
