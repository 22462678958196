const pendingDirectPost = {
  GET_PENDING_DIRECT_POST_REQUEST: 'GET_PENDING_DIRECT_POST_REQUEST',
  GET_PENDING_DIRECT_POST_SUCCESS: 'GET_PENDING_DIRECT_POST_SUCCESS',
  GET_PENDING_DIRECT_POST_ERROR: 'GET_PENDING_DIRECT_POST_ERROR',

  APPROVE_PENDING_DIRECT_POST_REQUEST: 'APPROVE_PENDING_DIRECT_POST_REQUEST',
  APPROVE_PENDING_DIRECT_POST_SUCCESS: 'APPROVE_PENDING_DIRECT_POST_SUCCESS',
  APPROVE_PENDING_DIRECT_POST_ERROR: 'APPROVE_PENDING_DIRECT_POST_ERROR',

  DELETE_PENDING_DIRECT_POST_REQUEST: 'DELETE_PENDING_DIRECT_POST_REQUEST',
  DELETE_PENDING_DIRECT_POST_SUCCESS: 'DELETE_PENDING_DIRECT_POST_SUCCESS',
  DELETE_PENDING_DIRECT_POST_ERROR: 'DELETE_PENDING_DIRECT_POST_ERROR',

  SET_PENDING_DIRECT_POST: 'SET_PENDING_DIRECT_POST',

  getPendingDirectPosts: (studentId) => ({
    type: pendingDirectPost.GET_PENDING_DIRECT_POST_REQUEST,
    studentId,
  }),

  approvePendingDirectPost: (data, studentId) => ({
    type: pendingDirectPost.APPROVE_PENDING_DIRECT_POST_REQUEST,
    data,
    studentId,
  }),

  deletePendingDirectPost: (data) => ({
    type: pendingDirectPost.DELETE_PENDING_DIRECT_POST_REQUEST,
    data,
  }),
};
export default pendingDirectPost;
