import update from 'immutability-helper';
import { registrationReportsActions } from '../../actions';
import initialState from '../../initialState';

const registrationReports = (
  state = initialState.registrationReports,
  actions
) => {
  switch (actions.type) {
    case registrationReportsActions.SET_REPORTS_CONTEXT:
      return {
        ...state,
        reportsContext: actions.data,
      };

    case registrationReportsActions.SET_STATISTICS_CONTEXT:
      return {
        ...state,
        statisticsContext: actions.data,
      };

    case registrationReportsActions.GET_SUMMARIZED_REPORTS_REQUEST:
      return {
        ...state,
        gettingSummarizedReports: true,
        gettingSummarizedReportsError: {},
      };

    case registrationReportsActions.GET_SUMMARIZED_REPORTS_SUCCESS: {
      const { responseData: data, requestData: context } = actions;
      const { summarizedReports } = state;

      let newState = state;

      const reportDataIndex = summarizedReports.findIndex(
        (record) =>
          record.academic_unit_id === context.academic_unit_id &&
          record.academic_year_id === context.academic_year_id &&
          record.campus_id === context.campus_id &&
          record.intake_id === context.intake_id &&
          record.programme_type_id === context.programme_type_id &&
          record.semester_id === context.semester_id
      );

      const reportContextData = {
        ...context,
        data,
      };

      if (reportDataIndex === -1) {
        newState = update(newState, {
          summarizedReports: { $push: [reportContextData] },
        });
      } else {
        newState = update(newState, {
          summarizedReports: {
            [reportDataIndex]: { $set: reportContextData },
          },
        });
      }

      return {
        ...newState,
        gettingSummarizedReports: false,
      };
    }

    case registrationReportsActions.GET_SUMMARIZED_REPORTS_ERROR:
      return {
        ...state,
        gettingSummarizedReportsError: actions.error,
        gettingSummarizedReports: false,
      };

    case registrationReportsActions.GET_DETAILED_REPORTS_REQUEST:
      return {
        ...state,
        gettingDetailedReportsError: {},
        gettingDetailedReports: true,
      };
    case registrationReportsActions.GET_DETAILED_REPORTS_SUCCESS: {
      const { data, reportContext: context } = actions;
      const { detailedReports } = state;

      let newState = state;

      const reportDataIndex = detailedReports.findIndex(
        (record) =>
          record.academic_unit_id === context.academic_unit_id &&
          record.academic_year_id === context.academic_year_id &&
          record.campus_id === context.campus_id &&
          record.intake_id === context.intake_id &&
          record.programme_type_id === context.programme_type_id &&
          record.semester_id === context.semester_id
      );

      const reportContextData = {
        ...context,
        data,
      };

      if (reportDataIndex === -1) {
        newState = update(newState, {
          detailedReports: { $push: [reportContextData] },
        });
      } else {
        newState = update(newState, {
          detailedReports: {
            [reportDataIndex]: { $set: reportContextData },
          },
        });
      }

      return {
        ...newState,
        gettingDetailedReports: false,
      };
    }

    case registrationReportsActions.GET_DETAILED_REPORTS_ERROR:
      return {
        ...state,
        gettingDetailedReportsError: actions.error,
        gettingDetailedReports: false,
      };

    case registrationReportsActions.GET_STUDENTS_REPORTS_RECORDS_REQUEST:
      return {
        ...state,
        loadError: {},
        gettingStudentReports: true,
      };
    case registrationReportsActions.GET_STUDENTS_REPORTS_RECORDS_SUCCESS: {
      const { data, programmeContext: context } = actions;

      const { studentsReportsRecords } = state;

      let newState = state;

      const programmeDataIndex = studentsReportsRecords.findIndex(
        (record) =>
          record.campus_id === context.campus_id &&
          record.programme_type_id === context.programme_type_id &&
          record.academic_year_id === context.academic_year_id &&
          record.intake_id === context.intake_id &&
          record.semester_id === context.semester_id &&
          record.programme_id === context.programme_id &&
          record.study_year_id === context.study_year_id &&
          record.academic_unit_id === context.academic_unit_id
      );

      const programeContextData = {
        ...context,
        data,
      };
      if (programmeDataIndex === -1) {
        newState = update(newState, {
          studentsReportsRecords: { $push: [programeContextData] },
        });
      } else {
        newState = update(newState, {
          studentsReportsRecords: {
            [programmeDataIndex]: { $set: programeContextData },
          },
        });
      }

      return {
        ...newState,
        gettingStudentReports: false,
      };
    }
    case registrationReportsActions.GET_STUDENTS_REPORTS_RECORDS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        gettingStudentReports: false,
      };

    case registrationReportsActions.DOWNLOAD_REGISTERED_STUDENTS_RECORDS_REQUEST:
      return {
        ...state,
        downloadingRegisteredStudents: true,
        downloadRegisteredError: {},
        downloadRegisteredSuccess: {},
      };
    case registrationReportsActions.DOWNLOAD_REGISTERED_STUDENTS_RECORDS_SUCCESS:
      return {
        ...state,
        downloadingRegisteredStudents: false,
        downloadRegisteredSuccess: actions.data,
      };
    case registrationReportsActions.DOWNLOAD_REGISTERED_STUDENTS_RECORDS_ERROR:
      return {
        ...state,
        downloadingRegisteredStudents: false,
        downloadRegisteredError: actions.error,
      };

    case registrationReportsActions.DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_REQUEST:
      return {
        ...state,
        downloadingUnRegisteredStudents: true,
        downloadUnRegisteredError: {},
      };
    case registrationReportsActions.DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_SUCCESS:
      return {
        ...state,
        downloadingUnRegisteredStudents: false,
        downloadUnRegisteredSuccess: actions.data,
      };
    case registrationReportsActions.DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_ERROR:
      return {
        ...state,
        downloadingUnRegisteredStudents: false,
        downloadUnRegisteredError: actions.error,
      };

    case registrationReportsActions.DOWNLOAD_FACULTY_REGISTRATION_RECORDS_REQUEST:
      return {
        ...state,
        downloadingFacultyReport: true,
        downloadFacultyReportError: {},
        downloadFacultyReportSuccess: {},
      };
    case registrationReportsActions.DOWNLOAD_FACULTY_REGISTRATION_RECORDS_SUCCESS:
      return {
        ...state,
        downloadingFacultyReport: false,
        downloadFacultyReportSuccess: actions.data,
      };
    case registrationReportsActions.DOWNLOAD_FACULTY_REGISTRATION_RECORDS_ERROR:
      return {
        ...state,
        downloadingFacultyReport: false,
        downloadFacultyReportError: actions.error,
      };

    case registrationReportsActions.GET_REGISTRATION_REPORT_SUMMARY_REQUEST:
      return {
        ...state,
        fetchingReportSummary: true,
        fetchReportSummaryError: {},
      };
    case registrationReportsActions.GET_REGISTRATION_REPORT_SUMMARY_SUCCESS: {
      const { responseData: data, requestData: context } = actions;
      const { reportSummary } = state;

      let newState = state;

      const reportDataIndex = reportSummary.findIndex(
        (record) =>
          record.academic_unit_id === context.academic_unit_id &&
          record.academic_year_id === context.academic_year_id &&
          record.campus_id === context.campus_id &&
          record.intake_id === context.intake_id &&
          record.programme_type_id === context.programme_type_id &&
          record.semester_id === context.semester_id
      );

      const reportContextData = {
        ...context,
        data,
      };

      if (reportDataIndex === -1) {
        newState = update(newState, {
          reportSummary: { $push: [reportContextData] },
        });
      } else {
        newState = update(newState, {
          reportSummary: {
            [reportDataIndex]: { $set: reportContextData },
          },
        });
      }

      return {
        ...newState,
        fetchingReportSummary: false,
      };
    }

    case registrationReportsActions.GET_REGISTRATION_REPORT_SUMMARY_ERROR:
      return {
        ...state,
        fetchingReportSummary: false,
        fetchReportSummaryError: actions.error,
      };

    case registrationReportsActions.GET_REGISTRATION_STATISTICS_REQUEST:
      return {
        ...state,
        gettingRegistrationStats: true,
        registrationStatistics: {},
        getRegistrationStatsError: {},
      };
    case registrationReportsActions.GET_REGISTRATION_STATISTICS_SUCCESS:
      return {
        ...state,
        registrationStatistics: actions.data,
        gettingRegistrationStats: false,
      };
    case registrationReportsActions.GET_REGISTRATION_STATISTICS_ERROR:
      return {
        ...state,
        getRegistrationStatsError: actions.data,
        gettingRegistrationStats: false,
      };

    case registrationReportsActions.DOWNLOAD_REGISTRATION_STATISTICS_REQUEST:
      return {
        ...state,
        downloadingStats: true,
        downloadStatsError: {},
        downloadStatsSuccess: {},
      };
    case registrationReportsActions.DOWNLOAD_REGISTRATION_STATISTICS_SUCCESS:
      return {
        ...state,
        downloadingStats: false,
        downloadStatsSuccess: actions.data,
      };
    case registrationReportsActions.DOWNLOAD_REGISTRATION_STATISTICS_ERROR:
      return {
        ...state,
        downloadingStats: false,
        downloadStatsError: actions.error,
      };

    default:
      return state;
  }
};
export default registrationReports;
