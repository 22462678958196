import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import {
  financialTransactionActions,
  pendingDirectPostActions,
} from '../../actions';

function* getFinancialTransactions(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/history/${actions.studentId}`,
      method: 'GET',
    });
    delete response.server;
    yield put({
      type: financialTransactionActions.GET_FINANCIAL_TRANSACTIONS_SUCCESS,
      data: response,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.GET_FINANCIAL_TRANSACTIONS_ERROR,
      error: error.data,
    });
  }
}

function* createDirectPost(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/direct-post`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: financialTransactionActions.CREATE_DIRECT_POST_SUCCESS,
      data: response,
    });
    yield put({
      type: financialTransactionActions.GET_FINANCIAL_TRANSACTIONS_REQUEST,
      studentId: actions.data.student_id,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.CREATE_DIRECT_POST_ERROR,
      error: error.data,
    });
  }
}

function* updateDirectPost(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/direct-post/${actions.directPostId}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: financialTransactionActions.UPDATE_DIRECT_POST_SUCCESS,
      data: response,
    });
    yield put({
      type: financialTransactionActions.GET_FINANCIAL_TRANSACTIONS_REQUEST,
      studentId: actions.data.student_id,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.UPDATE_DIRECT_POST_ERROR,
      error: error.data,
    });
  }
}

function* UploadDirectPostTemplate(actions) {
  try {
    const response = yield axios({
      url: '/registration/payment-transactions/upload-direct-post-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 600000,
    });
    yield put({
      type: financialTransactionActions.UPLOAD_DIRECT_POST_TEMPLATE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: pendingDirectPostActions.GET_PENDING_DIRECT_POST_REQUEST,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.UPLOAD_DIRECT_POST_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* downloadDirectPostTemplate() {
  try {
    yield axios({
      url: '/registration/payment-transactions/download-direct-post-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-DIRECT-POST-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: financialTransactionActions.DOWNLOAD_DIRECT_POST_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.DOWNLOAD_DIRECT_POST_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* uploadBulkBillingTemplate(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/graduation/upload-bulk-billing-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 600000,
    });
    yield put({
      type: financialTransactionActions.UPLOAD_BULK_BILLING_TEMPLATE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.UPLOAD_BULK_BILLING_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* downloadBulkBillingTemplate() {
  try {
    yield axios({
      url: '/results-mgt/graduation/download-bulk-billing-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-BULK-BILLING-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: financialTransactionActions.DOWNLOAD_BULK_BILLING_TEMPLATE_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.DOWNLOAD_BULK_BILLING_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* allocateTransaction(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-transactions/allocate-money-to-invoices/${actions.transactionId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: financialTransactionActions.ALLOCATE_TRANSACTION_SUCCESS,
      data: response,
    });
    yield put({
      type: financialTransactionActions.GET_FINANCIAL_TRANSACTIONS_REQUEST,
      studentId: actions.data.student_id,
    });
    yield put({
      type: 'SHOW_ALLOCATE_TRANSACTION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.ALLOCATE_TRANSACTION_ERROR,
      error: error.data,
    });
  }
}

function* transferFunds(actions) {
  try {
    const response = yield axios({
      url: `/registration/funds-transfer`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: financialTransactionActions.TRANSFER_FUNDS_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_TRANSFER_FUNDS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.TRANSFER_FUNDS_ERROR,
      error: error.data,
    });
  }
}

function* getPendingFundTransfers() {
  try {
    const response = yield axios({
      url: `/registration/funds-transfer`,
      method: 'GET',
    });
    yield put({
      type: financialTransactionActions.GET_PENDING_FUND_TRANSFERS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.GET_PENDING_FUND_TRANSFERS_ERROR,
      error: error.data,
    });
  }
}

function* approveFundTransfers(actions) {
  try {
    const response = yield axios({
      url: `/registration/funds-transfer/approve`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: financialTransactionActions.APPROVE_FUNDS_TRANSFER_SUCCESS,
      data: response,
    });
    yield put({
      type: financialTransactionActions.GET_PENDING_FUND_TRANSFERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.APPROVE_FUNDS_TRANSFER_ERROR,
      error: error.data,
    });
  }
}

function* deleteFundTransfer(actions) {
  try {
    const response = yield axios({
      url: `/registration/funds-transfer/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: financialTransactionActions.DELETE_FUNDS_TRANSFER_SUCCESS,
      data: response,
    });
    yield put({
      type: financialTransactionActions.GET_PENDING_FUND_TRANSFERS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: financialTransactionActions.DELETE_FUNDS_TRANSFER_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFinancialTransactions() {
  yield takeLatest(
    financialTransactionActions.GET_FINANCIAL_TRANSACTIONS_REQUEST,
    getFinancialTransactions
  );
}
function* watchCreateDirectPost() {
  yield takeLatest(
    financialTransactionActions.CREATE_DIRECT_POST_REQUEST,
    createDirectPost
  );
}
function* watchUpdateDirectPost() {
  yield takeLatest(
    financialTransactionActions.UPDATE_DIRECT_POST_REQUEST,
    updateDirectPost
  );
}

function* watchUploadDirectPostTemplate() {
  yield takeLatest(
    financialTransactionActions.UPLOAD_DIRECT_POST_TEMPLATE_REQUEST,
    UploadDirectPostTemplate
  );
}

function* watchDownloadDirectPostTemplate() {
  yield takeLatest(
    financialTransactionActions.DOWNLOAD_DIRECT_POST_TEMPLATE_REQUEST,
    downloadDirectPostTemplate
  );
}

function* watchUploadBulkBillingTemplate() {
  yield takeLatest(
    financialTransactionActions.UPLOAD_BULK_BILLING_TEMPLATE_REQUEST,
    uploadBulkBillingTemplate
  );
}

function* watchDownloadBulkBillingTemplate() {
  yield takeLatest(
    financialTransactionActions.DOWNLOAD_BULK_BILLING_TEMPLATE_REQUEST,
    downloadBulkBillingTemplate
  );
}

function* watchAllocateTransaction() {
  yield takeLatest(
    financialTransactionActions.ALLOCATE_TRANSACTION_REQUEST,
    allocateTransaction
  );
}

function* watchTransferFunds() {
  yield takeLatest(
    financialTransactionActions.TRANSFER_FUNDS_REQUEST,
    transferFunds
  );
}

function* watchGetPendingFundTransfers() {
  yield takeLatest(
    financialTransactionActions.GET_PENDING_FUND_TRANSFERS_REQUEST,
    getPendingFundTransfers
  );
}

function* watchApproveFundTransfers() {
  yield takeLatest(
    financialTransactionActions.APPROVE_FUNDS_TRANSFER_REQUEST,
    approveFundTransfers
  );
}

function* watchDeleteFundTransfer() {
  yield takeLatest(
    financialTransactionActions.DELETE_FUNDS_TRANSFER_REQUEST,
    deleteFundTransfer
  );
}

export default [
  fork(watchGetFinancialTransactions),
  fork(watchCreateDirectPost),
  fork(watchUpdateDirectPost),
  fork(watchUploadDirectPostTemplate),
  fork(watchDownloadDirectPostTemplate),
  fork(watchUploadBulkBillingTemplate),
  fork(watchDownloadBulkBillingTemplate),
  fork(watchAllocateTransaction),
  fork(watchTransferFunds),
  fork(watchGetPendingFundTransfers),
  fork(watchApproveFundTransfers),
  fork(watchDeleteFundTransfer),
];
