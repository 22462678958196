import { tuitionActions } from '../../actions';
import initialState from '../../initialState';

const tuition = (state = initialState.tuition, actions) => {
  switch (actions.type) {
    case tuitionActions.GET_TUITION_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };

    case tuitionActions.GET_TUITION_SUCCESS:
      return {
        ...state,
        tuition: actions.data.tuitionAmounts,
        error: {},
        loading: false,
      };
    case tuitionActions.GET_TUITION_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case tuitionActions.CREATE_TUITION_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };

    case tuitionActions.CREATE_TUITION_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case tuitionActions.CREATE_TUITION_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case tuitionActions.CREATE_FEES_ELEMENTS_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
      };
    case tuitionActions.CREATE_FEES_ELEMENTS_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };

    case tuitionActions.CREATE_FEES_ELEMENTS_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };

    case tuitionActions.ADD_TUITION_ELEMENTS_REQUEST:
      return {
        ...state,
        addingElement: true,
        error: {},
      };
    case tuitionActions.ADD_TUITION_ELEMENTS_SUCCESS:
      return {
        ...state,
        addingElement: false,
        addingElementSuccessResponse: actions.data,
      };
    case tuitionActions.ADD_TUITION_ELEMENTS_ERROR:
      return {
        ...state,
        addingElement: false,
        error: actions.error,
      };

    case tuitionActions.SET_TUITION_CONTEXT:
      return {
        ...state,
        tuitionContext: actions.context,
      };

    case tuitionActions.SET_SELECTED_TUITION_PROGRAMME_CONTEXT:
      return {
        ...state,
        selectedProgramme: actions.programme,
      };

    case tuitionActions.DELETE_TUITION_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };

    case tuitionActions.DELETE_TUITION_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };

    case tuitionActions.DELETE_TUITION_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case tuitionActions.UPDATE_TUITION_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };

    case tuitionActions.UPDATE_TUITION_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case tuitionActions.UPDATE_TUITION_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case tuitionActions.DOWNLOAD_TUITION_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateSuccess: {},
        downloadTemplateError: {},
      };
    case tuitionActions.DOWNLOAD_TUITION_SUCCESS:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateSuccess: actions.data,
      };
    case tuitionActions.DOWNLOAD_TUITION_ERROR:
      return {
        ...state,
        downloadingTemplate: false,
        downloadTemplateError: actions.error,
      };

    case tuitionActions.UPLOAD_TUITION_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadSuccess: {},
        uploadError: {},
      };
    case tuitionActions.UPLOAD_TUITION_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadSuccess: actions.data,
      };
    case tuitionActions.UPLOAD_TUITION_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case tuitionActions.GET_TUITION_EXEMPTIONS_REQUEST:
      return {
        ...state,
        gettingExemptions: true,
        tuitionExemptionsError: {},
      };
    case tuitionActions.GET_TUITION_EXEMPTIONS_SUCCESS:
      return {
        ...state,
        gettingExemptions: false,
        tuitionExemptions: actions.data,
      };
    case tuitionActions.GET_TUITION_EXEMPTIONS_ERROR:
      return {
        ...state,
        gettingExemptions: false,
        tuitionExemptionsError: actions.error,
      };

    case tuitionActions.ADD_TUITION_EXEMPTION_REQUEST:
      return {
        ...state,
        addingTuitionExemptions: true,
        addedTuitionExemptions: [],
        addTuitionExemptionsError: {},
      };
    case tuitionActions.ADD_TUITION_EXEMPTION_SUCCESS:
      return {
        ...state,
        addingTuitionExemptions: false,
        addedTuitionExemptions: actions.data,
      };
    case tuitionActions.ADD_TUITION_EXEMPTION_ERROR:
      return {
        ...state,
        addingTuitionExemptions: false,
        addTuitionExemptionsError: actions.error,
      };

    case tuitionActions.DELETE_TUITION_EXEMPTION_REQUEST:
      return {
        ...state,
        deletingTuitionExemption: true,
        deletedTuitionExemption: {},
        deleteTuitionExemptionsError: {},
      };
    case tuitionActions.DELETE_TUITION_EXEMPTION_SUCCESS:
      return {
        ...state,
        deletingTuitionExemption: false,
        deletedTuitionExemption: actions.data,
      };
    case tuitionActions.DELETE_TUITION_EXEMPTION_ERROR:
      return {
        ...state,
        deletingTuitionExemption: false,
        deleteTuitionExemptionsError: actions.error,
      };

    default:
      return state;
  }
};
export default tuition;
