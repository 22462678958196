const academicYearActions = {
  GET_ACADEMIC_YEARS_REQUEST: 'GET_ACADEMIC_YEARS_REQUEST',
  GET_ACADEMIC_YEARS_SUCCESS: 'GET_ACADEMIC_YEARS_SUCCESS',
  GET_ACADEMIC_YEARS_ERROR: 'GET_ACADEMIC_YEARS_ERROR',

  GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST: 'GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST',
  GET_ACADEMIC_YEARS_BY_CAMPUS_SUCCESS: 'GET_ACADEMIC_YEARS_BY_CAMPUS_SUCCESS',
  GET_ACADEMIC_YEARS_BY_CAMPUS_ERROR: 'GET_ACADEMIC_YEARS_BY_CAMPUS_ERROR',

  CREATE_ACADEMIC_YEAR_REQUEST: 'CREATE_ACADEMIC_YEAR_REQUEST',
  CREATE_ACADEMIC_YEAR_SUCCESS: 'CREATE_ACADEMIC_YEAR_SUCCESS',
  CREATE_ACADEMIC_YEAR_ERROR: 'CREATE_ACADEMIC_YEAR_ERROR',

  CREATE_NEW_SEMESTER_REQUEST: 'CREATE_NEW_SEMESTER_REQUEST',
  CREATE_NEW_SEMESTER_SUCCESS: 'CREATE_NEW_SEMESTER_SUCCESS',
  CREATE_NEW_SEMESTER_ERROR: 'CREATE_NEW_SEMESTER_ERROR',

  UPDATE_ACADEMIC_YEAR_REQUEST: 'UPDATE_ACADEMIC_YEAR_REQUEST',
  UPDATE_ACADEMIC_YEAR_SUCCESS: 'UPDATE_ACADEMIC_YEAR_SUCCESS',
  UPDATE_ACADEMIC_YEAR_ERROR: 'UPDATE_ACADEMIC_YEAR_ERROR',

  DELETE_ACADEMIC_YEAR_REQUEST: 'DELETE_ACADEMIC_YEAR_REQUEST',
  DELETE_ACADEMIC_YEAR_SUCCESS: 'DELETE_ACADEMIC_YEAR_SUCCESS',
  DELETE_ACADEMIC_YEAR_ERROR: 'DELETE_ACADEMIC_YEAR_ERROR',

  UPDATE_SEMESTER_DATA_REQUEST: 'UPDATE_SEMESTER_DATA_REQUEST',
  UPDATE_SEMESTER_DATA_SUCCESS: 'UPDATE_SEMESTER_DATA_SUCCESS',
  UPDATE_SEMESTER_DATA_ERROR: 'UPDATE_SEMESTER_DATA_ERROR',

  DELETE_SEMESTER_REQUEST: 'DELETE_SEMESTER_REQUEST',
  DELETE_SEMESTER_SUCCESS: 'DELETE_SEMESTER_SUCCESS',
  DELETE_SEMESTER_ERROR: 'DELETE_SEMESTER_ERROR',

  SET_SELECT_ACADEMIC_YEAR: 'SET_SELECT_ACADEMIC_YEAR',
  SET_ACADEMIC_YEAR_TO_EDIT: 'SET_ACADEMIC_YEAR_TO_EDIT',

  getAcademicYears: (params) => ({
    type: academicYearActions.GET_ACADEMIC_YEARS_REQUEST,
    params,
  }),

  getAcademicYearsByCampus: (params) => ({
    type: academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST,
    params,
  }),

  createAcademicYear: (data) => ({
    type: academicYearActions.CREATE_ACADEMIC_YEAR_REQUEST,
    data,
  }),

  createSemester: (data) => ({
    type: academicYearActions.CREATE_NEW_SEMESTER_REQUEST,
    data,
  }),

  updateAcademicYear: (contextId, data) => ({
    type: academicYearActions.UPDATE_ACADEMIC_YEAR_REQUEST,
    contextId,
    data,
  }),

  deleteAcademicYr: (contextId, data) => ({
    type: academicYearActions.DELETE_ACADEMIC_YEAR_REQUEST,
    contextId,
    data,
  }),

  updateSemesterData: (semesterId, data) => ({
    type: academicYearActions.UPDATE_SEMESTER_DATA_REQUEST,
    semesterId,
    data,
  }),

  setSelectAcademicYear: (data) => ({
    type: academicYearActions.SET_SELECT_ACADEMIC_YEAR,
    data,
  }),

  setEditAcadYrData: (dataToEdit) => ({
    type: academicYearActions.SET_ACADEMIC_YEAR_TO_EDIT,
    dataToEdit,
  }),

  deleteSemester: (semesterId) => ({
    type: academicYearActions.DELETE_SEMESTER_REQUEST,
    semesterId,
  }),
};

export default academicYearActions;
