import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { studentInvoiceActions } from '../../actions';

function* getStudentInvoices(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/all-invoices/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: studentInvoiceActions.GET_STUDENT_INVOICES_SUCCESS,
      data: response.data,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.GET_STUDENT_INVOICES_ERROR,
      error: error.data,
    });
  }
}

function* getStudentGraduationInvoices(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/graduation-fees/invoices/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: studentInvoiceActions.GET_STUDENT_GRADUATION_INVOICES_SUCCESS,
      data: response.element,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.GET_STUDENT_GRADUATION_INVOICES_ERROR,
      error: error.data,
    });
  }
}

function* getStudentUnpaidInvoices(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/show-unpaid-invoices/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: studentInvoiceActions.GET_STUDENT_UNPAID_INVOICES_SUCCESS,
      data: response.data,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.GET_STUDENT_UNPAID_INVOICES_ERROR,
      error: error.data,
    });
  }
}

function* getAllFeesElements(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/fees-elements/all-fees-elements-with-amounts/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: studentInvoiceActions.GET_ALL_FEES_ELEMENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.GET_ALL_FEES_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* createManualInvoice(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/manual-invoice',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentInvoiceActions.CREATE_MANUAL_INVOICE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentInvoiceActions.GET_STUDENT_INVOICES_REQUEST,
      studentId: actions.data.student_id,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.CREATE_MANUAL_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* createBulkManualInvoice(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/bulk-manual-invoice',
      method: 'POST',
      data: actions.data,
      timeout: 1200000,
    });
    yield put({
      type: studentInvoiceActions.CREATE_BULK_MANUAL_INVOICE_SUCCESS,
      data: response.invoicesCreated,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.CREATE_BULK_MANUAL_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* getStudentPaymentReferences(actions) {
  try {
    const response = yield axios({
      url: `/registration/payment-references/history/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: studentInvoiceActions.GET_STUDENT_PAYMENT_REFERENCES_SUCCESS,
      data: response.data,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.GET_STUDENT_PAYMENT_REFERENCES_ERROR,
      error: error.data,
    });
  }
}

function* generatePaymentReference(actions) {
  const getAxiosData = () => {
    if (actions.url === 'graduation-invoices') {
      return {
        url: `/registration/payment-references/graduation-fees-invoice-prn/${actions.data}`,
      };
    }
    return {
      url: `/registration/payment-references/${actions.url}/${actions.studentId}`,
      data: actions.data,
    };
  };

  try {
    const response = yield axios({
      ...getAxiosData(),
      method: 'POST',
    });
    yield put({
      type: studentInvoiceActions.GENERATE_PAYMENT_REFERENCE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentInvoiceActions.GET_STUDENT_PAYMENT_REFERENCES_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.GENERATE_PAYMENT_REFERENCE_ERROR,
      error: error.data,
    });
  }
}

function* getInvoiceElementDetails(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/${actions.url}/${actions.invoiceId}`,
      method: 'GET',
    });
    yield put({
      type: studentInvoiceActions.GET_INVOICE_ELEMENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.GET_INVOICE_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* offsetInvoice(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/allocate-money-to-invoice/${actions.invoiceId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentInvoiceActions.OFFSET_INVOICE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentInvoiceActions.GET_STUDENT_INVOICES_REQUEST,
      studentId: actions.data.student_id,
    });
    yield put({
      type: 'SHOW_OFFSET_INVOICE_MODAL',
      show: false,
      invoiceData: null,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.OFFSET_INVOICE_ERROR,
      error: error.data,
    });
  }
}

function* sendInvoiceOptionAction(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/${actions.url}/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentInvoiceActions.INVOICE_OPTION_ACTION_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentInvoiceActions.GET_STUDENT_INVOICES_REQUEST,
      studentId: actions.studentId,
    });
    yield put({
      type: 'SHOW_INVOICE_ACTIONS_MODAL',
      show: false,
      invoices: [],
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.INVOICE_OPTION_ACTION_ERROR,
      error: error.data,
    });
  }
}

function* uploadBulkInvoices(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/upload-bulk-manual-invoice-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 1000000,
    });
    yield put({
      type: studentInvoiceActions.UPLOAD_BULK_INVOICE_TEMPLATE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.UPLOAD_BULK_INVOICE_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadBulkInvoicesTemplate() {
  try {
    yield axios({
      url: '/registration/invoices/download-bulk-manual-invoice-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-BULK-INVOICE-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: studentInvoiceActions.DOWNLOAD_BULK_INVOICE_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: studentInvoiceActions.DOWNLOAD_BULK_INVOICE_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetStudentInvoices() {
  yield takeLatest(
    studentInvoiceActions.GET_STUDENT_INVOICES_REQUEST,
    getStudentInvoices
  );
}

function* watchGetStudentGraduationInvoices() {
  yield takeLatest(
    studentInvoiceActions.GET_STUDENT_GRADUATION_INVOICES_REQUEST,
    getStudentGraduationInvoices
  );
}

function* watchGetStudentUnpaidInvoices() {
  yield takeLatest(
    studentInvoiceActions.GET_STUDENT_UNPAID_INVOICES_REQUEST,
    getStudentUnpaidInvoices
  );
}

function* watchGetAllFeesElements() {
  yield takeLatest(
    studentInvoiceActions.GET_ALL_FEES_ELEMENTS_REQUEST,
    getAllFeesElements
  );
}

function* watchCreateManualInvoice() {
  yield takeLatest(
    studentInvoiceActions.CREATE_MANUAL_INVOICE_REQUEST,
    createManualInvoice
  );
}

function* watchCreateBulkManualInvoice() {
  yield takeLatest(
    studentInvoiceActions.CREATE_BULK_MANUAL_INVOICE_REQUEST,
    createBulkManualInvoice
  );
}

function* watchGetStudentPaymentReferences() {
  yield takeLatest(
    studentInvoiceActions.GET_STUDENT_PAYMENT_REFERENCES_REQUEST,
    getStudentPaymentReferences
  );
}

function* watchGeneratePaymentReference() {
  yield takeLatest(
    studentInvoiceActions.GENERATE_PAYMENT_REFERENCE_REQUEST,
    generatePaymentReference
  );
}

function* watchGetInvoiceElementDetails() {
  yield takeLatest(
    studentInvoiceActions.GET_INVOICE_ELEMENTS_REQUEST,
    getInvoiceElementDetails
  );
}

function* watchOffsetInvoice() {
  yield takeLatest(studentInvoiceActions.OFFSET_INVOICE_REQUEST, offsetInvoice);
}

function* watchSendInvoiceOptionAction() {
  yield takeLatest(
    studentInvoiceActions.INVOICE_OPTION_ACTION_REQUEST,
    sendInvoiceOptionAction
  );
}

function* watchUploadBulkInvoices() {
  yield takeLatest(
    studentInvoiceActions.UPLOAD_BULK_INVOICE_TEMPLATE_REQUEST,
    uploadBulkInvoices
  );
}
function* watchDownloadBulkInvoicesTemplate() {
  yield takeLatest(
    studentInvoiceActions.DOWNLOAD_BULK_INVOICE_TEMPLATE_REQUEST,
    downloadBulkInvoicesTemplate
  );
}

export default [
  fork(watchGetStudentInvoices),
  fork(watchGetStudentGraduationInvoices),
  fork(watchGetStudentUnpaidInvoices),
  fork(watchGetAllFeesElements),
  fork(watchCreateManualInvoice),
  fork(watchCreateBulkManualInvoice),
  fork(watchGetStudentPaymentReferences),
  fork(watchGeneratePaymentReference),
  fork(watchGetInvoiceElementDetails),
  fork(watchOffsetInvoice),
  fork(watchSendInvoiceOptionAction),
  fork(watchUploadBulkInvoices),
  fork(watchDownloadBulkInvoicesTemplate),
];
