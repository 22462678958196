import { resultsReportActions } from '../../actions';
import initialState from '../../initialState';

const resultReport = (state = initialState.resultReport, actions) => {
  switch (actions.type) {
    case resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_REQUEST:
      return {
        ...state,
        gettingResultsReport: true,
        reportError: {},
      };
    case resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_SUCCESS: {
      let { resultsReport } = state;
      const { context } = actions;
      const findIndex = resultsReport.findIndex(
        (report) => report.academic_year_id === context.academic_year_id
      );

      if (findIndex === -1) {
        resultsReport = [...resultsReport, { ...context, data: actions.data }];
      } else {
        resultsReport[findIndex] = { ...context, data: actions.data };
      }

      return {
        ...state,
        resultsReport,
        gettingResultsReport: false,
      };
    }
    case resultsReportActions.GET_RESULTS_UNIT_BI_REPORT_ERROR:
      return {
        ...state,
        reportError: actions.error,
        gettingResultsReport: false,
      };

    default:
      return state;
  }
};

export default resultReport;
