import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { financialStatementActions } from '../../actions';

function* getFinancialStatement(actions) {
  try {
    const response = yield axios({
      url: `/universal-payments/reports/financial-statement`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: financialStatementActions.GET_FINANCIAL_STATEMENT_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: financialStatementActions.GET_FINANCIAL_STATEMENT_ERROR,
      error: error.data,
    });
  }
}

function* downloadFinancialStatement(actions) {
  try {
    yield axios({
      url: `/universal-payments/reports/pdf-financial-statement`,
      method: 'POST',
      params: actions.params,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${actions?.params?.student || ''} STUDENT-FINANCIAL-STATEMENT.pdf`
      );
    });
    yield put({
      type: financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_SUCCESS,
      data: {
        server: { message: 'Financial Statement downloaded!', status: true },
      },
    });
  } catch (error) {
    yield put({
      type: financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetFinancialStatement() {
  yield takeLatest(
    financialStatementActions.GET_FINANCIAL_STATEMENT_REQUEST,
    getFinancialStatement
  );
}

function* watchDownloadFinancialStatement() {
  yield takeLatest(
    financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_REQUEST,
    downloadFinancialStatement
  );
}

export default [
  fork(watchGetFinancialStatement),
  fork(watchDownloadFinancialStatement),
];
