import update from 'immutability-helper';
import { receivableActions } from '../../actions';
import initialState from '../../initialState';

function receivable(state = initialState.receivables, actions) {
  switch (actions.type) {
    case receivableActions.GET_RECEIVABLES_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case receivableActions.GET_RECEIVABLES_SUCCESS:
      return {
        ...state,
        receivables: actions.data,
        loading: false,
      };
    case receivableActions.GET_RECEIVABLES_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case receivableActions.CREATE_RECEIVABLE_REQUEST:
      return {
        ...state,
        creating: true,
        createError: {},
        created: {},
      };
    case receivableActions.CREATE_RECEIVABLE_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };
    case receivableActions.CREATE_RECEIVABLE_ERROR:
      return {
        ...state,
        creating: false,
        createError: actions.error,
      };

    case receivableActions.UPDATE_RECEIVABLE_REQUEST:
      return {
        ...state,
        updating: true,
        updateError: {},
        updated: {},
      };
    case receivableActions.UPDATE_RECEIVABLE_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: actions.data,
      };
    case receivableActions.UPDATE_RECEIVABLE_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case receivableActions.GET_SINGLE_RECEIVABLE_REQUEST:
      return {
        ...state,
        gettingOne: true,
        getOneError: {},
        receivable: {},
      };
    case receivableActions.GET_SINGLE_RECEIVABLE_SUCCESS:
      return {
        ...state,
        gettingOne: false,
        receivable: actions.data,
      };
    case receivableActions.GET_SINGLE_RECEIVABLE_ERROR:
      return {
        ...state,
        gettingOne: false,
        getOneError: actions.error,
      };

    case receivableActions.DELETE_RECEIVABLE_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
        deleteResponse: {},
      };
    case receivableActions.DELETE_RECEIVABLE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case receivableActions.DELETE_RECEIVABLE_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case receivableActions.GET_RECEIVABLE_REPORT_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case receivableActions.GET_RECEIVABLE_REPORT_SUCCESS: {
      const { receivablesReport } = state;

      const { context, data } = actions;

      const findIndex = receivablesReport.findIndex(
        (report) =>
          report.payments_to === context.payments_to &&
          report.payments_from === context.payments_from &&
          report.account_name === context.account_name &&
          report.report_category === context.report_category
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findIndex === -1) {
        newState = update(newState, {
          receivablesReport: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          receivablesReport: { [findIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        loading: false,
      };
    }
    case receivableActions.GET_RECEIVABLE_REPORT_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case receivableActions.SET_CURRENT_RECEIVABLE_TAB:
      return {
        ...state,
        currentTab: actions.currentTab,
      };

    case receivableActions.SET_RECEIVABLES_REPORT_CONTEXT:
      return {
        ...state,
        receivablesContext: actions.context,
      };

    case receivableActions.SHOW_DELETE_RECEIVABLE_MODAL:
      return {
        ...state,
        showDeleteReceivableModal: actions.payload,
      };

    default:
      return state;
  }
}

export default receivable;
