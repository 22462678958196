import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { semesterEventActions, studentEnrollmentActions } from '../../actions';

function* enrollStudent(actions) {
  try {
    const response = yield axios({
      url: `/registration/enrollments/enroll-student/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentEnrollmentActions.ENROLL_STUDENT_SUCCESS,
      data: response,
    });
    yield put({
      type: studentEnrollmentActions.GET_ENROLL_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_ENROLLMENT_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentEnrollmentActions.ENROLL_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* deEnrollStudent(actions) {
  try {
    const response = yield axios({
      url: `/registration/enrollments/de-enroll/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentEnrollmentActions.DE_ENROLL_STUDENT_SUCCESS,
      data: response,
    });
    yield put({
      type: studentEnrollmentActions.GET_ENROLL_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_DE_ENROLL_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentEnrollmentActions.DE_ENROLL_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* studentLateEnrollment(actions) {
  try {
    const response = yield axios({
      url: `/registration/enrollments/late-enrollment/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentEnrollmentActions.ADD_LATE_ENROLLMENT_SUCCESS,
      data: response,
    });
    yield put({
      type: studentEnrollmentActions.GET_ENROLL_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_LATE_ENROLLMENT_MODAL',
      studentId: false,
    });
  } catch (error) {
    yield put({
      type: studentEnrollmentActions.ADD_LATE_ENROLLMENT_ERROR,
      error: error.data,
    });
  }
}

function* getEnrollmentHistory(actions) {
  try {
    const response = yield axios({
      url: `/registration/enrollments/history/${actions.studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: studentEnrollmentActions.GET_ENROLL_HISTORY_SUCCESS,
      data: response.data,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: studentEnrollmentActions.GET_ENROLL_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* watchEnrollStudent() {
  yield takeLatest(
    studentEnrollmentActions.ENROLL_STUDENT_REQUEST,
    enrollStudent
  );
}
function* watchDeEnrollStudent() {
  yield takeLatest(
    studentEnrollmentActions.DE_ENROLL_STUDENT_REQUEST,
    deEnrollStudent
  );
}
function* watchStudentLateEnrollment() {
  yield takeLatest(
    studentEnrollmentActions.ADD_LATE_ENROLLMENT_REQUEST,
    studentLateEnrollment
  );
}
function* watchEnrollmentHistory() {
  yield takeLatest(
    studentEnrollmentActions.GET_ENROLL_HISTORY_REQUEST,
    getEnrollmentHistory
  );
}

export default [
  fork(watchEnrollStudent),
  fork(watchDeEnrollStudent),
  fork(watchEnrollmentHistory),
  fork(watchStudentLateEnrollment),
];
