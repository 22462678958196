import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  authActions,
  roleGroupActions,
  systemUserActions,
} from '../../actions';

function* getUserData(actions) {
  try {
    const response = yield axios({
      url: '/users/user-data',
      method: 'GET',
      data: actions.data,
    });
    yield put({
      type: systemUserActions.GET_USERS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: systemUserActions.GET_USERS_ERROR,
      error: error.data,
    });
  }
}

function* createUser(actions) {
  try {
    const response = yield axios({
      url: '/users/user-data',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: systemUserActions.CREATE_USER_SUCCESS,
      data: response,
    });
    yield put({
      type: systemUserActions.GET_USERS_REQUEST,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_USERS_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
  } catch (error) {
    yield put({
      type: systemUserActions.CREATE_USER_ERROR,
      error: error.data,
    });
  }
}

function* userUpdate(actions) {
  try {
    const response = yield axios({
      url: `/users/user-data/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: systemUserActions.UPDATE_USER_SUCCESS,
      data: response,
    });
    yield put({
      type: roleGroupActions.GET_ROLE_GROUP_USERS_REQUEST,
      roleGroupId: actions.roleGroupId,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
    yield put({
      type: 'SHOW_EDIT_USER_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: systemUserActions.UPDATE_USER_ERROR,
      error: error.data,
    });
  }
}

function* getLecturers() {
  try {
    const response = yield axios({
      url: '/course-assignment/course-lecturers',
      method: 'GET',
    });
    yield put({
      type: systemUserActions.GET_LECTURERS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: systemUserActions.GET_LECTURERS_ERROR,
      error: error.data,
    });
  }
}

function* getDepartmentLecturers(actions) {
  try {
    const { departmentId } = actions;

    const response = yield axios({
      url: `/course-assignment/department-lecturers/${departmentId}`,
      method: 'GET',
    });
    yield put({
      type: systemUserActions.GET_DEPARTMENT_LECTURERS_SUCCESS,
      data: response.data,
      departmentId,
    });
  } catch (error) {
    yield put({
      type: systemUserActions.GET_DEPARTMENT_LECTURERS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetUserData() {
  yield takeLatest(systemUserActions.GET_USERS_REQUEST, getUserData);
}

function* watchCreateUser() {
  yield takeLatest(systemUserActions.CREATE_USER_REQUEST, createUser);
}

function* watchUserUpdate() {
  yield takeLatest(systemUserActions.UPDATE_USER_REQUEST, userUpdate);
}

function* watchGetLecturers() {
  yield takeLatest(systemUserActions.GET_LECTURERS_REQUEST, getLecturers);
}

function* watchGetDepartmentLecturers() {
  yield takeLatest(
    systemUserActions.GET_DEPARTMENT_LECTURERS_REQUEST,
    getDepartmentLecturers
  );
}

export default [
  fork(watchCreateUser),
  fork(watchGetUserData),
  fork(watchUserUpdate),
  fork(watchGetLecturers),
  fork(watchGetDepartmentLecturers),
];
