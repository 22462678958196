import resultReport from './result';
import academicRegistrar from './academicRegistrar';
import admissionReport from './admission';
import revenueReport from './revenue';

export default {
  resultReport,
  academicRegistrar,
  revenueReport,
  admissionReport,
};
