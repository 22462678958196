const creditNote = {
  GET_CREDIT_NOTE_REQUEST: 'GET_CREDIT_NOTE_REQUEST',
  GET_CREDIT_NOTE_SUCCESS: 'GET_CREDIT_NOTE_SUCCESS',
  GET_CREDIT_NOTE_ERROR: 'GET_CREDIT_NOTE_ERROR',

  GET_CREDIT_NOTE_REPORTS_REQUEST: 'GET_CREDIT_NOTE_REPORTS_REQUEST',
  GET_CREDIT_NOTE_REPORTS_SUCCESS: 'GET_CREDIT_NOTE_REPORTS_SUCCESS',
  GET_CREDIT_NOTE_REPORTS_ERROR: 'GET_CREDIT_NOTE_REPORTS_ERROR',

  GET_PENDING_CREDIT_NOTE_REQUEST: 'GET_PENDING_CREDIT_NOTE_REQUEST',
  GET_PENDING_CREDIT_NOTE_SUCCESS: 'GET_PENDING_CREDIT_NOTE_SUCCESS',
  GET_PENDING_CREDIT_NOTE_ERROR: 'GET_PENDING_CREDIT_NOTE_ERROR',

  GET_STUDENT_PENDING_CREDIT_NOTE_REQUEST:
    'GET_STUDENT_PENDING_CREDIT_NOTE_REQUEST',
  GET_STUDENT_PENDING_CREDIT_NOTE_SUCCESS:
    'GET_STUDENT_PENDING_CREDIT_NOTE_SUCCESS',
  GET_STUDENT_PENDING_CREDIT_NOTE_ERROR:
    'GET_STUDENT_PENDING_CREDIT_NOTE_ERROR',

  CREATE_CREDIT_NOTE_REQUEST: 'CREATE_CREDIT_NOTE_REQUEST',
  CREATE_CREDIT_NOTE_SUCCESS: 'CREATE_CREDIT_NOTE_SUCCESS',
  CREATE_CREDIT_NOTE_ERROR: 'CREATE_CREDIT_NOTE_ERROR',

  APPROVE_CREDIT_NOTE_REQUEST: 'APPROVE_CREDIT_NOTE_REQUEST',
  APPROVE_CREDIT_NOTE_SUCCESS: 'APPROVE_CREDIT_NOTE_SUCCESS',
  APPROVE_CREDIT_NOTE_ERROR: 'APPROVE_CREDIT_NOTE_ERROR',

  DECLINE_CREDIT_NOTE_REQUEST: 'DECLINE_CREDIT_NOTE_REQUEST',
  DECLINE_CREDIT_NOTE_SUCCESS: 'DECLINE_CREDIT_NOTE_SUCCESS',
  DECLINE_CREDIT_NOTE_ERROR: 'DECLINE_CREDIT_NOTE_ERROR',

  getCreditNotes: (studentId) => ({
    type: creditNote.GET_CREDIT_NOTE_REQUEST,
    studentId,
  }),

  getPendingCreditNotes: (studentId) => ({
    type: creditNote.GET_PENDING_CREDIT_NOTE_REQUEST,
    studentId,
  }),

  getStudentPendingCreditNotes: (studentId) => ({
    type: creditNote.GET_STUDENT_PENDING_CREDIT_NOTE_REQUEST,
    studentId,
  }),

  createCreditNote: (data, studentId) => ({
    type: creditNote.CREATE_CREDIT_NOTE_REQUEST,
    data,
    studentId,
  }),

  approveCreditNote: (data, studentId) => ({
    type: creditNote.APPROVE_CREDIT_NOTE_REQUEST,
    data,
    studentId,
  }),

  declineCreditNotes: (data, studentId) => ({
    type: creditNote.DECLINE_CREDIT_NOTE_REQUEST,
    data,
    studentId,
  }),

  getCreditNoteReport: (data) => ({
    type: creditNote.GET_CREDIT_NOTE_REPORTS_REQUEST,
    data,
  }),
};
export default creditNote;
