import { map, max, uniq } from 'lodash';
import moment from 'moment/moment';
import React from 'react';
import { Card } from 'react-bootstrap';
import AntDTable from './AntDTable';

function TimeSchedular({ ...props }) {
  const renderRow = (rowData, day) => {
    const findDayData = rowData?.data[day];
    const rowSpan = max(map(findDayData, 'span'));
    const duration = moment().diff(moment().add(rowSpan, 'hour'), 'hours') * -1;

    if (findDayData) {
      return (
        <Card
          body
          className="border-0 bg-transparent text-sm text-center h-100"
        >
          <div>
            <small>{uniq(map(findDayData, 'course_unit')).join(', ')}</small>
          </div>
          <div>
            <small className="fw-bold text-muted">
              {uniq(map(findDayData, 'lecturer')).join(', ')}
            </small>
          </div>
          <div>
            <strong>{uniq(map(findDayData, 'room')).join(', ')}</strong>
          </div>

          <small className="fw-bold text-primary">
            Duration: {`${duration}Hr${duration === 1 ? '' : 's'}`}
          </small>
        </Card>
      );
    }
    return '--x--';
  };

  const getRowSpan = (rowData, day) => {
    const findDayData = rowData?.data[day];

    let rowSpan = 1;
    let className = '';
    if (findDayData) {
      rowSpan = max(map(findDayData, 'span'));
      className = 'bg-light';
    }

    return {
      rowSpan,
      className,
    };
  };

  return (
    <AntDTable
      hideIndex
      bordered
      columns={[
        {
          title: 'TIME',
          key: 'time',
          fixed: 'left',
          dataIndex: 'start',
          width: 100,
          align: 'right',
          render: (start, row) => `${start} - ${row.end}`,
          className: 'text-sm fw-bold text-danger bg-gray',
          onHeaderCell: () => ({
            className: 'bg-white text-center fw-bold',
          }),
          onCell: () => ({
            className: 'border fw-bold',
          }),
          rowScope: 'row',
        },
        {
          title: 'MONDAY',
          key: 'monday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'MONDAY'),
          onCell: (rowData) => getRowSpan(rowData, 'MONDAY'),
          className: 'p-0',
        },
        {
          title: 'TUESDAY',
          key: 'tuesday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'TUESDAY'),
          onCell: (rowData) => getRowSpan(rowData, 'TUESDAY'),
          className: 'p-0',
        },
        {
          title: 'WEDNESDAY',
          key: 'wednesday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'WEDNESDAY'),
          onCell: (rowData) => getRowSpan(rowData, 'WEDNESDAY'),
          className: 'p-0',
        },
        {
          title: 'THURSDAY',
          key: 'thrusday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'THURSDAY'),
          onCell: (rowData) => getRowSpan(rowData, 'THURSDAY'),
          className: 'p-0',
        },
        {
          title: 'FRIDAY',
          key: 'friday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'FRIDAY'),
          onCell: (rowData) => getRowSpan(rowData, 'FRIDAY'),
          className: 'p-0',
        },
        {
          title: 'SATURDAY',
          key: 'saturday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'SATURDAY'),
          onCell: (rowData) => getRowSpan(rowData, 'SATURDAY'),
          className: 'p-0',
        },
        {
          title: 'SUNDAY',
          key: 'sunday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'SUNDAY'),
          onCell: (rowData) => getRowSpan(rowData, 'SUNDAY'),
          className: 'p-0',
        },
      ]}
      rowKey="id"
      {...props}
    />
  );
}

TimeSchedular.propTypes = {};

export default TimeSchedular;
