const institutionPolicy = {
  academicFeesPolicy: {
    academicFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    academicFeesPolicy: {},
    policyToEdit: {},
    selectedPolicy: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },
  applicationFeesPolicy: {
    applicationFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    applicationFeesPolicy: {},
    policyToEdit: {},
    selectedPolicy: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,

    updateApplicationPolicyError: {},
    updateApplicationPolicyResponse: {},
    updatingApplicationPolicy: false,
  },
  admissionFeesPolicy: {
    admissionFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    admissionFeesPolicy: {},
    policyToEdit: {},
    selectedPolicy: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,

    updateAdmissionPolicyError: {},
    updateAdmissionPolicyResponse: {},
    updatingAdmissionPolicy: false,

    deleteAdmissionPolicyError: {},
    deleteAdmissionPolicyResponse: {},
    deletingAdmissionPolicy: false,
  },
  registrationPolicy: {
    registrationPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    registrationPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },
  otherFeesPolicy: {
    otherFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    otherFeesPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },
  documentPolicy: {
    creatingPolicy: false,
    createPolicyError: {},
    createdPolicyResponse: {},

    updatingPolicy: false,
    updatePolicyError: {},
    updatedPolicyResponse: {},

    fetchingDocumentPolicies: false,
    fetchDocumentPolicyError: {},
    documentPolicies: [],

    deletePolicyResponse: {},
    deletePolicyError: {},
    deletingPolicy: false,

    fetchRegHistoryPolicyError: {},
    regHistoryPolicies: [],
    fetchingRegHistoryPolicies: false,

    updateRegHistoryResponse: {},
    updateRegHistoryPolicyError: {},
    updatingRegHistoryPolicy: false,

    createdRegHistoryPolicyResponse: {},
    createRegHistoryPolicyError: {},
    creatingRegHistoryPolicy: false,

    deleteRegHistoryError: {},
    deleteRegHistoryResponse: {},
    deletingRegHistory: false,
  },
  surchargePolicy: {
    surchargePolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    enrollmentPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    deletingElements: false,
    deleteElementError: {},
    deleteElementSuccess: {},
  },
  retakerPolicy: {
    retakerPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    retakerPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    deletingElements: false,
    deleteElementError: {},
    deleteElementSuccess: {},
  },
  extensionPolicy: {
    extensionPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    extensionPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    deletingElements: false,
    deleteElementError: {},
    deleteElementSuccess: {},
  },
  tuitionFeesPolicy: {
    tuitionFeesPolicies: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    tuitionFeesPolicy: {},
    policyToEdit: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
  },
  studyLevelPassMarkPolicy: {
    loading: false,
    studyLevelPolicies: [],
    loadError: {},

    creating: false,
    createResponse: {},
    createError: {},

    deleting: false,
    deleteResponse: {},
    deleteError: {},

    updating: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
  },
  courseResittingPolicy: {
    loading: false,
    allPolicies: [],
    loadError: {},

    creating: false,
    createResponse: {},
    createError: {},

    deleting: false,
    deleteResponse: {},
    deleteError: {},

    updating: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
  },
  resultsCategoryPolicy: {
    loading: false,
    resultsCategoryPolicies: [],
    degreeClassPolicies: [],
    loadError: {},

    creating: false,
    createResponse: {},
    createError: {},

    deleting: false,
    deleteResponse: {},
    deleteError: {},

    updating: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
    selectedLevel: null,
  },
  servicesPolicy: {
    loading: false,
    servicePolicies: [],
    loadError: {},

    creatingServicePolicy: false,
    createResponse: {},
    createError: {},

    deletingServicePolicy: false,
    deleteResponse: {},
    deleteError: {},

    updatingServicePolicy: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
  },
  concededPassPolicy: {
    loading: false,
    concededPassPolicies: [],
    loadError: {},

    creatingPolicy: false,
    createResponse: {},
    createError: {},

    deletingPolicy: false,
    deleteResponse: {},
    deleteError: {},

    updatingPolicy: false,
    updateResponse: {},
    updateError: {},

    policyToEdit: {},
    showModal: false,
  },
};

export default institutionPolicy;
