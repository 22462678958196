const studentRecord = {
  DOWNLOAD_STUDENT_TEMPLATE_REQUEST: 'DOWNLOAD_STUDENT_TEMPLATE_REQUEST',
  DOWNLOAD_STUDENT_TEMPLATE_SUCCESS: 'DOWNLOAD_STUDENT_TEMPLATE_SUCCESS',
  DOWNLOAD_STUDENT_TEMPLATE_ERROR: 'DOWNLOAD_STUDENT_TEMPLATE_ERROR',

  DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_REQUEST:
    'DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_REQUEST',
  DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_SUCCESS:
    'DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_SUCCESS',
  DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_ERROR:
    'DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_ERROR',

  UPLOAD_STUDENT_TEMPLATE_REQUEST: 'UPLOAD_STUDENT_TEMPLATE_REQUEST',
  UPLOAD_STUDENT_TEMPLATE_SUCCESS: 'UPLOAD_STUDENT_TEMPLATE_SUCCESS',
  UPLOAD_STUDENT_TEMPLATE_ERROR: 'UPLOAD_STUDENT_TEMPLATE_ERROR',

  VERIFY_UPLOAD_STUDENT_TEMPLATE_REQUEST:
    'VERIFY_UPLOAD_STUDENT_TEMPLATE_REQUEST',
  VERIFY_UPLOAD_STUDENT_TEMPLATE_SUCCESS:
    'VERIFY_UPLOAD_STUDENT_TEMPLATE_SUCCESS',
  VERIFY_UPLOAD_STUDENT_TEMPLATE_ERROR: 'VERIFY_UPLOAD_STUDENT_TEMPLATE_ERROR',

  UPLOAD_BULK_STUDENT_UPDATE_REQUEST: 'UPLOAD_BULK_STUDENT_UPDATE_REQUEST',
  UPLOAD_BULK_STUDENT_UPDATE_SUCCESS: 'UPLOAD_BULK_STUDENT_UPDATE_SUCCESS',
  UPLOAD_BULK_STUDENT_UPDATE_ERROR: 'UPLOAD_BULK_STUDENT_UPDATE_ERROR',

  GET_PENDING_STUDENTS_REQUEST: 'GET_PENDING_STUDENTS_REQUEST',
  GET_PENDING_STUDENTS_SUCCESS: 'GET_PENDING_STUDENTS_SUCCESS',
  GET_PENDING_STUDENTS_ERROR: 'GET_PENDING_STUDENTS_ERROR',

  GET_BATCH_STUDENTS_REQUEST: 'GET_BATCH_STUDENTS_REQUEST',
  GET_BATCH_STUDENTS_SUCCESS: 'GET_BATCH_STUDENTS_SUCCESS',
  GET_BATCH_STUDENTS_ERROR: 'GET_BATCH_STUDENTS_ERROR',

  GET_STUDENTS_DISSERTATION_REQUEST: 'GET_STUDENTS_DISSERTATION_REQUEST',
  GET_STUDENTS_DISSERTATION_SUCCESS: 'GET_STUDENTS_DISSERTATION_SUCCESS',
  GET_STUDENTS_DISSERTATION_ERROR: 'GET_STUDENTS_DISSERTATION_ERROR',

  UPDATE_STUDENTS_DISSERTATION_REQUEST: 'UPDATE_STUDENTS_DISSERTATION_REQUEST',
  UPDATE_STUDENTS_DISSERTATION_SUCCESS: 'UPDATE_STUDENTS_DISSERTATION_SUCCESS',
  UPDATE_STUDENTS_DISSERTATION_ERROR: 'UPDATE_STUDENTS_DISSERTATION_ERROR',

  APPROVE_PENDING_STUDENT_REQUEST: 'APPROVE_PENDING_STUDENT_REQUEST',
  APPROVE_PENDING_STUDENT_SUCCESS: 'APPROVE_PENDING_STUDENT_SUCCESS',
  APPROVE_PENDING_STUDENT_ERROR: 'APPROVE_PENDING_STUDENT_ERROR',

  APPROVE_PENDING_BATCH_REQUEST: 'APPROVE_PENDING_BATCH_REQUEST',
  APPROVE_PENDING_BATCH_SUCCESS: 'APPROVE_PENDING_BATCH_SUCCESS',
  APPROVE_PENDING_BATCH_ERROR: 'APPROVE_PENDING_BATCH_ERROR',

  SEARCH_STUDENT_REQUEST: 'SEARCH_STUDENT_REQUEST',
  SEARCH_STUDENT_SUCCESS: 'SEARCH_STUDENT_SUCCESS',
  SEARCH_STUDENT_ERROR: 'SEARCH_STUDENT_ERROR',

  GET_MY_STUDENT_BATCHES_REQUEST: 'GET_MY_STUDENT_BATCHES_REQUEST',
  GET_MY_STUDENT_BATCHES_SUCCESS: 'GET_MY_STUDENT_BATCHES_SUCCESS',
  GET_MY_STUDENT_BATCHES_ERROR: 'GET_MY_STUDENT_BATCHES_ERROR',

  SET_MY_BATCH_CONTEXT: 'SET_MY_BATCH_CONTEXT',

  SHOW_DELETE_STUDENT_FROM_SRM_MODAL: 'SHOW_DELETE_STUDENT_FROM_SRM_MODAL',

  DELETE_STUDENT_FROM_SRM_REQUEST: 'DELETE_STUDENT_FROM_SRM_REQUEST',
  DELETE_STUDENT_FROM_SRM_SUCCESS: 'DELETE_STUDENT_FROM_SRM_SUCCESS',
  DELETE_STUDENT_FROM_SRM_ERROR: 'DELETE_STUDENT_FROM_SRM_ERROR',

  downloadStudentTemplate: () => ({
    type: studentRecord.DOWNLOAD_STUDENT_TEMPLATE_REQUEST,
  }),

  downloadBulkUpdateTemplate: () => ({
    type: studentRecord.DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_REQUEST,
  }),

  searchStudent: (context) => ({
    type: studentRecord.SEARCH_STUDENT_REQUEST,
    context,
  }),

  uploadStudents: (data) => ({
    type: studentRecord.UPLOAD_STUDENT_TEMPLATE_REQUEST,
    data,
  }),

  verifyUploadStudentTemplate: (data) => ({
    type: studentRecord.VERIFY_UPLOAD_STUDENT_TEMPLATE_REQUEST,
    data,
  }),

  uploadBulkUpdateStudents: (data) => ({
    type: studentRecord.UPLOAD_BULK_STUDENT_UPDATE_REQUEST,
    data,
  }),

  getPendingStudents: () => ({
    type: studentRecord.GET_PENDING_STUDENTS_REQUEST,
  }),

  getBatchStudents: (data) => ({
    type: studentRecord.GET_BATCH_STUDENTS_REQUEST,
    data,
  }),

  getStudentDissertations: (data) => ({
    type: studentRecord.GET_STUDENTS_DISSERTATION_REQUEST,
    data,
  }),

  updateStudentDissertations: (data, id, programmeId) => ({
    type: studentRecord.UPDATE_STUDENTS_DISSERTATION_REQUEST,
    data,
    id,
    programmeId,
  }),

  getMyStudentBatches: (params) => ({
    type: studentRecord.GET_MY_STUDENT_BATCHES_REQUEST,
    params,
  }),

  setMyBatchContext: (params) => ({
    type: studentRecord.SET_MY_BATCH_CONTEXT,
    params,
  }),

  approvePendingStudents: (data, batchNumber, context) => ({
    type: studentRecord.APPROVE_PENDING_STUDENT_REQUEST,
    batchNumber,
    context,
    data,
  }),

  approvePendingBatch: (data, context) => ({
    type: studentRecord.APPROVE_PENDING_BATCH_REQUEST,
    context,
    data,
  }),

  showDeleteFromSRMModal: (payload) => ({
    type: studentRecord.SHOW_DELETE_STUDENT_FROM_SRM_MODAL,
    payload,
  }),

  deleteStudentsFromSRM: (payload, context) => ({
    type: studentRecord.DELETE_STUDENT_FROM_SRM_REQUEST,
    payload,
    context,
  }),
};

export default studentRecord;
