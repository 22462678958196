import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { debitNoteActions } from '../../actions';

function* getDebitNotes() {
  try {
    const response = yield axios({
      url: `/registration/invoices/debit-notes/all-approved`,
      method: 'GET',
    });
    yield put({
      type: debitNoteActions.GET_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.GET_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* getPendingDebitNotes() {
  try {
    const response = yield axios({
      url: `/registration/invoices/debit-notes/bulk-pending`,
      method: 'GET',
    });
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* getStudentPendingDebitNotes(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/debit-notes/all-pending/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: debitNoteActions.GET_STUDENT_PENDING_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.GET_STUDENT_PENDING_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* createDebitNote(actions) {
  try {
    const response = yield axios({
      url: `/registration/invoices/debit-notes/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: debitNoteActions.CREATE_DEBIT_NOTE_SUCCESS,
      data: response,
    });
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.CREATE_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingDebitNote(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/debit-notes/approve',
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: debitNoteActions.APPROVE_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.APPROVE_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* declinePendingDebitNote(actions) {
  try {
    const response = yield axios({
      url: '/registration/invoices/debit-notes/decline',
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: debitNoteActions.DECLINE_DEBIT_NOTE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: debitNoteActions.GET_PENDING_DEBIT_NOTE_REQUEST,
      studentId: actions.studentId,
    });
  } catch (error) {
    yield put({
      type: debitNoteActions.DECLINE_DEBIT_NOTE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetDebitNotes() {
  yield takeLatest(debitNoteActions.GET_DEBIT_NOTE_REQUEST, getDebitNotes);
}

function* watchGetPendingDebitNotes() {
  yield takeLatest(
    debitNoteActions.GET_PENDING_DEBIT_NOTE_REQUEST,
    getPendingDebitNotes
  );
}

function* watchGetStudentPendingDebitNotes() {
  yield takeLatest(
    debitNoteActions.GET_STUDENT_PENDING_DEBIT_NOTE_REQUEST,
    getStudentPendingDebitNotes
  );
}

function* watchCreateDebitNote() {
  yield takeLatest(debitNoteActions.CREATE_DEBIT_NOTE_REQUEST, createDebitNote);
}

function* watchApprovePendingDebitNote() {
  yield takeLatest(
    debitNoteActions.APPROVE_DEBIT_NOTE_REQUEST,
    approvePendingDebitNote
  );
}
function* watchDeclinePendingDebitNote() {
  yield takeLatest(
    debitNoteActions.DECLINE_DEBIT_NOTE_REQUEST,
    declinePendingDebitNote
  );
}

export default [
  fork(watchGetDebitNotes),
  fork(watchGetPendingDebitNotes),
  fork(watchGetStudentPendingDebitNotes),
  fork(watchCreateDebitNote),
  fork(watchApprovePendingDebitNote),
  fork(watchDeclinePendingDebitNote),
];
