const previousPayments = {
  DOWNLOAD_PAYMENTS_TEMPLATE_REQUEST: 'DOWNLOAD_PAYMENTS_TEMPLATE_REQUEST',
  DOWNLOAD_PAYMENTS_TEMPLATE_SUCCESS: 'DOWNLOAD_PAYMENTS_TEMPLATE_SUCCESS',
  DOWNLOAD_PAYMENTS_TEMPLATE_ERROR: 'DOWNLOAD_PAYMENTS_TEMPLATE_ERROR',

  UPLOAD_PAYMENTS_TEMPLATE_REQUEST: 'UPLOAD_PAYMENTS_TEMPLATE_REQUEST',
  UPLOAD_PAYMENTS_TEMPLATE_SUCCESS: 'UPLOAD_PAYMENTS_TEMPLATE_SUCCESS',
  UPLOAD_PAYMENTS_TEMPLATE_ERROR: 'UPLOAD_PAYMENTS_TEMPLATE_ERROR',

  GET_PREVIOUS_PAYMENTS_REQUEST: 'GET_PREVIOUS_PAYMENTS_REQUEST',
  GET_PREVIOUS_PAYMENTS_SUCCESS: 'GET_PREVIOUS_PAYMENTS_SUCCESS',
  GET_PREVIOUS_PAYMENTS_ERROR: 'GET_PREVIOUS_PAYMENTS_ERROR',

  GET_PREVIOUS_DEPOSITS_REQUEST: 'GET_PREVIOUS_DEPOSITS_REQUEST',
  GET_PREVIOUS_DEPOSITS_SUCCESS: 'GET_PREVIOUS_DEPOSITS_SUCCESS',
  GET_PREVIOUS_DEPOSITS_ERROR: 'GET_PREVIOUS_DEPOSITS_ERROR',

  PUSH_TO_STUDENT_ACCOUNT_REQUEST: 'PUSH_TO_STUDENT_ACCOUNT_REQUEST',
  PUSH_TO_STUDENT_ACCOUNT_SUCCESS: 'PUSH_TO_STUDENT_ACCOUNT_SUCCESS',
  PUSH_TO_STUDENT_ACCOUNT_ERROR: 'PUSH_TO_STUDENT_ACCOUNT_ERROR',

  PUSH_TO_PREPAYMENTS_REQUEST: 'PUSH_TO_PREPAYMENTS_REQUEST',
  PUSH_TO_PREPAYMENTS_SUCCESS: 'PUSH_TO_PREPAYMENTS_SUCCESS',
  PUSH_TO_PREPAYMENTS_ERROR: 'PUSH_TO_PREPAYMENTS_ERROR',

  DELETE_PREVIOUS_TUITION_REQUEST: 'DELETE_PREVIOUS_TUITION_REQUEST',
  DELETE_PREVIOUS_TUITION_SUCCESS: 'DELETE_PREVIOUS_TUITION_SUCCESS',
  DELETE_PREVIOUS_TUITION_ERROR: 'DELETE_PREVIOUS_TUITION_ERROR',

  DELETE_PREVIOUS_PREPAYMENTS_REQUEST: 'DELETE_PREVIOUS_PREPAYMENTS_REQUEST',
  DELETE_PREVIOUS_PREPAYMENTS_SUCCESS: 'DELETE_PREVIOUS_PREPAYMENTS_SUCCESS',
  DELETE_PREVIOUS_PREPAYMENTS_ERROR: 'DELETE_PREVIOUS_PREPAYMENTS_ERROR',

  SET_PREVIOUS_TUITION_TO_PUSH: 'SET_PREVIOUS_TUITION_TO_PUSH',

  downloadPaymentsTemplate: () => ({
    type: previousPayments.DOWNLOAD_PAYMENTS_TEMPLATE_REQUEST,
  }),

  uploadPaymentsTemplate: (data) => ({
    type: previousPayments.UPLOAD_PAYMENTS_TEMPLATE_REQUEST,
    data,
  }),

  fetchPayments: (studentNumber) => ({
    type: previousPayments.GET_PREVIOUS_PAYMENTS_REQUEST,
    params: { student_number: studentNumber },
  }),

  pushToStudentAccount: (payload, studentNo, studentId, studentProgId) => ({
    type: previousPayments.PUSH_TO_STUDENT_ACCOUNT_REQUEST,
    studentProgId,
    studentNo,
    studentId,
    payload,
  }),

  pushToPrepayments: (data) => ({
    type: previousPayments.PUSH_TO_PREPAYMENTS_REQUEST,
    data,
  }),

  deletePreviousTuitionDeposits: (data, studentNumber) => ({
    type: previousPayments.DELETE_PREVIOUS_TUITION_REQUEST,
    studentNumber,
    data,
  }),

  deletePreviousPrePayments: (data, studentNumber) => ({
    type: previousPayments.DELETE_PREVIOUS_PREPAYMENTS_REQUEST,
    studentNumber,
    data,
  }),

  fetchStudentDeposits: (studentNumber) => ({
    type: previousPayments.GET_PREVIOUS_DEPOSITS_REQUEST,
    params: { student_number: studentNumber },
  }),

  setPaymentToPush: (dataToPush) => ({
    type: previousPayments.SET_PREVIOUS_TUITION_TO_PUSH,
    dataToPush,
  }),
};

export default previousPayments;
