import { previousRegistrationActions } from '../../actions';
import initialState from '../../initialState';

const previousRegistration = (
  state = initialState.previousRegistration,
  actions
) => {
  switch (actions.type) {
    case previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
        downloadTemplateSuccess: {},
      };
    case previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadTemplateSuccess: actions.data,
        downloadingTemplate: false,
      };
    case previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_ERROR:
      return {
        ...state,
        downloadTemplateError: actions.error,
        downloadingTemplate: false,
      };

    case previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateSuccess: {},
        uploadTemplateError: {},
      };
    case previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    default:
      return state;
  }
};
export default previousRegistration;
