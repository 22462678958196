const userLogsActions = {
  GET_USER_LOGS_REQUEST: 'GET_USER_LOGS_REQUEST',
  GET_USER_LOGS_SUCCESS: 'GET_USER_LOGS_SUCCESS',
  GET_USER_LOGS_ERROR: 'GET_USER_LOGS_ERROR',

  getUserLogs: () => ({
    type: userLogsActions.GET_USER_LOGS_REQUEST,
  }),
};

export default userLogsActions;
