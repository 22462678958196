import { isEmpty } from 'lodash';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import './custom.scss';
import './assets/css/index.css';
import { PageLoader } from './components/common';
import { authActions, institutionStructureActions } from './config/actions';
import NotFound from './container/app/404';
import RoutePaths from './config/routes/routePath';
import VerifyEmail from './container/Auth/VerifyEmail';
import ChangeDefaultPassword from './container/AuthUser/ChangeDefaultPassword';
import EmailVerificationRequest from './container/Auth/EmailVerificationRequest';
import { getAccessToken } from './config/services/storageService';

const Dashboard = React.lazy(() => import('./container/Dashboard'));

function App() {
  const dispatch = useDispatch();
  const { institutionStructure } = useSelector(
    (state) => state.institutionStructure
  );

  useEffect(() => {
    if (isEmpty(institutionStructure)) {
      dispatch(institutionStructureActions.getInstitutionStructure());
    }
  }, []);

  useEffect(() => {
    // Prevent Going back to previous page
    window.history.pushState(null, document.title, '/');
    window.addEventListener('popstate', () => {
      // TO view the events, pass event as a prop to the function

      window.history.pushState(null, document.title, '/');
    });
  });

  const onIdle = () => {
    const accessToken = getAccessToken();
    if (accessToken) dispatch(authActions.logoutUser());
  };

  useIdleTimer({
    onIdle,
    timeout: process.env.REACT_APP_SESSION_TIME_OUT_MIC_SEC || 300000, // 5 Minutes
    promptTimeout: 0,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });

  return (
    <Suspense fallback={<PageLoader />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Dashboard />} />
          <Route
            path={RoutePaths.verifyEmail.path}
            exact
            element={<VerifyEmail />}
          />
          <Route
            path={RoutePaths.changeDefaultPassword.path}
            exact
            element={<ChangeDefaultPassword />}
          />
          <Route
            path={RoutePaths.requestEmailVerification.path}
            exact
            element={<EmailVerificationRequest />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
