const revenueReport = {
  gettingYearReport: false,
  academicYearReport: [],
  yearReportError: {},

  gettingUnitReport: false,
  academicUnitReport: [],
  unitReportError: {},
};

export default revenueReport;
