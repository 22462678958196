const studentEnrollment = {
  ENROLL_STUDENT_REQUEST: 'ENROLL_STUDENT_REQUEST',
  ENROLL_STUDENT_SUCCESS: 'ENROLL_STUDENT_SUCCESS',
  ENROLL_STUDENT_ERROR: 'ENROLL_STUDENT_ERROR',

  DE_ENROLL_STUDENT_REQUEST: 'DE_ENROLL_STUDENT_REQUEST',
  DE_ENROLL_STUDENT_SUCCESS: 'DE_ENROLL_STUDENT_SUCCESS',
  DE_ENROLL_STUDENT_ERROR: 'DE_ENROLL_STUDENT_ERROR',

  ADD_LATE_ENROLLMENT_REQUEST: 'ADD_LATE_ENROLLMENT_REQUEST',
  ADD_LATE_ENROLLMENT_SUCCESS: 'ADD_LATE_ENROLLMENT_SUCCESS',
  ADD_LATE_ENROLLMENT_ERROR: 'ADD_LATE_ENROLLMENT_ERROR',

  GET_ENROLL_HISTORY_REQUEST: 'GET_ENROLL_HISTORY_REQUEST',
  GET_ENROLL_HISTORY_SUCCESS: 'GET_ENROLL_HISTORY_SUCCESS',
  GET_ENROLL_HISTORY_ERROR: 'GET_ENROLL_HISTORY_ERROR',

  enrollStudent: (data, studentId, studentProgrammeId) => ({
    type: studentEnrollment.ENROLL_STUDENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  deEnrollStudent: (data, studentId, studentProgrammeId) => ({
    type: studentEnrollment.DE_ENROLL_STUDENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  studentLateEnrollment: (data, studentId, studentProgrammeId) => ({
    type: studentEnrollment.ADD_LATE_ENROLLMENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  getEnrollmentHistory: (studentId, studentProgrammeId) => ({
    type: studentEnrollment.GET_ENROLL_HISTORY_REQUEST,
    studentId,
    studentProgrammeId,
  }),
};
export default studentEnrollment;
