const admittedStudents = {
  GET_ADMITTED_STUDENTS_REQUEST: 'GET_ADMITTED_STUDENTS_REQUEST',
  GET_ADMITTED_STUDENTS_SUCCESS: 'GET_ADMITTED_STUDENTS_SUCCESS',
  GET_ADMITTED_STUDENTS_ERROR: 'GET_ADMITTED_STUDENTS_ERROR',

  SEARCH_ADMITTED_STUDENTS_REQUEST: 'SEARCH_ADMITTED_STUDENTS_REQUEST',
  SEARCH_ADMITTED_STUDENTS_SUCCESS: 'SEARCH_ADMITTED_STUDENTS_SUCCESS',
  SEARCH_ADMITTED_STUDENTS_ERROR: 'SEARCH_ADMITTED_STUDENTS_ERROR',

  EDIT_ADMITTED_STUDENT_REQUEST: 'EDIT_ADMITTED_STUDENT_REQUEST',
  EDIT_ADMITTED_STUDENT_SUCCESS: 'EDIT_ADMITTED_STUDENT_SUCCESS',
  EDIT_ADMITTED_STUDENT_ERROR: 'EDIT_ADMITTED_STUDENT_ERROR',

  UPDATE_HALL_OF_ATTACHMENT_REQUEST: 'UPDATE_HALL_OF_ATTACHMENT_REQUEST',
  UPDATE_HALL_OF_ATTACHMENT_SUCCESS: 'UPDATE_HALL_OF_ATTACHMENT_SUCCESS',
  UPDATE_HALL_OF_ATTACHMENT_ERROR: 'UPDATE_HALL_OF_ATTACHMENT_ERROR',

  DELETE_ADMITTED_STUDENTS_REQUEST: 'DELETE_ADMITTED_STUDENTS_REQUEST',
  DELETE_ADMITTED_STUDENTS_SUCCESS: 'DELETE_ADMITTED_STUDENTS_SUCCESS',
  DELETE_ADMITTED_STUDENTS_ERROR: 'DELETE_ADMITTED_STUDENTS_ERROR',

  GET_ADMISSION_PROGRAMME_REQUEST: 'GET_ADMISSION_PROGRAMME_REQUEST',
  GET_ADMISSION_PROGRAMME_SUCCESS: 'GET_ADMISSION_PROGRAMME_SUCCESS',
  GET_ADMISSION_PROGRAMME_ERROR: 'GET_ADMISSION_PROGRAMME_ERROR',

  GET_ADMISSION_STATISTICS_REQUEST: 'GET_ADMISSION_STATISTICS_REQUEST',
  GET_ADMISSION_STATISTICS_SUCCESS: 'GET_ADMISSION_STATISTICS_SUCCESS',
  GET_ADMISSION_STATISTICS_ERROR: 'GET_ADMISSION_STATISTICS_ERROR',

  DOWNLOAD_ALL_CAMPUS_REPORTS_REQUEST: 'DOWNLOAD_ALL_CAMPUS_REPORTS_REQUEST',
  DOWNLOAD_ALL_CAMPUS_REPORTS_SUCCESS: 'DOWNLOAD_ALL_CAMPUS_REPORTS_SUCCESS',
  DOWNLOAD_ALL_CAMPUS_REPORTS_ERROR: 'DOWNLOAD_ALL_CAMPUS_REPORTS_ERROR',

  UPLOAD_ADMITTED_STUDENTS_REQUEST: 'UPLOAD_ADMITTED_STUDENTS_REQUEST',
  UPLOAD_ADMITTED_STUDENTS_SUCCESS: 'UPLOAD_ADMITTED_STUDENTS_SUCCESS',
  UPLOAD_ADMITTED_STUDENTS_ERROR: 'UPLOAD_ADMITTED_STUDENTS_ERROR',

  DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_REQUEST:
    'DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_REQUEST',
  DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_SUCCESS:
    'DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_SUCCESS',
  DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_ERROR:
    'DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_ERROR',

  GET_ADMITTED_STUDENTS_REPORTS_REQUEST:
    'GET_ADMITTED_STUDENTS_REPORTS_REQUEST',
  GET_ADMITTED_STUDENTS_REPORTS_SUCCESS:
    'GET_ADMITTED_STUDENTS_REPORTS_SUCCESS',
  GET_ADMITTED_STUDENTS_REPORTS_ERROR: 'GET_ADMITTED_STUDENTS_REPORTS_ERROR',

  GENERATE_STUDENT_NUMBER_REQUEST: 'GENERATE_STUDENT_NUMBER_REQUEST',
  GENERATE_STUDENT_NUMBER_SUCCESS: 'GENERATE_STUDENT_NUMBER_SUCCESS',
  GENERATE_STUDENT_NUMBER_ERROR: 'GENERATE_STUDENT_NUMBER_ERROR',

  GENERATE_REGISTRATION_NUMBER_REQUEST: 'GENERATE_REGISTRATION_NUMBER_REQUEST',
  GENERATE_REGISTRATION_NUMBER_SUCCESS: 'GENERATE_REGISTRATION_NUMBER_SUCCESS',
  GENERATE_REGISTRATION_NUMBER_ERROR: 'GENERATE_REGISTRATION_NUMBER_ERROR',

  GENERATE_ADMISSION_LETTERS_REQUEST: 'GENERATE_ADMISSION_LETTERS_REQUEST',
  GENERATE_ADMISSION_LETTERS_SUCCESS: 'GENERATE_ADMISSION_LETTERS_SUCCESS',
  GENERATE_ADMISSION_LETTERS_ERROR: 'GENERATE_ADMISSION_LETTERS_ERROR',

  PRINT_ADMISSION_LETTERS_REQUEST: 'PRINT_ADMISSION_LETTERS_REQUEST',
  PRINT_ADMISSION_LETTERS_SUCCESS: 'PRINT_ADMISSION_LETTERS_SUCCESS',
  PRINT_ADMISSION_LETTERS_ERROR: 'PRINT_ADMISSION_LETTERS_ERROR',

  PUSH_ADMITTED_APPLICANTS_RECORDS_REQUEST:
    'PUSH_ADMITTED_APPLICANTS_RECORDS_REQUEST',
  PUSH_ADMITTED_APPLICANTS__RECORDS_SUCCESS:
    'PUSH_ADMITTED_APPLICANTS__RECORDS_SUCCESS',
  PUSH_ADMITTED_APPLICANTS_RECORDS_ERROR:
    'PUSH_ADMITTED_APPLICANTS_RECORDS_ERROR',

  PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_REQUEST:
    'PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_REQUEST',
  PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_SUCCESS:
    'PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_SUCCESS',
  PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_ERROR:
    'PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_ERROR',

  DOWNLOAD_ADMITTED_STUDENTS_RECORDS_REQUEST:
    'DOWNLOAD_ADMITTED_STUDENTS_RECORDS_REQUEST',
  DOWNLOAD_ADMITTED_STUDENTS_RECORDS_SUCCESS:
    'DOWNLOAD_ADMITTED_STUDENTS_RECORDS_SUCCESS',
  DOWNLOAD_ADMITTED_STUDENTS_RECORDS_ERROR:
    'DOWNLOAD_ADMITTED_STUDENTS_RECORDS_ERROR',

  DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_REQUEST:
    'DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_REQUEST',
  DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_SUCCESS:
    'DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_SUCCESS',
  DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_ERROR:
    'DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_ERROR',

  DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_REQUEST:
    'DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_REQUEST',
  DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_SUCCESS:
    'DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_SUCCESS',
  DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_ERROR:
    'DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_ERROR',

  SET_ADMITTED_STUDENTS_CONTEXT: 'SET_ADMITTED_STUDENTS_CONTEXT',
  SHOW_GENERATE_STUDENT_NUMBERS_MODAL: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
  SHOW_DOWNLOAD_ADMITTED_STUDENTS_MODAL:
    'SHOW_DOWNLOAD_ADMITTED_STUDENTS_MODAL',

  SET_ADMISSION_STATISTICS_CONTEXT: 'SET_ADMISSION_STATISTICS_CONTEXT',

  getAdmittedStudents: (context) => ({
    type: admittedStudents.GET_ADMITTED_STUDENTS_REQUEST,
    context,
  }),

  searchAdmittedStudents: (data) => ({
    type: admittedStudents.SEARCH_ADMITTED_STUDENTS_REQUEST,
    data,
  }),

  editAdmittedStudents: (applicantId, data, searchQuery) => ({
    type: admittedStudents.EDIT_ADMITTED_STUDENT_REQUEST,
    applicantId,
    data,
    searchQuery,
  }),

  updateHallOfAttachment: (data, context) => ({
    type: admittedStudents.UPDATE_HALL_OF_ATTACHMENT_REQUEST,
    data,
    context,
  }),

  getAdmissionProgrammes: (context) => ({
    type: admittedStudents.GET_ADMISSION_PROGRAMME_REQUEST,
    context,
  }),

  uploadAdmittedStudents: (data) => ({
    type: admittedStudents.UPLOAD_ADMITTED_STUDENTS_REQUEST,
    data,
  }),

  deleteAdmittedStudents: (
    data,
    admittedStudentsContext,
    deleteSRM = false
  ) => ({
    type: admittedStudents.DELETE_ADMITTED_STUDENTS_REQUEST,
    admittedStudentsContext,
    data,
    deleteSRM,
  }),

  downloadAdmittedStudentsTemplate: (data) => ({
    type: admittedStudents.DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_REQUEST,
    data,
  }),

  setAdmittedStudentsContext: (data) => ({
    type: admittedStudents.SET_ADMITTED_STUDENTS_CONTEXT,
    data,
  }),

  showGenerateStudentNumberModal: (payload) => ({
    type: admittedStudents.SHOW_GENERATE_STUDENT_NUMBERS_MODAL,
    payload,
  }),

  showDownloadAdmittedStudentsModal: (payload) => ({
    type: admittedStudents.SHOW_DOWNLOAD_ADMITTED_STUDENTS_MODAL,
    payload,
  }),

  generateStudentNumber: (data) => ({
    type: admittedStudents.GENERATE_STUDENT_NUMBER_REQUEST,
    data,
  }),

  generateRegistrationNumber: (data) => ({
    type: admittedStudents.GENERATE_REGISTRATION_NUMBER_REQUEST,
    data,
  }),

  generateAdmissionLetters: (data, category) => ({
    type: admittedStudents.GENERATE_ADMISSION_LETTERS_REQUEST,
    data,
    category,
  }),

  printAdmissionLetters: (context) => ({
    type: admittedStudents.PRINT_ADMISSION_LETTERS_REQUEST,
    context,
  }),

  pushAdmittedApplicants: (data, action) => ({
    type: admittedStudents.PUSH_ADMITTED_APPLICANTS_RECORDS_REQUEST,
    data,
    action,
  }),

  pushAdmittedApplicantsAsPreviousStudents: (data) => ({
    type: admittedStudents.PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_REQUEST,
    data,
  }),

  downloadNotPushedStudentsReport: (data) => ({
    type: admittedStudents.DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_REQUEST,
    data,
  }),

  downloadAdmittedStudentsRecords: (data) => ({
    type: admittedStudents.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_REQUEST,
    data,
  }),

  downloadAdmittedStudentsRecordsPdf: (data) => ({
    type: admittedStudents.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_REQUEST,
    data,
  }),

  getAdmittedStudentsReports: (params) => ({
    type: admittedStudents.GET_ADMITTED_STUDENTS_REPORTS_REQUEST,
    params,
  }),

  getAdmissionStatistics: (data) => ({
    type: admittedStudents.GET_ADMISSION_STATISTICS_REQUEST,
    data,
  }),

  allCampusReports: (data) => ({
    type: admittedStudents.DOWNLOAD_ALL_CAMPUS_REPORTS_REQUEST,
    data,
  }),

  setAdmissionStatisticsContext: (payload) => ({
    type: admittedStudents.SET_ADMISSION_STATISTICS_CONTEXT,
    payload,
  }),
};

export default admittedStudents;
