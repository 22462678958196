const studentRegistration = {
  REGISTER_STUDENT_REQUEST: 'REGISTER_STUDENT_REQUEST',
  REGISTER_STUDENT_SUCCESS: 'REGISTER_STUDENT_SUCCESS',
  REGISTER_STUDENT_ERROR: 'REGISTER_STUDENT_ERROR',

  REGISTER_STUDENT_FULLY_REQUEST: 'REGISTER_STUDENT_FULLY_REQUEST',
  REGISTER_STUDENT_FULLY_SUCCESS: 'REGISTER_STUDENT_FULLY_SUCCESS',
  REGISTER_STUDENT_FULLY_ERROR: 'REGISTER_STUDENT_FULLY_ERROR',

  UPDATE_STUDENT_REGISTRATION_REQUEST: 'UPDATE_STUDENT_REGISTRATION_REQUEST',
  UPDATE_STUDENT_REGISTRATION_SUCCESS: 'UPDATE_STUDENT_REGISTRATION_SUCCESS',
  UPDATE_STUDENT_REGISTRATION_ERROR: 'UPDATE_STUDENT_REGISTRATION_ERROR',

  DE_REGISTER_STUDENT_REQUEST: 'DE_REGISTER_STUDENT_REQUEST',
  DE_REGISTER_STUDENT_SUCCESS: 'DE_REGISTER_STUDENT_SUCCESS',
  DE_REGISTER_STUDENT_ERROR: 'DE_REGISTER_STUDENT_ERROR',

  ADD_LATE_REGISTRATION_REQUEST: 'ADD_LATE_REGISTRATION_REQUEST',
  ADD_LATE_REGISTRATION_SUCCESS: 'ADD_LATE_REGISTRATION_SUCCESS',
  ADD_LATE_REGISTRATION_ERROR: 'ADD_LATE_REGISTRATION_ERROR',

  GET_REGISTRATION_HISTORY_REQUEST: 'GET_REGISTRATION_HISTORY_REQUEST',
  GET_REGISTRATION_HISTORY_SUCCESS: 'GET_REGISTRATION_HISTORY_SUCCESS',
  GET_REGISTRATION_HISTORY_ERROR: 'GET_REGISTRATION_HISTORY_ERROR',

  GET_PREVIOUS_REGISTRATION_REQUEST: 'GET_PREVIOUS_REGISTRATION_REQUEST',
  GET_PREVIOUS_REGISTRATION_SUCCESS: 'GET_PREVIOUS_REGISTRATION_SUCCESS',
  GET_PREVIOUS_REGISTRATION_ERROR: 'GET_PREVIOUS_REGISTRATION_ERROR',

  BILL_PREVIOUS_BALANCES_REQUEST: 'BILL_PREVIOUS_BALANCES_REQUEST',
  BILL_PREVIOUS_BALANCES_SUCCESS: 'BILL_PREVIOUS_BALANCES_SUCCESS',
  BILL_PREVIOUS_BALANCES_ERROR: 'BILL_PREVIOUS_BALANCES_ERROR',

  GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST:
    'GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST',
  GET_MIGRATED_ENROLLMENT_BALANCES_SUCCESS:
    'GET_MIGRATED_ENROLLMENT_BALANCES_SUCCESS',
  GET_MIGRATED_ENROLLMENT_BALANCES_ERROR:
    'GET_MIGRATED_ENROLLMENT_BALANCES_ERROR',

  SET_SELECTED_REGISTRATION_RECORD: 'SET_SELECTED_REGISTRATION_RECORD',

  registerStudent: (data, studentId, studentProgrammeId) => ({
    type: studentRegistration.REGISTER_STUDENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  registerStudentFully: (
    data,
    registrationId,
    studentId,
    studentProgrammeId
  ) => ({
    type: studentRegistration.REGISTER_STUDENT_FULLY_REQUEST,
    data,
    registrationId,
    studentId,
    studentProgrammeId,
  }),

  updateStudentRegistration: (
    data,
    studentId,
    studentProgrammeId,
    registrationId
  ) => ({
    type: studentRegistration.UPDATE_STUDENT_REGISTRATION_REQUEST,
    data,
    studentId,
    studentProgrammeId,
    registrationId,
  }),

  deRegisterStudent: (data, studentId, studentProgrammeId) => ({
    type: studentRegistration.DE_REGISTER_STUDENT_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  studentLateRegistration: (data, studentId, studentProgrammeId) => ({
    type: studentRegistration.ADD_LATE_REGISTRATION_REQUEST,
    data,
    studentId,
    studentProgrammeId,
  }),

  getRegistrationHistory: (studentId, studentProgrammeId) => ({
    type: studentRegistration.GET_REGISTRATION_HISTORY_REQUEST,
    studentId,
    studentProgrammeId,
  }),

  getPreviousRegistrationHistory: (studentNo) => ({
    type: studentRegistration.GET_PREVIOUS_REGISTRATION_REQUEST,
    studentNo,
  }),

  getMigratedEnrollmentBalances: (programmeId) => ({
    type: studentRegistration.GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST,
    programmeId,
  }),

  billPreviousBalances: (data) => ({
    type: studentRegistration.BILL_PREVIOUS_BALANCES_REQUEST,
    data,
  }),

  setRegistrationRecord: (record) => ({
    type: studentRegistration.SET_SELECTED_REGISTRATION_RECORD,
    record,
  }),
};
export default studentRegistration;
