import { Avatar, Form } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import usePrevious from '../../components/Hooks/usePrevious';
import {
  AlertMessage,
  AntDInputText,
  DataSpinner,
  SubmitButton,
} from '../../components/common';
import { authActions } from '../../config/actions';
import RoutePaths from '../../config/routes/routePath';
import { getAccessToken } from '../../config/services/storageService';
import profileImg from '../../images/avatar.png';

function ChangeDefaultPassword() {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    loggingOut,
    logoutData,
    profileLoading,
    changeDefaultPasswordError,
    changeDefaultPasswordSuccess,
    authUser,
    changingDefaultPassword,
  } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const prevState = usePrevious({
    changeDefaultPasswordError,
    changeDefaultPasswordSuccess,
    logoutData,
  });
  const navigate = useNavigate();
  const token = getAccessToken();

  useEffect(() => {
    if (isEmpty(authUser) && !isEmpty(token)) {
      dispatch(authActions.getAuthUser());
    }
  }, []);

  useEffect(() => {
    if (isEmpty(authUser) && isEmpty(token)) {
      navigate(RoutePaths.login.path);
    } else if (authUser.is_default_password === false) {
      navigate('/');
    }
  }, [authUser, token]);

  useEffect(() => {
    if (
      !isEmpty(prevState) &&
      !isEmpty(changeDefaultPasswordError) &&
      changeDefaultPasswordError !== prevState.changePasswordError
    ) {
      setErrorMessage(
        changeDefaultPasswordError?.error?.message ||
          changeDefaultPasswordError?.server?.message
      );
    }

    // Password Reset Successfully
    if (
      !isEmpty(prevState) &&
      !isEmpty(changeDefaultPasswordSuccess) &&
      changeDefaultPasswordSuccess !== prevState.changeDefaultPasswordSuccess
    ) {
      if (changeDefaultPasswordSuccess?.server?.status === true) navigate('/');
    }

    // Logout Successfully
    if (
      !isEmpty(prevState) &&
      !isEmpty(logoutData) &&
      logoutData !== prevState.logoutData
    ) {
      if (logoutData?.server?.status === true) navigate('/');
    }
  }, [changeDefaultPasswordError, changeDefaultPasswordSuccess, logoutData]);

  const onSubmitForm = (data) => {
    setErrorMessage(null);
    if (data) dispatch(authActions.changeDefaultPassword(data));
  };

  const logoutUser = () => {
    dispatch(authActions.logoutUser());
  };

  return (
    <div className="d-flex min-vh-100">
      <Helmet>
        <title>Change Default Password</title>
      </Helmet>
      <Container className="w-100 auto-scroll py-4 my-auto text-center text-primary">
        <Row className="content-justify-center">
          <Col md={{ span: 4, offset: 4 }}>
            {profileLoading ? (
              <DataSpinner text="Loading Profile..." />
            ) : (
              <>
                <Avatar
                  src={authUser.avatar || profileImg}
                  alt="Profile Pic"
                  className="mb-2 bg-white"
                  size={{
                    xs: 24,
                    sm: 32,
                    md: 40,
                    lg: 64,
                    xl: 80,
                    xxl: 100,
                  }}
                />
                <h6 className="text-uppercase h-5 fw-bold text-primary my-2">
                  {`Hi ${authUser.surname}, Please change your default password to proceed.`}
                </h6>
                {errorMessage && <AlertMessage message={errorMessage} />}
                <Form
                  onFinish={onSubmitForm}
                  layout="vertical"
                  form={form}
                  size="middle"
                  className="text-start text-muted"
                >
                  <AntDInputText
                    name="new_password"
                    label="New Password"
                    rules={[
                      {
                        required: true,
                        message: 'Enter your New password',
                      },
                    ]}
                    inputAttributes={{
                      minLength: 8,
                      maxLength: 16,
                    }}
                    type="password"
                  />
                  <AntDInputText
                    name="confirm_password"
                    label="Confirm New Password"
                    itemAttributes={{
                      dependencies: 'new_password',
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Confirm your New Password',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue('new_password') === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'The two passwords that you entered do not match!'
                            )
                          );
                        },
                      }),
                    ]}
                    inputAttributes={{
                      minLength: 8,
                      maxLength: 16,
                    }}
                    type="password"
                  />

                  <div className="my-3">
                    <SubmitButton
                      text="Change Password"
                      block
                      loadingText="Updating Password..."
                      loading={changingDefaultPassword}
                    />
                  </div>
                </Form>

                <div className="text-center">
                  <SubmitButton
                    className=" text-danger text-uppercase text-sm fw-bold"
                    type="link"
                    onClick={logoutUser}
                    loading={loggingOut}
                    text="Log out"
                    loadingText="Logging out..."
                  />
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ChangeDefaultPassword;
