import appRoutes from '../routes/appRoutes';

const appInitialState = {
  auth: {
    isAuthenticated: true,
    myApps: [],

    loading: false,
    loginData: {},
    loginError: {},
    loggingOut: false,
    logoutData: {},
    logoutError: {},
    authUser: {},
    user: {},
    authUserError: {},
    gettingAuthUser: false,
    users: {},
    fetchUserError: {},
    requesting: false,
    verifying: false,
    requestEmailSuccess: {},
    requestEmailError: {},
    verifyEmailSuccess: {},
    verifyEmailError: {},

    sendingResendOTP: false,
    sendOTPError: {},
    sendOTPSuccess: {},

    resettingOTPPassword: false,
    resetOTPPasswordError: {},
    resetOTPPasswordSuccess: {},

    gettingUserApps: false,
    authUserRoles: [],
    authUserApps: [],
    authUserRoleError: {},

    userDepartments: [],
    gettingUserDepartments: false,
    userDepartmentError: {},

    changingPassword: false,
    changePasswordError: {},
    changePasswordSuccess: {},

    changingDefaultPassword: false,
    changeDefaultPasswordError: {},
    changeDefaultPasswordSuccess: {},

    currentRole: {},

    gettingUserRoleBounds: false,
    userRoleBounds: [],
    getUserRoleBoundError: {},

    authenticatingUserAction: false,
    authActionSuccess: {},
    authActionError: {},
  },
  activeApp: {
    activeApp: {},
    openApps: [appRoutes.find((app) => app.app_code === 'DASHBOARD')],
    appError: null,
  },
};

export default appInitialState;
