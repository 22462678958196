import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { teachingTimetableActions } from '../../actions';
import initialState from '../../initialState';

function teachingTimetable(state = initialState.teachingTimetable, actions) {
  switch (actions.type) {
    case teachingTimetableActions.ASSIGN_TEACHING_TIMETABLE_REQUEST:
      return {
        ...state,
        assigningLecturer: true,
        assignLectureError: {},
      };
    case teachingTimetableActions.ASSIGN_TEACHING_TIMETABLE_SUCCESS:
      return {
        ...state,
        assignedLectureError: actions.data,
        assigningLecturer: false,
      };
    case teachingTimetableActions.ASSIGN_TEACHING_TIMETABLE_ERROR:
      return {
        ...state,
        assignLectureError: actions.error,
        assigningLecturer: false,
      };

    case teachingTimetableActions.GET_TEACHING_TIMETABLE_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case teachingTimetableActions.GET_TEACHING_TIMETABLE_SUCCESS: {
      const { context, data } = actions;
      const { teachingTimetable: ASSIGNMENTs } = state;

      const findContextIndex = findIndex(
        ASSIGNMENTs,
        (list) =>
          list.semester_id === context.semester_id &&
          list.department_id === context.department_id &&
          list.academic_year_id === context.academic_year_id &&
          list.course_unit_id === context.course_unit_id &&
          list.study_year_id === context.study_year_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          teachingTimetable: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          teachingTimetable: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }
      return {
        ...newState,
        loading: false,
      };
    }
    case teachingTimetableActions.GET_TEACHING_TIMETABLE_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case teachingTimetableActions.SEARCH_TEACHING_TIMETABLE_REQUEST:
      return {
        ...state,
        searchError: {},
        searching: true,
        searchContext: actions.context,
      };
    case teachingTimetableActions.SEARCH_TEACHING_TIMETABLE_SUCCESS: {
      const { context, data } = actions;
      const { searchedTeachingTimetable } = state;

      const findContextIndex = findIndex(
        searchedTeachingTimetable,
        (list) =>
          list[`${context.category}_id`] ===
            context[`${context.category}_id`] &&
          list.semester_id === context.semester_id &&
          list.department_id === context.department_id &&
          list.academic_year_id === context.academic_year_id &&
          list.course_unit_id === context.course_unit_id &&
          list.study_year_id === context.study_year_id &&
          list.category === context.category
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          searchedTeachingTimetable: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          searchedTeachingTimetable: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }
      return {
        ...newState,
        searching: false,
      };
    }
    case teachingTimetableActions.SEARCH_TEACHING_TIMETABLE_ERROR:
      return {
        ...state,
        searchError: actions.error,
        searching: false,
      };

    case teachingTimetableActions.TEACHING_TIMETABLE_BY_DEPARTMENT_REQUEST:
      return {
        ...state,
        byDepartmentError: {},
        searchingByDepartment: true,
        departmentContext: actions.context,
      };
    case teachingTimetableActions.TEACHING_TIMETABLE_BY_DEPARTMENT_SUCCESS: {
      const { context, data } = actions;
      const { departmentTimetable } = state;

      const findContextIndex = findIndex(
        departmentTimetable,
        (list) =>
          list.semester_id === context.semester_id &&
          list.department_id === context.department_id &&
          list.academic_year_id === context.academic_year_id &&
          list.course_unit_id === context.course_unit_id &&
          list.study_year_id === context.study_year_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          departmentTimetable: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          departmentTimetable: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }
      return {
        ...newState,
        searchingByDepartment: false,
      };
    }
    case teachingTimetableActions.TEACHING_TIMETABLE_BY_DEPARTMENT_ERROR:
      return {
        ...state,
        byDepartmentError: actions.error,
        searchingByDepartment: false,
      };

    case teachingTimetableActions.TEACHING_TIMETABLE_BY_FACULTY_REQUEST:
      return {
        ...state,
        byFacultyError: {},
        searchingByFaculty: true,
        facultyContext: actions.context,
      };
    case teachingTimetableActions.TEACHING_TIMETABLE_BY_FACULTY_SUCCESS: {
      const { context, data } = actions;
      const { facultyTimetable } = state;

      const findContextIndex = findIndex(
        facultyTimetable,
        (list) =>
          list.semester_id === context.semester_id &&
          list.faculty_id === context.faculty_id &&
          list.academic_year_id === context.academic_year_id &&
          list.course_unit_id === context.course_unit_id &&
          list.study_year_id === context.study_year_id
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          facultyTimetable: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          facultyTimetable: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }
      return {
        ...newState,
        searchingByFaculty: false,
      };
    }
    case teachingTimetableActions.TEACHING_TIMETABLE_BY_FACULTY_ERROR:
      return {
        ...state,
        byFacultyError: actions.error,
        searchingByFaculty: false,
      };

    case teachingTimetableActions.DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        errorDownloadingTemplate: {},
      };
    case teachingTimetableActions.DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        successDownloadingTemplate: actions.data,
        downloadingTemplate: false,
      };
    case teachingTimetableActions.DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_ERROR:
      return {
        ...state,
        errorDownloadingTemplate: actions.error,
        downloadingTemplate: false,
      };

    case teachingTimetableActions.UPLOAD_TEACHING_TIMETABLE_REQUEST:
      return {
        ...state,
        uploading: true,
        uploadResponse: {},
        uploadError: {},
      };
    case teachingTimetableActions.UPLOAD_TEACHING_TIMETABLE_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadResponse: actions.data,
      };
    case teachingTimetableActions.UPLOAD_TEACHING_TIMETABLE_ERROR:
      return {
        ...state,
        uploading: false,
        uploadError: actions.error,
      };

    case teachingTimetableActions.DELETE_TEACHING_TIMETABLE_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteResponse: {},
        deleteError: {},
      };
    case teachingTimetableActions.DELETE_TEACHING_TIMETABLE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };
    case teachingTimetableActions.DELETE_TEACHING_TIMETABLE_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case teachingTimetableActions.EDIT_TEACHING_TIMETABLE_REQUEST:
      return {
        ...state,
        editing: true,
        editResponse: {},
        editError: {},
      };
    case teachingTimetableActions.EDIT_TEACHING_TIMETABLE_SUCCESS:
      return {
        ...state,
        editing: false,
        editResponse: actions.data,
      };
    case teachingTimetableActions.EDIT_TEACHING_TIMETABLE_ERROR:
      return {
        ...state,
        editing: false,
        editError: actions.error,
      };

    case teachingTimetableActions.SHOW_ASSIGN_LECTURER_MODAL:
      return {
        ...state,
        showAssignLecturerModal: actions.data,
      };

    default:
      return state;
  }
}

export default teachingTimetable;
