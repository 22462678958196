const lecturerCourseActions = {
  GET_COURSE_ASSIGNMENT_LECTURER_COURSES_REQUEST:
    'GET_COURSE_ASSIGNMENT_LECTURER_COURSES_REQUEST',
  GET_COURSE_ASSIGNMENT_LECTURER_COURSES_SUCCESS:
    'GET_COURSE_ASSIGNMENT_LECTURER_COURSES_SUCCESS',
  GET_COURSE_ASSIGNMENT_LECTURER_COURSES_ERROR:
    'GET_COURSE_ASSIGNMENT_LECTURER_COURSES_ERROR',

  GET_COURSE_ASSIGNMENT_STUDENTS_REQUEST:
    'GET_COURSE_ASSIGNMENT_STUDENTS_REQUEST',
  GET_COURSE_ASSIGNMENT_STUDENTS_SUCCESS:
    'GET_COURSE_ASSIGNMENT_STUDENTS_SUCCESS',
  GET_COURSE_ASSIGNMENT_STUDENTS_ERROR: 'GET_COURSE_ASSIGNMENT_STUDENTS_ERROR',

  SET_SELECTED_COURSE: 'SET_SELECTED_COURSE',

  GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_REQUEST:
    'GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_REQUEST',
  GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_SUCCESS:
    'GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_SUCCESS',
  GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_ERROR:
    'GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_ERROR',

  UPLOAD_RESULTS_REQUEST: 'UPLOAD_RESULTS_REQUEST',
  UPLOAD_RESULTS_SUCCESS: 'UPLOAD_RESULTS_SUCCESS',
  UPLOAD_RESULTS_ERROR: 'UPLOAD_RESULTS_ERROR',

  DOWNLOAD_TEMPLATE_REQUEST: 'DOWNLOAD_TEMPLATE_REQUEST',
  DOWNLOAD_TEMPLATE_SUCCESS: 'DOWNLOAD_TEMPLATE_SUCCESS',
  DOWNLOAD_TEMPLATE_ERROR: 'DOWNLOAD_TEMPLATE_ERROR',

  GET_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST:
    'GET_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST',
  GET_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS:
    'GET_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS',
  GET_LECTURER_RESULT_CATEGORY_ALIAS_ERROR:
    'GET_LECTURER_RESULT_CATEGORY_ALIAS_ERROR',

  SET_SELECTED_RESULT_ALIAS: 'SET_SELECTED_RESULT_ALIAS',

  CREATE_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST:
    'CREATE_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST',
  CREATE_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS:
    'CREATE_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS',
  CREATE_LECTURER_RESULT_CATEGORY_ALIAS_ERROR:
    'CREATE_LECTURER_RESULT_CATEGORY_ALIAS_ERROR',

  EDIT_RESULT_CATEGORY_ALIAS_REQUEST: 'EDIT_RESULT_CATEGORY_ALIAS_REQUEST',
  EDIT_RESULT_CATEGORY_ALIAS_SUCCESS: 'EDIT_RESULT_CATEGORY_ALIAS_SUCCESS',
  EDIT_RESULT_CATEGORY_ALIAS_ERROR: 'EDIT_RESULT_CATEGORY_ALIAS_ERROR',

  SET_EDIT_RESULT_CATEGORY_ALIAS: 'SET_EDIT_RESULT_CATEGORY_ALIAS',

  SET_SHOW_RESULT_CATEGORY_ALIAS: 'SET_SHOW_RESULT_CATEGORY_ALIAS',

  SET_SHOW_RESULT_COMPUTE_FINAL_MARKS: 'SET_SHOW_RESULT_COMPUTE_FINAL_MARKS',

  SET_SHOW_DELETE_RESULT_CATEGORY_ALIAS_MODAL:
    'SET_SHOW_DELETE_RESULT_CATEGORY_ALIAS_MODAL',
  SET_SELECTED_RESULT_CATEGORY_ALIASES: 'SET_SELECTED_RESULT_CATEGORY_ALIASES',

  DELETE_RESULT_CATEGORY_ALIAS_REQUEST: 'DELETE_RESULT_CATEGORY_ALIAS_REQUEST',
  DELETE_RESULT_CATEGORY_ALIAS_SUCCESS: 'DELETE_RESULT_CATEGORY_ALIAS_SUCCESS',
  DELETE_RESULT_CATEGORY_ALIAS_ERROR: 'DELETE_RESULT_CATEGORY_ALIAS_ERROR',

  COMPUTE_STUDENTS_RESULT_REQUEST: 'COMPUTE_STUDENTS_RESULT_REQUEST',
  COMPUTE_STUDENTS_RESULT_SUCCESS: 'COMPUTE_STUDENTS_RESULT_SUCCESS',
  COMPUTE_STUDENTS_RESULT_ERROR: 'COMPUTE_STUDENTS_RESULT_ERROR',

  getLecturerCourses: (params) => ({
    type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_LECTURER_COURSES_REQUEST,
    params,
  }),
  deleteResultCategoryAlias: (resultCategoryAliasIds, courseAssignmentId) => ({
    type: lecturerCourseActions.DELETE_RESULT_CATEGORY_ALIAS_REQUEST,
    resultCategoryAliasIds,
    courseAssignmentId,
  }),
  setShowDeleteResultCategoryAliasModal: (show) => ({
    type: lecturerCourseActions.SET_SHOW_DELETE_RESULT_CATEGORY_ALIAS_MODAL,
    data: show,
  }),
  setResultCategoryAliases: (data) => ({
    type: lecturerCourseActions.SET_SELECTED_RESULT_CATEGORY_ALIASES,
    data,
  }),
  createLecturerResultCategoryAlias: (data, courseAssignmentId) => ({
    type: lecturerCourseActions.CREATE_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST,
    data,
    courseAssignmentId,
  }),
  editResultCategoryAlias: (
    data,
    resultCategoryAliasId,
    courseAssignmentId
  ) => ({
    type: lecturerCourseActions.EDIT_RESULT_CATEGORY_ALIAS_REQUEST,
    data,
    courseAssignmentId,
    resultCategoryAliasId,
  }),
  setEditResultCategoryAlias: (data) => ({
    type: lecturerCourseActions.SET_EDIT_RESULT_CATEGORY_ALAIS,
    data,
  }),

  setShowAliasModal: (payload = false, aliasData = {}) => ({
    type: lecturerCourseActions.SET_SHOW_RESULT_CATEGORY_ALIAS,
    payload,
    aliasData,
  }),

  setComputeModal: (payload = false) => ({
    type: lecturerCourseActions.SET_SHOW_RESULT_COMPUTE_FINAL_MARKS,
    payload,
  }),

  getLecturerResultCategoryAliases: (courseAssignmentId) => ({
    type: lecturerCourseActions.GET_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST,
    courseAssignmentId,
  }),

  computeResults: (courseAssignmentId) => ({
    type: lecturerCourseActions.COMPUTE_STUDENTS_RESULT_REQUEST,
    courseAssignmentId,
  }),

  getLecturerCourseRawResults: (courseAssignmentId, params) => ({
    type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_REQUEST,
    courseAssignmentId,
    params,
  }),
  setSelectedResultAlias: (resultAlias) => ({
    type: lecturerCourseActions.SET_SELECTED_RESULT_ALIAS,
    resultAlias,
  }),
  getStudentsRegisteredForCourse: (params, courseAssignmentId) => ({
    type: lecturerCourseActions.GET_COURSE_ASSIGNMENT_STUDENTS_REQUEST,
    params,
    courseAssignmentId,
  }),
  setSelectedCourse: (course) => ({
    type: lecturerCourseActions.SET_SELECTED_COURSE,
    course,
  }),
};

export default lecturerCourseActions;
