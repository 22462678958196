import academicDocuments from './academicDocuments';
import admission from './admission';
import appInitialState from './app';
import authInitialState from './auth';
import businessIntelligence from './BusinessIntelligence';
import courseAssignmentState from './courseAssignment';
import enrollmentAndRegistration from './enrollmentAndRegistration';
import eventsInitialState from './events-mgt';
import eVoting from './eVoting';
import feeInitialState from './fees-mgt';
import institutionPolicy from './institutionPolicy';
import institutionSetup from './institutionSetup';
import lecturerInitialState from './lecturerManager';
import NTCManager from './NTCManager';
import paymentAndTransaction from './paymentAndTransaction';
import programmeInitialState from './programme-mgt';
import pujabMgtInitialState from './pujab-mgt';
import resultManager from './resultManager';
import senate from './senate';
import setting from './setting';
import sharedInitialSTate from './shared';
import studentsInitialState from './students-mgt';
import systemManager from './system-mgt';
import tab from './tab';
import universalPayment from './universalPayment';
import userMgtInitialState from './user-mgt';
import itsDataInitialState from './its-data';
import studentClearance from './StudentClearance';

export default {
  ...appInitialState,
  ...authInitialState,
  ...programmeInitialState,
  ...userMgtInitialState,
  ...eventsInitialState,
  ...feeInitialState,
  ...studentsInitialState,
  ...setting,
  tab,
  sharedInitialSTate,
  ...admission,
  ...institutionPolicy,
  ...paymentAndTransaction,
  ...enrollmentAndRegistration,
  ...institutionSetup,
  ...courseAssignmentState,
  ...universalPayment,
  ...resultManager,
  senate,
  ...NTCManager,
  ...academicDocuments,
  ...businessIntelligence,
  ...studentClearance,
  ...lecturerInitialState,
  ...pujabMgtInitialState,
  ...systemManager,
  ...eVoting,
  ...itsDataInitialState,
};
