const applicant = {
  GET_PROGRAMMES_BY_CONTEXT_REQUEST: 'GET_PROGRAMMES_BY_CONTEXT_REQUEST',
  GET_PROGRAMMES_BY_CONTEXT_SUCCESS: 'GET_PROGRAMMES_BY_CONTEXT_SUCCESS',
  GET_PROGRAMMES_BY_CONTEXT_ERROR: 'GET_PROGRAMMES_BY_CONTEXT_ERROR',

  SEARCH_FOR_APPLICANTS_REQUEST: 'SEARCH_FOR_APPLICANTS_REQUEST',
  SEARCH_FOR_APPLICANTS_SUCCESS: 'SEARCH_FOR_APPLICANTS_SUCCESS',
  SEARCH_FOR_APPLICANTS_ERROR: 'SEARCH_FOR_APPLICANTS_ERROR',

  GET_ADMISSION_APPLICANTS_REQUEST: 'GET_ADMISSION_APPLICANTS_REQUEST',
  GET_ADMISSION_APPLICANTS_SUCCESS: 'GET_ADMISSION_APPLICANTS_SUCCESS',
  GET_ADMISSION_APPLICANTS_ERROR: 'GET_ADMISSION_APPLICANTS_ERROR',

  GET_WEIGHTED_APPLICANTS_REQUEST: 'GET_WEIGHTED_APPLICANTS_REQUEST',
  GET_WEIGHTED_APPLICANTS_SUCCESS: 'GET_WEIGHTED_APPLICANTS_SUCCESS',
  GET_WEIGHTED_APPLICANTS_ERROR: 'GET_WEIGHTED_APPLICANTS_ERROR',

  GET_SELECTED_APPLICANTS_REQUEST: 'GET_SELECTED_APPLICANTS_REQUEST',
  GET_SELECTED_APPLICANTS_SUCCESS: 'GET_SELECTED_APPLICANTS_SUCCESS',
  GET_SELECTED_APPLICANTS_ERROR: 'GET_SELECTED_APPLICANTS_ERROR',

  GET_NOT_SELECTED_APPLICANTS_REQUEST: 'GET_NOT_SELECTED_APPLICANTS_REQUEST',
  GET_NOT_SELECTED_APPLICANTS_SUCCESS: 'GET_NOT_SELECTED_APPLICANTS_SUCCESS',
  GET_NOT_SELECTED_APPLICANTS_ERROR: 'GET_NOT_SELECTED_APPLICANTS_ERROR',

  DOWNLOAD_WEIGHTED_APPLICANTS_REQUEST: 'DOWNLOAD_WEIGHTED_APPLICANTS_REQUEST',
  DOWNLOAD_WEIGHTED_APPLICANTS_SUCCESS: 'DOWNLOAD_WEIGHTED_APPLICANTS_SUCCESS',
  DOWNLOAD_WEIGHTED_APPLICANTS_ERROR: 'DOWNLOAD_WEIGHTED_APPLICANTS_ERROR',

  DOWNLOAD_SELECTED_APPLICANTS_REQUEST: 'DOWNLOAD_SELECTED_APPLICANTS_REQUEST',
  DOWNLOAD_SELECTED_APPLICANTS_SUCCESS: 'DOWNLOAD_SELECTED_APPLICANTS_SUCCESS',
  DOWNLOAD_SELECTED_APPLICANTS_ERROR: 'DOWNLOAD_SELECTED_APPLICANTS_ERROR',

  RECOMMEND_APPLICANT_REQUEST: 'RECOMMEND_APPLICANT_REQUEST',
  RECOMMEND_APPLICANT_SUCCESS: 'RECOMMEND_APPLICANT_SUCCESS',
  RECOMMEND_APPLICANT_ERROR: 'RECOMMEND_APPLICANT_ERROR',

  DOWNLOAD_NOT_SELECTED_APPLICANTS_REQUEST:
    'DOWNLOAD_NOT_SELECTED_APPLICANTS_REQUEST',
  DOWNLOAD_NOT_SELECTED_APPLICANTS_SUCCESS:
    'DOWNLOAD_NOT_SELECTED_APPLICANTS_SUCCESS',
  DOWNLOAD_NOT_SELECTED_APPLICANTS_ERROR:
    'DOWNLOAD_NOT_SELECTED_APPLICANTS_ERROR',

  GENERATE_NOT_SELECTED_APPLICANTS_REASON_REQUEST:
    'GENERATE_NOT_SELECTED_APPLICANTS_REASON_REQUEST',
  GENERATE_NOT_SELECTED_APPLICANTS_REASON_SUCCESS:
    'GENERATE_NOT_SELECTED_APPLICANTS_REASON_SUCCESS',
  GENERATE_NOT_SELECTED_APPLICANTS_REASON_ERROR:
    'GENERATE_NOT_SELECTED_APPLICANTS_REASON_ERROR',

  DOWNLOAD_FORM_ATTACHMENT_REQUEST: 'DOWNLOAD_FORM_ATTACHMENT_REQUEST',
  DOWNLOAD_FORM_ATTACHMENT_SUCCESS: 'DOWNLOAD_FORM_ATTACHMENT_SUCCESS',
  DOWNLOAD_FORM_ATTACHMENT_ERROR: 'DOWNLOAD_FORM_ATTACHMENT_ERROR',

  DOWNLOAD_ADMISSION_APPLICANTS_REQUEST:
    'DOWNLOAD_ADMISSION_APPLICANTS_REQUEST',
  DOWNLOAD_ADMISSION_APPLICANTS_SUCCESS:
    'DOWNLOAD_ADMISSION_APPLICANTS_SUCCESS',
  DOWNLOAD_ADMISSION_APPLICANTS_ERROR: 'DOWNLOAD_ADMISSION_APPLICANTS_ERROR',

  DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_REQUEST:
    'DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_REQUEST',
  DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_SUCCESS:
    'DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_SUCCESS',
  DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_ERROR:
    'DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_ERROR',

  DOWNLOAD_ALL_APPLICANTS_SUBJECTS_REQUEST:
    'DOWNLOAD_ALL_APPLICANTS_SUBJECTS_REQUEST',
  DOWNLOAD_ALL_APPLICANTS_SUBJECTS_SUCCESS:
    'DOWNLOAD_ALL_APPLICANTS_SUBJECTS_SUCCESS',
  DOWNLOAD_ALL_APPLICANTS_SUBJECTS_ERROR:
    'DOWNLOAD_ALL_APPLICANTS_SUBJECTS_ERROR',

  GET_ADMISSION_SCHEME_REPORTS_REQUEST: 'GET_ADMISSION_SCHEME_REPORTS_REQUEST',
  GET_ADMISSION_SCHEME_REPORTS_SUCCESS: 'GET_ADMISSION_SCHEME_REPORTS_SUCCESS',
  GET_ADMISSION_SCHEME_REPORTS_ERROR: 'GET_ADMISSION_SCHEME_REPORTS_ERROR',

  GET_DETAILED_ADMISSION_SCHEME_REPORTS_REQUEST:
    'GET_DETAILED_ADMISSION_SCHEME_REPORTS_REQUEST',
  GET_DETAILED_ADMISSION_SCHEME_REPORTS_SUCCESS:
    'GET_DETAILED_ADMISSION_SCHEME_REPORTS_SUCCESS',
  GET_DETAILED_ADMISSION_SCHEME_REPORTS_ERROR:
    'GET_DETAILED_ADMISSION_SCHEME_REPORTS_ERROR',

  GET_ADMISSION_PROGRAMME_DETAILS_REQUEST:
    'GET_ADMISSION_PROGRAMME_DETAILS_REQUEST',
  GET_ADMISSION_PROGRAMME_DETAILS_SUCCESS:
    'GET_ADMISSION_PROGRAMME_DETAILS_SUCCESS',
  GET_ADMISSION_PROGRAMME_DETAILS_ERROR:
    'GET_ADMISSION_PROGRAMME_DETAILS_ERROR',

  GET_APPLICATION_FORM_DETAILS_REQUEST: 'GET_APPLICATION_FORM_DETAILS_REQUEST',
  GET_APPLICATION_FORM_DETAILS_SUCCESS: 'GET_APPLICATION_FORM_DETAILS_SUCCESS',
  GET_APPLICATION_FORM_DETAILS_ERROR: 'GET_APPLICATION_FORM_DETAILS_ERROR',

  DOWNLOAD_UNEB_REPORTS_REQUEST: 'DOWNLOAD_UNEB_REPORTS_REQUEST',
  DOWNLOAD_UNEB_REPORTS_SUCCESS: 'DOWNLOAD_UNEB_REPORTS_SUCCESS',
  DOWNLOAD_UNEB_REPORTS_ERROR: 'DOWNLOAD_UNEB_REPORTS_ERROR',

  UPDATE_APPLICANT_DETAILS_REQUEST: 'UPDATE_APPLICANT_DETAILS_REQUEST',
  UPDATE_APPLICANT_DETAILS_SUCCESS: 'UPDATE_APPLICANT_DETAILS_SUCCESS',
  UPDATE_APPLICANT_DETAILS_ERROR: 'UPDATE_APPLICANT_DETAILS_ERROR',

  ADMINISTRATIVELY_ADMIT_APPLICANT_REQUEST:
    ' ADMINISTRATIVELY_ADMIT_APPLICANT_REQUEST',
  ADMINISTRATIVELY_ADMIT_APPLICANT_SUCCESS:
    'ADMINISTRATIVELY_ADMIT_APPLICANT_SUCCESS',
  ADMINISTRATIVELY_ADMIT_APPLICANT_ERROR:
    'ADMINISTRATIVELY_ADMIT_APPLICANT_ERROR',

  GENERATE_APPLICANT_PRN_REQUEST: 'GENERATE_APPLICANT_PRN_REQUEST',
  GENERATE_APPLICANT_PRN_SUCCESS: 'GENERATE_APPLICANT_PRN_SUCCESS',
  GENERATE_APPLICANT_PRN_ERROR: 'GENERATE_APPLICANT_PRN_ERROR',

  DOWNLOAD_DIPLOMA_SCHEME_REQUEST: 'DOWNLOAD_DIPLOMA_SCHEME_REQUEST',
  DOWNLOAD_DIPLOMA_SCHEME_SUCCESS: 'DOWNLOAD_DIPLOMA_SCHEME_SUCCESS',
  DOWNLOAD_DIPLOMA_SCHEME_ERROR: 'DOWNLOAD_DIPLOMA_SCHEME_ERROR',

  SET_SELECTED_ADMISSION_PROGRAMME: 'SET_SELECTED_ADMISSION_PROGRAMME',
  SET_SELECTED_ADMISSION_DETAIL: 'SET_SELECTED_ADMISSION_DETAIL',
  SET_CATEGORY_SCHEME_CONTEXT: 'SET_CATEGORY_SCHEME_CONTEXT',
  SET_SINGLE_SEARCHED_APPLICANT: 'SET_SINGLE_SEARCHED_APPLICANT',
  SET_FORM_SECTION_DETAILS: 'SET_FORM_SECTION_DETAILS',
  SET_APPLICANT_DETAILS: 'SET_APPLICANT_DETAILS',

  DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_REQUEST:
    'DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_REQUEST',
  DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_SUCCESS:
    'DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_SUCCESS',
  DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_ERROR:
    'DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_ERROR',

  administrativelyAdmitApplicants: (data) => ({
    type: applicant.ADMINISTRATIVELY_ADMIT_APPLICANT_REQUEST,
    data,
  }),

  getAdmissionProgrammes: (params) => ({
    type: applicant.GET_PROGRAMMES_BY_CONTEXT_REQUEST,
    params,
  }),

  searchForApplicants: (params) => ({
    type: applicant.SEARCH_FOR_APPLICANTS_REQUEST,
    params,
  }),

  getAdmissionApplicants: (programmeCampusId) => ({
    type: applicant.GET_ADMISSION_APPLICANTS_REQUEST,
    programmeCampusId,
  }),

  getWeightedApplicants: (programmeCampusId) => ({
    type: applicant.GET_WEIGHTED_APPLICANTS_REQUEST,
    programmeCampusId,
  }),

  getSelectedApplicants: (programmeCampusId) => ({
    type: applicant.GET_SELECTED_APPLICANTS_REQUEST,
    programmeCampusId,
  }),

  getNotSelectedApplicants: (programmeCampusId) => ({
    type: applicant.GET_NOT_SELECTED_APPLICANTS_REQUEST,
    programmeCampusId,
  }),

  downloadWeightedApplicants: (programmeCampusId) => ({
    type: applicant.DOWNLOAD_WEIGHTED_APPLICANTS_REQUEST,
    programmeCampusId,
  }),

  downloadSelectedApplicants: (programmeCampusId) => ({
    type: applicant.DOWNLOAD_SELECTED_APPLICANTS_REQUEST,
    programmeCampusId,
  }),

  downloadNotSelectedApplicants: (programmeCampusId) => ({
    type: applicant.DOWNLOAD_NOT_SELECTED_APPLICANTS_REQUEST,
    programmeCampusId,
  }),

  generateNotSelectedApplicantsReason: (programmeCampusId) => ({
    type: applicant.GENERATE_NOT_SELECTED_APPLICANTS_REASON_REQUEST,
    programmeCampusId,
  }),

  downloadFormAttachment: (attachmentId, title) => ({
    type: applicant.DOWNLOAD_FORM_ATTACHMENT_REQUEST,
    attachmentId,
    title,
  }),

  downloadAdmissionApplicants: (programmeCampusId, context) => ({
    type: applicant.DOWNLOAD_ADMISSION_APPLICANTS_REQUEST,
    programmeCampusId,
    context,
  }),

  downloadDiplomaSchemeReport: (programmeCampusId, context) => ({
    type: applicant.DOWNLOAD_DIPLOMA_SCHEME_REQUEST,
    programmeCampusId,
    context,
  }),

  downloadGraduateProgrammeApplicants: (
    programmeCampusId,
    context,
    docType
  ) => ({
    type: applicant.DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_REQUEST,
    programmeCampusId,
    context,
    docType,
  }),

  downloadApplicantsSubjectCombinations: (context) => ({
    type: applicant.DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_REQUEST,
    context,
  }),

  downloadAllApplicantsSubjects: (context) => ({
    type: applicant.DOWNLOAD_ALL_APPLICANTS_SUBJECTS_REQUEST,
    context,
  }),

  updateApplicantDetails: (
    applicantId,
    data,
    programmeCampusId,
    formId,
    searchParams
  ) => ({
    type: applicant.UPDATE_APPLICANT_DETAILS_REQUEST,
    programmeCampusId,
    searchParams,
    applicantId,
    formId,
    data,
  }),

  getAdmissionProgrammeDetails: (params) => ({
    type: applicant.GET_ADMISSION_PROGRAMME_DETAILS_REQUEST,
    params,
  }),

  getApplicationFormDetails: (formId) => ({
    type: applicant.GET_APPLICATION_FORM_DETAILS_REQUEST,
    formId,
  }),

  downloadUNEBReports: (params) => ({
    type: applicant.DOWNLOAD_UNEB_REPORTS_REQUEST,
    params,
  }),

  setSelectedAdmission: (data) => ({
    type: applicant.SET_SELECTED_ADMISSION_DETAIL,
    data,
  }),

  setSchemeContext: (data) => ({
    type: applicant.SET_CATEGORY_SCHEME_CONTEXT,
    data,
  }),

  getAdmissionSchemeReports: (params) => ({
    type: applicant.GET_ADMISSION_SCHEME_REPORTS_REQUEST,
    params,
  }),

  getDetailedAdmissionSchemeReports: (params) => ({
    type: applicant.GET_DETAILED_ADMISSION_SCHEME_REPORTS_REQUEST,
    params,
  }),

  setSelectedProgramme: (data) => ({
    type: applicant.SET_SELECTED_ADMISSION_PROGRAMME,
    data,
  }),

  setApplicantDetails: (data) => ({
    type: applicant.SET_APPLICANT_DETAILS,
    data,
  }),

  setFormSectionDetails: (section) => ({
    type: applicant.SET_FORM_SECTION_DETAILS,
    section,
  }),

  setApplicantData: (section) => ({
    type: applicant.SET_SINGLE_SEARCHED_APPLICANT,
    section,
  }),

  generateApplicantPRN: (formId, programmeCampusId, data) => ({
    type: applicant.GENERATE_APPLICANT_PRN_REQUEST,
    formId,
    programmeCampusId,
    data,
  }),

  recommendApplicant: (formId, data, programmeCampusId) => ({
    type: applicant.RECOMMEND_APPLICANT_REQUEST,
    formId,
    data,
    programmeCampusId,
  }),
};

export default applicant;
