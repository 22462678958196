import initialState from '../initialState';

function settings(state = initialState.setting, action) {
  switch (action.type) {
    case 'SET_SELECTED_MENU':
      return {
        ...state,
        selectedMenu: action.payload,
      };

    case 'IS_TOGGLE_LEFT_MENU':
      return {
        ...state,
        isToggleLeftMenu: action.payload,
      };

    case 'SHOW_EDIT_USER_MODAL':
      return {
        ...state,
        showEditUserModal: action.payload,
      };

    case 'SHOW_EDIT_ACCESS_DOMAIN_MODAL':
      return {
        ...state,
        showEditAccessDomainModal: action.payload,
      };

    case 'SHOW_ACCESS_DOMAIN_MODAL':
      return {
        ...state,
        showAccessDomainModal: action.payload,
      };

    case 'SHOW_MODAL':
      return {
        ...state,
        showModal: action.payload,
      };

    case 'SHOW_CLEAR_STUDENT_MODAL':
      return {
        ...state,
        showClearStudentModal: action.payload,
      };

    case 'SHOW_CURRENT_PROG_MODAL':
      return {
        ...state,
        showCurrentProgModal: action.payload,
      };

    case 'SHOW_PRN_MODAL':
      return {
        ...state,
        showPRNModal: action.payload,
      };

    case 'SHOW_PRINT_STUDENT_LEDGER_MODAL':
      return {
        ...state,
        showPrintStudentLedgerModal: action.payload,
      };

    case 'SHOW_SPONSOR_RECEIPT_MODAL':
      return {
        ...state,
        showSponsorReceiptModal: action.payload,
      };

    case 'SHOW_UPLOAD_MODAL':
      return {
        ...state,
        showUploadModal: action.payload,
      };

    case 'OPEN_MODAL':
      return {
        ...state,
        openModal: action.payload,
      };

    case 'SHOW_EDIT_FEES_DISCOUNT_MODAL':
      return {
        ...state,
        showEditFeesDiscountModal: action.payload,
      };

    case 'SHOW_DELETE_MODAL':
      return {
        ...state,
        showDeleteModal: action.payload,
      };

    case 'SHOW_ALLOCATE_TRANSACTION_MODAL':
      return {
        ...state,
        showAllocateTransactionModal: action.payload,
      };

    case 'SHOW_CREATE_METADATA_MODAL':
      return {
        ...state,
        showCreateMetadataModal: action.payload,
      };

    case 'SHOW_EDIT_METADATA_VALUE_MODAL':
      return {
        ...state,
        showEditMetadataValueModal: action.payload,
      };

    case 'SHOW_COLLEGE_MODAL':
      return {
        ...state,
        showCollegeModal: action.payload,
      };

    case 'SHOW_FACULTY_MODAL':
      return {
        ...state,
        showFacultyModal: action.payload,
      };

    case 'SHOW_DEPARTMENT_MODAL':
      return {
        ...state,
        showDepartmentModal: action.payload,
      };

    case 'SHOW_EDIT_SUBJECT_MODAL':
      return {
        ...state,
        showEditSubjectModal: action.payload,
      };

    case 'SHOW_EDIT_UNEB_SUBJECT_MODAL':
      return {
        ...state,
        showEditUnebSubjectModal: action.payload,
      };

    case 'SHOW_EDIT_SUBJECT_COMBINATION_MODAL':
      return {
        ...state,
        showEditSubjectCombinationModal: action.payload,
      };

    case 'SHOW_CREATE_SUBJECT_COMBINATION_MODAL':
      return {
        ...state,
        showCreateSubjectCombinationModal: action.payload,
      };

    case 'SHOW_EDIT_SPECIALIZATION_MODAL':
      return {
        ...state,
        showEditSpecializationModal: action.payload,
      };

    case 'SHOW_EDIT_SEMESTER_LOAD_MODAL':
      return {
        ...state,
        showEditSemesterLoadModal: action.payload,
      };

    case 'SHOW_EDIT_PROGRAMME_ALIAS_MODAL':
      return {
        ...state,
        showEditProgrammeAliasModal: action.payload,
      };

    case 'SET_SELECTED_PROGRAMME':
      return {
        ...state,
        selectedProgramme: action.payload,
      };

    case 'SET_SELECTED_PROGRAMME_VERSION':
      return {
        ...state,
        selectedProgrammeVersion: action.payload,
      };

    case 'SHOW_ADD_USER_ROLE_APPLICATION_MODAL':
      return {
        ...state,
        showAddRoleApplicationModal: action.payload,
      };

    case 'SET_SELECTED_USER_PROFILE':
      return {
        ...state,
        selectedUserProfile: action.payload,
      };

    case 'SET_SELECTED_ACADEMIC_YEAR':
      return {
        ...state,
        selectedAcademicYear: action.payload,
      };

    case 'SHOW_CREATE_ROLE_GROUP_MODAL':
      return {
        ...state,
        showCreateRoleGroupModal: action.payload,
      };

    case 'SHOW_EDIT_ROLE_GROUP_MODAL':
      return {
        ...state,
        showEditRoleGroupModal: action.payload,
      };

    case 'SHOW_CREATE_SECURITY_PROFILE_MODAL':
      return {
        ...state,
        showCreateSecurityProfileModal: action.payload,
      };

    case 'SET_SELECTED_SECURITY_PROFILE':
      return {
        ...state,
        selectedSecurityProfile: action.payload,
      };

    case 'SHOW_CREATE_USER_ROLE_MODAL':
      return {
        ...state,
        showCreateUserRoleModal: action.payload,
      };

    case 'SHOW_EDIT_USER_ROLE_MODAL':
      return {
        ...state,
        showEditUserRoleModal: action.payload,
      };

    case 'SET_SELECTED_USER_ROLE':
      return {
        ...state,
        selectedUserRole: action.payload,
      };

    case 'SET_SELECTED_ADMISSION_SCHEME':
      return {
        ...state,
        selectedAdmissionScheme: action.payload,
      };

    case 'SET_PROGRAMME_TO_ADVERTISE':
      return {
        ...state,
        programmesToAdvertise: [...state.programmesToAdvertise, action.payload],
      };

    case 'REMOVE_PROGRAMME_TO_ADVERTISE': {
      const newSelectedProgrammes = state.programmesToAdvertise.filter(
        (programme) => programme.id !== action.payload.id
      );
      return {
        ...state,
        programmesToAdvertise: newSelectedProgrammes,
      };
    }

    case 'REMOVE_ALL_PROGRAMMES_TO_ADVERTISE':
      return {
        ...state,
        programmesToAdvertise: [],
      };

    case 'SET_SELECTED_SYSTEM_APP':
      return {
        ...state,
        selectedSystemApp: action.payload,
      };

    case 'SET_SELECTED_SYSTEM_USER':
      return {
        ...state,
        selectedSystemUser: action.payload,
      };

    case 'SHOW_CREATE_FEES_ELEMENTS_MODAL':
      return {
        ...state,
        showFeesElementsModal: action.payload,
      };

    case 'SHOW_EDIT_FEES_ELEMENTS_MODAL':
      return {
        ...state,
        showEditFeesElementsModal: action.payload,
      };

    case 'SHOW_ASSIGN_USER_ROLE_MODAL':
      return {
        ...state,
        showAssignUserRoleModal: action.payload,
      };

    case 'SHOW_EDIT_OTHER_FEES_AMOUNT_MODAL':
      return {
        ...state,
        showEditOtherFeesAmountModal: action.payload,
      };

    case 'SHOW_EDIT_FEES_WAIVERS_MODAL':
      return {
        ...state,
        showEditFeesWaiversModal: action.payload,
      };

    case 'SHOW_CREATE_FEES_WAIVERS_MODAL':
      return {
        ...state,
        showCreateFeesWaiversModal: action.payload,
      };

    case 'SHOW_EDIT_TUITION_MODAL':
      return {
        ...state,
        showEditTuition: action.payload,
      };

    case 'SHOW_ADD_ROLE_GROUP_ADMIN_MODAL':
      return {
        ...state,
        showAddRoleGroupAdminModal: action.payload,
      };
    case 'SHOW_OPEN_ADMISSION_MODAL':
      return {
        ...state,
        showOpenAdmissionsModal: action.payload,
        isEditingAdmission: action.isEditingAdmission,
        admissionToEdit: action.admissionToEdit,
        admissionEditAction: action.admissionEditAction,
      };
    case 'SET_CURRENT_PUJAB_TAB':
      return {
        ...state,
        currentPujabTab: action.payload,
      };
    case 'SET_UNEB_CENTER_TAB':
      return {
        ...state,
        currentUnebCenterTab: action.payload,
      };

    case 'SHOW_ASSIGN_ROLE_TO_USER_MODAL':
      return {
        ...state,
        showAssignRoleToUserModal: action.payload,
      };
    case 'SHOW_ADVERTISING_PROGRAM_MODAL':
      return {
        ...state,
        showAdvertisingProgramModal: action.payload,
      };

    case 'SHOW_EDIT_EVENT_MODAL':
      return {
        ...state,
        showEditModal: action.payload,
      };

    case 'SHOW_DELETE_EVENT_MODAL':
      return {
        ...state,
        showDeleteEventModal: action.payload,
      };

    case 'SHOW_DELETE_VERSION_CU_MODAL':
      return {
        ...state,
        showDeleteVersionCUModal: action.payload,
      };

    case 'SHOW_DELETE_ROOM':
      return {
        ...state,
        showDeleteRoom: action.payload,
      };

    case 'SHOW_EDIT_ROOM':
      return {
        ...state,
        showEditRoom: action.payload,
      };

    case 'SHOW_EDIT_MODAL':
      return {
        ...state,
        showEditModal: action.payload,
      };

    case 'SHOW_DETAILS_MODAL':
      return {
        ...state,
        showDetailsModal: action.payload,
      };

    case 'SHOW_SINGLE_APPLICATION_MODAL':
      return {
        ...state,
        showSingleApplicationFormModal: action.payload,
      };

    case 'SHOW_Form_Edit_MODAL':
      return {
        ...state,
        showFormEditModal: action.payload,
      };

    case 'SHOW_RESET_PASSWORD_MODAL':
      return {
        ...state,
        showResetPasswordModal: action.payload,
      };

    case 'SHOW_ADMIT_STUDENT_MODAL':
      return {
        ...state,
        showAdmitStudentModal: action.payload,
      };

    case 'SHOW_CREATE_STUDENT_MODAL':
      return {
        ...state,
        showCreateStudentModal: action.payload,
      };

    case 'SHOW_IMPORT_APPLICANTS':
      return {
        ...state,
        showImportApplicantModal: action.payload,
      };

    case 'SHOW_EDIT_ADMITTED_STUDENT':
      return {
        ...state,
        showEditAdmittedStudentModal: action.payload,
      };

    case 'SHOW_BUILDING_MODAL':
      return {
        ...state,
        showBuildingModal: action.payload,
      };

    case 'SHOW_ROOMS_MODAL':
      return {
        ...state,
        showRoomsModal: action.payload,
      };

    case 'SHOW_CHANGE_OF_PROGRAMME':
      return {
        ...state,
        showChangeOfProgrammeModal: action.payload,
      };

    case 'SHOW_PRINT_ADMISSIONS':
      return {
        ...state,
        showPrintAdmissionModal: action.payload,
      };

    case 'SHOW_UPLOAD_COURSE_WORK':
      return {
        ...state,
        showUploadCourseWorkModal: action.payload,
      };

    case 'SHOW_UPLOAD_FINAL_MARK':
      return {
        ...state,
        showUploadFinalWorkModal: action.payload,
      };

    case 'SHOW_REGISTRATION_MODAL':
      return {
        ...state,
        showRegistrationModal: action.payload,
      };

    case 'SHOW_DE_REGISTER_MODAL':
      return {
        ...state,
        showDeRegisterModal: action.payload,
      };

    case 'SHOW_LATE_ENROLLMENT_MODAL':
      return {
        ...state,
        showLateEnrollmentModal: action.payload,
      };

    case 'SHOW_RETAKE_ENROLLMENT_MODAL':
      return {
        ...state,
        showRetakeEnrollmentModal: action.payload,
      };

    case 'SHOW_REGISTER_FULLY_MODAL':
      return {
        ...state,
        showRegisterFullyModal: action.payload,
      };

    case 'SHOW_ENROLLMENT_MODAL':
      return {
        ...state,
        showEnrollmentModal: action.payload,
      };

    case 'SHOW_DE_ENROLL_MODAL':
      return {
        ...state,
        showDeEnrollModal: action.payload,
      };

    case 'SET_EVENT_CONTEXT_DATA':
      return {
        ...state,
        eventContextData: action.payload,
      };
    case 'SHOW_OFFSET_INVOICE_MODAL':
      return {
        ...state,
        showOffsetInvoiceModal: {
          show: action.show,
          invoiceData: action.invoiceData,
        },
      };
    case 'SHOW_INVOICE_ACTIONS_MODAL':
      return {
        ...state,
        showInvoiceActionsModal: {
          show: action.show,
          invoices: action.selectedInvoices,
          actionType: action.actionType,
          url: action.url,
        },
      };
    case 'SHOW_ADD_PROGRAMME_CRITERIA_MODAL':
      return {
        ...state,
        showProgrammeCriteriaModal: action.payload,
      };
    case 'SHOW_FACULTY_DOWNLOAD_MODAL':
      return {
        ...state,
        showFacultyDownloadModal: action.payload,
      };
    case 'SHOW_GENERATE_PRN_MODAL':
      return {
        ...state,
        showGeneratePRNModal: action.payload,
      };
    case 'SHOW_ADMINISTRATIVE_ADMISSION_MODAL':
      return {
        ...state,
        showAdministrativeAdmissionModal: action.payload,
      };
    case 'SHOW_RESULTS_APPROVAL_MODAL':
      return {
        ...state,
        showResultsApprovalModal: action.payload,
      };
    case 'SHOW_TRANSFER_FUNDS_MODAL':
      return {
        ...state,
        showTransferFundsModal: action.payload,
      };
    case 'SHOW_PUJAB_PROGRAMMES_MODAL':
      return {
        ...state,
        showPujabProgrammesModal: action.payload,
      };
    case 'SHOW_USER_ACTION_AUTH_MODAL':
      return {
        ...state,
        showActionAuthenticationModal: action.payload,
      };
    case 'SET_APP_NAVIGATION':
      return {
        ...state,
        appNavigation: action.payload,
      };
    default:
      return state;
  }
}

export default settings;
