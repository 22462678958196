import { academicYearActions } from '../../actions';
import initialState from '../../initialState';

const academicYear = (state = initialState.academicYear, actions) => {
  switch (actions.type) {
    case academicYearActions.GET_ACADEMIC_YEARS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };

    case academicYearActions.GET_ACADEMIC_YEARS_SUCCESS:
      return {
        ...state,
        academicYears: actions.data.academicYears,
        loading: false,
      };

    case academicYearActions.GET_ACADEMIC_YEARS_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_REQUEST:
      return {
        ...state,
        error: {},
        gettingAcademicYearByCampus: true,
        created: {},
      };

    case academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_SUCCESS:
      return {
        ...state,
        academicYearsByCampus: actions.data.academicYears,
        gettingAcademicYearByCampus: false,
      };

    case academicYearActions.GET_ACADEMIC_YEARS_BY_CAMPUS_ERROR:
      return {
        ...state,
        error: actions.error,
        gettingAcademicYearByCampus: false,
      };

    case academicYearActions.CREATE_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
        createdAcademicYear: {},
      };

    case academicYearActions.CREATE_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        createdAcademicYear: actions.data.academicYear,
        creating: false,
      };

    case academicYearActions.CREATE_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case academicYearActions.CREATE_NEW_SEMESTER_REQUEST:
      return {
        ...state,
        createSemesterError: {},
        creatingSemester: true,
      };

    case academicYearActions.CREATE_NEW_SEMESTER_SUCCESS:
      return {
        ...state,
        createdSemester: actions.data.academicYear,
        creatingSemester: false,
      };

    case academicYearActions.CREATE_NEW_SEMESTER_ERROR:
      return {
        ...state,
        createSemesterError: actions.error,
        creatingSemester: false,
      };

    case academicYearActions.UPDATE_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        updateAcademicYrError: {},
        updatingAcademicYr: true,
      };

    case academicYearActions.UPDATE_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        updatedAcademicYr: actions.data.academicYear,
        updatingAcademicYr: false,
      };

    case academicYearActions.UPDATE_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        updateAcademicYrError: actions.error,
        updatingAcademicYr: false,
      };

    case academicYearActions.DELETE_ACADEMIC_YEAR_REQUEST:
      return {
        ...state,
        deleteAcademicYrError: {},
        deletingAcademicYr: true,
      };

    case academicYearActions.DELETE_ACADEMIC_YEAR_SUCCESS:
      return {
        ...state,
        deletedAcademicYr: actions.data.academicYear,
        deletingAcademicYr: false,
      };

    case academicYearActions.DELETE_ACADEMIC_YEAR_ERROR:
      return {
        ...state,
        deleteAcademicYrError: actions.error,
        deletingAcademicYr: false,
      };

    case academicYearActions.UPDATE_SEMESTER_DATA_REQUEST:
      return {
        ...state,
        updateSemesterError: {},
        updatingSemester: true,
      };

    case academicYearActions.UPDATE_SEMESTER_DATA_SUCCESS:
      return {
        ...state,
        updatedSemester: actions.data.academicYear,
        updatingSemester: false,
      };

    case academicYearActions.UPDATE_SEMESTER_DATA_ERROR:
      return {
        ...state,
        updateSemesterError: actions.error,
        updatingSemester: false,
      };

    case academicYearActions.DELETE_SEMESTER_REQUEST:
      return {
        ...state,
        deleteSemesterError: {},
        deletingSemester: true,
      };

    case academicYearActions.DELETE_SEMESTER_SUCCESS:
      return {
        ...state,
        deletedSemester: actions.data.academicYear,
        deletingSemester: false,
        editAcadYrData: {
          ...state.editAcadYrData,
          semesters: state.editAcadYrData.semesters.filter(
            (item) => item.id !== actions.semesterId
          ),
        },
      };

    case academicYearActions.DELETE_SEMESTER_ERROR:
      return {
        ...state,
        deleteSemesterError: actions.error,
        deletingSemester: false,
      };

    case academicYearActions.SET_SELECT_ACADEMIC_YEAR:
      return {
        ...state,
        selectedAcademicYear: actions.data,
      };

    case academicYearActions.SET_ACADEMIC_YEAR_TO_EDIT:
      return {
        ...state,
        editAcadYrData: actions.dataToEdit,
      };

    default:
      return state;
  }
};

export default academicYear;
