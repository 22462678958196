import { financeClearanceActions } from '../../actions';
import initialState from '../../initialState';

const financeClearance = (state = initialState.financeClearance, actions) => {
  switch (actions.type) {
    case financeClearanceActions.GET_FC_GRADUATION_STATS_REPORT_REQUEST:
      return {
        ...state,
        gettingGraduationStats: true,
        graduationStatsError: {},
      };
    case financeClearanceActions.GET_FC_GRADUATION_STATS_REPORT_SUCCESS: {
      let { graduationStats } = state;
      const { context } = actions;
      const findIndex = graduationStats.findIndex(
        (report) => report.academic_year_id === context.academic_year_id
      );

      if (findIndex === -1) {
        graduationStats = [
          ...graduationStats,
          { ...context, data: actions.data },
        ];
      } else {
        graduationStats[findIndex] = { ...context, data: actions.data };
      }

      return {
        ...state,
        graduationStats,
        gettingGraduationStats: false,
      };
    }
    case financeClearanceActions.GET_FC_GRADUATION_STATS_REPORT_ERROR:
      return {
        ...state,
        graduationStatsError: actions.error,
        gettingGraduationStats: false,
      };

    case financeClearanceActions.GET_FC_GRADUATION_DETAILED_REPORT_REQUEST:
      return {
        ...state,
        gettingGraduationReport: true,
        graduationReportError: {},
      };
    case financeClearanceActions.GET_FC_GRADUATION_DETAILED_REPORT_SUCCESS: {
      let { graduationReport } = state;
      const { context } = actions;
      const findIndex = graduationReport.findIndex(
        (report) => report.academic_year_id === context.academic_year_id
      );

      if (findIndex === -1) {
        graduationReport = [
          ...graduationReport,
          { ...context, data: actions.data },
        ];
      } else {
        graduationReport[findIndex] = { ...context, data: actions.data };
      }

      return {
        ...state,
        graduationReport,
        gettingGraduationReport: false,
      };
    }
    case financeClearanceActions.GET_FC_GRADUATION_DETAILED_REPORT_ERROR:
      return {
        ...state,
        graduationReportError: actions.error,
        gettingGraduationReport: false,
      };

    case financeClearanceActions.DOWNLOAD_FC_GRADUATION_STATISTICS_REQUEST:
      return {
        ...state,
        downloadingReport: true,
        downloadError: {},
      };
    case financeClearanceActions.DOWNLOAD_FC_GRADUATION_STATISTICS_SUCCESS:
      return {
        ...state,
        downloadedReport: actions.data,
        downloadingReport: false,
      };
    case financeClearanceActions.DOWNLOAD_FC_GRADUATION_STATISTICS_ERROR:
      return {
        ...state,
        downloadError: actions.error,
        downloadingReport: false,
      };

    case financeClearanceActions.CLEAR_STUDENTS_FINANCE_REQUEST:
      return {
        ...state,
        clearingStudents: true,
        clearStudentsError: {},
      };
    case financeClearanceActions.CLEAR_STUDENTS_FINANCE_SUCCESS:
      return {
        ...state,
        clearedStudents: actions.data,
        clearingStudents: false,
      };
    case financeClearanceActions.CLEAR_STUDENTS_FINANCE_ERROR:
      return {
        ...state,
        clearStudentsError: actions.error,
        clearingStudents: false,
      };

    default:
      return state;
  }
};

export default financeClearance;
