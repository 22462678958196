import axios from 'axios';
import FileSaver from 'file-saver';
import { isEmpty } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { admittedStudentsActions } from '../../actions';

function* downloadAdmittedStudentsTemplate(actions) {
  try {
    yield axios({
      url: `/admissions/migrated-applicants/download-admit-applicants-template`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ADMITTED-STUDENTS-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadAllCampusReports(actions) {
  try {
    yield axios({
      url: `/admissions/admission-reports/download-admission-statistics`,
      method: 'POST',
      params: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ALL-CAMPUS-ADMISSION-REPORTS.xlsx`
      );
    });
    yield put({
      type: admittedStudentsActions.DOWNLOAD_ALL_CAMPUS_REPORTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.DOWNLOAD_ALL_CAMPUS_REPORTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadAdmittedStudents(actions) {
  try {
    const response = yield axios({
      url: '/admissions/migrated-applicants/upload-admit-applicants-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: admittedStudentsActions.UPLOAD_ADMITTED_STUDENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.UPLOAD_ADMITTED_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* deleteAdmittedStudents(actions) {
  try {
    const { data, admittedStudentsContext, deleteSRM } = actions;

    const response = yield axios({
      url: `/admissions/admitted/applicants/${
        deleteSRM ? 'delete-srm-students' : 'delete-students'
      }`,
      method: 'DELETE',
      data,
    });
    yield put({
      type: admittedStudentsActions.DELETE_ADMITTED_STUDENTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admittedStudentsActions.SHOW_GENERATE_STUDENT_NUMBERS_MODAL,
      payload: false,
    });
    yield put({
      type: admittedStudentsActions.GET_ADMITTED_STUDENTS_REQUEST,
      context: admittedStudentsContext,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.DELETE_ADMITTED_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* getAdmittedStudents(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/admissions/admitted/applicants',
      method: 'GET',
      params: context,
    });
    yield put({
      type: admittedStudentsActions.GET_ADMITTED_STUDENTS_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.GET_ADMITTED_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* searchAdmittedStudent(actions) {
  try {
    const response = yield axios({
      url: '/admissions/admission-reports/search-admitted-applicant',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: admittedStudentsActions.SEARCH_ADMITTED_STUDENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.SEARCH_ADMITTED_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* editAdmittedStudent(actions) {
  const { applicantId, data, searchQuery } = actions;
  try {
    const response = yield axios({
      url: `/admissions/migrated-applicants/${applicantId}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: admittedStudentsActions.EDIT_ADMITTED_STUDENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_EDIT_ADMITTED_STUDENT',
      payload: false,
    });
    if (!isEmpty(searchQuery)) {
      if (searchQuery?.searchBy) {
        yield put({
          type: admittedStudentsActions.SEARCH_ADMITTED_STUDENTS_REQUEST,
          data: searchQuery,
        });
      } else {
        yield put({
          type: admittedStudentsActions.GET_ADMITTED_STUDENTS_REQUEST,
          context: searchQuery,
        });
      }
    }
  } catch (error) {
    yield put({
      type: admittedStudentsActions.EDIT_ADMITTED_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* updateHallOfAttachment(actions) {
  try {
    const { context, data } = actions;

    const response = yield axios({
      url: '/admissions/migrated-applicants/update-hall',
      method: 'PUT',
      data,
    });
    yield put({
      type: admittedStudentsActions.UPDATE_HALL_OF_ATTACHMENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: admittedStudentsActions.GET_ADMITTED_STUDENTS_REQUEST,
      context,
    });
    yield put({
      type: 'SHOW_EDIT_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.UPDATE_HALL_OF_ATTACHMENT_ERROR,
      error: error.data,
    });
  }
}

function* getAdmissionProgrammes(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/admissions/admitted/programmes',
      method: 'GET',
      params: context,
    });
    yield put({
      type: admittedStudentsActions.GET_ADMISSION_PROGRAMME_SUCCESS,
      data: response.data,
      context,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.GET_ADMISSION_PROGRAMME_ERROR,
      error: error.data,
    });
  }
}

function* generateStudentNumbers(actions) {
  try {
    const response = yield axios({
      url: '/admissions/migrated-applicants/generate-student-numbers',
      method: 'POST',
      params: actions.data,
    });
    yield put({
      type: admittedStudentsActions.GENERATE_STUDENT_NUMBER_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.GENERATE_STUDENT_NUMBER_ERROR,
      error: error.data,
    });
  }
}

function* generateRegistrationNumbers(actions) {
  try {
    const response = yield axios({
      url: '/admissions/migrated-applicants/generate-registration-numbers',
      method: 'POST',
      params: actions.data,
    });
    yield put({
      type: admittedStudentsActions.GENERATE_REGISTRATION_NUMBER_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.GENERATE_REGISTRATION_NUMBER_ERROR,
      error: error.data,
    });
  }
}

function* generateAdmissionLetters(actions) {
  try {
    const { data, category } = actions;
    const response = yield axios({
      url: '/admissions/admitted/generate-admission-letters',
      method: 'POST',
      data: actions.data,
      timeout: 1200000,
    });
    yield put({
      type: admittedStudentsActions.GENERATE_ADMISSION_LETTERS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
      payload: false,
    });
    yield put({
      type: admittedStudentsActions.GET_ADMITTED_STUDENTS_REQUEST,
      context: { ...data, category },
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.GENERATE_ADMISSION_LETTERS_ERROR,
      error: error.data,
    });
  }
}

function* printAdmissionLetters(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/admissions/admitted/print-admission-letters',
      method: 'POST',
      data: context,
      timeout: 1200000,
      timeoutErrorMessage: 'Request Timed out',
      responseType: 'blob',
    });

    const openInNewTab = (href) => {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href,
      }).click();
    };

    // Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: 'application/pdf' });
    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    // Open the URL on new Window
    openInNewTab(fileURL);
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(file);
    }, 100);
    // window.open(fileURL, '_blank').focus();

    yield put({
      type: admittedStudentsActions.PRINT_ADMISSION_LETTERS_SUCCESS,
      data: { message: 'Admissions Downloaded Successfully ' },
    });
    yield put({
      type: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.PRINT_ADMISSION_LETTERS_ERROR,
      error: error.data,
    });
  }
}

function* pushAdmittedApplicants(actions) {
  try {
    const { action, data } = actions;
    let url = 'push-applicants-to-sic';

    if (action === 'create-new-student-profile') url = action;
    const response = yield axios({
      url: `/students-mgt/students/${url}`,
      method: 'POST',
      params: action,
      data: { applicants: data },
      timeout: 12000000,
    });
    yield put({
      type: admittedStudentsActions.PUSH_ADMITTED_APPLICANTS__RECORDS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_RECORDS_ERROR,
      error: error.data,
    });
  }
}

function* pushAdmittedApplicantsAsPreviousStudents(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/push-applicants-as-previous-students',
      method: 'POST',
      data: { applicants: actions.data },
      timeout: 12000000,
    });
    yield put({
      type: admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* downloadNotPushedStudentsReport(actions) {
  try {
    yield axios({
      url: `/students-mgt/students/download-not-pushed-applicants`,
      method: 'POST',
      params: actions.data,
      responseType: 'blob',
      timeout: 1200000,
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-NOT-PUSHED-STUDENTS.xlsx`
      );
    });
    yield put({
      type: admittedStudentsActions.DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadAdmittedStudentsRecords(actions) {
  try {
    yield axios({
      url: `/admissions/admission-reports/admitted-applicants`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ADMITTED-STUDENTS.xlsx`
      );
    });
    yield put({
      type: admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
      payload: false,
    });
    yield put({
      type: 'SHOW_DOWNLOAD_ADMITTED_STUDENTS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadAdmittedStudentsRecordsPdf(actions) {
  try {
    yield axios({
      url: `/admissions/admission-reports/pdf-admitted-applicants`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-ADMITTED-STUDENTS-PDF.pdf`
      );
    });
    yield put({
      type: admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
    yield put({
      type: 'SHOW_GENERATE_STUDENT_NUMBERS_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* getAdmittedStudentsReport(actions) {
  try {
    const { params } = actions;
    const response = yield axios({
      url: '/admissions/admission-reports/analytics',
      method: 'GET',
      params,
    });
    yield put({
      type: admittedStudentsActions.GET_ADMITTED_STUDENTS_REPORTS_SUCCESS,
      data: response.data,
      context: params,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.GET_ADMITTED_STUDENTS_REPORTS_ERROR,
      error: error.data,
    });
  }
}

function* getAdmissionStatistics(actions) {
  try {
    const response = yield axios({
      url: '/admissions/admission-reports/admission-statistics',
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: admittedStudentsActions.GET_ADMISSION_STATISTICS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: admittedStudentsActions.GET_ADMISSION_STATISTICS_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadAdmittedStudentsTemplate() {
  yield takeLatest(
    admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_TEMPLATE_REQUEST,
    downloadAdmittedStudentsTemplate
  );
}

function* watchUploadAdmittedStudents() {
  yield takeLatest(
    admittedStudentsActions.UPLOAD_ADMITTED_STUDENTS_REQUEST,
    uploadAdmittedStudents
  );
}

function* watchGetAdmittedStudents() {
  yield takeLatest(
    admittedStudentsActions.GET_ADMITTED_STUDENTS_REQUEST,
    getAdmittedStudents
  );
}

function* watchDeleteAdmittedStudents() {
  yield takeLatest(
    admittedStudentsActions.DELETE_ADMITTED_STUDENTS_REQUEST,
    deleteAdmittedStudents
  );
}

function* watchSearchAdmittedStudent() {
  yield takeLatest(
    admittedStudentsActions.SEARCH_ADMITTED_STUDENTS_REQUEST,
    searchAdmittedStudent
  );
}

function* watchEditAdmittedStudent() {
  yield takeLatest(
    admittedStudentsActions.EDIT_ADMITTED_STUDENT_REQUEST,
    editAdmittedStudent
  );
}

function* watchUpdateHallOfAttachment() {
  yield takeLatest(
    admittedStudentsActions.UPDATE_HALL_OF_ATTACHMENT_REQUEST,
    updateHallOfAttachment
  );
}

function* watchGetAdmissionProgrammes() {
  yield takeLatest(
    admittedStudentsActions.GET_ADMISSION_PROGRAMME_REQUEST,
    getAdmissionProgrammes
  );
}

function* watchGenerateStudentNumbers() {
  yield takeLatest(
    admittedStudentsActions.GENERATE_STUDENT_NUMBER_REQUEST,
    generateStudentNumbers
  );
}

function* watchGenerateRegistrationNumbers() {
  yield takeLatest(
    admittedStudentsActions.GENERATE_REGISTRATION_NUMBER_REQUEST,
    generateRegistrationNumbers
  );
}

function* watchGenerateAdmissionLetters() {
  yield takeLatest(
    admittedStudentsActions.GENERATE_ADMISSION_LETTERS_REQUEST,
    generateAdmissionLetters
  );
}

function* watchPrintAdmissionLetters() {
  yield takeLatest(
    admittedStudentsActions.PRINT_ADMISSION_LETTERS_REQUEST,
    printAdmissionLetters
  );
}

function* watchPushAdmittedApplicants() {
  yield takeLatest(
    admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_RECORDS_REQUEST,
    pushAdmittedApplicants
  );
}

function* watchPushAdmittedApplicantsAsPreviousStudents() {
  yield takeLatest(
    admittedStudentsActions.PUSH_ADMITTED_APPLICANTS_AS_PREVIOUS_STUDENTS_REQUEST,
    pushAdmittedApplicantsAsPreviousStudents
  );
}

function* watchDownloadNotPushedStudentsReport() {
  yield takeLatest(
    admittedStudentsActions.DOWNLOAD_NOT_PUSHED_STUDENTS_REPORT_REQUEST,
    downloadNotPushedStudentsReport
  );
}

function* watchDownloadAdmittedStudentsRecords() {
  yield takeLatest(
    admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_REQUEST,
    downloadAdmittedStudentsRecords
  );
}

function* watchDownloadAdmittedStudentsRecordsPdf() {
  yield takeLatest(
    admittedStudentsActions.DOWNLOAD_ADMITTED_STUDENTS_RECORDS_PDF_REQUEST,
    downloadAdmittedStudentsRecordsPdf
  );
}

function* watchGetAdmittedStudentsReport() {
  yield takeLatest(
    admittedStudentsActions.GET_ADMITTED_STUDENTS_REPORTS_REQUEST,
    getAdmittedStudentsReport
  );
}

function* watchGetAdmissionStatistics() {
  yield takeLatest(
    admittedStudentsActions.GET_ADMISSION_STATISTICS_REQUEST,
    getAdmissionStatistics
  );
}

function* watchDownloadAllCampusReports() {
  yield takeLatest(
    admittedStudentsActions.DOWNLOAD_ALL_CAMPUS_REPORTS_REQUEST,
    downloadAllCampusReports
  );
}

export default [
  fork(watchDownloadAdmittedStudentsTemplate),
  fork(watchUploadAdmittedStudents),
  fork(watchGetAdmittedStudents),
  fork(watchSearchAdmittedStudent),
  fork(watchEditAdmittedStudent),
  fork(watchGetAdmissionProgrammes),
  fork(watchDeleteAdmittedStudents),
  fork(watchGenerateStudentNumbers),
  fork(watchGenerateRegistrationNumbers),
  fork(watchGenerateAdmissionLetters),
  fork(watchPrintAdmissionLetters),
  fork(watchPushAdmittedApplicants),
  fork(watchDownloadNotPushedStudentsReport),
  fork(watchDownloadAdmittedStudentsRecords),
  fork(watchDownloadAdmittedStudentsRecordsPdf),
  fork(watchGetAdmittedStudentsReport),
  fork(watchGetAdmissionStatistics),
  fork(watchDownloadAllCampusReports),
  fork(watchUpdateHallOfAttachment),
  fork(watchPushAdmittedApplicantsAsPreviousStudents),
];
