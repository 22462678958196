import { isEmpty } from 'lodash';
import { roleGroupActions } from '../../actions';
import initialState from '../../initialState';

function roleGroup(state = initialState.roleGroup, actions) {
  switch (actions.type) {
    case roleGroupActions.GET_ROLE_GROUP_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case roleGroupActions.GET_ROLE_GROUP_SUCCESS: {
      let { selectedRoleGroup } = state;
      if (!isEmpty(selectedRoleGroup)) {
        const findSelectedGroup = actions.data?.find(
          (userRoleGroup) =>
            parseInt(userRoleGroup.id, 10) ===
            parseInt(selectedRoleGroup.id, 10)
        );
        if (findSelectedGroup) selectedRoleGroup = findSelectedGroup;
      }

      return {
        ...state,
        roleGroups: actions.data,
        error: {},
        selectedRoleGroup,
        loading: false,
      };
    }
    case roleGroupActions.GET_ROLE_GROUP_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case roleGroupActions.GET_ROLE_GROUP_USERS_REQUEST:
      return {
        ...state,
        gettingRoleGroupUsers: true,
        getRoleGroupUserError: {},
      };
    case roleGroupActions.GET_ROLE_GROUP_USERS_SUCCESS: {
      let { roleGroupUsers } = state;
      const groupUsers = {
        roleGroupId: actions.roleGroupId,
        users: actions.data,
      };

      const findGroupIndex = roleGroupUsers.findIndex(
        (roleGroupUser) =>
          parseInt(roleGroupUser.roleGroupId, 10) ===
          parseInt(groupUsers.roleGroupId, 10)
      );
      if (findGroupIndex === -1) {
        roleGroupUsers = [...roleGroupUsers, groupUsers];
      } else {
        roleGroupUsers[findGroupIndex] = groupUsers;
      }

      return {
        ...state,
        roleGroupUsers,
        gettingRoleGroupUsers: false,
      };
    }
    case roleGroupActions.GET_ROLE_GROUP_USERS_ERROR:
      return {
        ...state,
        getRoleGroupUserError: actions.error,
        gettingRoleGroupUsers: false,
      };

    case roleGroupActions.GET_SINGLE_ROLE_GROUP_REQUEST:
      return {
        ...state,
        fetchingOne: true,
        singleRoleGroupError: {},
        singleRoleGroup: {},
      };
    case roleGroupActions.GET_SINGLE_ROLE_GROUP_SUCCESS: {
      const currentRoleGroup = actions.data;
      let { singleRoleGroups } = state;
      if (!isEmpty(currentRoleGroup)) {
        const roleGroupIndex = singleRoleGroups.findIndex(
          (singleRoleGroup) => singleRoleGroup.id === currentRoleGroup.id
        );
        if (roleGroupIndex !== -1) {
          singleRoleGroups[roleGroupIndex] = currentRoleGroup;
        } else {
          singleRoleGroups = [...singleRoleGroups, currentRoleGroup];
        }
      }
      return {
        ...state,
        selectedRoleGroup: currentRoleGroup,
        singleRoleGroup: currentRoleGroup,
        singleRoleGroups,
        fetchingOne: false,
      };
    }
    case roleGroupActions.GET_SINGLE_ROLE_GROUP_ERROR:
      return {
        ...state,
        singleRoleGroupError: actions.error,
        fetchingOne: false,
      };

    case roleGroupActions.SET_SELECTED_ROLE_GROUP:
      return {
        ...state,
        selectedRoleGroup: actions.payload,
      };

    case roleGroupActions.SET_MY_SELECTED_ROLE_GROUP:
      return {
        ...state,
        mySelectedRoleGroup: actions.payload,
      };

    case roleGroupActions.CREATE_ROLE_GROUP_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
        created: {},
      };
    case roleGroupActions.CREATE_ROLE_GROUP_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };
    case roleGroupActions.CREATE_ROLE_GROUP_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };

    case roleGroupActions.UPDATE_ROLE_GROUP_REQUEST:
      return {
        ...state,
        updating: true,
        updateRoleGroupError: {},
        updateRoleGroupSuccess: {},
      };
    case roleGroupActions.UPDATE_ROLE_GROUP_SUCCESS:
      return {
        ...state,
        updating: false,
        updateRoleGroupSuccess: actions.data,
      };
    case roleGroupActions.UPDATE_ROLE_GROUP_ERROR:
      return {
        ...state,
        updating: false,
        updateRoleGroupError: actions.error,
      };

    case roleGroupActions.DELETE_ROLE_GROUP_REQUEST:
      return {
        ...state,
        deleting: true,
        deleteError: {},
      };
    case roleGroupActions.DELETE_ROLE_GROUP_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteSuccess: actions.data,
      };
    case roleGroupActions.DELETE_ROLE_GROUP_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case roleGroupActions.INSERT_ROLE_GROUP_APPS_REQUEST:
      return {
        ...state,
        addingApps: true,
        addAppError: {},
        addedApps: [],
      };
    case roleGroupActions.INSERT_ROLE_GROUP_APPS_SUCCESS:
      return {
        ...state,
        addingApps: false,
        addedApps: actions.data,
      };
    case roleGroupActions.INSERT_ROLE_GROUP_APPS_ERROR:
      return {
        ...state,
        addingApps: false,
        addAppError: actions.error,
      };

    case roleGroupActions.DROP_ROLE_GROUP_APPS_REQUEST:
      return {
        ...state,
        droppingApps: true,
        dropError: {},
        droppedApps: [],
      };
    case roleGroupActions.DROP_ROLE_GROUP_APPS_SUCCESS:
      return {
        ...state,
        droppingApps: false,
        droppedApps: actions.data,
      };
    case roleGroupActions.DROP_ROLE_GROUP_APPS_ERROR:
      return {
        ...state,
        droppingApps: false,
        dropError: actions.error,
      };

    case roleGroupActions.ADD_ROLE_GROUP_ADMIN_REQUEST:
      return {
        ...state,
        addingAdmins: true,
        addAdminError: {},
        addAdminSuccess: [],
      };
    case roleGroupActions.ADD_ROLE_GROUP_ADMIN_SUCCESS:
      return {
        ...state,
        addingAdmins: false,
        addAdminSuccess: actions.data,
      };
    case roleGroupActions.ADD_ROLE_GROUP_ADMIN_ERROR:
      return {
        ...state,
        addingAdmins: false,
        addAdminError: actions.error,
      };

    case roleGroupActions.ACTIVATE_OR_DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        activatingUser: true,
        activateUserSuccess: {},
        activateUserError: {},
      };

    case roleGroupActions.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activatingUser: false,
        activateUserSuccess: actions.data,
      };
    case roleGroupActions.ACTIVATE_OR_DEACTIVATE_USER_ERROR:
      return {
        ...state,
        activatingUser: false,
        activateUserError: actions.error,
      };
    case roleGroupActions.SHOW_DEACTIVATE_USER_MODAL:
      return {
        ...state,
        showDeactivateUserModal: actions.payload,
      };

    default:
      return state;
  }
}

export default roleGroup;
