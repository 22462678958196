const metadata = {
  GET_META_DATA_REQUEST: 'GET_META_DATA_REQUEST',
  GET_META_DATA_SUCCESS: 'GET_META_DATA_SUCCESS',
  GET_META_DATA_ERROR: 'GET_META_DATA_ERROR',

  CREATE_META_DATA_SUCCESS: 'CREATE_META_DATA_SUCCESS',
  CREATE_META_DATA_REQUEST: 'CREATE_META_DATA_REQUEST',
  CREATE_META_DATA_ERROR: 'CREATE_META_DATA_ERROR',

  UPDATE_META_DATA_SUCCESS: 'UPDATE_META_DATA_SUCCESS',
  UPDATE_META_DATA_REQUEST: 'UPDATE_META_DATA_REQUEST',
  UPDATE_META_DATA_ERROR: 'UPDATE_META_DATA_ERROR',

  CREATE_META_DATA_VALUE_SUCCESS: 'CREATE_META_DATA_VALUE_SUCCESS',
  CREATE_META_DATA_VALUE_REQUEST: 'CREATE_META_DATA_VALUE_REQUEST',
  CREATE_META_DATA_VALUE_ERROR: 'CREATE_META_DATA_VALUE_ERROR',

  UPDATE_META_DATA_VALUE_SUCCESS: 'UPDATE_META_DATA_VALUE_SUCCESS',
  UPDATE_META_DATA_VALUE_REQUEST: 'UPDATE_META_DATA_VALUE_REQUEST',
  UPDATE_META_DATA_VALUE_ERROR: 'UPDATE_META_DATA_VALUE_ERROR',

  DELETE_META_DATA_VALUE_SUCCESS: 'DELETE_META_DATA_VALUE_SUCCESS',
  DELETE_META_DATA_VALUE_REQUEST: 'DELETE_META_DATA_VALUE_REQUEST',
  DELETE_META_DATA_VALUE_ERROR: 'DELETE_META_DATA_VALUE_ERROR',

  getMetadata: (data) => ({
    type: metadata.GET_META_DATA_REQUEST,
    data,
  }),

  createMetadata: (data) => ({
    type: metadata.CREATE_META_DATA_REQUEST,
    data,
  }),

  updateMetadata: (metadataId, data) => ({
    type: metadata.UPDATE_META_DATA_REQUEST,
    data,
    metadataId,
  }),

  createMetadataValue: (data) => ({
    type: metadata.CREATE_META_DATA_VALUE_REQUEST,
    data,
  }),

  updateMetadataValue: (metadataValueId, data) => ({
    type: metadata.UPDATE_META_DATA_VALUE_REQUEST,
    data,
    metadataValueId,
  }),

  deleteMetadataValue: (id) => ({
    type: metadata.DELETE_META_DATA_VALUE_REQUEST,
    id,
  }),
};

export default metadata;
