const resultDashboardActions = {
  FETCH_DASHBOARD_DATA_REQUEST: 'FETCH_DASHBOARD_DATA_REQUEST',
  FETCH_DASHBOARD_DATA_SUCCESS: 'FETCH_DASHBOARD_DATA_SUCCESS',
  FETCH_DASHBOARD_DATA_ERROR: 'FETCH_DASHBOARD_DATA_ERROR',

  GET_SEMESTER_PROGRAMMES_REQUEST: 'GET_SEMESTER_PROGRAMMES_REQUEST',
  GET_SEMESTER_PROGRAMMES_SUCCESS: 'GET_SEMESTER_PROGRAMMES_SUCCESS',
  GET_SEMESTER_PROGRAMMES_ERROR: 'GET_SEMESTER_PROGRAMMES_ERROR',

  GET_DETAILED_RESULT_REPORT_REQUEST: 'GET_DETAILED_RESULT_REPORT_REQUEST',
  GET_DETAILED_RESULT_REPORT_SUCCESS: 'GET_DETAILED_RESULT_REPORT_SUCCESS',
  GET_DETAILED_RESULT_REPORT_ERROR: 'GET_DETAILED_RESULT_REPORT_ERROR',

  GET_RESULT_SUMMARY_REPORT_REQUEST: 'GET_RESULT_SUMMARY_REPORT_REQUEST',
  GET_RESULT_SUMMARY_REPORT_SUCCESS: 'GET_RESULT_SUMMARY_REPORT_SUCCESS',
  GET_RESULT_SUMMARY_REPORT_ERROR: 'GET_RESULT_SUMMARY_REPORT_ERROR',

  DOWNLOAD_SEMESTER_RESULT_REQUEST: 'DOWNLOAD_SEMESTER_RESULT_REQUEST',
  DOWNLOAD_SEMESTER_RESULT_SUCCESS: 'DOWNLOAD_SEMESTER_RESULT_SUCCESS',
  DOWNLOAD_SEMESTER_RESULT_ERROR: 'DOWNLOAD_SEMESTER_RESULT_ERROR',

  SET_SELECTED_RESULT_CONTEXT: 'SET_SELECTED_RESULT_CONTEXT',

  SET_DEPARTMENT_CONTEXT: 'SET_DEPARTMENT_CONTEXT',
  SET_SELECTED_STUDY_YEAR: 'SET_SELECTED_STUDY_YEAR',
  SET_PROGRAMMES_TOGGLE: 'SET_PROGRAMMES_TOGGLE',

  SUBMITTING_PROGRAMME_REPORT_REQUEST: 'SUBMITTING_PROGRAMME_REPORT_REQUEST',
  SUBMITTING_PROGRAMME_REPORT_SUCCESS: 'SUBMITTING_PROGRAMME_REPORT_SUCCESS',
  SUBMITTING_PROGRAMME_REPORT_ERROR: 'SUBMITTING_PROGRAMME_REPORT_ERROR',

  setDepartmentContext: (context) => ({
    type: resultDashboardActions.SET_DEPARTMENT_CONTEXT,
    context,
  }),

  setSelectedResultContext: (context) => ({
    type: resultDashboardActions.SET_SELECTED_RESULT_CONTEXT,
    context,
  }),

  getResultSummaryReport: (context, category = 'programme') => ({
    type: resultDashboardActions.GET_RESULT_SUMMARY_REPORT_REQUEST,
    context,
    category,
  }),

  downloadSemesterResult: (context) => ({
    type: resultDashboardActions.DOWNLOAD_SEMESTER_RESULT_REQUEST,
    context,
  }),

  getDepartmentProgrammes: (params) => ({
    type: resultDashboardActions.GET_SEMESTER_PROGRAMMES_REQUEST,
    params,
  }),

  setSelectedStudyYear: (yearId) => ({
    type: resultDashboardActions.SET_SELECTED_STUDY_YEAR,
    yearId,
  }),

  setProgrammesToggle: (bol) => ({
    type: resultDashboardActions.SET_PROGRAMMES_TOGGLE,
    bol,
  }),

  fetchDashboardData: (params) => ({
    type: resultDashboardActions.FETCH_DASHBOARD_DATA_REQUEST,
    params,
  }),

  getResultDetailedReports: (params) => ({
    type: resultDashboardActions.GET_DETAILED_RESULT_REPORT_REQUEST,
    params,
  }),

  submitProgrammeReport: (context) => ({
    type: resultDashboardActions.SUBMITTING_PROGRAMME_REPORT_REQUEST,
    context,
  }),
};

export default resultDashboardActions;
