const concededPassPolicyActions = {
  CREATE_CONCEDED_PASS_POLICY_REQUEST: 'CREATE_CONCEDED_PASS_POLICY_REQUEST',
  CREATE_CONCEDED_PASS_POLICY_SUCCESS: 'CREATE_CONCEDED_PASS_POLICY_SUCCESS',
  CREATE_CONCEDED_PASS_POLICY_ERROR: 'CREATE_CONCEDED_PASS_POLICY_ERROR',

  GET_CONCEDED_PASS_POLICY_REQUEST: 'GET_CONCEDED_PASS_POLICY_REQUEST',
  GET_CONCEDED_PASS_POLICY_SUCCESS: 'GET_CONCEDED_PASS_POLICY_SUCCESS',
  GET_CONCEDED_PASS_POLICY_ERROR: 'GET_CONCEDED_PASS_POLICY_ERROR',

  EDIT_CONCEDED_PASS_POLICY_REQUEST: 'EDIT_CONCEDED_PASS_POLICY_REQUEST',
  EDIT_CONCEDED_PASS_POLICY_SUCCESS: 'EDIT_CONCEDED_PASS_POLICY_SUCCESS',
  EDIT_CONCEDED_PASS_POLICY_ERROR: 'EDIT_CONCEDED_PASS_POLICY_ERROR',

  EDIT_DEGREE_CLASS_POLICY_REQUEST: 'EDIT_DEGREE_CLASS_POLICY_REQUEST',
  EDIT_DEGREE_CLASS_POLICY_SUCCESS: 'EDIT_DEGREE_CLASS_POLICY_SUCCESS',
  EDIT_DEGREE_CLASS_POLICY_ERROR: 'EDIT_DEGREE_CLASS_POLICY_ERROR',

  DELETE_CONCEDED_PASS_POLICY_REQUEST:
    'DELETE_CONCEDED_PASS_POLICY_POLICY_REQUEST',
  DELETE_CONCEDED_PASS_POLICY_SUCCESS:
    'DELETE_CONCEDED_PASS_POLICY_POLICY_SUCCESS',
  DELETE_CONCEDED_PASS_POLICY_ERROR: 'DELETE_CONCEDED_PASS_POLICY_POLICY_ERROR',

  SET_CONCEDED_PASS_POLICY_TO_EDIT: 'SET_CONCEDED_PASS_POLICY_TO_EDIT',
  SHOW_CONCEDED_PASS_POLICY_MODAL: 'SHOW_CONCEDED_PASS_POLICY_MODAL',

  createConcededPassPolicy: (data) => ({
    type: concededPassPolicyActions.CREATE_CONCEDED_PASS_POLICY_REQUEST,
    data,
  }),

  getConcededPassPolicies: () => ({
    type: concededPassPolicyActions.GET_CONCEDED_PASS_POLICY_REQUEST,
  }),

  updateConcededPassPolicy: (policyId, data) => ({
    type: concededPassPolicyActions.EDIT_CONCEDED_PASS_POLICY_REQUEST,
    policyId,
    data,
  }),

  deleteConcededPassPolicy: (policyId) => ({
    type: concededPassPolicyActions.DELETE_CONCEDED_PASS_POLICY_REQUEST,
    policyId,
  }),

  setPolicyToEdit: (policyToEdit) => ({
    type: concededPassPolicyActions.SET_CONCEDED_PASS_POLICY_TO_EDIT,
    policyToEdit,
  }),

  setShowModal: (payload) => ({
    type: concededPassPolicyActions.SHOW_CONCEDED_PASS_POLICY_MODAL,
    payload,
  }),
};

export default concededPassPolicyActions;
