import { isEmpty } from 'lodash';
import { studentRecordActions } from '../../actions';
import initialState from '../../initialState';

const studentRecord = (state = initialState.studentRecord, actions) => {
  switch (actions.type) {
    case studentRecordActions.GET_STUDENT_RECORD_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case studentRecordActions.GET_STUDENT_RECORD_SUCCESS: {
      const { studentRecords } = state;
      const studentData = { ...actions.data, searchKey: actions.studentId };

      if (!isEmpty(studentData)) {
        const findStudentRecordIndex = studentRecords.findIndex(
          (record) => record.id === studentData.id
        );

        if (findStudentRecordIndex !== -1) {
          studentRecords[findStudentRecordIndex] = studentData;
        } else {
          studentRecords.push(studentData);
        }
      }
      const currentStudentProgramme = !isEmpty(actions.data?.academic_records)
        ? actions.data?.academic_records[0]
        : {};

      return {
        ...state,
        studentRecords,
        currentStudent: studentData,
        currentStudentProgramme,
        error: {},
        loading: false,
      };
    }
    case studentRecordActions.GET_STUDENT_RECORD_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case studentRecordActions.UPDATE_CURRENT_STUDENT_PROG_REQUEST:
      return {
        ...state,
        updateCurrentProgError: {},
        updatingCurrentProg: true,
      };

    case studentRecordActions.UPDATE_CURRENT_STUDENT_PROG_SUCCESS:
      return {
        ...state,
        updateCurrentProgSuccess: actions.data,
        updatingCurrentProg: false,
      };

    case studentRecordActions.UPDATE_CURRENT_STUDENT_PROG_ERROR:
      return {
        ...state,
        updateCurrentProgError: actions.error,
        updatingCurrentProg: false,
      };

    case studentRecordActions.SET_STUDENT_ACADEMIC_STATUS_REQUEST:
      return {
        ...state,
        academicStatusError: {},
        settingAcademicStatus: true,
      };

    case studentRecordActions.SET_STUDENT_ACADEMIC_STATUS_SUCCESS:
      return {
        ...state,
        academicStatusSuccess: actions.data,
        settingAcademicStatus: false,
      };

    case studentRecordActions.SET_STUDENT_ACADEMIC_STATUS_ERROR:
      return {
        ...state,
        academicStatusError: actions.error,
        settingAcademicStatus: false,
      };

    case studentRecordActions.CHANGE_VERIFICATION_STATUS_REQUEST:
      return {
        ...state,
        updateDocVerifyError: {},
        updatingDocVerifyStatus: true,
      };
    case studentRecordActions.CHANGE_VERIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        updateDocVerifySuccess: actions.data,
        updatingDocVerifyStatus: false,
      };
    case studentRecordActions.CHANGE_VERIFICATION_STATUS_ERROR:
      return {
        ...state,
        updateDocVerifyError: actions.error,
        updatingDocVerifyStatus: false,
      };

    case studentRecordActions.SET_STUDENT_RECORD:
      return {
        ...state,
        currentStudent: actions.studentRecordToSet,
      };

    case studentRecordActions.SET_CURRENT_STUDENT_PROGRAMME:
      return {
        ...state,
        currentStudentProgramme: actions.currentStudentProgramme,
      };

    default:
      return state;
  }
};
export default studentRecord;
