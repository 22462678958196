import update from 'immutability-helper';
import { financialStatementActions } from '../../actions';
import initialState from '../../initialState';

const financialStatement = (
  state = initialState.financialStatement,
  actions
) => {
  switch (actions.type) {
    case financialStatementActions.GET_FINANCIAL_STATEMENT_REQUEST:
      return {
        ...state,
        gettingFinancialStatement: true,
        financialStatementError: {},
      };
    case financialStatementActions.GET_FINANCIAL_STATEMENT_SUCCESS: {
      const { data, context } = actions;

      const { financialStatements } = state;

      let newState = state;

      const studentData = {
        ...context,
        data,
      };

      const statementIndex = financialStatements.findIndex(
        (structure) => structure.student === context.student
      );

      if (statementIndex === -1) {
        newState = update(newState, {
          financialStatements: { $push: [studentData] },
        });
      } else {
        newState = update(newState, {
          financialStatements: {
            [statementIndex]: { $set: studentData },
          },
        });
      }

      return { ...newState, gettingFinancialStatement: false };
    }

    case financialStatementActions.GET_FINANCIAL_STATEMENT_ERROR:
      return {
        ...state,
        gettingFinancialStatement: false,
        financialStatementError: actions.data,
      };

    case financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_REQUEST:
      return {
        ...state,
        downloading: true,
        downloadSuccess: {},
        downloadError: {},
      };
    case financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_SUCCESS:
      return {
        ...state,
        downloading: false,
        downloadSuccess: actions.data,
      };
    case financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_ERROR:
      return {
        ...state,
        downloading: false,
        downloadError: actions.error,
      };

    default:
      return state;
  }
};

export default financialStatement;
