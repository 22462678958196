import update from 'immutability-helper';
import { findIndex } from 'lodash';
import { courseAssignmentActions } from '../../actions';
import initialState from '../../initialState';

function auth(state = initialState.courseAssignment, actions) {
  switch (actions.type) {
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST:
      return {
        ...state,
        gettingDepartmentProgrammes: true,
        departmentContext: actions.context,
        selectedCourse: {},
        departmentProgrammeError: {},
      };
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_SUCCESS: {
      const { context, data } = actions;
      const { departmentProgrammes } = state;
      const contextData = { ...context, data };
      const findContextIndex = departmentProgrammes.findIndex(
        (currentContext) =>
          currentContext.department_id === context.department_id
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          departmentProgrammes: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          departmentProgrammes: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingDepartmentProgrammes: false,
      };
    }
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_ERROR:
      return {
        ...state,
        departmentProgrammeError: actions.error,
        gettingDepartmentProgrammes: false,
      };
    case courseAssignmentActions.SHOW_EDIT_RESULT_CATEGORIES_MODAL:
      return {
        ...state,
        showEditResultCategoriesModal: actions.payload,
      };
    case courseAssignmentActions.SET_EDIT_RESULT_CATEGORY:
      return {
        ...state,
        resultCategoriesToEdit: actions.payload,
      };
    case courseAssignmentActions.EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST:
      return {
        ...state,
        editingCourseAssignmentResultCategories: true,
        editCourseAssignmentResultCategoriesError: {},
      };
    case courseAssignmentActions.EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_SUCCESS:
      return {
        ...state,
        editingCourseAssignmentResultCategories: false,
      };
    case courseAssignmentActions.EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_ERROR:
      return {
        ...state,
        editingCourseAssignmentResultCategories: false,
        editCourseAssignmentResultCategoriesError: actions.error,
      };
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_REQUEST:
      return {
        ...state,
        gettingAssignmentCourse: true,
        assignmentContext: actions.context,
        selectedCourse: {},
        getAssignmentCourseError: {},
      };
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_SUCCESS: {
      const { context, data } = actions;
      const { assignmentCourses } = state;
      const contextData = { ...context, data };
      const findContextIndex = assignmentCourses.findIndex(
        (currentContext) =>
          currentContext.department_id === context.department_id &&
          currentContext.semester_id === context.semester_id &&
          currentContext.academic_year_id === context.academic_year_id
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          assignmentCourses: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          assignmentCourses: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingAssignmentCourse: false,
      };
    }
    case courseAssignmentActions.GET_COURSE_ASSIGNMENT_COURSES_ERROR:
      return {
        ...state,
        getAssignmentError: actions.error,
        gettingAssignmentCourse: false,
      };

    case courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        gettingContextAssignments: true,
        contextAssignmentError: {},
      };
    case courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_SUCCESS: {
      const { data, context } = actions;
      const { contextAssignments } = state;

      const findContextIndex = findIndex(
        contextAssignments,
        (dataContext) =>
          dataContext.academic_year_id === context.academic_year_id &&
          dataContext.semester_id === context.semester_id &&
          dataContext.study_year_id === context.study_year_id &&
          dataContext.department_id === context.department_id &&
          dataContext.intake_id === context.intake_id &&
          dataContext.campus_id === context.campus_id &&
          dataContext.course_unit_id === context.course_unit_id
      );

      let newState = state;

      const contextData = { ...data, ...actions.context };

      if (findContextIndex === -1) {
        newState = update(newState, {
          contextAssignments: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          contextAssignments: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingContextAssignments: false,
      };
    }

    case courseAssignmentActions.GET_CONTEXT_ASSIGNMENTS_ERROR:
      return {
        ...state,
        contextAssignmentError: actions.error,
        gettingContextAssignments: false,
      };

    case courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_REQUEST:
      return {
        ...state,
        assigningLecturers: true,
        assignError: {},
      };
    case courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_SUCCESS:
      return {
        ...state,
        assignSuccess: actions.data,
        assigningLecturers: false,
      };
    case courseAssignmentActions.ASSIGN_LECTURERS_TO_COURSE_ERROR:
      return {
        ...state,
        assignError: actions.error,
        assigningLecturers: false,
      };

    case courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST:
      return {
        ...state,
        addingLecturer: true,
        addLecturerError: {},
      };
    case courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        addingLecturer: false,
      };
    case courseAssignmentActions.ADD_LECTURER_TO_COURSE_ASSIGNMENT_ERROR:
      return {
        ...state,
        addLecturerError: actions.error,
        addingLecturer: false,
      };

    case courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST:
      return {
        ...state,
        editingLecturer: true,
        editLecturerError: {},
      };
    case courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_SUCCESS:
      return {
        ...state,
        editingLecturer: false,
      };
    case courseAssignmentActions.EDIT_COURSE_ASSIGNMENT_LECTURERS_ERROR:
      return {
        ...state,
        editLecturerError: actions.error,
        editingLecturer: false,
      };

    case courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST:
      return {
        ...state,
        removingLecturers: true,
        removeLecturerError: {},
      };
    case courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        removingLecturers: false,
      };
    case courseAssignmentActions.REMOVE_LECTURERS_FROM_ASSIGNMENT_ERROR:
      return {
        ...state,
        removeLecturerError: actions.error,
        removingLecturers: false,
      };

    case courseAssignmentActions.SET_SELECTED_ASSIGNMENT_COURSE:
      return {
        ...state,
        selectedCourse: actions.course,
      };

    case courseAssignmentActions.SET_SELECTED_ASSIGNMENT_LECTURERS:
      return {
        ...state,
        selectedLecturers: actions.data,
      };

    case courseAssignmentActions.SET_SHOW_DELETE_MODAL:
      return {
        ...state,
        showDeleteModal: actions.data,
      };

    case courseAssignmentActions.SET_PROGRAMME_CONTEXT_ID:
      return {
        ...state,
        programmeContextId: actions.id,
      };

    case courseAssignmentActions.SET_SHOW_ADD_LECTURER_MODAL:
      return {
        ...state,
        showAddLecturerModal: actions.data,
      };

    case courseAssignmentActions.SET_EDIT_ASSIGNMENT_LECTURER:
      return {
        ...state,
        editing: actions.editing,
        dataToEdit: actions.dataToEdit,
      };

    default:
      return state;
  }
}

export default auth;
