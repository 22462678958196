import { merge, uniqBy } from 'lodash';
import update from 'immutability-helper';
import { admissionApplicantActions } from '../../actions';
import initialState from '../../initialState';

const applicant = (state = initialState.admissionApplicant, actions) => {
  switch (actions.type) {
    case admissionApplicantActions.GET_PROGRAMMES_BY_CONTEXT_REQUEST:
      return {
        ...state,
        gettingProgrammes: true,
        getProgrammesError: {},
      };
    case admissionApplicantActions.GET_PROGRAMMES_BY_CONTEXT_SUCCESS: {
      const { admissionProgrammes } = state;
      const { context, data } = actions;

      const findContextIndex = admissionProgrammes.findIndex(
        (currentContext) =>
          context.academic_year_id === currentContext.academic_year_id &&
          context.intake_id === currentContext.intake_id &&
          context.admission_scheme_id === currentContext.admission_scheme_id &&
          context.category === currentContext.category
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          admissionProgrammes: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          admissionProgrammes: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingProgrammes: false,
      };
    }
    case admissionApplicantActions.GET_PROGRAMMES_BY_CONTEXT_ERROR:
      return {
        ...state,
        gettingProgrammes: false,
        getProgrammesError: actions.error,
      };

    case admissionApplicantActions.SEARCH_FOR_APPLICANTS_REQUEST:
      return {
        ...state,
        searchingApplicants: true,
        searchApplicantError: {},
      };
    case admissionApplicantActions.SEARCH_FOR_APPLICANTS_SUCCESS:
      return {
        ...state,
        searchedApplicants: actions.data,
        searchingApplicants: false,
      };
    case admissionApplicantActions.SEARCH_FOR_APPLICANTS_ERROR:
      return {
        ...state,
        searchingApplicants: false,
        searchApplicantError: actions.error,
      };

    case admissionApplicantActions.ADMINISTRATIVELY_ADMIT_APPLICANT_REQUEST:
      return {
        ...state,
        administrativelyAdmitting: true,
        administrativelyAdmitError: {},
      };
    case admissionApplicantActions.ADMINISTRATIVELY_ADMIT_APPLICANT_SUCCESS:
      return {
        ...state,
        administrativelyAdmitted: actions.data,
        administrativelyAdmitting: false,
      };
    case admissionApplicantActions.ADMINISTRATIVELY_ADMIT_APPLICANT_ERROR:
      return {
        ...state,
        administrativelyAdmitting: false,
        administrativelyAdmitError: actions.error,
      };

    case admissionApplicantActions.UPDATE_APPLICANT_DETAILS_REQUEST:
      return {
        ...state,
        updatingApplicant: true,
        updateApplicantError: {},
      };
    case admissionApplicantActions.UPDATE_APPLICANT_DETAILS_SUCCESS:
      return {
        ...state,
        updatedApplicant: actions.data,
        updatingApplicant: false,
      };
    case admissionApplicantActions.UPDATE_APPLICANT_DETAILS_ERROR:
      return {
        ...state,
        updatingApplicant: false,
        updateApplicantError: actions.error,
      };

    case admissionApplicantActions.GET_ADMISSION_PROGRAMME_DETAILS_REQUEST:
      return {
        ...state,
        gettingProgrammesDetails: true,
        getProgrammeDetailsError: {},
      };
    case admissionApplicantActions.GET_ADMISSION_PROGRAMME_DETAILS_SUCCESS:
      return {
        ...state,
        admissionProgrammeDetails: actions.data,
        gettingProgrammesDetails: false,
      };
    case admissionApplicantActions.GET_ADMISSION_PROGRAMME_DETAILS_ERROR:
      return {
        ...state,
        gettingProgrammesDetails: false,
        getProgrammeDetailsError: actions.error,
      };

    case admissionApplicantActions.GET_APPLICATION_FORM_DETAILS_REQUEST:
      return {
        ...state,
        gettingApplicationForm: true,
        selectedFormId: actions.formId,
      };
    case admissionApplicantActions.GET_APPLICATION_FORM_DETAILS_SUCCESS: {
      const { applicationForms } = state;
      return {
        ...state,
        applicationForms: uniqBy(
          [
            { data: actions.data, form_id: actions.formId },
            ...applicationForms,
          ],
          'form_id'
        ),
        applicationFormError: {},
        gettingApplicationForm: false,
      };
    }
    case admissionApplicantActions.GET_APPLICATION_FORM_DETAILS_ERROR:
      return {
        ...state,
        applicationFormError: actions.error,
        gettingApplicationForm: false,
      };

    case admissionApplicantActions.DOWNLOAD_UNEB_REPORTS_REQUEST:
      return {
        ...state,
        downloadingUNEBReports: true,
        downloadUNEBError: {},
      };
    case admissionApplicantActions.DOWNLOAD_UNEB_REPORTS_SUCCESS: {
      return {
        ...state,
        downloadData: actions.data,
        downloadingUNEBReports: false,
      };
    }
    case admissionApplicantActions.DOWNLOAD_UNEB_REPORTS_ERROR:
      return {
        ...state,
        downloadUNEBError: actions.error,
        downloadingUNEBReports: false,
      };

    case admissionApplicantActions.GET_ADMISSION_APPLICANTS_REQUEST:
      return {
        ...state,
        gettingApplicants: true,
        getApplicantError: {},
      };
    case admissionApplicantActions.GET_ADMISSION_APPLICANTS_SUCCESS:
      return {
        ...state,
        admissionApplicants: actions.data,
        gettingApplicants: false,
      };
    case admissionApplicantActions.GET_ADMISSION_APPLICANTS_ERROR:
      return {
        ...state,
        gettingApplicants: false,
        getApplicantError: actions.error,
      };

    case admissionApplicantActions.GET_WEIGHTED_APPLICANTS_REQUEST:
      return {
        ...state,
        gettingWeightedApplicants: true,
        getApplicantWeightedError: {},
      };
    case admissionApplicantActions.GET_WEIGHTED_APPLICANTS_SUCCESS: {
      const { data, programmeCampusId } = actions;
      const { allWeightedApplicants } = state;

      let newState = state;
      const weightedApplicantsData = {
        programmeCampusId,
        weightedApplicants: data,
      };
      const foundIndex = allWeightedApplicants.findIndex(
        (applicantContext) =>
          applicantContext.programmeCampusId === programmeCampusId
      );
      if (foundIndex === -1) {
        newState = update(newState, {
          allWeightedApplicants: { $push: [weightedApplicantsData] },
        });
      } else {
        newState = update(newState, {
          allWeightedApplicants: {
            [foundIndex]: { $set: weightedApplicantsData },
          },
        });
      }

      return { ...newState, gettingWeightedApplicants: false };
    }
    case admissionApplicantActions.GET_WEIGHTED_APPLICANTS_ERROR:
      return {
        ...state,
        gettingWeightedApplicants: false,
        getApplicantWeightedError: actions.error,
      };

    case admissionApplicantActions.GET_SELECTED_APPLICANTS_REQUEST:
      return {
        ...state,
        gettingSelectedApplicants: true,
        getApplicantSelectedError: {},
      };
    case admissionApplicantActions.GET_SELECTED_APPLICANTS_SUCCESS: {
      const { data, programmeCampusId } = actions;
      const { allSelectedApplicants } = state;

      let newState = state;
      const selectedApplicantsData = {
        programmeCampusId,
        selectedApplicants: data,
      };
      const foundIndex = allSelectedApplicants.findIndex(
        (applicantContext) =>
          applicantContext.programmeCampusId === programmeCampusId
      );
      if (foundIndex === -1) {
        newState = update(newState, {
          allSelectedApplicants: { $push: [selectedApplicantsData] },
        });
      } else {
        newState = update(newState, {
          allSelectedApplicants: {
            [foundIndex]: { $set: selectedApplicantsData },
          },
        });
      }
      return { ...newState, gettingSelectedApplicants: false };
    }
    case admissionApplicantActions.GET_SELECTED_APPLICANTS_ERROR:
      return {
        ...state,
        gettingSelectedApplicants: false,
        getApplicantSelectedError: actions.error,
      };

    case admissionApplicantActions.GET_NOT_SELECTED_APPLICANTS_REQUEST:
      return {
        ...state,
        gettingNotSelectedApplicants: true,
        getNotSelectedApplicantsError: {},
      };
    case admissionApplicantActions.GET_NOT_SELECTED_APPLICANTS_SUCCESS: {
      const { data, programmeCampusId } = actions;
      const { allUnselectedApplicants } = state;

      let newState = state;
      const unSelectedApplicantsData = {
        programmeCampusId,
        selectedApplicants: data,
      };
      const foundIndex = allUnselectedApplicants.findIndex(
        (applicantContext) =>
          applicantContext.programmeCampusId === programmeCampusId
      );
      if (foundIndex === -1) {
        newState = update(newState, {
          allUnselectedApplicants: { $push: [unSelectedApplicantsData] },
        });
      } else {
        newState = update(newState, {
          allUnselectedApplicants: {
            [foundIndex]: { $set: unSelectedApplicantsData },
          },
        });
      }
      return { ...newState, gettingNotSelectedApplicants: false };
    }
    case admissionApplicantActions.GET_NOT_SELECTED_APPLICANTS_ERROR:
      return {
        ...state,
        gettingNotSelectedApplicants: false,
        getNotSelectedApplicantsError: actions.error,
      };

    case admissionApplicantActions.GENERATE_NOT_SELECTED_APPLICANTS_REASON_REQUEST:
      return {
        ...state,
        generatingNotSelectedApplicantsReason: true,
        generateNotSelectedApplicantsReasonError: {},
      };
    case admissionApplicantActions.GENERATE_NOT_SELECTED_APPLICANTS_REASON_SUCCESS:
      return {
        ...state,
        generateNotSelectedApplicantsReasonSuccess: actions.data,
        generatingNotSelectedApplicantsReason: false,
      };
    case admissionApplicantActions.GENERATE_NOT_SELECTED_APPLICANTS_REASON_ERROR:
      return {
        ...state,
        generatingNotSelectedApplicantsReason: false,
        generateNotSelectedApplicantsReasonError: actions.error,
      };

    case admissionApplicantActions.DOWNLOAD_WEIGHTED_APPLICANTS_REQUEST:
      return {
        ...state,
        downloadingWeightedApplicants: true,
        downloadWeightedApplicantsError: {},
      };
    case admissionApplicantActions.DOWNLOAD_WEIGHTED_APPLICANTS_SUCCESS:
      return {
        ...state,
        downloadWeightedApplicantsSuccess: actions.data,
        downloadingWeightedApplicants: false,
      };
    case admissionApplicantActions.DOWNLOAD_WEIGHTED_APPLICANTS_ERROR:
      return {
        ...state,
        downloadingWeightedApplicants: false,
        downloadWeightedApplicantsError: actions.error,
      };

    case admissionApplicantActions.DOWNLOAD_SELECTED_APPLICANTS_REQUEST:
      return {
        ...state,
        downloadingSelectedApplicants: true,
        downloadSelectedApplicantsError: {},
      };
    case admissionApplicantActions.DOWNLOAD_SELECTED_APPLICANTS_SUCCESS:
      return {
        ...state,
        downloadSelectedApplicantsSuccess: actions.data,
        downloadingSelectedApplicants: false,
      };
    case admissionApplicantActions.DOWNLOAD_SELECTED_APPLICANTS_ERROR:
      return {
        ...state,
        downloadingSelectedApplicants: false,
        downloadSelectedApplicantsError: actions.error,
      };

    case admissionApplicantActions.DOWNLOAD_NOT_SELECTED_APPLICANTS_REQUEST:
      return {
        ...state,
        downloadingNotSelectedApplicants: true,
        downloadNotSelectedApplicantsError: {},
      };
    case admissionApplicantActions.DOWNLOAD_NOT_SELECTED_APPLICANTS_SUCCESS:
      return {
        ...state,
        downloadNotSelectedApplicantsSuccess: actions.data,
        downloadingNotSelectedApplicants: false,
      };
    case admissionApplicantActions.DOWNLOAD_NOT_SELECTED_APPLICANTS_ERROR:
      return {
        ...state,
        downloadingNotSelectedApplicants: false,
        downloadNotSelectedApplicantsError: actions.error,
      };

    case admissionApplicantActions.DOWNLOAD_ADMISSION_APPLICANTS_REQUEST:
      return {
        ...state,
        downloadingApplicants: true,
        downloadApplicantsError: {},
      };
    case admissionApplicantActions.DOWNLOAD_ADMISSION_APPLICANTS_SUCCESS:
      return {
        ...state,
        downloadApplicantsResponse: actions.data,
        downloadingApplicants: false,
      };
    case admissionApplicantActions.DOWNLOAD_ADMISSION_APPLICANTS_ERROR:
      return {
        ...state,
        downloadingApplicants: false,
        downloadApplicantsError: actions.error,
      };

    case admissionApplicantActions.DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_REQUEST:
      return {
        ...state,
        downloadingApplicants: true,
        downloadApplicantsError: {},
      };
    case admissionApplicantActions.DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_SUCCESS:
      return {
        ...state,
        downloadApplicantsResponse: actions.data,
        downloadingApplicants: false,
      };
    case admissionApplicantActions.DOWNLOAD_GRADUATE_PROGRAMME_APPLICANTS_ERROR:
      return {
        ...state,
        downloadingApplicants: false,
        downloadApplicantsError: actions.error,
      };

    case admissionApplicantActions.DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_REQUEST:
      return {
        ...state,
        downloadingApplicantsSubjects: true,
        downloadApplicantsSubjectsError: {},
      };
    case admissionApplicantActions.DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_SUCCESS:
      return {
        ...state,
        downloadApplicantsSubjectsResponse: actions.data,
        downloadingApplicantsSubjects: false,
      };
    case admissionApplicantActions.DOWNLOAD_APPLICANTS_SUBJECT_COMBINATIONS_ERROR:
      return {
        ...state,
        downloadingApplicantsSubjects: false,
        downloadApplicantsSubjectsError: actions.error,
      };

    case admissionApplicantActions.DOWNLOAD_ALL_APPLICANTS_SUBJECTS_REQUEST:
      return {
        ...state,
        downloadingAllApplicantsSubjects: true,
        downloadAllApplicantsSubjectsError: {},
      };
    case admissionApplicantActions.DOWNLOAD_ALL_APPLICANTS_SUBJECTS_SUCCESS:
      return {
        ...state,
        downloadAllApplicantsSubjectsResponse: actions.data,
        downloadingAllApplicantsSubjects: false,
      };
    case admissionApplicantActions.DOWNLOAD_ALL_APPLICANTS_SUBJECTS_ERROR:
      return {
        ...state,
        downloadingAllApplicantsSubjects: false,
        downloadAllApplicantsSubjectsError: actions.error,
      };

    case admissionApplicantActions.DOWNLOAD_FORM_ATTACHMENT_REQUEST:
      return {
        ...state,
        downloadingFormAttachment: true,
        downloadFormAttachmentError: {},
      };
    case admissionApplicantActions.DOWNLOAD_FORM_ATTACHMENT_SUCCESS:
      return {
        ...state,
        downloadFormAttachmentResponse: actions.data,
        downloadingFormAttachment: false,
      };
    case admissionApplicantActions.DOWNLOAD_FORM_ATTACHMENT_ERROR:
      return {
        ...state,
        downloadingFormAttachment: false,
        downloadFormAttachmentError: actions.error,
      };

    case admissionApplicantActions.DOWNLOAD_DIPLOMA_SCHEME_REQUEST:
      return {
        ...state,
        downloadingDiplomaSchemeReport: true,
        downloadDiplomaSchemeError: {},
      };
    case admissionApplicantActions.DOWNLOAD_DIPLOMA_SCHEME_SUCCESS:
      return {
        ...state,
        downloadDiplomaSchemeReportResponse: actions.data,
        downloadingDiplomaSchemeReport: false,
      };
    case admissionApplicantActions.DOWNLOAD_DIPLOMA_SCHEME_ERROR:
      return {
        ...state,
        downloadingDiplomaSchemeReport: false,
        downloadDiplomaSchemeError: actions.error,
      };

    case admissionApplicantActions.GET_ADMISSION_SCHEME_REPORTS_REQUEST:
      return {
        ...state,
        gettingAdmissionReports: true,
        AdmissionReportsError: {},
      };
    case admissionApplicantActions.GET_ADMISSION_SCHEME_REPORTS_SUCCESS: {
      const { data, context } = actions;
      const { admissionReports } = state;

      let newState = state;
      const selectedContextReport = {
        ...context,
        data,
      };

      const foundIndex = admissionReports.findIndex(
        (record) =>
          record.academic_year_id === context.academic_year_id &&
          record.admission_scheme_id === context.admission_scheme_id &&
          record.degree_category_id === context.degree_category_id &&
          record.intake_id === context.intake_id
      );

      if (foundIndex === -1) {
        newState = update(newState, {
          admissionReports: { $push: [selectedContextReport] },
        });
      } else {
        newState = update(newState, {
          admissionReports: {
            [foundIndex]: { $set: selectedContextReport },
          },
        });
      }
      return { ...newState, gettingAdmissionReports: false };
    }
    case admissionApplicantActions.GET_ADMISSION_SCHEME_REPORTS_ERROR:
      return {
        ...state,
        gettingAdmissionReports: false,
        AdmissionReportsError: actions.error,
      };

    case admissionApplicantActions.GET_DETAILED_ADMISSION_SCHEME_REPORTS_REQUEST:
      return {
        ...state,
        gettingAdmissionReports: true,
        AdmissionReportsError: {},
      };
    case admissionApplicantActions.GET_DETAILED_ADMISSION_SCHEME_REPORTS_SUCCESS: {
      const { data, context } = actions;
      const { detailedAdmissionReports } = state;

      let newState = state;
      const selectedContextReport = {
        ...context,
        data,
      };

      const foundIndex = detailedAdmissionReports.findIndex(
        (record) =>
          record.academic_year_id === context.academic_year_id &&
          record.admission_scheme_id === context.admission_scheme_id &&
          record.degree_category_id === context.degree_category_id &&
          record.intake_id === context.intake_id
      );

      if (foundIndex === -1) {
        newState = update(newState, {
          detailedAdmissionReports: { $push: [selectedContextReport] },
        });
      } else {
        newState = update(newState, {
          detailedAdmissionReports: {
            [foundIndex]: { $set: selectedContextReport },
          },
        });
      }
      return { ...newState, gettingAdmissionReports: false };
    }
    case admissionApplicantActions.GET_DETAILED_ADMISSION_SCHEME_REPORTS_ERROR:
      return {
        ...state,
        gettingAdmissionReports: false,
        AdmissionReportsError: actions.error,
      };

    case admissionApplicantActions.GENERATE_APPLICANT_PRN_REQUEST:
      return {
        ...state,
        generatingPRN: true,
      };
    case admissionApplicantActions.GENERATE_APPLICANT_PRN_SUCCESS:
      return {
        ...state,
        generatingPRN: false,
      };
    case admissionApplicantActions.GENERATE_APPLICANT_PRN_ERROR:
      return {
        ...state,
        generatingPRN: false,
      };

    case admissionApplicantActions.SET_SELECTED_ADMISSION_DETAIL:
      return {
        ...state,
        selectedAdmission: actions.data,
      };

    case admissionApplicantActions.SET_CATEGORY_SCHEME_CONTEXT: {
      const { categorySchemeContext } = state;
      return {
        ...state,
        categorySchemeContext: merge(categorySchemeContext, actions.data),
      };
    }

    case admissionApplicantActions.SET_SELECTED_ADMISSION_PROGRAMME:
      return {
        ...state,
        selectedAdmissionProgramme: actions.data,
      };

    case admissionApplicantActions.SET_APPLICANT_DETAILS:
      return {
        ...state,
        applicantDetails: actions.data,
      };

    case admissionApplicantActions.SET_FORM_SECTION_DETAILS:
      return {
        ...state,
        sectionDetails: actions.section,
      };

    case admissionApplicantActions.SET_SINGLE_SEARCHED_APPLICANT:
      return {
        ...state,
        singleSearchedApplicant: actions.section,
      };

    case admissionApplicantActions.RECOMMEND_APPLICANT_REQUEST:
      return {
        ...state,
        recommending: true,
        recommendError: {},
      };
    case admissionApplicantActions.RECOMMEND_APPLICANT_SUCCESS:
      return {
        ...state,
        recommendSuccess: actions.data,
        recommending: false,
      };
    case admissionApplicantActions.RECOMMEND_APPLICANT_ERROR:
      return {
        ...state,
        recommending: false,
        recommendError: actions.error,
      };

    default:
      return state;
  }
};

export default applicant;
