const admission = {
  admissionForm: {
    loading: false,
    creating: false,
    editing: false,
    deleting: false,
    admissionForms: [],
    admissionForm: {},
    loadError: {},
    createError: {},
    editError: {},
    deleteError: {},
  },
  admissionScheme: {
    loading: false,
    creating: false,
    editing: false,
    deleting: false,
    admissionSchemes: [],
    emisAdmissionSchemeCategories: [],
    admissionScheme: {},
    created: {},
    updated: {},
    loadError: {},
    createError: {},
    editError: {},
    deleteError: {},
  },
  admissionService: {
    loading: false,
    admissionServices: [],
    loadError: {},

    submittingApplication: false,
    submittedApplication: {},
    submitApplicationError: {},

    loadProgrammesError: {},
    serviceProgrammes: [],
    loadingServiceProg: false,

    serviceEventError: {},
    serviceEvents: [],
    loadingServiceEvent: false,

    approveServiceError: {},
    approveServiceResponse: {},
    approvingService: false,

    acceptOrDeclineServiceError: {},
    acceptOrDeclineServiceResponse: {},
    acceptingOrDecliningService: false,

    downloadingReport: false,
    downloadError: {},
    downloadedReport: {},

    showCreateForm: false,

    editServiceError: {},
    editingServiceAcadYear: false,
    editServiceAcadYearResponse: {},

    copContext: {},
  },
  runningAdmission: {
    loading: false,
    creating: false,
    editing: false,
    deleting: false,
    starting: false,
    stopping: false,
    runningAdmissions: [],
    runningAdmission: {},
    loadError: {},
    createError: {},
    editError: {},
    deleteError: {},
    startError: {},
    stopError: {},
    singleRunningAdmissions: [],
    selectedRunningAdmission: {},

    managedDetailsError: {},
    fetchingManagedDetails: false,
    managedProgrammeCampuses: [],

    downloadingSelectedStudents: false,
    downloadSelectedStudentsError: {},
    downloadSelectedStudentsSuccess: {},
  },
  runningAdmissionProgramme: {
    loading: false,
    creating: false,
    editing: false,
    deleting: false,
    runningAdmissionProgrammes: [],
    runningAdmissionProgramme: {},
    loadError: {},
    createError: {},
    editError: {},
    deleteError: {},

    selectedRunningProgramme: {},

    downloadingAdmissions: false,
    downloadAdmissionsError: {},
    downloadAdmissionsResponse: {},

    uploadingAdmissions: false,
    uploadAdmissionsError: {},
    uploadAdmissionsResponse: {},

    gettingSpecialFees: false,
    specialFees: [],
    specialFeesError: {},

    creatingSpecialFees: false,
    createdSpecialFees: [],
    createSpecialFeesError: {},

    updatingSpecialFees: false,
    updatedSpecialFees: {},
    updateSpecialFeesError: {},
    updatingSpecialFeesId: null,

    deletingSpecialFees: false,
    deletedSpecialFees: {},
    deleteSpecialFeesError: {},
    deletingSpecialFeesId: null,

    updatingStatus: false,
    updatedStatus: {},
    updateStatusError: {},

    fetchingWeightingCriteria: false,
    fetchedWeightingCriteria: {},
    fetchWeightingCriteriaError: {},

    fetchingSelectionCriteria: false,
    fetchedSelectionCriteria: {},
    fetchSelectionCriteriaError: {},

    updatingWeightingCriteria: false,
    updatedWeightingCriteria: {},
    updateWeightingCriteriaError: {},

    updatingSelectionCriteria: false,
    updatedSelectionCriteria: {},
    updateSelectionCriteriaError: {},

    runningSelection: false,
    runningSelectionSuccess: {},
    runningSelectionError: {},

    runningWeighting: false,
    runningWeightingSuccess: {},
    runningWeightingError: {},

    runningBulkWeighting: false,
    bulkWeightingSuccess: {},
    bulkWeightingError: {},
  },
  runningAdmissionCampus: {
    loading: false,
    creating: false,
    editing: false,
    deletingProgramme: false,
    runningAdmissionProgrammeId: null,
    runningAdmissionCampuses: [],
    runningAdmissionCampus: {},
    loadError: {},
    createError: {},
    editError: {},
    deleteError: {},
    singleRunningAdmissionCampuses: [],

    updatingRunningAdmissionCampus: false,
    updateRunningAdmissionCampusError: {},
    updatedRunningAdmissionCampus: {},

    creatingCapacitySitting: false,
    createCapacitySitingError: {},
    createdCapacitySiting: {},

    updatingCapacitySiting: false,
    updateCapacitySitingError: {},
    updatedCapacitySitting: {},

    deletingCapacitySiting: false,
    deleteCapacitySitingError: {},
    deleteCapacitySitingSuccess: {},

    updatingRemarks: false,
    updateRemarksError: {},
    updateRemarksResponse: {},

    aliasDetails: {},
  },
  admissionApplicant: {
    loading: false,
    error: {},
    applicants: [],

    gettingApplicants: false,
    getApplicantError: {},
    admissionApplicants: [],

    gettingProgrammes: false,
    getProgrammesError: {},
    admissionProgrammes: [],

    deletingStudents: false,
    deleteStudentsError: {},

    searchingApplicants: false,
    searchApplicantError: {},
    searchedApplicants: [],

    gettingProgrammesDetails: false,
    getProgrammeDetailsError: {},
    admissionProgrammeDetails: {},

    singleSearchedApplicant: {},
    sectionDetails: {},
    applicantDetails: {},
    selectedAdmission: {},
    categorySchemeContext: {},
    selectedAdmissionProgramme: {},

    gettingAdmissionReports: false,
    AdmissionReportsError: {},
    admissionReports: [],
    detailedAdmissionReports: [],

    applicationForms: [],
    gettingApplicationForm: false,
    selectedFormId: null,
    applicationFormError: {},

    downloadingApplicants: false,
    downloadApplicantsError: {},
    downloadApplicantsResponse: {},

    downloadingFormAttachment: false,
    downloadFormAttachmentError: {},
    downloadFormAttachmentResponse: {},

    updatingApplicant: false,
    updateApplicantError: {},
    updatedApplicant: {},

    downloadingUNEBReports: false,
    downloadUNEBError: {},
    downloadData: {},

    gettingWeightedApplicants: false,
    getApplicantWeightedError: {},
    allWeightedApplicants: [],

    gettingSelectedApplicants: false,
    getApplicantSelectedError: {},
    allSelectedApplicants: [],

    gettingNotSelectedApplicants: false,
    getNotSelectedApplicantsError: {},
    allUnselectedApplicants: [],

    generatingNotSelectedApplicantsReason: false,
    generateNotSelectedApplicantsReasonError: {},
    generateNotSelectedApplicantsReasonSuccess: {},

    downloadingWeightedApplicants: false,
    downloadWeightedApplicantsError: {},
    downloadWeightedApplicantsSuccess: {},

    downloadingSelectedApplicants: false,
    downloadSelectedApplicantsError: {},
    downloadSelectedApplicantsSuccess: {},

    downloadingNotSelectedApplicants: false,
    downloadNotSelectedApplicantsError: {},
    downloadNotSelectedApplicantsSuccess: {},

    generatingPRN: false,

    downloadingApplicantsSubjects: false,
    downloadApplicantsSubjectsError: {},
    downloadApplicantsSubjectsResponse: {},

    downloadingAllApplicantsSubjects: false,
    downloadAllApplicantsSubjectsError: {},
    downloadAllApplicantsSubjectsResponse: {},

    administrativelyAdmitting: false,
    administrativelyAdmitError: {},
    administrativelyAdmitted: {},

    downloadDiplomaSchemeReportResponse: {},
    downloadingDiplomaSchemeReport: false,
    downloadDiplomaSchemeError: {},

    recommending: false,
    recommendSuccess: {},
    recommendError: {},
  },

  admittedStudent: {
    admittedStudents: [],
    gettingAdmittedStudents: false,
    getAdmittedStudentsError: {},

    updatingHallOfAttachment: false,

    searchedAdmittedStudents: [],
    searchingAdmittedStudent: false,
    searchAdmittedStudentError: {},

    editingAdmittedStudent: false,
    editingAdmittedStudentError: {},

    downloadingAdmittedStudentsTemplate: false,
    downloadAdmittedStudentsTemplateError: {},
    downloadAdmittedStudentsTemplateSuccess: {},

    uploadingAdmittedStudents: false,
    uploadAdmittedStudentsError: {},
    uploadAdmittedStudentsSuccess: {},

    generatingStudentNumber: false,
    generateStudentNumberError: {},
    generateStudentNumberSuccess: {},

    generatingRegistrationNumber: false,
    generateRegistrationNumberError: {},
    generateRegistrationNumberSuccess: {},

    admittedStudentsContext: {},
    showingGenerateModal: false,
    showingDownloadModal: false,

    gettingAdmissionProgrammes: false,
    admissionProgrammes: [],
    admissionProgrammeError: {},

    gettingAdmittedReports: false,
    admittedReports: [],
    admittedReportsError: {},

    gettingAdmissionStatistics: false,
    admissionStatistics: {},
    admissionStatisticsError: {},

    generatingAdmissionLetters: false,
    generateAdmissionLetterError: {},
    generateAdmissionLetter: {},

    printingAdmissionLetters: false,
    printAdmissionLetterError: {},
    printAdmissionLetter: {},

    pushingAdmittedApplicants: false,
    pushAdmittedApplicantsSuccess: {},
    pushAdmittedApplicantsError: {},

    pushingPreviouslyAdmittedApplicants: false,
    pushPreviouslyAdmittedApplicantsSuccess: {},
    pushPreviouslyAdmittedApplicantsError: {},

    downloadingNotPushedReport: false,
    downloadNotPushedReportError: {},
    downloadNotPushedReportSuccess: {},

    downloadingAdmittedStudents: false,
    downloadAdmittedStudentsError: {},
    downloadAdmittedStudentsSuccess: {},

    downloadingAdmittedStudentsPdf: false,
    downloadAdmittedStudentsPdfError: {},
    downloadAdmittedStudentsPdfSuccess: {},

    downloadingAllCampusReports: false,
    downloadAllCampusReportsError: {},
    downloadAllCampusReportsSuccess: {},

    staticsContext: {},
  },

  weightingAndSelection: {
    creatingCriteria: false,
    createCriteriaError: {},
    createdCriteria: {},

    getCriteriaError: {},
    weightingCriteria: [],
    gettingCriteria: false,

    addCriteriaSubjectsError: {},
    addedCriteriaSubjects: {},
    addingCriteriaSubjects: false,

    actionToPerform: '',
    currentCriteriaTab: 'view',
    currentSelectionTab: 'view',
    criteriaToAddSubjects: {},
    selectedCriteria: {},
    selectionCriteria: {},

    addNewCategoryError: {},
    addedCategory: {},
    categoryView: 'view',
    addingNewCategory: false,

    createSelectionCriteriaError: {},
    createdSelectionCriteria: {},
    creatingSelectionCriteria: false,

    getSelectionError: {},
    weightingSelection: [],
    gettingSelection: false,

    updateResponse: {},
    updateCategoryError: {},
    updatingCategory: false,

    deleteCategoryError: {},
    deleteResponse: {},
    deletingCategory: false,
  },
};

export default admission;
