import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { userLogsActions } from '../../actions';

function* getUserLogs() {
  try {
    const response = yield axios({
      url: `/app/audit-trails/recent`,
      method: 'GET',
    });
    yield put({
      type: userLogsActions.GET_USER_LOGS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: userLogsActions.GET_USER_LOGS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetUserLogs() {
  yield takeLatest(userLogsActions.GET_USER_LOGS_REQUEST, getUserLogs);
}

export default [fork(watchGetUserLogs)];
