const studentInvoice = {
  GET_STUDENT_INVOICES_REQUEST: 'GET_STUDENT_INVOICES_REQUEST',
  GET_STUDENT_INVOICES_SUCCESS: 'GET_STUDENT_INVOICES_SUCCESS',
  GET_STUDENT_INVOICES_ERROR: 'GET_STUDENT_INVOICES_ERROR',

  GET_STUDENT_UNPAID_INVOICES_REQUEST: 'GET_STUDENT_UNPAID_INVOICES_REQUEST',
  GET_STUDENT_UNPAID_INVOICES_SUCCESS: 'GET_STUDENT_UNPAID_INVOICES_SUCCESS',
  GET_STUDENT_UNPAID_INVOICES_ERROR: 'GET_STUDENT_UNPAID_INVOICES_ERROR',

  GET_STUDENT_GRADUATION_INVOICES_REQUEST:
    'GET_STUDENT_GRADUATION_INVOICES_REQUEST',
  GET_STUDENT_GRADUATION_INVOICES_SUCCESS:
    'GET_STUDENT_GRADUATION_INVOICES_SUCCESS',
  GET_STUDENT_GRADUATION_INVOICES_ERROR:
    'GET_STUDENT_GRADUATION_INVOICES_ERROR',

  GET_ALL_FEES_ELEMENTS_REQUEST: 'GET_ALL_FEES_ELEMENTS_REQUEST',
  GET_ALL_FEES_ELEMENTS_SUCCESS: 'GET_ALL_FEES_ELEMENTS_SUCCESS',
  GET_ALL_FEES_ELEMENTS_ERROR: 'GET_ALL_FEES_ELEMENTS_ERROR',

  CREATE_MANUAL_INVOICE_REQUEST: 'CREATE_MANUAL_INVOICE_REQUEST',
  CREATE_MANUAL_INVOICE_SUCCESS: 'CREATE_MANUAL_INVOICE_SUCCESS',
  CREATE_MANUAL_INVOICE_ERROR: 'CREATE_MANUAL_INVOICE_ERROR',

  CREATE_BULK_MANUAL_INVOICE_REQUEST: 'CREATE_BULK_MANUAL_INVOICE_REQUEST',
  CREATE_BULK_MANUAL_INVOICE_SUCCESS: 'CREATE_BULK_MANUAL_INVOICE_SUCCESS',
  CREATE_BULK_MANUAL_INVOICE_ERROR: 'CREATE_BULK_MANUAL_INVOICE_ERROR',

  GENERATE_PAYMENT_REFERENCE_REQUEST: 'GENERATE_PAYMENT_REFERENCE_REQUEST',
  GENERATE_PAYMENT_REFERENCE_SUCCESS: 'GENERATE_PAYMENT_REFERENCE_SUCCESS',
  GENERATE_PAYMENT_REFERENCE_ERROR: 'GENERATE_PAYMENT_REFERENCE_ERROR',

  GET_STUDENT_PAYMENT_REFERENCES_REQUEST:
    'GET_STUDENT_PAYMENT_REFERENCES_REQUEST',
  GET_STUDENT_PAYMENT_REFERENCES_SUCCESS:
    'GET_STUDENT_PAYMENT_REFERENCES_SUCCESS',
  GET_STUDENT_PAYMENT_REFERENCES_ERROR: 'GET_STUDENT_PAYMENT_REFERENCES_ERROR',

  GET_INVOICE_ELEMENTS_REQUEST: 'GET_INVOICE_ELEMENTS_REQUEST',
  GET_INVOICE_ELEMENTS_SUCCESS: 'GET_INVOICE_ELEMENTS_SUCCESS',
  GET_INVOICE_ELEMENTS_ERROR: 'GET_INVOICE_ELEMENTS_ERROR',

  OFFSET_INVOICE_REQUEST: 'OFFSET_INVOICE_REQUEST',
  OFFSET_INVOICE_SUCCESS: 'OFFSET_INVOICE_SUCCESS',
  OFFSET_INVOICE_ERROR: 'OFFSET_INVOICE_ERROR',

  INVOICE_OPTION_ACTION_REQUEST: 'INVOICE_OPTION_ACTION_REQUEST',
  INVOICE_OPTION_ACTION_SUCCESS: 'INVOICE_OPTION_ACTION_SUCCESS',
  INVOICE_OPTION_ACTION_ERROR: 'INVOICE_OPTION_ACTION_ERROR',

  UPLOAD_BULK_INVOICE_TEMPLATE_REQUEST: 'UPLOAD_BULK_INVOICE_TEMPLATE_REQUEST',
  UPLOAD_BULK_INVOICE_TEMPLATE_SUCCESS: 'UPLOAD_BULK_INVOICE_TEMPLATE_SUCCESS',
  UPLOAD_BULK_INVOICE_TEMPLATE_ERROR: 'UPLOAD_BULK_INVOICE_TEMPLATE_ERROR',

  DOWNLOAD_BULK_INVOICE_TEMPLATE_REQUEST:
    'DOWNLOAD_BULK_INVOICE_TEMPLATE_REQUEST',
  DOWNLOAD_BULK_INVOICE_TEMPLATE_SUCCESS:
    'DOWNLOAD_BULK_INVOICE_TEMPLATE_SUCCESS',
  DOWNLOAD_BULK_INVOICE_TEMPLATE_ERROR: 'DOWNLOAD_BULK_INVOICE_TEMPLATE_ERROR',

  SET_GENERATED_REFERENCE: 'SET_GENERATED_REFERENCE',
  SET_MY_UNPAID_INVOICES: 'SET_MY_UNPAID_INVOICES',

  getStudentInvoices: (studentId) => ({
    type: studentInvoice.GET_STUDENT_INVOICES_REQUEST,
    studentId,
  }),

  getStudentGraduationInvoices: (studentId) => ({
    type: studentInvoice.GET_STUDENT_GRADUATION_INVOICES_REQUEST,
    studentId,
  }),

  getStudentUnpaidInvoices: (studentId) => ({
    type: studentInvoice.GET_STUDENT_UNPAID_INVOICES_REQUEST,
    studentId,
  }),

  getAllFeesElements: (studentId) => ({
    type: studentInvoice.GET_ALL_FEES_ELEMENTS_REQUEST,
    studentId,
  }),

  createManualInvoice: (data) => ({
    type: studentInvoice.CREATE_MANUAL_INVOICE_REQUEST,
    data,
  }),

  createBulkManualInvoice: (data) => ({
    type: studentInvoice.CREATE_BULK_MANUAL_INVOICE_REQUEST,
    data,
  }),

  getStudentPaymentReference: (studentId) => ({
    type: studentInvoice.GET_STUDENT_PAYMENT_REFERENCES_REQUEST,
    studentId,
  }),

  generatePaymentReference: (studentId, data, url) => ({
    type: studentInvoice.GENERATE_PAYMENT_REFERENCE_REQUEST,
    studentId,
    data,
    url,
  }),

  getInvoiceElementDetails: (invoiceId, url) => ({
    type: studentInvoice.GET_INVOICE_ELEMENTS_REQUEST,
    invoiceId,
    url,
  }),

  offsetInvoice: (invoiceId, data) => ({
    type: studentInvoice.OFFSET_INVOICE_REQUEST,
    invoiceId,
    data,
  }),

  sendInvoiceOptionAction: (studentId, data, url) => ({
    type: studentInvoice.INVOICE_OPTION_ACTION_REQUEST,
    studentId,
    url,
    data,
  }),

  setGeneratedReference: (data) => ({
    type: studentInvoice.SET_GENERATED_REFERENCE,
    data,
  }),

  setMyUnpaidInvoices: (data) => ({
    type: studentInvoice.SET_MY_UNPAID_INVOICES,
    data,
  }),

  uploadBulkInvoiceTemplate: (data) => ({
    type: studentInvoice.UPLOAD_BULK_INVOICE_TEMPLATE_REQUEST,
    data,
  }),

  downloadBulkInvoiceTemplate: () => ({
    type: studentInvoice.DOWNLOAD_BULK_INVOICE_TEMPLATE_REQUEST,
  }),
};

export default studentInvoice;
