import { debitNoteActions } from '../../actions';
import initialState from '../../initialState';

const debitNote = (state = initialState.debitNote, actions) => {
  switch (actions.type) {
    case debitNoteActions.GET_DEBIT_NOTE_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };

    case debitNoteActions.GET_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        allDebitNotes: actions.data,
        loading: false,
      };

    case debitNoteActions.GET_DEBIT_NOTE_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case debitNoteActions.GET_PENDING_DEBIT_NOTE_REQUEST:
      return {
        ...state,
        getError: {},
        gettingPending: true,
      };

    case debitNoteActions.GET_PENDING_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        pendingDebitNotes: actions.data,
        gettingPending: false,
      };

    case debitNoteActions.GET_PENDING_DEBIT_NOTE_ERROR:
      return {
        ...state,
        getError: actions.error,
        gettingPending: false,
      };

    case debitNoteActions.GET_STUDENT_PENDING_DEBIT_NOTE_REQUEST:
      return {
        ...state,
        fetchPendingDebitError: {},
        fetchingPendingDebit: true,
      };

    case debitNoteActions.GET_STUDENT_PENDING_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        pendingStudentDebitNotes: actions.data,
        fetchingPendingDebit: false,
      };

    case debitNoteActions.GET_STUDENT_PENDING_DEBIT_NOTE_ERROR:
      return {
        ...state,
        fetchPendingDebitError: actions.error,
        fetchingPendingDebit: false,
      };

    case debitNoteActions.CREATE_DEBIT_NOTE_REQUEST:
      return {
        ...state,
        createDebitNoteError: {},
        createDebitNoteSuccess: {},
        creatingDebitNote: true,
      };

    case debitNoteActions.CREATE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        createDebitNoteSuccess: actions.data,
        creatingDebitNote: false,
      };

    case debitNoteActions.CREATE_DEBIT_NOTE_ERROR:
      return {
        ...state,
        createDebitNoteError: actions.error,
        creatingDebitNote: false,
      };

    case debitNoteActions.APPROVE_DEBIT_NOTE_REQUEST:
      return {
        ...state,
        approveError: {},
        approving: true,
      };

    case debitNoteActions.APPROVE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        approveSuccess: actions.data,
        approving: false,
      };

    case debitNoteActions.APPROVE_DEBIT_NOTE_ERROR:
      return {
        ...state,
        approveError: actions.error,
        approving: false,
      };
    case debitNoteActions.DECLINE_DEBIT_NOTE_REQUEST:
      return {
        ...state,
        declineError: {},
        declining: true,
      };

    case debitNoteActions.DECLINE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        declineSuccess: actions.data,
        declining: false,
      };

    case debitNoteActions.DECLINE_DEBIT_NOTE_ERROR:
      return {
        ...state,
        declineError: actions.error,
        declining: false,
      };

    case debitNoteActions.SET_CLEAR_TABLE_ROWS:
      return {
        ...state,
        clearTableRows: actions.payload,
      };

    default:
      return state;
  }
};
export default debitNote;
