import { metadataActions } from '../../actions';
import initialState from '../../initialState';

function metadata(state = initialState.metadata, actions) {
  switch (actions.type) {
    case metadataActions.GET_META_DATA_REQUEST:
      return {
        ...state,
        metadataError: {},
        updatedData: {},
        createdData: {},
        updateMetadataValueResponse: {},
        loading: true,
      };
    case metadataActions.GET_META_DATA_SUCCESS:
      return {
        ...state,
        metadata: actions.data,
        metadataError: {},
        loading: false,
      };
    case metadataActions.GET_META_DATA_ERROR:
      return {
        ...state,
        metadataError: actions.error,
        loading: false,
      };
    case metadataActions.CREATE_META_DATA_REQUEST:
      return {
        ...state,
        creating: true,
        metadataError: {},
        createdData: {},
      };
    case metadataActions.CREATE_META_DATA_SUCCESS:
      return {
        ...state,
        creating: false,
        createdData: actions.data,
      };
    case metadataActions.CREATE_META_DATA_ERROR:
      return {
        ...state,
        creating: false,
        metadataError: actions.error,
      };
    case metadataActions.UPDATE_META_DATA_REQUEST:
      return {
        ...state,
        updating: true,
        metadataError: {},
        updatedData: {},
      };
    case metadataActions.UPDATE_META_DATA_SUCCESS:
      return {
        ...state,
        updating: false,
        updatedData: actions.data,
      };
    case metadataActions.UPDATE_META_DATA_ERROR:
      return {
        ...state,
        updating: false,
        metadataError: actions.error,
      };

    case metadataActions.CREATE_META_DATA_VALUE_REQUEST:
      return {
        ...state,
        creating: true,
        metadataValueError: {},
        createdMetadataValue: {},
      };
    case metadataActions.CREATE_META_DATA_VALUE_SUCCESS:
      return {
        ...state,
        creating: false,
        createdMetadataValue: actions.data,
      };
    case metadataActions.CREATE_META_DATA_VALUE_ERROR:
      return {
        ...state,
        creating: false,
        metadataValueError: actions.error,
      };
    case metadataActions.UPDATE_META_DATA_VALUE_REQUEST:
      return {
        ...state,
        updatingMetadataValue: true,
        metadataValueError: {},
        updatedMetadataValue: {},
      };
    case metadataActions.UPDATE_META_DATA_VALUE_SUCCESS:
      return {
        ...state,
        updatingMetadataValue: false,
        updateMetadataValueResponse: actions.data,
      };
    case metadataActions.UPDATE_META_DATA_VALUE_ERROR:
      return {
        ...state,
        updatingMetadataValue: false,
        metadataValueError: actions.error,
      };

    case metadataActions.DELETE_META_DATA_VALUE_REQUEST:
      return {
        ...state,
        deleteMetadataValueResponse: {},
        deletingMetadataValue: true,
        deletingMetadataValueId: actions.id,
      };
    case metadataActions.DELETE_META_DATA_VALUE_SUCCESS:
      return {
        ...state,
        deletingMetadataValue: false,
        deleteMetadataValueResponse: actions.data,
      };
    case metadataActions.DELETE_META_DATA_VALUE_ERROR:
      return {
        ...state,
        deletingMetadataValue: false,
        deleteMetadataValueError: actions.error,
      };

    default:
      return state;
  }
}

export default metadata;
