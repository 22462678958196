const previousRegistration = {
  DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST:
    'DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST',
  DOWNLOAD_REGISTRATION_TEMPLATE_SUCCESS:
    'DOWNLOAD_REGISTRATION_TEMPLATE_SUCCESS',
  DOWNLOAD_REGISTRATION_TEMPLATE_ERROR: 'DOWNLOAD_REGISTRATION_TEMPLATE_ERROR',

  UPLOAD_REGISTRATION_TEMPLATE_REQUEST: 'UPLOAD_REGISTRATION_TEMPLATE_REQUEST',
  UPLOAD_REGISTRATION_TEMPLATE_SUCCESS: 'UPLOAD_REGISTRATION_TEMPLATE_SUCCESS',
  UPLOAD_REGISTRATION_TEMPLATE_ERROR: 'UPLOAD_REGISTRATION_TEMPLATE_ERROR',

  downloadPreviousRegistration: () => ({
    type: previousRegistration.DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST,
  }),

  uploadPreviousRegistration: (data) => ({
    type: previousRegistration.UPLOAD_REGISTRATION_TEMPLATE_REQUEST,
    data,
  }),
};

export default previousRegistration;
