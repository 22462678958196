import { combineReducers } from 'redux';
import { authActions } from '../actions';
import NTCResult from './NTCManager/NTCResult';
import NTCStudent from './NTCManager/NTCStudent';
import NTCSubject from './NTCManager/NTCSubject';
import academicDocument from './academicDocument';
import admissionForm from './admission-mgt/admissionForm';
import admissionScheme from './admission-mgt/admissionScheme';
import admissionService from './admission-mgt/admissionStudentService';
import admittedStudent from './admission-mgt/admittedStudents';
import admissionApplicant from './admission-mgt/applicant';
import runningAdmission from './admission-mgt/runningAdmission';
import runningAdmissionCampus from './admission-mgt/runningAdmissionCampus';
import runningAdmissionProgramme from './admission-mgt/runningAdmissionProgramme';
import weightingAndSelection from './admission-mgt/weightingAndSelection';
import activeApp from './app/activeApp';
import server from './app/server';
import auditTrails from './auditTrails/userLogs';
import auth from './auth';
import academicRegistrar from './businessIntelligence/academicRegistrar';
import admissionReport from './businessIntelligence/admission';
import resultReport from './businessIntelligence/result';
import revenueReport from './businessIntelligence/revenue';
import courseAssignment from './courseAssignment';
import building from './courseAssignment/building';
import semesterCourseLoad from './courseAssignment/semesterCourseLoad';
import teachingTimetable from './courseAssignment/teachingTimetable';
import electivePosition from './eVoting/electivePosition';
import previousRegistration from './enrollmentAndRegistration/previousRegistration';
import registrationReports from './enrollmentAndRegistration/registrationReports';
import semesterEvent from './enrollmentAndRegistration/semesterEvent';
import studentEnrollment from './enrollmentAndRegistration/studentEnrollment';
import studentRegistration from './enrollmentAndRegistration/studentRegistration';
import academicYear from './events-mgt/academicYear';
import event from './events-mgt/event';
import feesApprovals from './fees-mgt/feesApprovals';
import feesElements from './fees-mgt/feesElements';
import feesWaivers from './fees-mgt/feesWaivers';
import functionalFees from './fees-mgt/functionalFees';
import graduationFees from './fees-mgt/graduationFees';
import otherFees from './fees-mgt/otherFees';
import tuition from './fees-mgt/tuition';
import waiverDiscounts from './fees-mgt/waiverDiscounts';
import academicFeesPolicy from './institutionPolicy/academicFeesPolicy';
import admissionFeesPolicy from './institutionPolicy/admissionFeesPolicy';
import applicationFeesPolicy from './institutionPolicy/applicationFeesPolicy';
import concededPassPolicy from './institutionPolicy/concededPassPolicy';
import courseResittingPolicy from './institutionPolicy/courseResittingPolicy';
import documentPolicy from './institutionPolicy/documentPolicy';
import extensionPolicy from './institutionPolicy/extensionPolicy';
import otherFeesPolicy from './institutionPolicy/otherFeesPolicy';
import registrationPolicy from './institutionPolicy/registrationPolicy';
import resultsCategoryPolicy from './institutionPolicy/resultsCategoryPolicy';
import retakerPolicy from './institutionPolicy/retakerPolicy';
import servicesPolicy from './institutionPolicy/servicesPolicy';
import studyLevelPolicy from './institutionPolicy/studyLevelPolicy';
import surchargePolicy from './institutionPolicy/surchargePolicy';
import tuitionFeesPolicy from './institutionPolicy/tuitionFeesPolicy';
import college from './institutionSetup/college';
import department from './institutionSetup/department';
import documentSetting from './institutionSetup/documentSetting';
import faculty from './institutionSetup/faculty';
import institutionStructure from './institutionSetup/institutionStructure';
import metadata from './institutionSetup/metadata';
import itsSearchData from './its-data/searchData';
import lecturerCourse from './lecturer-mgt/lecturerCourses';
import bulkPayments from './paymentAndTransaction/bulkPayments';
import creditNote from './paymentAndTransaction/creditNote';
import debitNote from './paymentAndTransaction/debitNote';
import feesStructure from './paymentAndTransaction/feesStructure';
import financialStatement from './paymentAndTransaction/financialStatement';
import financialTransaction from './paymentAndTransaction/financialTransaction';
import paymentsReports from './paymentAndTransaction/paymentsReports';
import pendingDirectPost from './paymentAndTransaction/pendingDirectPost';
import pendingExemptedInvoice from './paymentAndTransaction/pendingExemptedInvoice';
import pendingVoidedInvoice from './paymentAndTransaction/pendingVoidedInvoice';
import previousPayments from './paymentAndTransaction/previousPayments';
import sponsor from './paymentAndTransaction/sponsor';
import studentInvoice from './paymentAndTransaction/studentInvoice';
import studentLedger from './paymentAndTransaction/studentLedger';
import studentRecord from './paymentAndTransaction/studentRecord';
import courseUnit from './programme-mgt/courseUnit';
import grading from './programme-mgt/grading';
import programme from './programme-mgt/programme';
import programmeAlias from './programme-mgt/programmeAlias';
import programmeSearch from './programme-mgt/programmeSearch';
import programmeVersion from './programme-mgt/programmeVersion';
import semesterLoad from './programme-mgt/semesterLoad';
import specialization from './programme-mgt/specialization';
import subject from './programme-mgt/subject';
import unebCenter from './programme-mgt/unebCenter';
import unebSubject from './programme-mgt/unebSubject';
import pujabAdmission from './pujab-mgt/admissions';
import pujabApplicants from './pujab-mgt/applicants';
import pujabInstitution from './pujab-mgt/institutions';
import pujabProgramme from './pujab-mgt/programmes';
import pujabReport from './pujab-mgt/report';
import pujabSchoolAdmin from './pujab-mgt/schoolAdmin';
import batchResult from './resultManager/batchResult';
import graduationList from './resultManager/graduationList';
import resultDashboard from './resultManager/resultDashboard';
import resultsUserLogs from './resultManager/resultLogs';
import resultNode from './resultManager/resultNode';
import searchedStudentResult from './resultManager/searchResult';
import senate from './resultManager/senate';
import studentGrade from './resultManager/studentGrade';
import settings from './settings';
import shared from './shared';
import financeClearance from './studentClearance/financeClearance';
import student from './students-mgt/student';
import studentSupport from './students-mgt/studentSupport';
import students from './students-mgt/students';
import acmisCampuses from './systemManager/campus';
import acmisProgrammes from './systemManager/programme';
import systemScheme from './systemManager/schemes';
import acmisSponsors from './systemManager/sponsors';
import emisStudents from './systemManager/studentRecords';
import acmisStudyLevels from './systemManager/studyLevels';
import tab from './tab';
import universalPayment from './universalPayment';
import universalInvoice from './universalPayment/universalInvoice';
import roleGroup from './useraccess-mgt/roleGroup';
import securityProfile from './useraccess-mgt/securityProfile';
import systemApp from './useraccess-mgt/systemApps';
import systemUser from './useraccess-mgt/systemUser';
import userRole from './useraccess-mgt/userRole';

const appReducer = combineReducers({
  settings,
  auth,
  institutionStructure,
  documentSetting,
  metadata,
  faculty,
  college,
  department,
  courseUnit,
  programmeSearch,
  programme,
  subject,
  specialization,
  grading,
  programmeVersion,
  unebSubject,
  unebCenter,
  semesterLoad,
  server,
  activeApp,
  securityProfile,
  userRole,
  shared,
  roleGroup,
  programmeAlias,
  tab,
  systemUser,
  systemApp,
  tuition,
  otherFees,
  functionalFees,
  feesWaivers,
  feesElements,
  graduationFees,
  waiverDiscounts,
  academicYear,
  event,
  admissionForm,
  weightingAndSelection,
  admissionScheme,
  admissionService,
  runningAdmission,
  runningAdmissionProgramme,
  runningAdmissionCampus,
  students,
  studentSupport,
  student,
  admissionApplicant,
  admittedStudent,
  registrationPolicy,
  otherFeesPolicy,
  documentPolicy,
  academicFeesPolicy,
  applicationFeesPolicy,
  admissionFeesPolicy,
  surchargePolicy,
  retakerPolicy,
  extensionPolicy,
  tuitionFeesPolicy,
  studyLevelPolicy,
  servicesPolicy,
  courseResittingPolicy,
  resultsCategoryPolicy,
  concededPassPolicy,
  financialTransaction,
  financialStatement,
  studentRecord,
  pendingDirectPost,
  pendingVoidedInvoice,
  pendingExemptedInvoice,
  creditNote,
  debitNote,
  feesStructure,
  studentLedger,
  studentEnrollment,
  studentRegistration,
  semesterEvent,
  studentInvoice,
  registrationReports,
  previousRegistration,
  paymentsReports,
  feesApprovals,
  courseAssignment,
  semesterCourseLoad,
  building,
  teachingTimetable,
  ...universalPayment,
  universalInvoice,
  senate,
  resultNode,
  graduationList,
  resultDashboard,
  studentGrade,
  batchResult,
  bulkPayments,
  previousPayments,
  sponsor,
  academicDocument,
  NTCStudent,
  NTCSubject,
  NTCResult,
  academicRegistrar,
  financeClearance,
  revenueReport,
  resultReport,
  admissionReport,
  lecturerCourse,
  pujabInstitution,
  pujabProgramme,
  pujabAdmission,
  pujabReport,
  pujabApplicants,
  pujabSchoolAdmin,
  acmisSponsors,
  systemScheme,
  acmisCampuses,
  acmisProgrammes,
  acmisStudyLevels,
  emisStudents,
  resultsUserLogs,
  searchedStudentResult,
  electivePosition,
  itsSearchData,
  auditTrails,
});

const rootReducer = (state, actions) => {
  if (actions.type === authActions.LOGOUT_SUCCESS) {
    return appReducer(undefined, actions);
  }

  return appReducer(state, actions);
};

export default rootReducer;
