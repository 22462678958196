import { pendingDirectPostActions } from '../../actions';
import initialState from '../../initialState';

const pendingDirectPost = (state = initialState.pendingDirectPost, actions) => {
  switch (actions.type) {
    case pendingDirectPostActions.GET_PENDING_DIRECT_POST_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };

    case pendingDirectPostActions.GET_PENDING_DIRECT_POST_SUCCESS:
      return {
        ...state,
        pendingDirectPosts: actions.data,
        error: {},
        loading: false,
      };

    case pendingDirectPostActions.GET_PENDING_DIRECT_POST_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case pendingDirectPostActions.APPROVE_PENDING_DIRECT_POST_REQUEST:
      return {
        ...state,
        approveError: {},
        approving: true,
      };

    case pendingDirectPostActions.APPROVE_PENDING_DIRECT_POST_SUCCESS:
      return {
        ...state,
        approveSuccess: actions.data,
        approving: false,
      };

    case pendingDirectPostActions.APPROVE_PENDING_DIRECT_POST_ERROR:
      return {
        ...state,
        approveError: actions.error,
        approving: false,
      };

    case pendingDirectPostActions.DELETE_PENDING_DIRECT_POST_REQUEST:
      return {
        ...state,
        deleteDepositError: {},
        deletingDeposit: true,
      };
    case pendingDirectPostActions.DELETE_PENDING_DIRECT_POST_SUCCESS:
      return {
        ...state,
        deleteDepositSuccess: actions.data,
        deletingDeposit: false,
      };
    case pendingDirectPostActions.DELETE_PENDING_DIRECT_POST_ERROR:
      return {
        ...state,
        deleteDepositError: actions.error,
        deletingDeposit: false,
      };

    default:
      return state;
  }
};
export default pendingDirectPost;
