import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { pujabSchoolAdminActions } from '../../actions';

function* getPujabSchoolAdmins(actions) {
  try {
    const { context } = actions;
    const response = yield axios({
      url: '/pujab/school-admins',
      method: 'GET',
      params: context,
    });
    yield put({
      type: pujabSchoolAdminActions.GET_PUJAB_SCHOOL_ADMINS_SUCCESS,
      data: response,
      context,
    });
  } catch (error) {
    yield put({
      type: pujabSchoolAdminActions.GET_PUJAB_SCHOOL_ADMINS_ERROR,
      error: error.data,
    });
  }
}

function* createPujabSchoolAdmin(actions) {
  try {
    const { data, context } = actions;
    const response = yield axios({
      url: `/pujab/school-admins`,
      method: 'POST',
      data,
    });
    yield put({
      type: pujabSchoolAdminActions.CREATE_PUJAB_SCHOOL_ADMIN_SUCCESS,
      data: response.data,
      context,
    });
    if (context) {
      yield put({
        type: pujabSchoolAdminActions.GET_PUJAB_SCHOOL_ADMINS_REQUEST,
        context,
      });
    }
    yield put({
      type: 'SET_UNEB_CENTER_TAB',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: pujabSchoolAdminActions.CREATE_PUJAB_SCHOOL_ADMIN_ERROR,
      error: error.data,
    });
  }
}

function* updatePujabSchoolAdmin(actions) {
  try {
    const { id, data, context } = actions;
    const response = yield axios({
      url: `/pujab/school-admins/${id}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: pujabSchoolAdminActions.UPDATE_PUJAB_SCHOOL_ADMIN_SUCCESS,
      data: response.data,
      context,
    });
    if (context) {
      yield put({
        type: pujabSchoolAdminActions.GET_PUJAB_SCHOOL_ADMINS_REQUEST,
        context,
      });
    }
    yield put({
      type: 'SHOW_MODAL',
      payload: 'manage',
    });
  } catch (error) {
    yield put({
      type: pujabSchoolAdminActions.UPDATE_PUJAB_SCHOOL_ADMIN_ERROR,
      error: error.data,
    });
  }
}

function* watchGetPujabSchoolAdmins() {
  yield takeLatest(
    pujabSchoolAdminActions.GET_PUJAB_SCHOOL_ADMINS_REQUEST,
    getPujabSchoolAdmins
  );
}

function* watchCreatePujabSchoolAdmin() {
  yield takeLatest(
    pujabSchoolAdminActions.CREATE_PUJAB_SCHOOL_ADMIN_REQUEST,
    createPujabSchoolAdmin
  );
}

function* watchUpdatePujabSchoolAdmin() {
  yield takeLatest(
    pujabSchoolAdminActions.UPDATE_PUJAB_SCHOOL_ADMIN_REQUEST,
    updatePujabSchoolAdmin
  );
}

export default [
  fork(watchGetPujabSchoolAdmins),
  fork(watchCreatePujabSchoolAdmin),
  fork(watchUpdatePujabSchoolAdmin),
];
