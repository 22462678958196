const transaction = {
  SEARCH_UNIVERSAL_TRANSACTION_REQUEST: 'SEARCH_UNIVERSAL_TRANSACTION_REQUEST',
  SEARCH_UNIVERSAL_TRANSACTION_SUCCESS: 'SEARCH_UNIVERSAL_TRANSACTION_SUCCESS',
  SEARCH_UNIVERSAL_TRANSACTION_ERROR: 'SEARCH_UNIVERSAL_TRANSACTION_ERROR',

  GET_TRANSACTION_REPORTS_REQUEST: 'GET_TRANSACTION_REPORTS_REQUEST',
  GET_TRANSACTION_REPORTS_SUCCESS: 'GET_TRANSACTION_REPORTS_SUCCESS',
  GET_TRANSACTION_REPORTS_ERROR: 'GET_TRANSACTION_REPORTS_ERROR',

  GET_SPONSOR_PAYMENTS_REPORT_REQUEST: 'GET_SPONSOR_PAYMENTS_REPORT_REQUEST',
  GET_SPONSOR_PAYMENTS_REPORT_SUCCESS: 'GET_SPONSOR_PAYMENTS_REPORT_SUCCESS',
  GET_SPONSOR_PAYMENTS_REPORT_ERROR: 'GET_SPONSOR_PAYMENTS_REPORT_ERROR',

  GET_SPONSOR_ALLOCATIONS_UNIVERSAL_REQUEST:
    'GET_SPONSOR_ALLOCATIONS_UNIVERSAL_REQUEST',
  GET_SPONSOR_ALLOCATIONS_UNIVERSAL_SUCCESS:
    'GET_SPONSOR_ALLOCATIONS_UNIVERSAL_SUCCESS',
  GET_SPONSOR_ALLOCATIONS_UNIVERSAL_ERROR:
    'GET_SPONSOR_ALLOCATIONS_UNIVERSAL_ERROR',

  GET_UNIVERSAL_PAYMENTS_REPORTS_REQUEST:
    'GET_UNIVERSAL_PAYMENTS_REPORTS_REQUEST',
  GET_UNIVERSAL_PAYMENTS_REPORTS_SUCCESS:
    'GET_UNIVERSAL_PAYMENTS_REPORTS_SUCCESS',
  GET_UNIVERSAL_PAYMENTS_REPORTS_ERROR: 'GET_UNIVERSAL_PAYMENTS_REPORTS_ERROR',

  GET_UNIVERSAL_REPORTS_DETAILS_REQUEST:
    'GET_UNIVERSAL_REPORTS_DETAILS_REQUEST',
  GET_UNIVERSAL_REPORTS_DETAILS_SUCCESS:
    'GET_UNIVERSAL_REPORTS_DETAILS_SUCCESS',
  GET_UNIVERSAL_REPORTS_DETAILS_ERROR: 'GET_UNIVERSAL_REPORTS_DETAILS_ERROR',

  DOWNLOAD_UNIVERSAL_REPORT_DETAIL_REQUEST:
    'DOWNLOAD_UNIVERSAL_REPORT_DETAIL_REQUEST',
  DOWNLOAD_UNIVERSAL_REPORT_DETAIL_SUCCESS:
    'DOWNLOAD_UNIVERSAL_REPORT_DETAIL_SUCCESS',
  DOWNLOAD_UNIVERSAL_REPORT_DETAIL_ERROR:
    'DOWNLOAD_UNIVERSAL_REPORT_DETAIL_ERROR',

  DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_REQUEST:
    'DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_REQUEST',
  DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_SUCCESS:
    'DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_SUCCESS',
  DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_ERROR:
    'DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_ERROR',

  DOWNLOAD_TRANSACTION_REPORT_DETAIL_REQUEST:
    'DOWNLOAD_TRANSACTION_REPORT_DETAIL_REQUEST',
  DOWNLOAD_TRANSACTION_REPORT_DETAIL_SUCCESS:
    'DOWNLOAD_TRANSACTION_REPORT_DETAIL_SUCCESS',
  DOWNLOAD_TRANSACTION_REPORT_DETAIL_ERROR:
    'DOWNLOAD_TRANSACTION_REPORT_DETAIL_ERROR',

  DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_REQUEST:
    'DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_REQUEST',
  DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_SUCCESS:
    'DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_SUCCESS',
  DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_ERROR:
    'DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_ERROR',

  GET_STUDENT_REVENUE_REPORTS_REQUEST: 'GET_STUDENT_REVENUE_REPORTS_REQUEST',
  GET_STUDENT_REVENUE_REPORTS_SUCCESS: 'GET_STUDENT_REVENUE_REPORTS_SUCCESS',
  GET_STUDENT_REVENUE_REPORTS_ERROR: 'GET_STUDENT_REVENUE_REPORTS_ERROR',

  GET_GLOBAL_REVENUE_REPORTS_REQUEST: 'GET_GLOBAL_REVENUE_REPORTS_REQUEST',
  GET_GLOBAL_REVENUE_REPORTS_SUCCESS: 'GET_GLOBAL_REVENUE_REPORTS_SUCCESS',
  GET_GLOBAL_REVENUE_REPORTS_ERROR: 'GET_GLOBAL_REVENUE_REPORTS_ERROR',

  GET_DETAILED_GLOBAL_REVENUE_REPORTS_REQUEST:
    'GET_DETAILED_GLOBAL_REVENUE_REPORTS_REQUEST',
  GET_DETAILED_GLOBAL_REVENUE_REPORTS_SUCCESS:
    'GET_DETAILED_GLOBAL_REVENUE_REPORTS_SUCCESS',
  GET_DETAILED_GLOBAL_REVENUE_REPORTS_ERROR:
    'GET_DETAILED_GLOBAL_REVENUE_REPORTS_ERROR',

  DOWNLOAD_GLOBAL_REVENUE_REPORTS_REQUEST:
    'DOWNLOAD_GLOBAL_REVENUE_REPORTS_REQUEST',
  DOWNLOAD_GLOBAL_REVENUE_REPORTS_SUCCESS:
    'DOWNLOAD_GLOBAL_REVENUE_REPORTS_SUCCESS',
  DOWNLOAD_GLOBAL_REVENUE_REPORTS_ERROR:
    'DOWNLOAD_GLOBAL_REVENUE_REPORTS_ERROR',

  GET_COLLECTION_REPORTS_REQUEST: 'GET_COLLECTION_REPORTS_REQUEST',
  GET_COLLECTION_REPORTS_SUCCESS: 'GET_COLLECTION_REPORTS_SUCCESS',
  GET_COLLECTION_REPORTS_ERROR: 'GET_COLLECTION_REPORTS_ERROR',

  GET_FINANCIAL_YR_UNALLOCATED_REPORTS_REQUEST:
    'GET_FINANCIAL_YR_UNALLOCATED_REPORTS_REQUEST',
  GET_FINANCIAL_YR_UNALLOCATED_REPORTS_SUCCESS:
    'GET_FINANCIAL_YR_UNALLOCATED_REPORTS_SUCCESS',
  GET_FINANCIAL_YR_UNALLOCATED_REPORTS_ERROR:
    'GET_FINANCIAL_YR_UNALLOCATED_REPORTS_ERROR',

  GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST:
    'GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST',
  GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_SUCCESS:
    'GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_SUCCESS',
  GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_ERROR:
    'GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_ERROR',

  GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST:
    'GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST',
  GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_SUCCESS:
    'GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_SUCCESS',
  GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_ERROR:
    'GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_ERROR',

  GENERATE_SELECTED_QUARTER_REPORT_REQUEST:
    'GENERATE_SELECTED_QUARTER_REPORT_REQUEST',
  GENERATE_SELECTED_QUARTER_REPORT_SUCCESS:
    'GENERATE_SELECTED_QUARTER_REPORT_SUCCESS',
  GENERATE_SELECTED_QUARTER_REPORT_ERROR:
    'GENERATE_SELECTED_QUARTER_REPORT_ERROR',

  GET_SELECTED_QUARTER_REPORT_REQUEST: 'GET_SELECTED_QUARTER_REPORT_REQUEST',
  GET_SELECTED_QUARTER_REPORT_SUCCESS: 'GET_SELECTED_QUARTER_REPORT_SUCCESS',
  GET_SELECTED_QUARTER_REPORT_ERROR: 'GET_SELECTED_QUARTER_REPORT_ERROR',

  GET_MONTHLY_COLLECTIONS_REPORTS_REQUEST:
    'GET_MONTHLY_COLLECTIONS_REPORTS_REQUEST',
  GET_MONTHLY_COLLECTIONS_REPORTS_SUCCESS:
    'GET_MONTHLY_COLLECTIONS_REPORTS_SUCCESS',
  GET_MONTHLY_COLLECTIONS_REPORTS_ERROR:
    'GET_MONTHLY_COLLECTIONS_REPORTS_ERROR',

  GET_DETAILED_PERIOD_REPORTS_REQUEST: 'GET_DETAILED_PERIOD_REPORTS_REQUEST',
  GET_DETAILED_PERIOD_REPORTS_SUCCESS: 'GET_DETAILED_PERIOD_REPORTS_SUCCESS',
  GET_DETAILED_PERIOD_REPORTS_ERROR: 'GET_DETAILED_PERIOD_REPORTS_ERROR',

  GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_REQUEST:
    'GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_REQUEST',
  GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_SUCCESS:
    'GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_SUCCESS',
  GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_ERROR:
    'GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_ERROR',

  GET_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST:
    'GET_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST',
  GET_QUARTERLY_BILLING_DETAILS_REPORTS_SUCCESS:
    'GET_QUARTERLY_BILLING_DETAILS_REPORTS_SUCCESS',
  GET_QUARTERLY_BILLING_DETAILS_REPORTS_ERROR:
    'GET_QUARTERLY_BILLING_DETAILS_REPORTS_ERROR',

  GET_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST:
    'GET_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST',
  GET_QUARTERLY_STUDENT_DETAILS_REPORTS_SUCCESS:
    'GET_QUARTERLY_STUDENT_DETAILS_REPORTS_SUCCESS',
  GET_QUARTERLY_STUDENT_DETAILS_REPORTS_ERROR:
    'GET_QUARTERLY_STUDENT_DETAILS_REPORTS_ERROR',

  DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST:
    'DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST',
  DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_SUCCESS:
    'DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_SUCCESS',
  DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_ERROR:
    'DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_ERROR',

  DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST:
    'DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST',
  DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_SUCCESS:
    'DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_SUCCESS',
  DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_ERROR:
    'DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_ERROR',

  DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_REQUEST:
    'DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_REQUEST',
  DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_SUCCESS:
    'DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_SUCCESS',
  DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_ERROR:
    'DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_ERROR',

  DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_REQUEST:
    'DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_REQUEST',
  DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_SUCCESS:
    'DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_SUCCESS',
  DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_ERROR:
    'DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_ERROR',

  DOWNLOAD_DETAILED_PERIOD_REPORTS_REQUEST:
    'DOWNLOAD_DETAILED_PERIOD_REPORTS_REQUEST',
  DOWNLOAD_DETAILED_PERIOD_REPORTS_SUCCESS:
    'DOWNLOAD_DETAILED_PERIOD_REPORTS_SUCCESS',
  DOWNLOAD_DETAILED_PERIOD_REPORTS_ERROR:
    'DOWNLOAD_DETAILED_PERIOD_REPORTS_ERROR',

  DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_REQUEST:
    'DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_REQUEST',
  DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_SUCCESS:
    'DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_SUCCESS',
  DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_ERROR:
    'DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_ERROR',

  DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_REQUEST:
    'DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_REQUEST',
  DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_SUCCESS:
    'DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_SUCCESS',
  DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_ERROR:
    'DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_ERROR',

  SELECTED_REPORT_CONTEXT: 'SELECTED_REPORT_CONTEXT',
  SET_CURRENT_UNIVERSAL_REPORT: 'SET_CURRENT_UNIVERSAL_REPORT',
  SET_UNIVERSAL_REPORTS_CONTEXT: 'SET_UNIVERSAL_REPORTS_CONTEXT',
  SET_SELECTED_FINANCIAL_YEAR_CONTEXT: 'SET_SELECTED_FINANCIAL_YEAR_CONTEXT',

  searchUniversalTransaction: (data) => ({
    type: transaction.SEARCH_UNIVERSAL_TRANSACTION_REQUEST,
    data,
  }),

  getSponsorPaymentReports: (params) => ({
    type: transaction.GET_SPONSOR_PAYMENTS_REPORT_REQUEST,
    params,
  }),

  getSponsorAllocationReport: (params) => ({
    type: transaction.GET_SPONSOR_ALLOCATIONS_UNIVERSAL_REQUEST,
    params,
  }),

  getTransactionReports: (context) => ({
    type: transaction.GET_TRANSACTION_REPORTS_REQUEST,
    context,
  }),

  getCollectionReports: (reportCategory, url) => ({
    type: transaction.GET_COLLECTION_REPORTS_REQUEST,
    reportCategory,
    url,
  }),

  getStudentRevenueCollectionReports: (context) => ({
    type: transaction.GET_STUDENT_REVENUE_REPORTS_REQUEST,
    context,
  }),

  getGlobalRevenueCollectionReports: (context) => ({
    type: transaction.GET_GLOBAL_REVENUE_REPORTS_REQUEST,
    context,
  }),

  downloadGlobalRevenueCollectionReports: (context) => ({
    type: transaction.DOWNLOAD_GLOBAL_REVENUE_REPORTS_REQUEST,
    context,
  }),

  getDetailedGlobalRevenueCollectionReports: (context) => ({
    type: transaction.GET_DETAILED_GLOBAL_REVENUE_REPORTS_REQUEST,
    context,
  }),

  getMonthlyCollectionReports: () => ({
    type: transaction.GET_MONTHLY_COLLECTIONS_REPORTS_REQUEST,
  }),

  getUniversalPaymentsReports: (context) => ({
    type: transaction.GET_UNIVERSAL_PAYMENTS_REPORTS_REQUEST,
    context,
  }),

  getFinancialYrQuarterlyReports: (context) => ({
    type: transaction.GET_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST,
    context,
  }),

  generateFinancialYrQuarterlyReports: (context) => ({
    type: transaction.GENERATE_FINANCIAL_YEAR_QUARTERLY_REPORTS_REQUEST,
    context,
  }),

  getFinancialYrUnallocatedReports: (context) => ({
    type: transaction.GET_FINANCIAL_YR_UNALLOCATED_REPORTS_REQUEST,
    context,
  }),

  getQuarterlyOpeningReceivablesReports: (context) => ({
    type: transaction.GET_QUARTERLY_OPENING_RECEIVABLES_REPORTS_REQUEST,
    context,
  }),

  getQuarterlyBillingDetailsReports: (context) => ({
    type: transaction.GET_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST,
    context,
  }),

  getQuarterlyStudentDetailsReports: (context) => ({
    type: transaction.GET_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST,
    context,
  }),

  getSelectedQuarterReport: (context) => ({
    type: transaction.GET_SELECTED_QUARTER_REPORT_REQUEST,
    context,
  }),

  generateSelectedQuarterReport: (context, quarter) => ({
    type: transaction.GENERATE_SELECTED_QUARTER_REPORT_REQUEST,
    context,
    quarter,
  }),

  getReportsDetails: (context) => ({
    type: transaction.GET_UNIVERSAL_REPORTS_DETAILS_REQUEST,
    context,
  }),

  getDetailedPeriodicReports: (context) => ({
    type: transaction.GET_DETAILED_PERIOD_REPORTS_REQUEST,
    context,
  }),

  downloadDetailedReport: (context) => ({
    type: transaction.DOWNLOAD_UNIVERSAL_REPORT_DETAIL_REQUEST,
    context,
  }),

  downloadTransactionReport: (context) => ({
    type: transaction.DOWNLOAD_TRANSACTION_REPORT_DETAIL_REQUEST,
    context,
  }),

  downloadReportSummery: (context, reportType) => ({
    type: transaction.DOWNLOAD_UNIVERSAL_REPORT_SUMMERY_REQUEST,
    context,
    reportType,
  }),

  downloadAllTransactionsDetailedReport: (context) => ({
    type: transaction.DOWNLOAD_ALL_ACCOUNT_TRANSACTIONS_REPORT_REQUEST,
    context,
  }),

  downloadDetailedPeriodicReports: (context) => ({
    type: transaction.DOWNLOAD_DETAILED_PERIOD_REPORTS_REQUEST,
    context,
  }),

  downloadQuarterlyFeesAllocations: (context) => ({
    type: transaction.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_REPORT_REQUEST,
    context,
  }),

  downloadQuarterlyFeesAllocationDetails: (context) => ({
    type: transaction.DOWNLOAD_QUARTERLY_FEES_ALLOCATION_DETAILS_REQUEST,
    context,
  }),

  downloadQuarterlyBillingDetails: (context) => ({
    type: transaction.DOWNLOAD_QUARTERLY_BILLING_DETAILS_REPORTS_REQUEST,
    context,
  }),

  downloadQuarterlyStudentDetails: (context) => ({
    type: transaction.DOWNLOAD_QUARTERLY_STUDENT_DETAILS_REPORTS_REQUEST,
    context,
  }),

  downloadQuarterlyReceivableDetails: (context) => ({
    type: transaction.DOWNLOAD_QUARTERLY_RECEIVABLE_DETAILS_REQUEST,
    context,
  }),

  downloadQuarterlyDebitNoteDetails: (context) => ({
    type: transaction.DOWNLOAD_QUARTERLY_DEBIT_DETAILS_REPORTS_REQUEST,
    context,
  }),

  setSelectedReportContext: (context) => ({
    type: transaction.SELECTED_REPORT_CONTEXT,
    context,
  }),

  setUniversalReportContext: (context) => ({
    type: transaction.SET_UNIVERSAL_REPORTS_CONTEXT,
    context,
  }),

  setCurrentReport: (report) => ({
    type: transaction.SET_CURRENT_UNIVERSAL_REPORT,
    report,
  }),

  setSelectedFinancialYr: (data) => ({
    type: transaction.SET_SELECTED_FINANCIAL_YEAR_CONTEXT,
    data,
  }),
};

export default transaction;
