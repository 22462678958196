const revenueReportActions = {
  GET_ACADEMIC_YEAR_COLLECTION_REPORT_REQUEST:
    'GET_ACADEMIC_YEAR_COLLECTION_REPORT_REQUEST',
  GET_ACADEMIC_YEAR_COLLECTION_REPORT_SUCCESS:
    'GET_ACADEMIC_YEAR_COLLECTION_REPORT_SUCCESS',
  GET_ACADEMIC_YEAR_COLLECTION_REPORT_ERROR:
    'GET_ACADEMIC_YEAR_COLLECTION_REPORT_ERROR',

  GET_ACADEMIC_UNIT_COLLECTION_REPORT_REQUEST:
    'GET_ACADEMIC_UNIT_COLLECTION_REPORT_REQUEST',
  GET_ACADEMIC_UNIT_COLLECTION_REPORT_SUCCESS:
    'GET_ACADEMIC_UNIT_COLLECTION_REPORT_SUCCESS',
  GET_ACADEMIC_UNIT_COLLECTION_REPORT_ERROR:
    'GET_ACADEMIC_UNIT_COLLECTION_REPORT_ERROR',

  getAcadYrCollectionReport: (params) => ({
    type: revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_REQUEST,
    params,
  }),

  getAcadUnitCollectionReport: (params) => ({
    type: revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_REQUEST,
    params,
  }),
};

export default revenueReportActions;
