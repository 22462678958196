const institutionSetup = {
  institutionStructure: {
    loading: false,
    institutionStructure: {},

    updating: false,
    deleteError: {},
    updateError: {},

    creating: false,
    createResponse: {},
    createError: {},

    uploadingLogo: false,
    uploadSuccess: {},
    uploadError: {},

    dashboardData: {},
    dashboardError: {},
  },
  documentSetting: {
    loading: false,
    documentSettings: [],
    loadError: {},

    loadingPreview: false,
    documentPreview: [],
    loadPreviewError: {},

    creating: false,
    createResponse: {},
    createError: {},
  },
  metadata: {
    metadata: [],
    gradings: [],
    singleMetadata: {},
    metadataError: {},
    createdData: {},
    updatedData: {},
    createdMetadataValue: {},
    updatedMetadataValue: {},
    metadataValueError: {},
    deleteMetadataValueResponse: {},
    updateMetadataValueResponse: {},
    deletingMetadataValueId: null,
    updatingMetadataValueId: null,
    updatingMetadataValue: false,
    deletingMetadataValue: false,
    updateMetadataValueError: {},
    deleteMetadataValueError: {},
    loading: false,
    creating: false,
  },
  college: {
    fetchingOne: false,
    colleges: [],
    collegeError: {},
    createdCollege: {},
    deleteResponse: {},
    updateResponse: {},
    deletingId: null,
    updatingId: null,
    updating: false,
    deleting: false,
    loading: false,
    creating: false,

    downloadResponse: {},
    downloading: false,
    downloadError: {},

    uploading: false,
    uploadResponse: {},
    uploadError: {},
  },
  faculty: {
    faculties: [],
    faculty: {},
    facultyError: {},
    createdFacultyData: {},
    deleteResponse: {},
    updateResponse: {},
    deletingId: null,
    updatingId: null,
    updating: false,
    deleting: false,
    updateError: {},
    deleteError: {},
    loading: false,
    creating: false,

    downloading: false,
    downloadResponse: {},
    downloadError: {},

    uploading: false,
    uploadResponse: {},
    uploadError: {},
  },
  department: {
    departments: [],
    department: {},
    departmentError: {},
    createdDepartmentData: {},
    createDepartmentError: {},
    deleteResponse: {},
    updateResponse: {},
    deletingId: null,
    updatingId: null,
    updating: false,
    deleting: false,
    updateError: {},
    deleteError: {},
    loading: false,
    creating: false,

    downloadingDepartment: false,
    downloadDepartmentResponse: {},
    downloadDepartmentError: {},

    uploadingDepartment: false,
    uploadDepartmentResponse: {},
    uploadDepartmentError: {},
  },
};

export default institutionSetup;
