const eventsInitialState = {
  academicYear: {
    academicYears: [],
    academicYearsByCampus: [],
    created: {},
    deleteError: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    createdAcademicYear: {},
    createError: {},
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,

    gettingAcademicYearByCampus: false,

    createSemesterError: {},
    createdSemester: {},
    creatingSemester: false,

    updateSemesterError: {},
    updatedSemester: {},
    updatingSemester: false,

    updateAcademicYrError: {},
    updatedAcademicYr: {},
    updatingAcademicYr: false,

    deleteAcademicYrError: {},
    deletedAcademicYr: {},
    deletingAcademicYr: false,

    selectedAcademicYear: null,
    editAcadYrData: {},
  },
  event: {
    events: [],
    academicYearEvents: [],
    semesterEvents: [],
    created: {},
    updateError: {},
    createResponse: {},
    updateResponse: {},
    deleteResponse: {},
    creating: false,
    createdEvent: {},
    createError: {},
    gettingAcademicYearEvents: false,
    gettingSemesterEvents: false,
    loading: false,
    fetching: false,
    updating: false,
    deleting: false,
    deleteError: {},
    editError: {},
    editedEvent: {},

    semesterEventData: {},
  },
};

export default eventsInitialState;
