const resultManager = {
  resultNode: {
    assignedCourses: [],
    loadError: {},
    loading: false,

    evaluatingRemarks: false,
    evaluateError: {},

    courseNodes: {},
    nodeDetails: [],
    fetching: false,

    creating: false,
    createResponse: {},
    createError: {},

    assignedLecturers: {},
    nodesContext: {},
    resultsContext: {},
    publishContext: {},
    selectedNode: null,

    downloadTemplateSuccess: {},
    downloadTemplateError: {},
    downloadingTemplate: false,

    downloading: false,
    downloadError: {},
    downloadSuccess: {},

    uploading: false,
    uploadSuccess: {},
    uploadError: {},

    updating: false,
    updateSuccess: {},
    updateError: {},

    updatingAcademicYears: false,
    updateAYSuccess: false,
    updateAYError: {},

    nodeResults: {},
    contextResults: [],
    dataToEdit: {},
    selectedProgramme: null,

    gettingCourses: false,
    resultCourses: [],
    resultCourseError: {},

    gettingCourseResults: false,
    courseResults: [],
    courseResultError: {},

    publishing: false,
    publishResponse: {},
    publishError: {},
  },
  graduationList: {
    departments: [],
    loadError: {},
    loading: false,

    programmes: [],
    programmeError: {},
    fetchingProgramme: false,

    unitProgrammes: [],
    unitProgrammeError: {},
    fetchingUnitProgramme: false,

    provisionalList: [],
    listError: {},
    fetchingList: false,

    graduationListError: {},
    gradListDetails: [],
    fetchingGraduationList: false,

    schoolContext: {},
    selectedProgramme: {},
    selectedAcadYear: null,
    selectedDepartment: {},

    generating: false,
    generateResponse: {},
    generateError: {},

    graduatingStudents: false,
    graduateStudentsSuccess: {},
    graduateStudentsError: {},

    addingToProvList: false,
    addToProvListSuccess: {},
    addToProvListError: {},

    provisionalListContext: {},
    finalListContext: {},

    finalistListError: {},
    gettingFinalists: false,
    finalistsList: [],

    generatingProvList: false,
    generateProvListSuccess: {},
    generateProvListError: {},

    fetchingFinalist: false,
    fetchFinalistError: {},
    fetchFinalistSuccess: {},

    addToGradListError: {},
    addToGradListSuccess: {},
    addingToGradList: false,

    addCategoryError: {},
    addCategoryToProvListSuccess: {},
    addingCategoryToProvList: false,

    gettingGraduatedStudents: false,
    graduatedStudents: [],
    getGraduatedStudentError: [],

    updateGraduationYearSuccess: {},
    updateGraduationYearError: {},
    updatingGraduationYear: false,

    removedFromProvisionalList: {},
    removeFromProvisionalListError: {},
    removingFromProvisionalList: false,

    removedFromFinalList: {},
    removeFromFinalListError: {},
    removingFromFinalList: false,

    downloadFinalListResponse: {},
    downloadFinalListError: {},
    downloadingFinalList: false,

    billingSuccess: {},
    billingError: {},
    billingStudents: false,

    showPrintModal: false,
    showPreviewModal: false,
  },
  batchResult: {
    resultBatches: [],
    batchResultError: {},
    loadingBatchResult: false,

    fetchingSingleBatch: false,
    singleBatch: {},
    singleBatchError: {},

    gettingBatchApprovalResults: false,
    batchApprovalResults: [],
    getBatchApprovalError: {},

    approvingBatchResults: false,
    approvedBatchResults: [],
    approveBatchResultsError: {},

    approvingSelectedResults: false,
    approvedSelectedResults: [],
    approveSelectedResultsError: {},

    submittingBatchResults: false,
    submitBatchResults: [],
    submitBatchResultsError: {},

    submittingSelectedResults: false,
    submitSelectedResults: [],
    submitSelectedResultsError: {},

    updatingSingleBatch: false,
    updateBatchError: {},
    updateResponse: {},

    deletingResultBatch: false,
    deleteBatchResponse: {},
    deleteBatchError: {},

    deletingResultDetail: false,
    deleteResultDetailError: {},
    deleteDetailResponse: {},

    searchingResultBatch: false,
    searchResultBatchError: {},
    searchedResultBatches: [],

    downloadingBatchResults: false,
    downloadedResult: {},
    downloadResultError: {},

    batchParams: {},
    resultToEdit: {},
  },
  resultDashboard: {
    loadError: {},
    dashboardData: {},
    loading: false,

    fetching: false,
    detailedReports: [],
    fetchError: {},

    programmes: [],
    programmeError: {},
    fetchingProgramme: false,

    departmentContext: {},
    selectedStudyYear: null,
    toggleProgrammes: false,

    selectedResultContext: {},

    gettingSemesterSummary: false,
    semesterResultSummaryError: {},
    semesterResultSummary: [],

    downloadingResults: false,
    downloadResultError: {},
    downloadResultSuccess: [],

    submittingProgrammeReport: false,
    submittedProgrammeReport: {},
    submitProgrammeReportError: {},

    gettingDepartmentReport: false,
    departmentReports: [],
    departmentReportError: {},

    gettingFacultyReport: false,
    facultyReports: [],
    facultyReportError: {},
    facultyContext: {},

    gettingSenateReport: false,
    senateReports: [],
    senateContext: {},
    senateReportError: {},
  },
  studentGrade: {
    selectedProgramme: {},
    selectedContext: {},

    gettingStudentGrades: false,
    studentGrades: [],
    studentGradeError: {},

    computingGrades: false,
    computeGradeError: {},
    computeGradeSuccess: {},

    computingSingleGrades: false,
    computeSingleGradeError: {},
    computeSingleGradeSuccess: {},
  },
  searchedStudentResult: {
    currentStudent: {},

    searchingResult: false,
    searchResults: [],
    searchError: {},

    updatingResult: false,
    updateSuccess: {},
    updateError: {},

    computingResults: false,
    computeSuccess: {},
    computeError: {},
  },
  resultLogs: {
    gettingLogs: false,
    userLogs: {},
    getLogsError: {},
  },
};

export default resultManager;
