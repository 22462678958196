import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { revenueReportActions } from '../../actions';

function* getAcadYrCollectionsReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/admission-reports/trans`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* getAcadUnitCollectionsReport(actions) {
  try {
    const response = yield axios({
      url: `/bi/admission-reports/trans-unit`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_SUCCESS,
      data: response.result,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_ERROR,
      error: error.data,
    });
  }
}

function* watchGetAcadYrCollectionsReport() {
  yield takeLatest(
    revenueReportActions.GET_ACADEMIC_YEAR_COLLECTION_REPORT_REQUEST,
    getAcadYrCollectionsReport
  );
}

function* watchGetAcadUnitCollectionsReport() {
  yield takeLatest(
    revenueReportActions.GET_ACADEMIC_UNIT_COLLECTION_REPORT_REQUEST,
    getAcadUnitCollectionsReport
  );
}

export default [
  fork(watchGetAcadYrCollectionsReport),
  fork(watchGetAcadUnitCollectionsReport),
];
