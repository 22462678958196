const courseAssignmentState = {
  courseAssignment: {
    departmentProgrammes: [],
    gettingDepartmentProgrammes: false,
    departmentProgrammeError: {},
    departmentContext: {},
    assignmentContext: {},

    assignmentCourses: [],
    gettingAssignmentCourses: false,
    getAssignmentCourseError: {},

    selectedCourse: {},
    selectedLecturers: [],

    lecturers: [],
    gettingLecturers: false,
    getLecturerError: {},

    assignSuccess: {},
    assigningLecturers: false,
    assignError: {},

    addingLecturers: false,
    addLecturerError: {},

    editing: false,
    dataToEdit: {},
    resultCategoriesToEdit: [],

    editingLecturer: false,
    editLecturerError: {},

    removingLecturers: false,
    removeLecturerError: {},

    showAddLecturerModal: false,
    showEditResultCategoriesModal: false,
    showDeleteModal: false,

    editCourseAssignmentResultCategories: {},
    editCourseAssignmentResultCategoriesError: {},
    editingCourseAssignmentResultCategories: false,

    contextAssignments: [],
    contextAssignment: {},
    contextAssignmentError: {},
    gettingContextAssignments: false,
  },
  building: {
    fetching: false,
    buildings: [],
    error: {},

    gettingByCampus: false,
    buildingsByCampus: [],
    getByCampusError: {},

    creatingBuilding: false,
    creatingBuildingError: {},
    creatingBuildingSuccess: {},

    downloadingTemplate: false,
    errorDownloadingTemplate: {},
    successDownloadingTemplate: {},

    uploading: false,
    uploadError: {},
    uploadResponse: {},

    deleting: false,
    deleteError: {},
    deleteResponse: {},

    editing: false,
    editError: {},
    editResponse: {},

    selectedCampusId: null,

    programmeContextId: null,
  },
  teachingTimetable: {
    loading: false,
    teachingTimetable: [],
    loadError: {},

    searching: false,
    searchedTeachingTimetable: [],
    searchError: {},
    searchContext: {},

    searchingByDepartment: false,
    departmentTimetable: [],
    byDepartmentError: {},
    departmenyContext: {},

    searchingByFaculty: false,
    facultyTimetable: [],
    byFacultyError: {},
    facultyContext: {},

    showAssignLecturerModal: false,

    assigningLecturer: false,
    assigLectureError: {},
    assigedLecture: {},

    downloadingTemplate: false,
    errorDownloadingTemplate: {},
    successDownloadingTemplate: {},

    uploading: false,
    uploadError: {},
    uploadResponse: {},

    deleting: false,
    deleteError: {},
    deleteResponse: {},

    editing: false,
    editError: {},
    editResponse: {},
  },
  semesterCourseLoad: {
    semesterCourseLoads: [],
    loading: false,
    loadError: {},

    updating: false,
    updateError: {},
    updatedData: {},

    creating: false,
    createError: {},
    createdData: {},

    deleting: false,
    deleteError: {},
    deleteResponse: {},

    context: {},

    currentTab: 'semester-course-loads',

    showEditModal: false,
  },
};

export default courseAssignmentState;
