import { userLogsActions } from '../../actions';
import initialState from '../../initialState';

const userLogs = (state = initialState.itsData, actions) => {
  switch (actions.type) {
    case userLogsActions.GET_USER_LOGS_REQUEST:
      return {
        ...state,
        gettingUserLogs: true,
        getLogsError: {},
      };

    case userLogsActions.GET_USER_LOGS_SUCCESS:
      return {
        ...state,
        gettingUserLogs: false,
        userLogs: actions.data,
      };

    case userLogsActions.GET_USER_LOGS_ERROR:
      return {
        ...state,
        gettingUserLogs: false,
        getLogsError: actions.data,
      };

    default:
      return state;
  }
};

export default userLogs;
