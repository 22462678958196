const ChartOfAccount = {
  GET_CHART_OF_ACCOUNTS_REQUEST: 'GET_CHART_OF_ACCOUNTS_REQUEST',
  GET_CHART_OF_ACCOUNTS_SUCCESS: 'GET_CHART_OF_ACCOUNTS_SUCCESS',
  GET_CHART_OF_ACCOUNTS_ERROR: 'GET_CHART_OF_ACCOUNTS_ERROR',

  GET_SINGLE_CHART_OF_ACCOUNT_REQUEST: 'GET_SINGLE_CHART_OF_ACCOUNT_REQUEST',
  GET_SINGLE_CHART_OF_ACCOUNT_SUCCESS: 'GET_SINGLE_CHART_OF_ACCOUNT_SUCCESS',
  GET_SINGLE_CHART_OF_ACCOUNT_ERROR: 'GET_SINGLE_CHART_OF_ACCOUNT_ERROR',

  CREATE_CHART_OF_ACCOUNT_SUCCESS: 'CREATE_CHART_OF_ACCOUNT_SUCCESS',
  CREATE_CHART_OF_ACCOUNT_REQUEST: 'CREATE_CHART_OF_ACCOUNT_REQUEST',
  CREATE_CHART_OF_ACCOUNT_ERROR: 'CREATE_CHART_OF_ACCOUNT_ERROR',

  UPDATE_CHART_OF_ACCOUNT_SUCCESS: 'UPDATE_CHART_OF_ACCOUNT_SUCCESS',
  UPDATE_CHART_OF_ACCOUNT_REQUEST: 'UPDATE_CHART_OF_ACCOUNT_REQUEST',
  UPDATE_CHART_OF_ACCOUNT_ERROR: 'UPDATE_CHART_OF_ACCOUNT_ERROR',

  DELETE_CHART_OF_ACCOUNT_REQUEST: 'DELETE_CHART_OF_ACCOUNT_REQUEST',
  DELETE_CHART_OF_ACCOUNT_SUCCESS: 'DELETE_CHART_OF_ACCOUNT_SUCCESS',
  DELETE_CHART_OF_ACCOUNT_ERROR: 'DELETE_CHART_OF_ACCOUNT_ERROR',

  DOWNLOAD_CHART_TEMPLATE_REQUEST: 'DOWNLOAD_CHART_TEMPLATE_REQUEST',
  DOWNLOAD_CHART_TEMPLATE_SUCCESS: 'DOWNLOAD_CHART_TEMPLATE_SUCCESS',
  DOWNLOAD_CHART_TEMPLATE_ERROR: 'DOWNLOAD_CHART_TEMPLATE_ERROR',

  UPLOAD_CHART_TEMPLATE_REQUEST: 'UPLOAD_CHART_TEMPLATE_REQUEST',
  UPLOAD_CHART_TEMPLATE_SUCCESS: 'UPLOAD_CHART_TEMPLATE_SUCCESS',
  UPLOAD_CHART_TEMPLATE_ERROR: 'UPLOAD_CHART_TEMPLATE_ERROR',

  SET_CURRENT_CHART_OF_ACCOUNT_TAB: 'SET_CURRENT_CHART_OF_ACCOUNT_TAB',
  SHOW_DELETE_CHART_OF_ACCOUNT_MODAL: 'SHOW_DELETE_CHART_OF_ACCOUNT_MODAL',

  getChartOfAccounts: (data) => ({
    type: ChartOfAccount.GET_CHART_OF_ACCOUNTS_REQUEST,
    data,
  }),

  createChartOfAccount: (data) => ({
    type: ChartOfAccount.CREATE_CHART_OF_ACCOUNT_REQUEST,
    data,
  }),

  getSingleChartOfAccount: (chartOfAccountId, data) => ({
    type: ChartOfAccount.GET_SINGLE_CHART_OF_ACCOUNT_REQUEST,
    chartOfAccountId,
    data,
  }),

  updateChartOfAccount: (chartOfAccountId, data) => ({
    type: ChartOfAccount.UPDATE_CHART_OF_ACCOUNT_REQUEST,
    chartOfAccountId,
    data,
  }),

  deleteChartOfAccount: (chartOfAccountId) => ({
    type: ChartOfAccount.DELETE_CHART_OF_ACCOUNT_REQUEST,
    chartOfAccountId,
  }),

  downloadChartTemplate: () => ({
    type: ChartOfAccount.DOWNLOAD_CHART_TEMPLATE_REQUEST,
  }),

  uploadChartTemplate: (data) => ({
    type: ChartOfAccount.UPLOAD_CHART_TEMPLATE_REQUEST,
    data,
  }),

  setCurrentTab: (currentTab) => ({
    type: ChartOfAccount.SET_CURRENT_CHART_OF_ACCOUNT_TAB,
    currentTab,
  }),

  showDeleteModal: (payload) => ({
    type: ChartOfAccount.SHOW_DELETE_CHART_OF_ACCOUNT_MODAL,
    payload,
  }),
};

export default ChartOfAccount;
