import { uniqBy } from 'lodash';
import { semesterEventActions } from '../../actions';
import initialState from '../../initialState';

const semesterEvent = (state = initialState.semesterEvent, actions) => {
  switch (actions.type) {
    case semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allCurrentSemesterEvents: uniqBy(
          [
            {
              studentId: actions.studentId,
              studentProgrammeId: actions.studentProgrammeId,
              ...actions.data,
            },
            ...state.allCurrentSemesterEvents,
          ],
          'studentProgrammeId'
        ),
      };
    case semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };
    default:
      return state;
  }
};
export default semesterEvent;
