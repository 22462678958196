import update from 'immutability-helper';
import { lecturerCourseActions } from '../../actions';
import initialState from '../../initialState';

const lecturerCourses = (state = initialState.lecturerCourse, actions) => {
  switch (actions.type) {
    case lecturerCourseActions.GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_REQUEST:
      return {
        ...state,
        gettingRawResults: true,
        getRawResultsError: {},
      };
    case lecturerCourseActions.GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_SUCCESS: {
      const { courseAssignmentId, resultCategory, data } = actions;
      const { rawResults } = state;
      const contextData = { courseAssignmentId, data, resultCategory };
      const findContextIndex = rawResults.findIndex(
        (currentContext) =>
          currentContext.courseAssignmentId === courseAssignmentId
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          rawResults: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          rawResults: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingRawResults: false,
      };
    }
    case lecturerCourseActions.GET_COURSE_ASSIGNMENT_UPLOADED_RESULTS_ERROR:
      return {
        ...state,
        getRawResultsError: actions.error,
        gettingRawResults: false,
      };
    case lecturerCourseActions.GET_COURSE_ASSIGNMENT_LECTURER_COURSES_REQUEST:
      return {
        ...state,
        gettingCourses: true,
        assignmentContext: actions.params,
        getCoursesError: {},
      };
    case lecturerCourseActions.GET_COURSE_ASSIGNMENT_LECTURER_COURSES_SUCCESS: {
      const { context, data } = actions;
      const { lecturerCourses: courses } = state;
      const contextData = { ...context, data };
      const findContextIndex = courses.findIndex(
        (currentContext) =>
          currentContext.academic_year_id === context.academic_year_id &&
          currentContext.department_id === context.department_id &&
          currentContext.semester_id === context.semester_id
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          lecturerCourses: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          lecturerCourses: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingCourses: false,
      };
    }
    case lecturerCourseActions.GET_COURSE_ASSIGNMENT_LECTURER_COURSES_ERROR:
      return {
        ...state,
        gettingCourses: false,
        getCoursesError: actions.error,
      };

    case lecturerCourseActions.GET_COURSE_ASSIGNMENT_STUDENTS_REQUEST:
      return {
        ...state,
        gettingStudents: true,
        getStudentsError: {},
      };
    case lecturerCourseActions.GET_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST:
      return {
        ...state,
        gettingLecturerResultCategoryAliases: true,
        lecturerResultCategoriesError: {},
      };
    case lecturerCourseActions.GET_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS: {
      const { courseAssignmentId, data } = actions;
      const { lecturerResultCategoryAliases } = state;

      const contextData = {
        courseAssignmentId,
        aliases: data,
      };
      const findContextIndex = lecturerResultCategoryAliases?.findIndex(
        (currentContext) =>
          currentContext.courseAssignmentId === courseAssignmentId
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          lecturerResultCategoryAliases: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          lecturerResultCategoryAliases: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingLecturerResultCategoryAliases: false,
      };
    }
    case lecturerCourseActions.GET_LECTURER_RESULT_CATEGORY_ALIAS_ERROR:
      return {
        ...state,
        gettingLecturerResultCategoryAliases: false,
        lecturerResultCategoriesError: actions.error,
      };
    case lecturerCourseActions.SET_SELECTED_RESULT_ALIAS: {
      return {
        ...state,
        selectedResultAlias: actions.resultAlias,
      };
    }
    case lecturerCourseActions.CREATE_LECTURER_RESULT_CATEGORY_ALIAS_REQUEST:
      return {
        ...state,
        creatingLecturerResultCategoryAlias: true,
        createLecturerResultCategoryAliasError: {},
      };
    case lecturerCourseActions.CREATE_LECTURER_RESULT_CATEGORY_ALIAS_SUCCESS:
      return {
        ...state,
        creatingLecturerResultCategoryAlias: false,
        createLecturerResultCategoryAliasSuccess: actions.data,
      };
    case lecturerCourseActions.SET_EDIT_RESULT_CATEGORY_ALIAS:
      return {
        ...state,
        editResultAlias: actions.data,
      };
    case lecturerCourseActions.SET_SHOW_RESULT_CATEGORY_ALIAS:
      return {
        ...state,
        showAliasModal: actions.payload,
        aliasData: actions.aliasData,
      };
    case lecturerCourseActions.SET_SHOW_RESULT_COMPUTE_FINAL_MARKS:
      return {
        ...state,
        showComputeModal: actions.payload,
      };
    case lecturerCourseActions.DELETE_RESULT_CATEGORY_ALIAS_REQUEST:
      return {
        ...state,
        deletingResultCategoryAlias: true,
        deleteResultCategoryAliasError: {},
      };
    case lecturerCourseActions.DELETE_RESULT_CATEGORY_ALIAS_SUCCESS:
      return {
        ...state,
        deletingResultCategoryAlias: false,
        deleteResultCategoryAliasSuccess: actions.data,
      };
    case lecturerCourseActions.DELETE_RESULT_CATEGORY_ALIAS_ERROR:
      return {
        ...state,
        deletingResultCategoryAlias: false,
        deleteResultCategoryAliasError: actions.error,
      };
    case lecturerCourseActions.SET_SHOW_DELETE_RESULT_CATEGORY_ALIAS_MODAL:
      return {
        ...state,
        showDeleteResultCategoryAliasModal: actions.data,
      };

    case lecturerCourseActions.SET_SELECTED_RESULT_CATEGORY_ALIASES:
      return {
        ...state,
        selectedResultCategoryAliases: actions.data,
      };
    case lecturerCourseActions.CREATE_LECTURER_RESULT_CATEGORY_ALIAS_ERROR:
      return {
        ...state,
        creatingLecturerResultCategoryAlias: false,
        createLecturerResultCategoryAliasError: actions.error,
      };

    case lecturerCourseActions.EDIT_RESULT_CATEGORY_ALIAS_REQUEST:
      return {
        ...state,
        editingResultCategoryAlias: true,
        editResultCategoryAliasError: {},
      };
    case lecturerCourseActions.EDIT_RESULT_CATEGORY_ALIAS_SUCCESS:
      return {
        ...state,
        editingResultCategoryAlias: false,
        editResultCategoryAlias: actions.data,
      };
    case lecturerCourseActions.EDIT_RESULT_CATEGORY_ALIAS_ERROR:
      return {
        ...state,
        editingResultCategoryAlias: false,
        editResultCategoryAliasError: actions.error,
      };

    case lecturerCourseActions.COMPUTE_STUDENTS_RESULT_REQUEST:
      return {
        ...state,
        computingResults: true,
        computeResultError: {},
      };
    case lecturerCourseActions.COMPUTE_STUDENTS_RESULT_SUCCESS:
      return {
        ...state,
        computingResults: false,
      };
    case lecturerCourseActions.COMPUTE_STUDENTS_RESULT_ERROR:
      return {
        ...state,
        computingResults: false,
        computeResultError: actions.error,
      };

    case lecturerCourseActions.GET_COURSE_ASSIGNMENT_STUDENTS_SUCCESS: {
      const { courseAssignmentId, data } = actions;
      const { courseRegisteredStudents } = state;
      const contextData = {
        courseAssignmentId,
        students: data.students,
      };
      const findContextIndex = courseRegisteredStudents?.findIndex(
        (currentContext) =>
          currentContext.courseAssignmentId === courseAssignmentId
      );

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          courseRegisteredStudents: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          courseRegisteredStudents: {
            [findContextIndex]: { $set: contextData },
          },
        });
      }

      return {
        ...newState,
        gettingStudents: false,
      };
    }

    case lecturerCourseActions.GET_COURSE_ASSIGNMENT_STUDENTS_ERROR:
      return {
        ...state,
        gettingStudents: false,
        getStudentsError: actions.error,
      };
    case lecturerCourseActions.SET_SELECTED_COURSE:
      return {
        ...state,
        selectedCourse: actions.course,
      };
    default:
      return state;
  }
};

export default lecturerCourses;
