import { resultsCategoryPolicyActions } from '../../actions';
import initialState from '../../initialState';

const resultsCategoryPolicy = (
  state = initialState.resultsCategoryPolicy,
  actions
) => {
  switch (actions.type) {
    case resultsCategoryPolicyActions.CREATE_RESULT_CATEGORY_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case resultsCategoryPolicyActions.CREATE_RESULT_CATEGORY_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case resultsCategoryPolicyActions.CREATE_RESULT_CATEGORY_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case resultsCategoryPolicyActions.CREATE_DEGREE_CLASS_REQUEST:
      return {
        ...state,
        createError: {},
        creating: true,
      };
    case resultsCategoryPolicyActions.CREATE_DEGREE_CLASS_SUCCESS:
      return {
        ...state,
        createResponse: actions.data,
        creating: false,
      };
    case resultsCategoryPolicyActions.CREATE_DEGREE_CLASS_ERROR:
      return {
        ...state,
        createError: actions.error,
        creating: false,
      };

    case resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_SUCCESS:
      return {
        ...state,
        resultsCategoryPolicies: actions.data,
        loading: false,
      };
    case resultsCategoryPolicyActions.GET_RESULT_CATEGORY_POLICY_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case resultsCategoryPolicyActions.GET_DEGREE_CLASS_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };
    case resultsCategoryPolicyActions.GET_DEGREE_CLASS_SUCCESS:
      return {
        ...state,
        degreeClassPolicies: actions.data,
        loading: false,
      };
    case resultsCategoryPolicyActions.GET_DEGREE_CLASS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case resultsCategoryPolicyActions.EDIT_RESULT_CATEGORY_POLICY_REQUEST:
      return {
        ...state,
        updateError: {},
        updating: true,
      };
    case resultsCategoryPolicyActions.EDIT_RESULT_CATEGORY_POLICY_SUCCESS:
      return {
        ...state,
        updateResponse: actions.data,
        updating: false,
      };
    case resultsCategoryPolicyActions.EDIT_RESULT_CATEGORY_POLICY_ERROR:
      return {
        ...state,
        updateError: actions.error,
        updating: false,
      };

    case resultsCategoryPolicyActions.DELETE_RESULT_CATEGORY_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
      };
    case resultsCategoryPolicyActions.DELETE_RESULT_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };
    case resultsCategoryPolicyActions.DELETE_RESULT_CATEGORY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case resultsCategoryPolicyActions.DELETE_DEGREE_CLASS_POLICY_REQUEST:
      return {
        ...state,
        deleteError: {},
        deleting: true,
      };
    case resultsCategoryPolicyActions.DELETE_DEGREE_CLASS_POLICY_SUCCESS:
      return {
        ...state,
        deleteResponse: actions.data,
        deleting: false,
      };
    case resultsCategoryPolicyActions.DELETE_DEGREE_CLASS_POLICY_ERROR:
      return {
        ...state,
        deleteError: actions.error,
        deleting: false,
      };

    case resultsCategoryPolicyActions.SET_RESULT_CATEGORY_POLICY_TO_EDIT:
      return {
        ...state,
        policyToEdit: actions.policyToEdit,
      };

    case resultsCategoryPolicyActions.SET_SELECTED_STUDY_LEVEL:
      return {
        ...state,
        selectedLevel: actions.level,
      };

    default:
      return state;
  }
};

export default resultsCategoryPolicy;
