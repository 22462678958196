const apps = {
  dashboard: {
    app_code: 'DASHBOARD',
    title: 'MY APPLICATIONS',
    shortName: 'MY APPLICATIONS',
    desc: 'Manage Your Apps.',
    icon: 'icon-drawer',
    actions: ['View all your Role apps'],
  },
  institutionSetUp: {
    app_code: 'INST_MGT',
    title: 'Institution Setup',
    shortName: 'Institution Setup',
    desc: 'Create and Manage System-wide Metadata and Key Settings.',
    icon: 'icon-drawer',
    actions: [
      'Define Institution Structure',
      'Manage Metadata',
      'Manage Departments, Faculties/Schools, Colleges',
    ],
  },
  userMgt: {
    app_code: 'USER_MGT',
    title: "Group Admins' Access",
    shortName: 'User Group Access',
    desc: "Manage User Groups and their Admins' Acess.",
    icon: 'icon-drawer',
  },
  programmeMgt: {
    app_code: 'PROG_MGT',
    title: 'Programmes & Curriculum',
    shortName: 'Programmes',
    desc: 'Manage Programmes and their Curricula.',
    icon: 'icon-drawer',
  },
  feesMgt: {
    app_code: 'FEES_MGT',
    title: 'Fees Management',
    shortName: 'Fees Mgt',
    desc: 'Create and Manage Fees Items and their amounts and Fees Waivers.',
    icon: 'icon-drawer',
  },
  policyMgt: {
    app_code: 'POLICY_MGT',
    title: 'Institution Policies',
    shortName: 'Institution Policies',
    desc: 'Manage Institution Policies.',
    icon: 'icon-drawer',
  },
  eventMgt: {
    app_code: 'EVT_MGT',
    title: 'Events Management',
    shortName: 'Events Scheduling',
    desc: 'Manage Key Events, Academic Calendar, Teaching and Examination Timetables.',
    icon: 'icon-drawer',
  },
  admissionMgt: {
    app_code: 'ADM_MGT',
    title: 'Admissions Management',
    shortName: 'Admissions Mgt',
    desc: 'Open running schemes, Manage Applicants and View Reports.',
    icon: 'icon-drawer',
  },
  studentRecordsMgt: {
    app_code: 'STD_MGT',
    title: "Students' Records Management",
    shortName: 'Student Records',
    desc: "Manage Students' Records and Bio data.",
    icon: 'icon-drawer',
  },
  photoAndBioMgt: {
    app_code: 'PB_MGT',
    title: 'Photo & Biometrics Capture',
    shortName: 'Photo & Biometrics',
    desc: 'Upload Students photos and Biometric Data.',
    icon: 'icon-drawer',
  },
  enrollmentMgt: {
    title: 'Enrollment & Registration',
    shortName: 'Enrollment & Registration',
    app_code: 'ERM_MGT',
    desc: 'Manage Enrollments and Registration Records.',
    icon: 'icon-user-follow',
  },
  staffAndStudentMgt: {
    title: 'Staff and Student Identification',
    shortName: 'Staff and Student ID',
    icon: 'icon-user-female',
    app_code: 'IDT_MGT',
    desc: 'Manage Staff and Student Identification.',
  },
  paymentAndTransactionMgt: {
    title: "Students' Billing & Payments Tracker",
    shortName: "Students' Payments",
    icon: 'icon-briefcase',
    app_code: 'STM_MGT',
    desc: "Manage Students' Invoices and Payments.",
  },
  universalPaymentMgt: {
    title: 'Universal Payments',
    shortName: 'Universal Payments',
    icon: 'icon-wallet',
    app_code: 'TP_MGT',
    desc: 'Manage Universal Receivables and Payments.',
  },
  courseAssignmentMgt: {
    title: 'Course Assignment & TIMETABLE',
    shortName: 'Course Assignment',
    icon: 'icon-graduation',
    app_code: 'CA_MGT',
    desc: 'Assign Courses/Modules to Lecturers/Tutors.',
  },
  businessIntelligentMgt: {
    title: 'Business Intelligence',
    shortName: 'Business Intelligence',
    icon: 'icon-umbrella',
    app_code: 'BI_MGT',
    desc: 'View Business Intelligence Reports.',
  },
  resultMgt: {
    title: 'Results Management',
    shortName: 'Results',
    icon: 'icon-paper-clip',
    app_code: 'RM_MGT',
    desc: 'Manage Results and View Reports.',
  },
  deanMgt: {
    title: 'Deans & Students’ Welfare',
    shortName: 'Deans and Students’ Welfare',
    icon: 'icon-paper-clip',
    app_code: 'DEAN_MGT',
    desc: "Manage Students' Welfare.",
  },
  humanResourceMgt: {
    title: 'Human Resource Management',
    shortName: 'Human Resource',
    icon: 'icon-paper-clip',
    app_code: 'HR_MGT',
    desc: 'Manage Human Resource.',
  },
  academicDocumentMgt: {
    title: 'Academic Documents Production',
    shortName: 'Academic Documents',
    icon: 'icon-paper-clip',
    app_code: 'ACADEMICS_MGT',
    desc: 'Produce Academic Documents.',
  },
  estatesMgt: {
    title: 'Estates Management',
    shortName: 'Estates Mgt',
    icon: 'icon-paper-clip',
    app_code: 'ESTATES_MGT',
    desc: 'Manage Estates.',
  },
  eVotingMgt: {
    title: 'E-Voting',
    shortName: 'E-Voting',
    icon: 'icon-paper-clip',
    app_code: 'E_VOTING_MGT',
    desc: 'Manage e-Voting.',
  },
  medicalServicesMgt: {
    title: 'Medical Services',
    shortName: 'Medical Services',
    icon: 'icon-paper-clip',
    app_code: 'MEDICAL_MGT',
    desc: 'Manage Medical Services.',
  },
  outreachMgt: {
    title: 'Outreach Management',
    shortName: 'Outreach Mgt',
    icon: 'icon-paper-clip',
    app_code: 'OUTREACH_MGT',
    desc: 'Manage OutReach.',
  },
  pointOfServiceMgt: {
    title: 'Point Of Service',
    shortName: 'Point Of Service',
    icon: 'icon-paper-clip',
    app_code: 'POS_MGT',
    desc: 'Manage Point Of Service.',
  },
  studentClearanceMgt: {
    title: "Student's Clearance Tracker",
    shortName: "Student's Clearance",
    icon: 'icon-paper-clip',
    app_code: 'SCM_MGT',
    desc: "Manage Student's Clearances.",
  },
  userProfileMgt: {
    title: 'User Profile',
    shortName: 'My Profile',
    icon: 'icon-paper-clip',
    app_code: 'MY_PROFILE',
    desc: 'MANAGE Your Profile.',
  },
  studentSupportMgt: {
    title: 'User Support',
    shortName: 'User Support',
    icon: 'icon-paper-clip',
    app_code: 'STD_QRY',
    desc: 'Manage Staff and Student Quick Support Queries.',
  },
  NTCMgt: {
    title: 'NTC Results Management',
    shortName: 'NTC Management',
    icon: 'icon-ntcresults',
    app_code: 'NTC_MGT',
    desc: "Manage NTC Students' Results.",
  },
  lecturerMgt: {
    title: 'Results Upload & Submission',
    shortName: 'Results Upload & Submission',
    icon: 'icon-lecturer',
    app_code: 'LECTURER_MGT',
    desc: "Upload and Process Students' Results and View Timetables",
  },
  emisIntegrationMgt: {
    title: 'EMIS INTERFACE',
    shortName: 'EMIS MGT',
    icon: 'icon-integration',
    app_code: 'EMIS_MGT',
    desc: 'Manage EMIS Integration and Settings.',
  },
  pujabMgt: {
    title: 'PUJAB Admissions Management',
    shortName: 'PUJAB',
    icon: 'icon-pujab',
    app_code: 'PUJAB_MGT',
    desc: 'Manage Pujab Admissions.',
  },
  itsDataMgt: {
    title: 'ITS Data',
    shortName: 'ITS DATA',
    icon: 'icon-itsData',
    app_code: 'ITS_DATA',
    desc: 'MANAGE ITS DATA',
  },
  auditMgt: {
    title: 'AUDIT TRAILS',
    shortName: 'AUDIT TRAILS',
    icon: 'icon-userLogs',
    app_code: 'LOGS_MGT',
    desc: 'View User Audit Trails',
  },
};

export default apps;
