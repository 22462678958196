const graduationListActions = {
  GET_FACULTY_DEPARTMENTS_REQUEST: 'GET_FACULTY_DEPARTMENTS_REQUEST',
  GET_FACULTY_DEPARTMENTS_SUCCESS: 'GET_FACULTY_DEPARTMENTS_SUCCESS',
  GET_FACULTY_DEPARTMENTS_ERROR: 'GET_FACULTY_DEPARTMENTS_ERROR',

  GET_DEPARTMENT_PROGRAMMES_REQUEST: 'GET_DEPARTMENT_PROGRAMMES_REQUEST',
  GET_DEPARTMENT_PROGRAMMES_SUCCESS: 'GET_DEPARTMENT_PROGRAMMES_SUCCESS',
  GET_DEPARTMENT_PROGRAMMES_ERROR: 'GET_DEPARTMENT_PROGRAMMES_ERROR',

  GET_ACADEMIC_UNIT_PROGRAMMES_REQUEST: 'GET_ACADEMIC_UNIT_PROGRAMMES_REQUEST',
  GET_ACADEMIC_UNIT_PROGRAMMES_SUCCESS: 'GET_ACADEMIC_UNIT_PROGRAMMES_SUCCESS',
  GET_ACADEMIC_UNIT_PROGRAMMES_ERROR: 'GET_ACADEMIC_UNIT_PROGRAMMES_ERROR',

  GET_PROVISIONAL_LIST_REQUEST: 'GET_PROVISIONAL_LIST_REQUEST',
  GET_PROVISIONAL_LIST_SUCCESS: 'GET_PROVISIONAL_LIST_SUCCESS',
  GET_PROVISIONAL_LIST_ERROR: 'GET_PROVISIONAL_LIST_ERROR',

  GET_FINALISTS_LIST_REQUEST: 'GET_FINALISTS_LIST_REQUEST',
  GET_FINALISTS_LIST_SUCCESS: 'GET_FINALISTS_LIST_SUCCESS',
  GET_FINALISTS_LIST_ERROR: 'GET_FINALISTS_LIST_ERROR',

  GET_GRADUATION_LIST_REQUEST: 'GET_GRADUATION_LIST_REQUEST',
  GET_GRADUATION_LIST_SUCCESS: 'GET_GRADUATION_LIST_SUCCESS',
  GET_GRADUATION_LIST_ERROR: 'GET_GRADUATION_LIST_ERROR',

  DOWNLOAD_FINAL_GRAD_LIST_REQUEST: 'DOWNLOAD_FINAL_GRAD_LIST_REQUEST',
  DOWNLOAD_FINAL_GRAD_LIST_SUCCESS: 'DOWNLOAD_FINAL_GRAD_LIST_SUCCESS',
  DOWNLOAD_FINAL_GRAD_LIST_ERROR: 'DOWNLOAD_FINAL_GRAD_LIST_ERROR',

  GENERATE_PROVISIONAL_LIST_REQUEST: 'GENERATE_PROVISIONAL_LIST_REQUEST',
  GENERATE_PROVISIONAL_LIST_SUCCESS: 'GENERATE_PROVISIONAL_LIST_SUCCESS',
  GENERATE_PROVISIONAL_LIST_ERROR: 'GENERATE_PROVISIONAL_LIST_ERROR',

  UPDATE_STUDENT_GRADUATION_YEAR_REQUEST:
    'UPDATE_STUDENT_GRADUATION_YEAR_REQUEST',
  UPDATE_STUDENT_GRADUATION_YEAR_SUCCESS:
    'UPDATE_STUDENT_GRADUATION_YEAR_SUCCESS',
  UPDATE_STUDENT_GRADUATION_YEAR_ERROR: 'UPDATE_STUDENT_GRADUATION_YEAR_ERROR',

  ADD_TO_PROVISIONAL_LIST_REQUEST: 'ADD_TO_PROVISIONAL_LIST_REQUEST',
  ADD_TO_PROVISIONAL_LIST_SUCCESS: 'ADD_TO_PROVISIONAL_LIST_SUCCESS',
  ADD_TO_PROVISIONAL_LIST_ERROR: 'ADD_TO_PROVISIONAL_LIST_ERROR',

  REMOVE_FROM_PROVISIONAL_LIST_REQUEST: 'REMOVE_FROM_PROVISIONAL_LIST_REQUEST',
  REMOVE_FROM_PROVISIONAL_LIST_SUCCESS: 'REMOVE_FROM_PROVISIONAL_LIST_SUCCESS',
  REMOVE_FROM_PROVISIONAL_LIST_ERROR: 'REMOVE_FROM_PROVISIONAL_LIST_ERROR',

  REMOVE_FROM_FINAL_LIST_REQUEST: 'REMOVE_FROM_FINAL_LIST_REQUEST',
  REMOVE_FROM_FINAL_LIST_SUCCESS: 'REMOVE_FROM_FINAL_LIST_SUCCESS',
  REMOVE_FROM_FINAL_LIST_ERROR: 'REMOVE_FROM_FINAL_LIST_ERROR',

  DOWNLOAD_DRAFT_RESULTS_REQUEST: 'DOWNLOAD_DRAFT_RESULTS_REQUEST',
  DOWNLOAD_DRAFT_RESULTS_SUCCESS: 'DOWNLOAD_DRAFT_RESULTS_SUCCESS',
  DOWNLOAD_DRAFT_RESULTS_ERROR: 'DOWNLOAD_DRAFT_RESULTS_ERROR',

  SEARCH_FINALIST_REQUEST: 'SEARCH_FINALIST_REQUEST',
  SEARCH_FINALIST_SUCCESS: 'SEARCH_FINALIST_SUCCESS',
  SEARCH_FINALIST_ERROR: 'SEARCH_FINALIST_ERROR',

  ADD_TO_GRAD_LIST_REQUEST: 'ADD_TO_GRAD_LIST_REQUEST',
  ADD_TO_GRAD_LIST_SUCCESS: 'ADD_TO_GRAD_LIST_SUCCESS',
  ADD_TO_GRAD_LIST_ERROR: 'ADD_TO_GRAD_LIST_ERROR',

  ADD_CATEGORY_TO_PROVISIONAL_LIST_REQUEST:
    'ADD_CATEGORY_TO_PROVISIONAL_LIST_REQUEST',
  ADD_CATEGORY_TO_PROVISIONAL_LIST_SUCCESS:
    'ADD_CATEGORY_TO_PROVISIONAL_LIST_SUCCESS',
  ADD_CATEGORY_TO_PROVISIONAL_ERROR: 'ADD_CATEGORY_TO_PROVISIONAL_ERROR',

  GRADUATE_STUDENTS_REQUEST: 'GRADUATE_STUDENTS_REQUEST',
  GRADUATE_STUDENTS_SUCCESS: 'GRADUATE_STUDENTS_SUCCESS',
  GRADUATE_STUDENTS_ERROR: 'GRADUATE_STUDENTS_ERROR',

  BILL_GRAD_LIST_STUDENTS_REQUEST: 'BILL_GRAD_LIST_STUDENTS_REQUEST',
  BILL_GRAD_LIST_STUDENTS_SUCCESS: 'BILL_GRAD_LIST_STUDENTS_SUCCESS',
  BILL_GRAD_LIST_STUDENTS_ERROR: 'BILL_GRAD_LIST_STUDENTS_ERROR',

  SET_FACULTY_CONTEXT: 'SET_FACULTY_CONTEXT',
  SET_SELECTED_GRADUATION_PROGRAMME: 'SET_SELECTED_GRADUATION_PROGRAMME',
  SET_SELECTED_DEPARTMENT: 'SET_SELECTED_DEPARTMENT',
  SET_SELECTED_NODE: 'SET_SELECTED_NODE',
  SET_RESULTS_CONTEXT: 'SET_RESULTS_CONTEXT',
  SET_SELECTED_ACADEMIC_YEAR: 'SET_SELECTED_ACADEMIC_YEAR',
  SHOW_PRINT_MODAL: 'SHOW_PRINT_MODAL',
  SHOW_PREVIEW_MODAL: 'SHOW_PREVIEW_MODAL',

  GET_GRADUATED_STUDENTS_REQUEST: 'GET_GRADUATED_STUDENTS_REQUEST',
  GET_GRADUATED_STUDENTS_SUCCESS: 'GET_GRADUATED_STUDENTS_SUCCESS',

  GET_GRADUATED_STUDENTS_ERROR: 'GET_GRADUATED_STUDENTS_ERROR',

  SET_PROVISIONAL_LIST_CONTEXT: 'SET_PROVISIONAL_LIST_CONTEXT',
  SET_FINAL_LIST_CONTEXT: 'SET_FINAL_LIST_CONTEXT',
  SET_SHOW_CONFIRM_MODAL: 'SET_SHOW_CONFIRM_MODAL',
  SET_SELECTED_STUDENT_LIST: 'SET_SELECTED_STUDENT_LIST',

  getFacultyDepartments: (params) => ({
    type: graduationListActions.GET_FACULTY_DEPARTMENTS_REQUEST,
    params,
  }),

  getDepartmentProgrammes: (params) => ({
    type: graduationListActions.GET_DEPARTMENT_PROGRAMMES_REQUEST,
    params,
  }),

  getAcademicUnitProgrammes: (data) => ({
    type: graduationListActions.GET_ACADEMIC_UNIT_PROGRAMMES_REQUEST,
    data,
  }),

  getProvisionalList: (params) => ({
    type: graduationListActions.GET_PROVISIONAL_LIST_REQUEST,
    params,
  }),

  getFinalistsList: (params) => ({
    type: graduationListActions.GET_FINALISTS_LIST_REQUEST,
    params,
  }),

  getGraduationList: (params) => ({
    type: graduationListActions.GET_GRADUATION_LIST_REQUEST,
    params,
  }),

  downloadGraduationList: (params) => ({
    type: graduationListActions.DOWNLOAD_FINAL_GRAD_LIST_REQUEST,
    params,
  }),

  getGraduatedStudents: (params) => ({
    type: graduationListActions.GET_GRADUATED_STUDENTS_REQUEST,
    params,
  }),

  generateProvisionalList: (params, data) => ({
    type: graduationListActions.GENERATE_PROVISIONAL_LIST_REQUEST,
    params,
    data,
  }),

  updateGraduationYear: (data, contextData) => ({
    type: graduationListActions.UPDATE_STUDENT_GRADUATION_YEAR_REQUEST,
    contextData,
    data,
  }),

  addToProvisionalList: (data) => ({
    type: graduationListActions.ADD_TO_PROVISIONAL_LIST_REQUEST,
    data,
  }),

  removeFromProvisionalList: (data, params) => ({
    type: graduationListActions.REMOVE_FROM_PROVISIONAL_LIST_REQUEST,
    data,
    params,
  }),

  removeFromFinalList: (data, params) => ({
    type: graduationListActions.REMOVE_FROM_FINAL_LIST_REQUEST,
    data,
    params,
  }),

  downloadDraftResults: (params) => ({
    type: graduationListActions.DOWNLOAD_DRAFT_RESULTS_REQUEST,
    params,
  }),

  searchFinalist: (params) => ({
    type: graduationListActions.SEARCH_FINALIST_REQUEST,
    params,
  }),

  addToGradList: (data, params) => ({
    type: graduationListActions.ADD_TO_GRAD_LIST_REQUEST,
    params,
    data,
  }),

  addCategoryToProvisionalList: (data, params) => ({
    type: graduationListActions.ADD_CATEGORY_TO_PROVISIONAL_LIST_REQUEST,
    params,
    data,
  }),

  graduateStudents: (data, context) => ({
    type: graduationListActions.GRADUATE_STUDENTS_REQUEST,
    context,
    data,
  }),

  billGraduationListStudents: (data, context) => ({
    type: graduationListActions.BILL_GRAD_LIST_STUDENTS_REQUEST,
    data,
    context,
  }),

  setFacultyContext: (context) => ({
    type: graduationListActions.SET_FACULTY_CONTEXT,
    context,
  }),

  setSelectedProgramme: (data) => ({
    type: graduationListActions.SET_SELECTED_GRADUATION_PROGRAMME,
    data,
  }),

  setSelectedDepartment: (context) => ({
    type: graduationListActions.SET_SELECTED_DEPARTMENT,
    context,
  }),

  setSelectedAcadYear: (year) => ({
    type: graduationListActions.SET_SELECTED_ACADEMIC_YEAR,
    year,
  }),

  showPrintModal: (e = true) => ({
    type: graduationListActions.SHOW_PRINT_MODAL,
    e,
  }),

  showPreviewModal: (e = true) => ({
    type: graduationListActions.SHOW_PREVIEW_MODAL,
    e,
  }),

  setProvisionalListContext: (context = {}) => ({
    type: graduationListActions.SET_PROVISIONAL_LIST_CONTEXT,
    context,
  }),

  setFinalListContext: (context = {}) => ({
    type: graduationListActions.SET_FINAL_LIST_CONTEXT,
    context,
  }),

  setShowConfirmModal: (payload = false) => ({
    type: graduationListActions.SET_SHOW_CONFIRM_MODAL,
    payload,
  }),

  setSelectedStudentList: (payload = []) => ({
    type: graduationListActions.SET_SELECTED_STUDENT_LIST,
    payload,
  }),
};

export default graduationListActions;
