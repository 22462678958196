const runningAdmission = {
  GET_RUNNING_ADMISSION_REQUEST: 'GET_RUNNING_ADMISSION_REQUEST',
  GET_RUNNING_ADMISSION_SUCCESS: 'GET_RUNNING_ADMISSION_SUCCESS',
  GET_RUNNING_ADMISSION_ERROR: 'GET_RUNNING_ADMISSION_ERROR',

  GET_SINGLE_RUNNING_ADMISSION_REQUEST: 'GET_SINGLE_RUNNING_ADMISSION_REQUEST',
  GET_SINGLE_RUNNING_ADMISSION_SUCCESS: 'GET_SINGLE_RUNNING_ADMISSION_SUCCESS',
  GET_SINGLE_RUNNING_ADMISSION_ERROR: 'GET_SINGLE_RUNNING_ADMISSION_ERROR',

  CREATE_RUNNING_ADMISSION_SUCCESS: 'CREATE_RUNNING_ADMISSION_SUCCESS',
  CREATE_RUNNING_ADMISSION_REQUEST: 'CREATE_RUNNING_ADMISSION_REQUEST',
  CREATE_RUNNING_ADMISSION_ERROR: 'CREATE_RUNNING_ADMISSION_ERROR',

  START_RUNNING_ADMISSION_SUCCESS: 'START_RUNNING_ADMISSION_SUCCESS',
  START_RUNNING_ADMISSION_REQUEST: 'START_RUNNING_ADMISSION_REQUEST',
  START_RUNNING_ADMISSION_ERROR: 'START_RUNNING_ADMISSION_ERROR',

  STOP_RUNNING_ADMISSION_SUCCESS: 'STOP_RUNNING_ADMISSION_SUCCESS',
  STOP_RUNNING_ADMISSION_REQUEST: 'STOP_RUNNING_ADMISSION_REQUEST',
  STOP_RUNNING_ADMISSION_ERROR: 'STOP_RUNNING_ADMISSION_ERROR',

  UPDATE_RUNNING_ADMISSION_SUCCESS: 'UPDATE_RUNNING_ADMISSION_SUCCESS',
  UPDATE_RUNNING_ADMISSION_REQUEST: 'UPDATE_RUNNING_ADMISSION_REQUEST',
  UPDATE_RUNNING_ADMISSION_ERROR: 'UPDATE_RUNNING_ADMISSION_ERROR',

  DELETE_RUNNING_ADMISSION_SUCCESS: 'DELETE_RUNNING_ADMISSION_SUCCESS',
  DELETE_RUNNING_ADMISSION_REQUEST: 'DELETE_RUNNING_ADMISSION_REQUEST',
  DELETE_RUNNING_ADMISSION_ERROR: 'DELETE_RUNNING_ADMISSION_ERROR',

  GET_MANAGED_PROG_DETAILS_REQUEST: 'GET_MANAGED_PROG_DETAILS_REQUEST',
  GET_MANAGED_PROG_DETAILS_SUCCESS: 'GET_MANAGED_PROG_DETAILS_SUCCESS',
  GET_MANAGED_PROG_DETAILS_ERROR: 'GET_MANAGED_PROG_DETAILS_ERROR',

  DOWNLOAD_ADMISSION_SELECTED_STUDENTS_REQUEST:
    'DOWNLOAD_ADMISSION_SELECTED_STUDENTS_REQUEST',
  DOWNLOAD_ADMISSION_SELECTED_STUDENTS_SUCCESS:
    'DOWNLOAD_ADMISSION_SELECTED_STUDENTS_SUCCESS',
  DOWNLOAD_ADMISSION_SELECTED_STUDENTS_ERROR:
    'DOWNLOAD_ADMISSION_SELECTED_STUDENTS_ERROR',

  setSelectedRunningAdmission: (e) => ({
    type: 'SET_SELECTED_RUNNING_ADMISSION',
    payload: e,
  }),

  getRunningAdmissions: (data) => ({
    type: runningAdmission.GET_RUNNING_ADMISSION_REQUEST,
    data,
  }),

  createRunningAdmission: (data) => ({
    type: runningAdmission.CREATE_RUNNING_ADMISSION_REQUEST,
    data,
  }),

  getSingleRunningAdmission: (runningAdmissionId, data) => ({
    type: runningAdmission.GET_SINGLE_RUNNING_ADMISSION_REQUEST,
    runningAdmissionId,
    data,
  }),

  startRunningAdmission: (runningAdmissionId) => ({
    type: runningAdmission.START_RUNNING_ADMISSION_REQUEST,
    runningAdmissionId,
  }),

  stopRunningAdmission: (runningAdmissionId) => ({
    type: runningAdmission.STOP_RUNNING_ADMISSION_REQUEST,
    runningAdmissionId,
  }),

  updateRunningAdmission: (runningAdmissionId, data, admissionEditAction) => ({
    type: runningAdmission.UPDATE_RUNNING_ADMISSION_REQUEST,
    runningAdmissionId,
    data,
    admissionEditAction,
  }),

  deleteRunningAdmission: (runningAdmissionId) => ({
    type: runningAdmission.DELETE_RUNNING_ADMISSION_REQUEST,
    runningAdmissionId,
  }),

  fetchManagedDetails: (contextId) => ({
    type: runningAdmission.GET_MANAGED_PROG_DETAILS_REQUEST,
    contextId,
  }),

  downloadRunningAdmissionSelectedStudents: (runningAdmissionId) => ({
    type: runningAdmission.DOWNLOAD_ADMISSION_SELECTED_STUDENTS_REQUEST,
    runningAdmissionId,
  }),
};

export default runningAdmission;
