import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { previousRegistrationActions } from '../../actions';

function* downloadPreviousRegistration(actions) {
  try {
    yield axios({
      url: `/students-mgt/students-records/previous-enrollment-records-template`,
      method: 'POST',
      data: actions.data,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-PREVIOUS-REGISTRATION-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadPreviousRegistration(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students-records/upload-previous-enrollment-records-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadPreviousRegistration() {
  yield takeLatest(
    previousRegistrationActions.DOWNLOAD_REGISTRATION_TEMPLATE_REQUEST,
    downloadPreviousRegistration
  );
}

function* watchUploadPreviousRegistration() {
  yield takeLatest(
    previousRegistrationActions.UPLOAD_REGISTRATION_TEMPLATE_REQUEST,
    uploadPreviousRegistration
  );
}

export default [
  fork(watchDownloadPreviousRegistration),
  fork(watchUploadPreviousRegistration),
];
