import { creditNoteActions } from '../../actions';
import initialState from '../../initialState';

const creditNote = (state = initialState.creditNote, actions) => {
  switch (actions.type) {
    case creditNoteActions.GET_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        loadError: {},
        loading: true,
      };

    case creditNoteActions.GET_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        allCreditNotes: actions.data,
        loading: false,
      };

    case creditNoteActions.GET_CREDIT_NOTE_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case creditNoteActions.GET_CREDIT_NOTE_REPORTS_REQUEST:
      return {
        ...state,
        loadNotesReportsError: {},
        loadingNotesReports: true,
      };

    case creditNoteActions.GET_CREDIT_NOTE_REPORTS_SUCCESS:
      return {
        ...state,
        creditNotesReports: actions.data,
        notesReportDate: actions.context,
        loadingNotesReports: false,
      };

    case creditNoteActions.GET_CREDIT_NOTE_REPORTS_ERROR:
      return {
        ...state,
        loadNotesReportsError: actions.error,
        loadingNotesReports: false,
      };

    case creditNoteActions.GET_PENDING_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        getError: {},
        gettingPending: true,
      };

    case creditNoteActions.GET_PENDING_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        pendingCreditNotes: actions.data,
        gettingPending: false,
      };

    case creditNoteActions.GET_PENDING_CREDIT_NOTE_ERROR:
      return {
        ...state,
        getError: actions.error,
        gettingPending: false,
      };

    case creditNoteActions.GET_STUDENT_PENDING_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        fetchPendingCreditError: {},
        fetchingPendingCredit: true,
      };

    case creditNoteActions.GET_STUDENT_PENDING_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        pendingStudentCreditNotes: actions.data,
        fetchingPendingCredit: false,
      };

    case creditNoteActions.GET_STUDENT_PENDING_CREDIT_NOTE_ERROR:
      return {
        ...state,
        fetchPendingCreditError: actions.error,
        fetchingPendingCredit: false,
      };

    case creditNoteActions.CREATE_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        createCreditNoteError: {},
        createCreditNoteSuccess: {},
        creating: true,
      };

    case creditNoteActions.CREATE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        createCreditNoteSuccess: actions.data,
        creating: false,
      };

    case creditNoteActions.CREATE_CREDIT_NOTE_ERROR:
      return {
        ...state,
        createCreditNoteError: actions.error,
        creating: false,
      };

    case creditNoteActions.APPROVE_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        approveError: {},
        approving: true,
      };

    case creditNoteActions.APPROVE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        approveSuccess: actions.data,
        approving: false,
      };

    case creditNoteActions.APPROVE_CREDIT_NOTE_ERROR:
      return {
        ...state,
        approveError: actions.error,
        approving: false,
      };

    case creditNoteActions.DECLINE_CREDIT_NOTE_REQUEST:
      return {
        ...state,
        declineError: {},
        declining: true,
      };

    case creditNoteActions.DECLINE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        declineSuccess: actions.data,
        declining: false,
      };

    case creditNoteActions.DECLINE_CREDIT_NOTE_ERROR:
      return {
        ...state,
        declineError: actions.error,
        declining: false,
      };

    default:
      return state;
  }
};
export default creditNote;
