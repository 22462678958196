const courseAssignment = {
  GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST:
    'GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST',
  GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_SUCCESS:
    'GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_SUCCESS',
  GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_ERROR:
    'GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_ERROR',

  GET_COURSE_ASSIGNMENT_COURSES_REQUEST:
    'GET_COURSE_ASSIGNMENT_COURSES_REQUEST',
  GET_COURSE_ASSIGNMENT_COURSES_SUCCESS:
    'GET_COURSE_ASSIGNMENT_COURSES_SUCCESS',
  GET_COURSE_ASSIGNMENT_COURSES_ERROR: 'GET_COURSE_ASSIGNMENT_COURSES_ERROR',

  GET_CONTEXT_ASSIGNMENTS_REQUEST: 'GET_CONTEXT_ASSIGNMENTS_REQUEST',
  GET_CONTEXT_ASSIGNMENTS_SUCCESS: 'GET_CONTEXT_ASSIGNMENTS_SUCCESS',
  GET_CONTEXT_ASSIGNMENTS_ERROR: 'GET_CONTEXT_ASSIGNMENTS_ERROR',

  ASSIGN_LECTURERS_TO_COURSE_REQUEST: 'ASSIGN_LECTURERS_TO_COURSE_REQUEST',
  ASSIGN_LECTURERS_TO_COURSE_SUCCESS: 'ASSIGN_LECTURERS_TO_COURSE_SUCCESS',
  ASSIGN_LECTURERS_TO_COURSE_ERROR: 'ASSIGN_LECTURERS_TO_COURSE_ERROR',

  ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST:
    'ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST',
  ADD_LECTURER_TO_COURSE_ASSIGNMENT_SUCCESS:
    'ADD_LECTURER_TO_COURSE_ASSIGNMENT_SUCCESS',
  ADD_LECTURER_TO_COURSE_ASSIGNMENT_ERROR:
    'ADD_LECTURER_TO_COURSE_ASSIGNMENT_ERROR',

  EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST:
    'EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST',
  EDIT_COURSE_ASSIGNMENT_LECTURERS_SUCCESS:
    'EDIT_COURSE_ASSIGNMENT_LECTURERS_SUCCESS',
  EDIT_COURSE_ASSIGNMENT_LECTURERS_ERROR:
    'EDIT_COURSE_ASSIGNMENT_LECTURERS_ERROR',

  REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST:
    'REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST',
  REMOVE_LECTURERS_FROM_ASSIGNMENT_SUCCESS:
    'REMOVE_LECTURERS_FROM_ASSIGNMENT_SUCCESS',
  REMOVE_LECTURERS_FROM_ASSIGNMENT_ERROR:
    'REMOVE_LECTURERS_FROM_ASSIGNMENT_ERROR',

  SET_SELECTED_ASSIGNMENT_COURSE: 'SET_SELECTED_ASSIGNMENT_COURSE',
  SET_SELECTED_ASSIGNMENT_LECTURERS: 'SET_SELECTED_ASSIGNMENT_LECTURERS',
  SET_SHOW_DELETE_MODAL: 'SET_SHOW_DELETE_MODAL',

  EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST:
    'EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST',
  EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_SUCCESS:
    'EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_SUCCESS',
  EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_ERROR:
    'EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_ERROR',

  SET_PROGRAMME_CONTEXT_ID: 'SET_PROGRAMME_CONTEXT_ID',
  SET_SHOW_ADD_LECTURER_MODAL: 'SET_SHOW_ADD_LECTURER_MODAL',
  SET_EDIT_ASSIGNMENT_LECTURER: 'SET_EDIT_ASSIGNMENT_LECTURER',
  SET_EDIT_RESULT_CATEGORY: 'SET_EDIT_RESULT_CATEGORY',
  SHOW_EDIT_RESULT_CATEGORIES_MODAL: 'SHOW_EDIT_RESULT_CATEGORIES_MODAL',

  getDepartmentProgrammes: (context) => ({
    type: courseAssignment.GET_COURSE_ASSIGNMENT_DEPARTMENT_PROGRAMMES_REQUEST,
    context,
  }),

  editCourseAssignmentResultCategory: (data, context) => ({
    type: courseAssignment.EDTING_COURSE_ASSIGNMENT_RESULT_CATEGORIES_REQUEST,
    data,
    context,
  }),

  setShowEditResultCategoriesModal: (e) => ({
    type: courseAssignment.SHOW_EDIT_RESULT_CATEGORIES_MODAL,
    payload: e,
  }),

  setEditResultCategory: (e) => ({
    type: courseAssignment.SET_EDIT_RESULT_CATEGORY,
    payload: e,
  }),

  getAssignmentCourses: (context) => ({
    type: courseAssignment.GET_COURSE_ASSIGNMENT_COURSES_REQUEST,
    context,
  }),

  getContextAssignments: (context) => ({
    type: courseAssignment.GET_CONTEXT_ASSIGNMENTS_REQUEST,
    context,
  }),

  assignLecturersToCourse: (data, context) => ({
    type: courseAssignment.ASSIGN_LECTURERS_TO_COURSE_REQUEST,
    data,
    context,
  }),

  removeLecturersFromAssignment: (data, context) => ({
    type: courseAssignment.REMOVE_LECTURERS_FROM_ASSIGNMENT_REQUEST,
    data,
    context,
  }),

  addLecturersToAssignment: (assignmentId, data, context) => ({
    type: courseAssignment.ADD_LECTURER_TO_COURSE_ASSIGNMENT_REQUEST,
    data,
    assignmentId,
    context,
  }),

  editAssignmentLecturer: (assignmentLectureId, data, context) => ({
    type: courseAssignment.EDIT_COURSE_ASSIGNMENT_LECTURERS_REQUEST,
    data,
    assignmentLectureId,
    context,
  }),

  setSelectedCourse: (course) => ({
    type: courseAssignment.SET_SELECTED_ASSIGNMENT_COURSE,
    course,
  }),

  setSelectedLecturers: (data) => ({
    type: courseAssignment.SET_SELECTED_ASSIGNMENT_LECTURERS,
    data,
  }),

  setShowDeleteModal: (data) => ({
    type: courseAssignment.SET_SHOW_DELETE_MODAL,
    data,
  }),

  setProgrammeContextId: (id) => ({
    type: courseAssignment.SET_PROGRAMME_CONTEXT_ID,
    id,
  }),

  setAddLecturerModal: (data) => ({
    type: courseAssignment.SET_SHOW_ADD_LECTURER_MODAL,
    data,
  }),

  setEditAssignmentLecturer: (editing, dataToEdit) => ({
    type: courseAssignment.SET_EDIT_ASSIGNMENT_LECTURER,
    editing,
    dataToEdit,
  }),
};
export default courseAssignment;
