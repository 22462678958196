const academicRegistrarActions = {
  GET_GRADUATION_STATS_REPORT_REQUEST: 'GET_GRADUATION_STATS_REPORT_REQUEST',
  GET_GRADUATION_STATS_REPORT_SUCCESS: 'GET_GRADUATION_STATS_REPORT_SUCCESS',
  GET_GRADUATION_STATS_REPORT_ERROR: 'GET_GRADUATION_STATS_REPORT_ERROR',

  GET_GRADUATION_DETAILED_REPORT_REQUEST:
    'GET_GRADUATION_DETAILED_REPORT_REQUEST',
  GET_GRADUATION_DETAILED_REPORT_SUCCESS:
    'GET_GRADUATION_DETAILED_REPORT_SUCCESS',
  GET_GRADUATION_DETAILED_REPORT_ERROR: 'GET_GRADUATION_DETAILED_REPORT_ERROR',

  DOWNLOAD_GRADUATION_STATISTICS_REQUEST:
    'DOWNLOAD_GRADUATION_STATISTICS_REQUEST',
  DOWNLOAD_GRADUATION_STATISTICS_SUCCESS:
    'DOWNLOAD_GRADUATION_STATISTICS_SUCCESS',
  DOWNLOAD_GRADUATION_STATISTICS_ERROR: 'DOWNLOAD_GRADUATION_STATISTICS_ERROR',

  GET_ENROLLMENT_REPORT_REQUEST: 'GET_ENROLLMENT_REPORT_REQUEST',
  GET_ENROLLMENT_REPORT_SUCCESS: 'GET_ENROLLMENT_REPORT_SUCCESS',
  GET_ENROLLMENT_REPORT_ERROR: 'GET_ENROLLMENT_REPORT_ERROR',

  GET_BI_DEMOGRAPHIC_REPORT_REQUEST: 'GET_BI_DEMOGRAPHIC_REPORT_REQUEST',
  GET_BI_DEMOGRAPHIC_REPORT_SUCCESS: 'GET_BI_DEMOGRAPHIC_REPORT_SUCCESS',
  GET_BI_DEMOGRAPHIC_REPORT_ERROR: 'GET_BI_DEMOGRAPHIC_REPORT_ERROR',

  DOWNLOAD_BI_DEMOGRAPHIC_REPORT_REQUEST:
    'DOWNLOAD_BI_DEMOGRAPHIC_REPORT_REQUEST',
  DOWNLOAD_BI_DEMOGRAPHIC_REPORT_SUCCESS:
    'DOWNLOAD_BI_DEMOGRAPHIC_REPORT_SUCCESS',
  DOWNLOAD_BI_DEMOGRAPHIC_REPORT_ERROR: 'DOWNLOAD_BI_DEMOGRAPHIC_REPORT_ERROR',

  SET_DEMOGRAPHIC_REPORT_CONTEXT_REQUEST:
    'SET_DEMOGRAPHIC_REPORT_CONTEXT_REQUEST',
  SET_GRADUATION_CONTEXT: 'SET_GRADUATION_CONTEXT',

  setGraduationContext: (context) => ({
    type: academicRegistrarActions.SET_GRADUATION_CONTEXT,
    context,
  }),

  getGraduationStatistics: (data) => ({
    type: academicRegistrarActions.GET_GRADUATION_STATS_REPORT_REQUEST,
    data,
  }),

  getGraduationDetailedReport: (data) => ({
    type: academicRegistrarActions.GET_GRADUATION_DETAILED_REPORT_REQUEST,
    data,
  }),

  downloadGraduationStatistics: (data) => ({
    type: academicRegistrarActions.DOWNLOAD_GRADUATION_STATISTICS_REQUEST,
    data,
  }),

  getEnrollmentReport: (data) => ({
    type: academicRegistrarActions.GET_ENROLLMENT_REPORT_REQUEST,
    data,
  }),

  getDemographicReport: (params) => ({
    type: academicRegistrarActions.GET_BI_DEMOGRAPHIC_REPORT_REQUEST,
    params,
  }),

  setDemographicReportContext: (data) => ({
    type: academicRegistrarActions.SET_DEMOGRAPHIC_REPORT_CONTEXT_REQUEST,
    data,
  }),

  downloadBiDemographicReport: (data) => ({
    type: academicRegistrarActions.DOWNLOAD_BI_DEMOGRAPHIC_REPORT_REQUEST,
    data,
  }),
};

export default academicRegistrarActions;
