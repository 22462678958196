import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { BarLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: var(--bs-primary);
  color: var(--bs-primary) !important;
`;

function PageLoader({ message }) {
  return (
    <div className="vh-100 text-center bg-light d-flex m-0 p-4">
      <div className="align-middle my-auto mx-auto">
        <BarLoader color="#303952" css={override} size={150} />
        <div className="fw-bold text-uppercase text-sm text-primary mt-3">
          {message}
        </div>
      </div>
    </div>
  );
}

PageLoader.defaultProps = {
  message: 'Please wait...',
};

PageLoader.propTypes = {
  message: PropTypes.string,
};

export default PageLoader;
