const studentsActions = {
  GET_STUDENTS_REQUEST: 'GET_STUDENTS_REQUEST',
  GET_STUDENTS_SUCCESS: 'GET_STUDENTS_SUCCESS',
  GET_STUDENTS_ERROR: 'GET_STUDENTS_ERROR',

  GET_CONTEXT_STUDENTS_REQUEST: 'GET_CONTEXT_STUDENTS_REQUEST',
  GET_CONTEXT_STUDENTS_SUCCESS: 'GET_CONTEXT_STUDENTS_SUCCESS',
  GET_CONTEXT_STUDENTS_ERROR: 'GET_CONTEXT_STUDENTS_ERROR',

  GET_SINGLE_STUDENT_REQUEST: 'GET_SINGLE_STUDENT_REQUEST',
  GET_SINGLE_STUDENT_SUCCESS: 'GET_SINGLE_STUDENT_SUCCESS',
  GET_SINGLE_STUDENT_ERROR: 'GET_SINGLE_STUDENT_ERROR',

  GET_ALL_STUDENTS_REQUEST: 'GET_ALL_STUDENTS_REQUEST',
  GET_ALL_STUDENTS_SUCCESS: 'GET_ALL_STUDENTS_SUCCESS',
  GET_ALL_STUDENTS_ERROR: 'GET_ALL_STUDENTS_ERROR',

  CREATE_STUDENTS_REQUEST: 'CREATE_STUDENTS_REQUEST',
  CREATE_STUDENTS_SUCCESS: 'CREATE_STUDENTS_SUCCESS',
  CREATE_STUDENTS_ERROR: 'CREATE_STUDENTS_ERROR',

  GET_PROGRAMME_STUDENTS_REQUEST: 'GET_PROGRAMME_STUDENTS_REQUEST',
  GET_PROGRAMME_STUDENTS_SUCCESS: 'GET_PROGRAMME_STUDENTS_SUCCESS',
  GET_PROGRAMME_STUDENTS_ERROR: 'GET_PROGRAMME_STUDENTS_ERROR',

  BULK_EDIT_PROGRAMME_VERSION_REQUEST: 'BULK_EDIT_PROGRAMME_VERSION_REQUEST',
  BULK_EDIT_PROGRAMME_VERSION_SUCCESS: 'BULK_EDIT_PROGRAMME_VERSION_SUCCESS',
  BULK_EDIT_PROGRAMME_VERSION_ERROR: 'BULK_EDIT_PROGRAMME_VERSION_ERROR',

  UPLOAD_STUDENT_PHOTO_REQUEST: 'UPLOAD_STUDENT_PHOTO_REQUEST',
  UPLOAD_STUDENT_PHOTO_SUCCESS: 'UPLOAD_STUDENT_PHOTO_SUCCESS',
  UPLOAD_STUDENT_PHOTO_ERROR: 'UPLOAD_STUDENT_PHOTO_ERROR',

  UPDATE_STUDENTS_REQUEST: 'UPDATE_STUDENTS_REQUEST',
  UPDATE_STUDENTS_SUCCESS: 'UPDATE_STUDENTS_SUCCESS',
  UPDATE_STUDENTS_ERROR: 'UPDATE_STUDENTS_ERROR',

  DELETE_STUDENTS_REQUEST: 'DELETE_STUDENTS_REQUEST',
  DELETE_STUDENTS_SUCCESS: 'DELETE_STUDENTS_SUCCESS',
  DELETE_STUDENTS_ERROR: 'DELETE_STUDENTS_ERROR',

  SET_STUDENTS_CONTEXT: 'SET_STUDENTS_CONTEXT',
  SET_SELECTED_STUDENT: 'SET_SELECTED_STUDENT',
  SET_UPLOADING_STUDENT: 'SET_UPLOADING_STUDENT',
  SET_IDENTIFYING_STUDENT: 'SET_IDENTIFYING_STUDENT',
  SET_NEW_STUDENTS_CONTEXT: 'SET_NEW_STUDENTS_CONTEXT',
  SET_SELECTED_STUDENT_PROGRAMME: 'SET_SELECTED_STUDENT_PROGRAMME',

  GET_STUDENT_REQUEST: 'GET_STUDENT_REQUEST',
  GET_STUDENT_SUCCESS: 'GET_STUDENT_SUCCESS',
  GET_STUDENT_ERROR: 'GET_STUDENT_ERROR',

  GET_REGISTRATION_STATUS_REQUEST: 'GET_REGISTRATION_STATUS_REQUEST',
  GET_REGISTRATION_STATUS_SUCCESS: 'GET_REGISTRATION_STATUS_SUCCESS',
  GET_REGISTRATION_STATUS_ERROR: 'GET_REGISTRATION_STATUS_ERROR',

  GET_STUDENT_PROGRAMME_COURSE_UNITS_REQUEST:
    'GET_STUDENT_PROGRAMME_COURSE_UNITS_REQUEST',
  GET_STUDENT_PROGRAMME_COURSE_UNITS_SUCCESS:
    'GET_STUDENT_PROGRAMME_COURSE_UNITS_SUCCESS',
  GET_STUDENT_PROGRAMME_COURSE_UNITS_ERROR:
    'GET_STUDENT_PROGRAMME_COURSE_UNITS_ERROR',

  GET_LATE_COURSES_TO_REGISTER_REQUEST: 'GET_LATE_COURSES_TO_REGISTER_REQUEST',
  GET_LATE_COURSES_TO_REGISTER_SUCCESS: 'GET_LATE_COURSES_TO_REGISTER_SUCCESS',
  GET_LATE_COURSES_TO_REGISTER_ERROR: 'GET_LATE_COURSES_TO_REGISTER_ERROR',

  GET_DASHBOARD_STUDENTS_REQUEST: 'GET_DASHBOARD_STUDENTS_REQUEST',
  GET_DASHBOARD_STUDENTS_SUCCESS: 'GET_DASHBOARD_STUDENTS_SUCCESS',
  GET_DASHBOARD_STUDENTS_ERROR: 'GET_DASHBOARD_STUDENTS_ERROR',

  UPDATE_STUDENT_REQUEST: 'UPDATE_STUDENT_REQUEST',
  UPDATE_STUDENT_SUCCESS: 'UPDATE_STUDENT_SUCCESS',
  UPDATE_STUDENT_ERROR: 'UPDATE_STUDENT_ERROR',

  CHECK_EXAMINATION_PERMIT_REQUEST: 'CHECK_EXAMINATION_PERMIT_REQUEST',
  CHECK_EXAMINATION_PERMIT_SUCCESS: 'CHECK_EXAMINATION_PERMIT_SUCCESS',
  CHECK_EXAMINATION_PERMIT_ERROR: 'CHECK_EXAMINATION_PERMIT_ERROR',

  GET_CURRENT_REGISTRATIONS_REQUEST: 'GET_CURRENT_REGISTRATIONS_REQUEST',
  GET_CURRENT_REGISTRATIONS_SUCCESS: 'GET_CURRENT_REGISTRATIONS_SUCCESS',
  GET_CURRENT_REGISTRATIONS_ERROR: 'GET_CURRENT_REGISTRATIONS_ERROR',

  DOWNLOAD_PROGRAMME_STUDENTS_REQUEST: 'DOWNLOAD_PROGRAMME_STUDENTS_REQUEST',
  DOWNLOAD_PROGRAMME_STUDENTS_SUCCESS: 'DOWNLOAD_PROGRAMME_STUDENTS_SUCCESS',
  DOWNLOAD_PROGRAMME_STUDENTS_ERROR: 'DOWNLOAD_PROGRAMME_STUDENTS_ERROR',

  DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_REQUEST:
    'DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_REQUEST',
  DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_SUCCESS:
    'DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_SUCCESS',
  DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_ERROR:
    'DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_ERROR',

  EDIT_STUDENTS_STATUS_REQUEST: 'EDIT_STUDENTS_STATUS_REQUEST',
  EDIT_STUDENTS_STATUS_SUCCESS: 'EDIT_STUDENTS_STATUS_SUCCESS',
  EDIT_STUDENTS_STATUS_ERROR: 'EDIT_STUDENTS_STATUS_ERROR',

  EDIT_SINGLE_STUDENT_STATUS_REQUEST: 'EDIT_SINGLE_STUDENT_STATUS_REQUEST',
  EDIT_SINGLE_STUDENT_STATUS_SUCCESS: 'EDIT_SINGLE_STUDENT_STATUS_SUCCESS',
  EDIT_SINGLE_STUDENT_STATUS_ERROR: 'EDIT_SINGLE_STUDENT_STATUS_ERROR',

  SHOW_STUDENT_PROFILE_MODAL: 'SHOW_STUDENT_PROFILE_MODAL',
  SHOW_EDIT_VERSION_MODAL: 'SHOW_EDIT_VERSION_MODAL',
  SHOW_EDIT_ACCOUNT_MODAL: 'SHOW_EDIT_ACCOUNT_MODAL',
  SHOW_SEARCH_ACCOUNT_MODAL: 'SHOW_SEARCH_ACCOUNT_MODAL',
  SET_LIST_VIEW_STUDENTS: 'SET_LIST_VIEW_STUDENTS',

  getStudents: (context) => ({
    type: studentsActions.GET_STUDENTS_REQUEST,
    context,
  }),

  setShowStudentProfileModal: (payload) => ({
    type: studentsActions.SHOW_STUDENT_PROFILE_MODAL,
    payload,
  }),

  setStudentsList: (payload) => ({
    type: studentsActions.SET_LIST_VIEW_STUDENTS,
    payload,
  }),

  setShowEditVersionModal: (payload) => ({
    type: studentsActions.SHOW_EDIT_VERSION_MODAL,
    payload,
  }),

  setShowEditAccountModal: (payload) => ({
    type: studentsActions.SHOW_EDIT_ACCOUNT_MODAL,
    payload,
  }),

  setShowSearchAccountModal: (payload) => ({
    type: studentsActions.SHOW_SEARCH_ACCOUNT_MODAL,
    payload,
  }),

  getDashboardData: () => ({
    type: studentsActions.GET_DASHBOARD_STUDENTS_REQUEST,
  }),

  getStudentsByContext: (context) => ({
    type: studentsActions.GET_CONTEXT_STUDENTS_REQUEST,
    context,
  }),

  getSingleStudent: (query, purpose) => ({
    type: studentsActions.GET_SINGLE_STUDENT_REQUEST,
    query,
    purpose,
  }),

  getAllStudents: (context) => ({
    type: studentsActions.GET_ALL_STUDENTS_REQUEST,
    context,
  }),

  setStudentsContext: (context) => ({
    type: studentsActions.SET_STUDENTS_CONTEXT,
    context,
  }),

  editStudentStatus: (students, context) => ({
    type: studentsActions.EDIT_STUDENTS_STATUS_REQUEST,
    context,
    students,
  }),

  editSingleStudentStatus: (students, context) => ({
    type: studentsActions.EDIT_SINGLE_STUDENT_STATUS_REQUEST,
    context,
    students,
  }),

  setNewStudentsContext: (context) => ({
    type: studentsActions.SET_NEW_STUDENTS_CONTEXT,
    context,
  }),

  setSelectedStudent: (student) => ({
    type: studentsActions.SET_SELECTED_STUDENT,
    student,
  }),

  setSelectedProgramme: (programme) => ({
    type: studentsActions.SET_SELECTED_STUDENT_PROGRAMME,
    programme,
  }),

  createStudents: (data, currentContext) => ({
    type: studentsActions.CREATE_STUDENTS_REQUEST,
    data,
    currentContext,
  }),

  fetchProgrammeStudents: (context) => ({
    type: studentsActions.GET_PROGRAMME_STUDENTS_REQUEST,
    context,
  }),

  bulkEditProgrammeVersion: (context, studentContext) => ({
    type: studentsActions.BULK_EDIT_PROGRAMME_VERSION_REQUEST,
    context,
    studentContext,
  }),

  uploadPhoto: (studentId, formData, studentNoOrReg) => ({
    type: studentsActions.UPLOAD_STUDENT_PHOTO_REQUEST,
    studentId,
    formData,
    studentNoOrReg,
  }),

  updateStudents: (id, data, currentContext) => ({
    type: studentsActions.UPDATE_STUDENTS_REQUEST,
    id,
    data,
    currentContext,
  }),

  deleteStudents: (id, currentContext) => ({
    type: studentsActions.DELETE_STUDENTS_REQUEST,
    id,
    currentContext,
  }),

  getStudentByRegNo: (regNo) => ({
    type: studentsActions.GET_STUDENT_REQUEST,
    regNo,
  }),

  getRegistrationStatus: (studentId) => ({
    type: studentsActions.GET_REGISTRATION_STATUS_REQUEST,
    studentId,
  }),

  getCourseUnits: (studentId) => ({
    type: studentsActions.GET_STUDENT_PROGRAMME_COURSE_UNITS_REQUEST,
    studentId,
  }),

  printPermit: (registrationId) => ({
    type: studentsActions.CHECK_EXAMINATION_PERMIT_REQUEST,
    registrationId,
  }),

  getLateCourseUnits: (studentProgId, semesterId) => ({
    type: studentsActions.GET_LATE_COURSES_TO_REGISTER_REQUEST,
    studentProgId,
    semesterId,
  }),

  updateStudent: (data, url) => ({
    type: studentsActions.UPDATE_STUDENT_REQUEST,
    data,
    url,
  }),

  getCurrentRegistrations: (data) => ({
    type: studentsActions.GET_CURRENT_REGISTRATIONS_REQUEST,
    data,
  }),

  downloadProgrammeStudents: (data) => ({
    type: studentsActions.DOWNLOAD_PROGRAMME_STUDENTS_REQUEST,
    data,
  }),

  downloadProgrammeStudentsByContext: (context) => ({
    type: studentsActions.DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_REQUEST,
    context,
  }),
};
export default studentsActions;
