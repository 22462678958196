import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useImmer } from 'use-immer';
import { times } from 'lodash';
import { Spreadsheet } from 'react-spreadsheet';
import produce from 'immer';

function ExcelSpreadsheet({ rows, ...props }) {
  const [excelData, setExcelData] = useImmer([]);

  const onChangeExcel = useCallback((data) => {
    setExcelData(produce(() => data));
  });

  return (
    <Spreadsheet
      data={excelData}
      onChange={onChangeExcel}
      columnLabels={[
        'CODE',
        'NAME',
        'ADDRESS',
        'DISTRICT',
        'VILLAGE',
        'COUNTY',
        'SLOGAN',
        'WEBSITE',
        'EMAIL',
        'TELEPHONE 1',
        'TELEPHONE 2',
        'ACADEMIC UNIT',
        'INSTITUTION TYPE',
      ]}
      rowLabels={times(rows).map((n) => n + 1)}
      {...props}
    />
  );
}

ExcelSpreadsheet.defaultProps = {
  rows: 1000,
};

ExcelSpreadsheet.propTypes = {
  rows: PropTypes.number,
};

export default ExcelSpreadsheet;
