const studentRecord = {
  GET_STUDENT_RECORD_REQUEST: 'GET_STUDENT_RECORD_REQUEST',
  GET_STUDENT_RECORD_SUCCESS: 'GET_STUDENT_RECORD_SUCCESS',
  GET_STUDENT_RECORD_ERROR: 'GET_STUDENT_RECORD_ERROR',

  UPDATE_CURRENT_STUDENT_PROG_REQUEST: 'UPDATE_CURRENT_STUDENT_PROG_REQUEST',
  UPDATE_CURRENT_STUDENT_PROG_SUCCESS: 'UPDATE_CURRENT_STUDENT_PROG_SUCCESS',
  UPDATE_CURRENT_STUDENT_PROG_ERROR: 'UPDATE_CURRENT_STUDENT_PROG_ERROR',

  CHANGE_VERIFICATION_STATUS_REQUEST: 'CHANGE_VERIFICATION_STATUS_REQUEST',
  CHANGE_VERIFICATION_STATUS_SUCCESS: 'CHANGE_VERIFICATION_STATUS_SUCCESS',
  CHANGE_VERIFICATION_STATUS_ERROR: 'CHANGE_VERIFICATION_STATUS_ERROR',

  SET_STUDENT_ACADEMIC_STATUS_REQUEST: 'SET_STUDENT_ACADEMIC_STATUS_REQUEST',
  SET_STUDENT_ACADEMIC_STATUS_SUCCESS: 'SET_STUDENT_ACADEMIC_STATUS_SUCCESS',
  SET_STUDENT_ACADEMIC_STATUS_ERROR: 'SET_STUDENT_ACADEMIC_STATUS_ERROR',

  SET_STUDENT_RECORD: 'SET_STUDENT_RECORD',

  SET_CURRENT_STUDENT_PROGRAMME: 'SET_CURRENT_STUDENT_PROGRAMME',

  getStudentRecord: (studentId) => ({
    type: studentRecord.GET_STUDENT_RECORD_REQUEST,
    studentId,
  }),

  updateCurrentStudentProg: (studentId, studentProgId, studentNumber) => ({
    type: studentRecord.UPDATE_CURRENT_STUDENT_PROG_REQUEST,
    studentProgId,
    studentNumber,
    studentId,
  }),

  changeVerifyStatus: (data, studentProgId, studentNumber) => ({
    type: studentRecord.CHANGE_VERIFICATION_STATUS_REQUEST,
    studentProgId,
    studentNumber,
    data,
  }),

  setStudentAcademicStatus: (studentProgrammeId, studentId, data) => ({
    type: studentRecord.SET_STUDENT_ACADEMIC_STATUS_REQUEST,
    studentId,
    studentProgrammeId,
    data,
  }),

  setStudentRecord: (studentRecordToSet = {}) => ({
    type: studentRecord.SET_STUDENT_RECORD,
    studentRecordToSet,
  }),

  setStudentProgramme: (currentStudentProgramme = {}) => ({
    type: studentRecord.SET_CURRENT_STUDENT_PROGRAMME,
    currentStudentProgramme,
  }),
};
export default studentRecord;
