import { sharedActions } from '../actions';
import initialState from '../initialState';

const tab = (state = initialState.sharedInitialSTate, actions) => {
  switch (actions.type) {
    case sharedActions.DOWNLOAD_EXCEL_FILE_REQUEST:
      return {
        ...state,
        downloadingExcelFile: true,
      };
    case sharedActions.DOWNLOAD_EXCEL_FILE_SUCCESS:
      return {
        ...state,
        downloadingExcelFile: false,
      };
    case sharedActions.DOWNLOAD_EXCEL_FILE_ERROR:
      return {
        ...state,
        downloadingExcelFile: false,
        downloadExcelFileError: actions.error,
      };

    case sharedActions.DOWNLOAD_PDF_FILE_REQUEST:
      return {
        ...state,
        downloadingPDFFile: true,
      };
    case sharedActions.DOWNLOAD_PDF_FILE_SUCCESS:
      return {
        ...state,
        downloadingPDFFile: false,
      };
    case sharedActions.DOWNLOAD_PDF_FILE_ERROR:
      return {
        ...state,
        downloadingPDFFile: false,
        downloadPDFFileError: actions.error,
      };

    case sharedActions.UPLOAD_EXCEL_FILE_REQUEST:
      return {
        ...state,
        uploadingExcelFile: true,
      };
    case sharedActions.UPLOAD_EXCEL_FILE_SUCCESS:
      return {
        ...state,
        uploadingExcelFile: false,
      };
    case sharedActions.UPLOAD_EXCEL_FILE_ERROR:
      return {
        ...state,
        uploadingExcelFile: false,
        uploadExcelFileError: actions.error,
      };

    default:
      return state;
  }
};

export default tab;
