const settings = {
  setSelectedMenu: (e) => ({
    type: 'SET_SELECTED_MENU',
    payload: e,
  }),

  toggleLeftMenuAction: (e) => ({
    type: 'IS_TOGGLE_LEFT_MENU',
    payload: e,
  }),

  showEditUserModal: (e) => ({
    type: 'SHOW_EDIT_USER_MODAL',
    payload: e,
  }),

  showEditAccessDomainModal: (e) => ({
    type: 'SHOW_EDIT_ACCESS_DOMAIN_MODAL',
    payload: e,
  }),

  showAccessDomainModal: (e) => ({
    type: 'SHOW_ACCESS_DOMAIN_MODAL',
    payload: e,
  }),

  showDeleteModal: (e) => ({
    type: 'SHOW_DELETE_MODAL',
    payload: e,
  }),

  showDeleteRoom: (e) => ({
    type: 'SHOW_DELETE_ROOM',
    payload: e,
  }),

  showEditRoom: (e) => ({
    type: 'SHOW_EDIT_ROOM',
    payload: e,
  }),

  showEditModal: (e) => ({
    type: 'SHOW_EDIT_MODAL',
    payload: e,
  }),

  showDetailsModal: (e) => ({
    type: 'SHOW_DETAILS_MODAL',
    payload: e,
  }),

  showDeleteVersionCUModal: (e) => ({
    type: 'SHOW_DELETE_VERSION_CU_MODAL',
    payload: e,
  }),

  showAllocateTransactionModal: (e) => ({
    type: 'SHOW_ALLOCATE_TRANSACTION_MODAL',
    payload: e,
  }),

  showModal: (e) => ({
    type: 'SHOW_MODAL',
    payload: e,
  }),

  showClearStudentModal: (e) => ({
    type: 'SHOW_CLEAR_STUDENT_MODAL',
    payload: e,
  }),

  showPRNModal: (e) => ({
    type: 'SHOW_PRN_MODAL',
    payload: e,
  }),

  showCurrentProgModal: (e) => ({
    type: 'SHOW_CURRENT_PROG_MODAL',
    payload: e,
  }),

  showPrintStudentLedgerModal: (e) => ({
    type: 'SHOW_PRINT_STUDENT_LEDGER_MODAL',
    payload: e,
  }),

  showSponsorReceiptModal: (e) => ({
    type: 'SHOW_SPONSOR_RECEIPT_MODAL',
    payload: e,
  }),

  showUploadModal: (e) => ({
    type: 'SHOW_UPLOAD_MODAL',
    payload: e,
  }),

  openModal: (e) => ({
    type: 'OPEN_MODAL',
    payload: e,
  }),

  showBuildingModal: (e) => ({
    type: 'SHOW_BUILDING_MODAL',
    payload: e,
  }),

  showRoomsModal: (e) => ({
    type: 'SHOW_ROOMS_MODAL',
    payload: e,
  }),

  showCreateMetadataModal: (e) => ({
    type: 'SHOW_CREATE_METADATA_MODAL',
    payload: e,
  }),

  showEditMetadataValueModal: (e) => ({
    type: 'SHOW_EDIT_METADATA_VALUE_MODAL',
    payload: e,
  }),

  showCollegeModal: (e) => ({
    type: 'SHOW_COLLEGE_MODAL',
    payload: e,
  }),

  showFacultyModal: (e) => ({
    type: 'SHOW_FACULTY_MODAL',
    payload: e,
  }),

  showDepartmentModal: (e) => ({
    type: 'SHOW_DEPARTMENT_MODAL',
    payload: e,
  }),

  showEditSubjectModal: (e) => ({
    type: 'SHOW_EDIT_SUBJECT_MODAL',
    payload: e,
  }),

  showEditUnebSubjectModal: (e) => ({
    type: 'SHOW_EDIT_UNEB_SUBJECT_MODAL',
    payload: e,
  }),

  showEditSubjectCombinationModal: (e) => ({
    type: 'SHOW_EDIT_SUBJECT_COMBINATION_MODAL',
    payload: e,
  }),

  showCreateSubjectCombinationModal: (e) => ({
    type: 'SHOW_CREATE_SUBJECT_COMBINATION_MODAL',
    payload: e,
  }),

  showEditSpecializationModal: (e) => ({
    type: 'SHOW_EDIT_SPECIALIZATION_MODAL',
    payload: e,
  }),

  showEditSemesterLoadModal: (e) => ({
    type: 'SHOW_EDIT_SEMESTER_LOAD_MODAL',
    payload: e,
  }),

  showEditProgrammeAliasModal: (e) => ({
    type: 'SHOW_EDIT_PROGRAMME_ALIAS_MODAL',
    payload: e,
  }),

  setSelectedResultProgramme: (e) => ({
    type: 'SET_SELECTED_RESULT_PROGRAMME',
    payload: e,
  }),

  setSelectedResultContext: (e) => ({
    type: 'SET_SELECTED_RESULT_CONTEXT',
    payload: e,
  }),

  setSelectedProgramme: (e) => ({
    type: 'SET_SELECTED_PROGRAMME',
    payload: e,
  }),

  setSelectedProgrammeVersion: (e) => ({
    type: 'SET_SELECTED_PROGRAMME_VERSION',
    payload: e,
  }),

  showAddRoleApplicationModal: (e) => ({
    type: 'SHOW_ADD_USER_ROLE_APPLICATION_MODAL',
    payload: e,
  }),

  setSelectedUserProfile: (e) => ({
    type: 'SET_SELECTED_USER_PROFILE',
    payload: e,
  }),

  showCreateRoleGroupModal: (e) => ({
    type: 'SHOW_CREATE_ROLE_GROUP_MODAL',
    payload: e,
  }),

  showEditRoleGroupModal: (e) => ({
    type: 'SHOW_EDIT_ROLE_GROUP_MODAL',
    payload: e,
  }),

  showCreateSecurityProfileModal: (e) => ({
    type: 'SHOW_CREATE_SECURITY_PROFILE_MODAL',
    payload: e,
  }),

  setSelectedSecurityProfile: (e) => ({
    type: 'SET_SELECTED_SECURITY_PROFILE',
    payload: e,
  }),

  showCreateUserRoleModal: (e) => ({
    type: 'SHOW_CREATE_USER_ROLE_MODAL',
    payload: e,
  }),

  showEditUserRoleModal: (e) => ({
    type: 'SHOW_EDIT_USER_ROLE_MODAL',
    payload: e,
  }),

  setSelectedUserRole: (e) => ({
    type: 'SET_SELECTED_USER_ROLE',
    payload: e,
  }),

  setSelectedSystemApp: (e) => ({
    type: 'SET_SELECTED_SYSTEM_APP',
    payload: e,
  }),

  setSelectedSystemUser: (e) => ({
    type: 'SET_SELECTED_SYSTEM_USER',
    payload: e,
  }),

  setSelectedAdmissionScheme: (e) => ({
    type: 'SET_SELECTED_ADMISSION_SCHEME',
    payload: e,
  }),

  setProgrammeToAdvertise: (e) => ({
    type: 'SET_PROGRAMME_TO_ADVERTISE',
    payload: e,
  }),

  removeProgrammeToAdvertise: (e) => ({
    type: 'REMOVE_PROGRAMME_TO_ADVERTISE',
    payload: e,
  }),

  removeAllProgrammeToAdvertise: () => ({
    type: 'REMOVE_ALL_PROGRAMMES_TO_ADVERTISE',
  }),

  setSelectedAcademicYear: (e) => ({
    type: 'SET_SELECTED_ACADEMIC_YEAR',
    payload: e,
  }),

  showCreateFeesElementsModal: (e) => ({
    type: 'SHOW_CREATE_FEES_ELEMENTS_MODAL',
    payload: e,
  }),
  showEditFeesElementsModal: (e) => ({
    type: 'SHOW_EDIT_FEES_ELEMENTS_MODAL',
    payload: e,
  }),

  showEditTuitionModal: (e) => ({
    type: 'SHOW_EDIT_TUITION_MODAL',
    payload: e,
  }),

  showAssignUserRoleModal: (e) => ({
    type: 'SHOW_ASSIGN_USER_ROLE_MODAL',
    payload: e,
  }),

  showEditOtherFeesAmountModal: (e) => ({
    type: 'SHOW_EDIT_OTHER_FEES_AMOUNT_MODAL',
    payload: e,
  }),
  showEditFeesWaiversModal: (e) => ({
    type: 'SHOW_EDIT_FEES_WAIVERS_MODAL',
    payload: e,
  }),
  showEditFeesDiscountModal: (e) => ({
    type: 'SHOW_EDIT_FEES_DISCOUNT_MODAL',
    payload: e,
  }),
  showCreateFeesWaiversModal: (e) => ({
    type: 'SHOW_CREATE_FEES_WAIVERS_MODAL',
    payload: e,
  }),
  showAddRoleGroupAdminModal: (e) => ({
    type: 'SHOW_ADD_ROLE_GROUP_ADMIN_MODAL',
    payload: e,
  }),

  showAssignRoleToUserModal: (e) => ({
    type: 'SHOW_ASSIGN_ROLE_TO_USER_MODAL',
    payload: e,
  }),
  showOpenAdmissionsModal: (
    e,
    isEditingAdmission = false,
    admissionToEdit = null,
    admissionEditAction = null
  ) => ({
    type: 'SHOW_OPEN_ADMISSION_MODAL',
    payload: e,
    isEditingAdmission,
    admissionToEdit,
    admissionEditAction,
  }),

  showAdvertisingProgramModal: (e) => ({
    type: 'SHOW_ADVERTISING_PROGRAM_MODAL',
    payload: e,
  }),

  showEditEventModal: (e) => ({
    type: 'SHOW_EDIT_EVENT_MODAL',
    payload: e,
  }),

  showDeleteEventModal: (e) => ({
    type: 'SHOW_DELETE_EVENT_MODAL',
    payload: e,
  }),

  setCurrentPujabTab: (e) => ({
    type: 'SET_CURRENT_PUJAB_TAB',
    payload: e,
  }),

  setUnebCenterTab: (e) => ({
    type: 'SET_UNEB_CENTER_TAB',
    payload: e,
  }),

  showSingleApplicationFormModal: (e) => ({
    type: 'SHOW_SINGLE_APPLICATION_MODAL',
    payload: e,
  }),
  showFormEditModal: (e) => ({
    type: 'SHOW_Form_Edit_MODAL',
    payload: e,
  }),
  showResetPasswordModal: (e) => ({
    type: 'SHOW_RESET_PASSWORD_MODAL',
    payload: e,
  }),

  showAdmitStudentModal: (e) => ({
    type: 'SHOW_ADMIT_STUDENT_MODAL',
    payload: e,
  }),
  showCreateStudentModal: (e) => ({
    type: 'SHOW_CREATE_STUDENT_MODAL',
    payload: e,
  }),
  showImportApplicantModal: (e) => ({
    type: 'SHOW_IMPORT_APPLICANTS',
    payload: e,
  }),

  showEditAdmittedStudentModal: (e) => ({
    type: 'SHOW_EDIT_ADMITTED_STUDENT',
    payload: e,
  }),

  showChangeOfProgrammeModal: (e) => ({
    type: 'SHOW_CHANGE_OF_PROGRAMME',
    payload: e,
  }),

  showPrintAdmissionModal: (e) => ({
    type: 'SHOW_PRINT_ADMISSIONS',
    payload: e,
  }),

  showUploadCourseWorkModal: (e) => ({
    type: 'SHOW_UPLOAD_COURSE_WORK',
    payload: e,
  }),

  showUploadFinalWorkModal: (e) => ({
    type: 'SHOW_UPLOAD_FINAL_MARK',
    payload: e,
  }),

  showRegistrationModal: (e) => ({
    type: 'SHOW_REGISTRATION_MODAL',
    payload: e,
  }),

  showDeRegisterModal: (e) => ({
    type: 'SHOW_DE_REGISTER_MODAL',
    payload: e,
  }),

  showRegisterFullyModal: (e) => ({
    type: 'SHOW_REGISTER_FULLY_MODAL',
    payload: e,
  }),

  showLateEnrollmentModal: (e) => ({
    type: 'SHOW_LATE_ENROLLMENT_MODAL',
    payload: e,
  }),
  showRetakeEnrollmentModal: (e) => ({
    type: 'SHOW_RETAKE_ENROLLMENT_MODAL',
    payload: e,
  }),
  showEnrollmentModal: (e) => ({
    type: 'SHOW_ENROLLMENT_MODAL',
    payload: e,
  }),
  showDeEnrollModal: (e) => ({
    type: 'SHOW_DE_ENROLL_MODAL',
    payload: e,
  }),
  eventContextData: (e) => ({
    type: 'SET_EVENT_CONTEXT_DATA',
    payload: e,
  }),
  showProvisionalEnrolModal: (e) => ({
    type: 'SHOW_PROVISIONAL_ENROL',
    payload: e,
  }),
  showOffsetInvoiceModal: (show, invoiceData) => ({
    type: 'SHOW_OFFSET_INVOICE_MODAL',
    show,
    invoiceData,
  }),
  showInvoiceActionsModal: (
    show,
    selectedInvoices,
    actionType = 'void',
    url
  ) => ({
    type: 'SHOW_INVOICE_ACTIONS_MODAL',
    show,
    selectedInvoices,
    actionType,
    url,
  }),
  showAddProgrammeCriteriaModal: (payload) => ({
    type: 'SHOW_ADD_PROGRAMME_CRITERIA_MODAL',
    payload,
  }),
  showFacultyDownloadModal: (payload) => ({
    type: 'SHOW_FACULTY_DOWNLOAD_MODAL',
    payload,
  }),
  showGeneratePRNModal: (payload) => ({
    type: 'SHOW_GENERATE_PRN_MODAL',
    payload,
  }),
  showAdministrativeAdmissionModal: (payload) => ({
    type: 'SHOW_ADMINISTRATIVE_ADMISSION_MODAL',
    payload,
  }),
  showResultsApprovalModal: (payload) => ({
    type: 'SHOW_RESULTS_APPROVAL_MODAL',
    payload,
  }),
  showTransferFundsModal: (payload) => ({
    type: 'SHOW_TRANSFER_FUNDS_MODAL',
    payload,
  }),
  showPujabProgrammesModal: (payload) => ({
    type: 'SHOW_PUJAB_PROGRAMMES_MODAL',
    payload,
  }),
  showUserActionAuthenticationModal: (payload) => ({
    type: 'SHOW_USER_ACTION_AUTH_MODAL',
    payload,
  }),
  setAppNavigation: (payload) => ({
    type: 'SET_APP_NAVIGATION',
    payload,
  }),
};

export default settings;
