const debitNote = {
  GET_DEBIT_NOTE_REQUEST: 'GET_DEBIT_NOTE_REQUEST',
  GET_DEBIT_NOTE_SUCCESS: 'GET_DEBIT_NOTE_SUCCESS',
  GET_DEBIT_NOTE_ERROR: 'GET_DEBIT_NOTE_ERROR',

  GET_PENDING_DEBIT_NOTE_REQUEST: 'GET_PENDING_DEBIT_NOTE_REQUEST',
  GET_PENDING_DEBIT_NOTE_SUCCESS: 'GET_PENDING_DEBIT_NOTE_SUCCESS',
  GET_PENDING_DEBIT_NOTE_ERROR: 'GET_PENDING_DEBIT_NOTE_ERROR',

  GET_STUDENT_PENDING_DEBIT_NOTE_REQUEST:
    'GET_STUDENT_PENDING_DEBIT_NOTE_REQUEST',
  GET_STUDENT_PENDING_DEBIT_NOTE_SUCCESS:
    'GET_STUDENT_PENDING_DEBIT_NOTE_SUCCESS',
  GET_STUDENT_PENDING_DEBIT_NOTE_ERROR: 'GET_STUDENT_PENDING_DEBIT_NOTE_ERROR',

  CREATE_DEBIT_NOTE_REQUEST: 'CREATE_DEBIT_NOTE_REQUEST',
  CREATE_DEBIT_NOTE_SUCCESS: 'CREATE_DEBIT_NOTE_SUCCESS',
  CREATE_DEBIT_NOTE_ERROR: 'CREATE_DEBIT_NOTE_ERROR',

  APPROVE_DEBIT_NOTE_REQUEST: 'APPROVE_DEBIT_NOTE_REQUEST',
  APPROVE_DEBIT_NOTE_SUCCESS: 'APPROVE_DEBIT_NOTE_SUCCESS',
  APPROVE_DEBIT_NOTE_ERROR: 'APPROVE_DEBIT_NOTE_ERROR',

  DECLINE_DEBIT_NOTE_REQUEST: 'DECLINE_DEBIT_NOTE_REQUEST',
  DECLINE_DEBIT_NOTE_SUCCESS: 'DECLINE_DEBIT_NOTE_SUCCESS',
  DECLINE_DEBIT_NOTE_ERROR: 'DECLINE_DEBIT_NOTE_ERROR',

  SET_CLEAR_TABLE_ROWS: 'SET_CLEAR_TABLE_ROWS',

  getDebitNotes: (studentId) => ({
    type: debitNote.GET_DEBIT_NOTE_REQUEST,
    studentId,
  }),

  getPendingDebitNotes: (studentId) => ({
    type: debitNote.GET_PENDING_DEBIT_NOTE_REQUEST,
    studentId,
  }),

  getStudentPendingDebitNotes: (studentId) => ({
    type: debitNote.GET_STUDENT_PENDING_DEBIT_NOTE_REQUEST,
    studentId,
  }),

  createDebitNote: (data, studentId) => ({
    type: debitNote.CREATE_DEBIT_NOTE_REQUEST,
    data,
    studentId,
  }),

  approveDebitNote: (data, studentId) => ({
    type: debitNote.APPROVE_DEBIT_NOTE_REQUEST,
    data,
    studentId,
  }),

  declineDebitNotes: (data, studentId) => ({
    type: debitNote.DECLINE_DEBIT_NOTE_REQUEST,
    data,
    studentId,
  }),

  setClearTableRow: (payload) => ({
    type: debitNote.SET_CLEAR_TABLE_ROWS,
    payload,
  }),
};
export default debitNote;
