import axios from 'axios';
import { fork, put, takeLatest } from 'redux-saga/effects';
import {
  semesterEventActions,
  studentRegistrationActions,
} from '../../actions';

function* registerStudent(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/register`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentRegistrationActions.REGISTER_STUDENT_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_REGISTRATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.REGISTER_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* registerStudentFully(actions) {
  try {
    const { registrationId, studentId, studentProgrammeId, data } = actions;
    const response = yield axios({
      url: `/registration/student-registration/register-fully/${registrationId}`,
      method: 'POST',
      data,
    });
    yield put({
      type: studentRegistrationActions.REGISTER_STUDENT_FULLY_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId,
      studentProgrammeId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId,
      studentProgrammeId,
    });
    yield put({
      type: 'SHOW_REGISTRATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.REGISTER_STUDENT_FULLY_ERROR,
      error: error.data,
    });
  }
}

function* updateStudentRegistration(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/update-course-units/${actions.registrationId}`,
      method: 'POST',
      data: { course_units: actions.data },
    });
    yield put({
      type: studentRegistrationActions.UPDATE_STUDENT_REGISTRATION_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_REGISTRATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.UPDATE_STUDENT_REGISTRATION_ERROR,
      error: error.data,
    });
  }
}

function* deRegisterStudent(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/de-register/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentRegistrationActions.DE_REGISTER_STUDENT_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: semesterEventActions.GET_STUDENT_SEMESTER_EVENTS_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_DE_REGISTER_MODAL',
      payload: { show: false, studentRegistration: {} },
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.DE_REGISTER_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* studentLateRegistration(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/late-registration/${actions.studentId}`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentRegistrationActions.ADD_LATE_REGISTRATION_SUCCESS,
      data: response,
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
    yield put({
      type: 'SHOW_REGISTRATION_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.ADD_LATE_REGISTRATION_ERROR,
      error: error.data,
    });
  }
}

function* getRegistrationHistory(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/history/${actions.studentProgrammeId}`,
      method: 'GET',
    });
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_SUCCESS,
      data: response.data,
      studentId: actions.studentId,
      studentProgrammeId: actions.studentProgrammeId,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.GET_REGISTRATION_HISTORY_ERROR,
      error: error.data,
    });
  }
}

function* getPreviousRegistrationHistory(actions) {
  try {
    const response = yield axios({
      url: `/registration/migrated-enrollments`,
      method: 'GET',
      params: { student_number: actions.studentNo },
    });
    yield put({
      type: studentRegistrationActions.GET_PREVIOUS_REGISTRATION_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.GET_PREVIOUS_REGISTRATION_ERROR,
      error: error.data,
    });
  }
}

function* getMigratedEnrollmentBalances(actions) {
  try {
    const response = yield axios({
      url: `/registration/migrated-enrollments/enrollment-balances`,
      method: 'GET',
      params: { programme: actions.programmeId },
    });
    yield put({
      type: studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_ERROR,
      error: error.data,
    });
  }
}

function* billPreviousBalances(actions) {
  try {
    const response = yield axios({
      url: `/registration/enrollments/bill-previous-balances`,
      method: 'POST',
      data: actions.data.students,
    });
    yield put({
      type: studentRegistrationActions.BILL_PREVIOUS_BALANCES_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST,
      programmeId: actions.data.programme,
    });
  } catch (error) {
    yield put({
      type: studentRegistrationActions.BILL_PREVIOUS_BALANCES_ERROR,
      error: error.data,
    });
  }
}

function* watchRegisterStudent() {
  yield takeLatest(
    studentRegistrationActions.REGISTER_STUDENT_REQUEST,
    registerStudent
  );
}

function* watchRegisterStudentFully() {
  yield takeLatest(
    studentRegistrationActions.REGISTER_STUDENT_FULLY_REQUEST,
    registerStudentFully
  );
}

function* watchUpdateStudentRegistration() {
  yield takeLatest(
    studentRegistrationActions.UPDATE_STUDENT_REGISTRATION_REQUEST,
    updateStudentRegistration
  );
}
function* watchDeRegisterStudent() {
  yield takeLatest(
    studentRegistrationActions.DE_REGISTER_STUDENT_REQUEST,
    deRegisterStudent
  );
}
function* watchLateRegistrationStudent() {
  yield takeLatest(
    studentRegistrationActions.ADD_LATE_REGISTRATION_REQUEST,
    studentLateRegistration
  );
}
function* watchRegistrationHistory() {
  yield takeLatest(
    studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST,
    getRegistrationHistory
  );
}
function* watchPreviousRegistrationHistory() {
  yield takeLatest(
    studentRegistrationActions.GET_PREVIOUS_REGISTRATION_REQUEST,
    getPreviousRegistrationHistory
  );
}
function* watchGetMigratedEnrollmentBalances() {
  yield takeLatest(
    studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST,
    getMigratedEnrollmentBalances
  );
}
function* watchBillPreviousBalances() {
  yield takeLatest(
    studentRegistrationActions.BILL_PREVIOUS_BALANCES_REQUEST,
    billPreviousBalances
  );
}

export default [
  fork(watchRegisterStudent),
  fork(watchDeRegisterStudent),
  fork(watchRegistrationHistory),
  fork(watchRegisterStudentFully),
  fork(watchBillPreviousBalances),
  fork(watchLateRegistrationStudent),
  fork(watchUpdateStudentRegistration),
  fork(watchPreviousRegistrationHistory),
  fork(watchGetMigratedEnrollmentBalances),
];
