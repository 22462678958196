import axios from 'axios';
import FileSaver from 'file-saver';
import { isEmpty } from 'lodash';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { tabActions, tuitionActions } from '../../actions';

function* getTuitionAmounts(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/tuition-amounts/fees-elements-amounts',
      method: 'POST',
      data: actions.context,
    });
    yield put({
      type: tuitionActions.GET_TUITION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: tuitionActions.GET_TUITION_ERROR,
      error: error.data,
    });
  }
}

function* createTuitionAmounts(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/tuition-amounts/bulk-create',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: tuitionActions.CREATE_TUITION_SUCCESS,
      data: response,
    });
    yield put({
      type: tuitionActions.GET_TUITION_REQUEST,
      context: actions.currentContext,
    });
    yield put({
      type: tabActions.TUITION_TAB,
      activeTab: 'view',
    });
  } catch (error) {
    yield put({
      type: tuitionActions.CREATE_TUITION_ERROR,
      error: error.data,
    });
  }
}

function* addTuitionElements(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/tuition-amounts/add-element-amounts/${actions.contextID}`,
      method: 'POST',
      data: { tuitionAmountFeesElements: actions.data },
    });
    yield put({
      type: tuitionActions.ADD_TUITION_ELEMENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: tuitionActions.GET_TUITION_REQUEST,
      context: actions.tuitionContext,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: tuitionActions.ADD_TUITION_ELEMENTS_ERROR,
      error: error.data,
    });
  }
}

function* deleteTuitionAmount(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/tuition-amounts/delete-element-amount/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: tuitionActions.DELETE_TUITION_SUCCESS,
      data: response,
    });
    yield put({
      type: tuitionActions.GET_TUITION_REQUEST,
      context: actions.currentContext,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: tuitionActions.DELETE_TUITION_ERROR,
      error: error.data,
    });
  }
}
function* updateTuition(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/tuition-amounts/update-element-amount/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: tuitionActions.UPDATE_TUITION_SUCCESS,
      data: response,
    });
    yield put({
      type: tuitionActions.GET_TUITION_REQUEST,
      context: actions.currentContext,
    });
  } catch (error) {
    yield put({
      type: tuitionActions.GET_TUITION_ERROR,
      error: error.data,
    });
  }
}

function* downloadTuitionTemplate() {
  try {
    yield axios({
      url: '/fees-mgt/tuition-amounts/download-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-TUITION-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: tuitionActions.DOWNLOAD_TUITION_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: tuitionActions.DOWNLOAD_TUITION_ERROR,
      error: error.data,
    });
  }
}

function* uploadTuition(actions) {
  try {
    const response = yield axios({
      url: '/fees-mgt/tuition-amounts/upload-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 300000,
    });
    yield put({
      type: tuitionActions.UPLOAD_TUITION_SUCCESS,
      data: response.data,
    });
    if (!isEmpty(actions.context) && Object.keys(actions.context).length > 3) {
      yield put({
        type: tuitionActions.GET_TUITION_REQUEST,
        context: actions.context,
      });
    }
    yield put({
      type: 'SHOW_UPLOAD_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: tuitionActions.UPLOAD_TUITION_ERROR,
      error: error.data,
    });
  }
}

function* getTuitionExemptions() {
  try {
    const response = yield axios({
      url: `/fees-mgt/manage-affiliates`,
      method: 'GET',
    });
    yield put({
      type: tuitionActions.GET_TUITION_EXEMPTIONS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: tuitionActions.GET_TUITION_EXEMPTIONS_ERROR,
      error: error.data,
    });
  }
}

function* addTuitionExemptions(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/manage-affiliates`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: tuitionActions.ADD_TUITION_EXEMPTION_SUCCESS,
      data: response,
    });
    yield put({
      type: tuitionActions.GET_TUITION_EXEMPTIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: tuitionActions.ADD_TUITION_EXEMPTION_ERROR,
      error: error.data,
    });
  }
}

function* deleteTuitionExemptions(actions) {
  try {
    const response = yield axios({
      url: `/fees-mgt/manage-affiliates/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: tuitionActions.DELETE_TUITION_EXEMPTION_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: tuitionActions.GET_TUITION_EXEMPTIONS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: tuitionActions.DELETE_TUITION_EXEMPTION_ERROR,
      error: error.data,
    });
  }
}

function* watchGetTuitionAmounts() {
  yield takeLatest(tuitionActions.GET_TUITION_REQUEST, getTuitionAmounts);
}
function* watchAddTuitionElements() {
  yield takeLatest(
    tuitionActions.ADD_TUITION_ELEMENTS_REQUEST,
    addTuitionElements
  );
}
function* watchCreateTuitionAmounts() {
  yield takeLatest(tuitionActions.CREATE_TUITION_REQUEST, createTuitionAmounts);
}
function* watchDeleteTuitionAmounts() {
  yield takeLatest(tuitionActions.DELETE_TUITION_REQUEST, deleteTuitionAmount);
}
function* watchUpdateTuitionAmounts() {
  yield takeLatest(tuitionActions.UPDATE_TUITION_REQUEST, updateTuition);
}
function* watchDownloadTuitionTemplate() {
  yield takeLatest(
    tuitionActions.DOWNLOAD_TUITION_REQUEST,
    downloadTuitionTemplate
  );
}
function* watchUploadTuition() {
  yield takeLatest(tuitionActions.UPLOAD_TUITION_REQUEST, uploadTuition);
}
function* watchAddTuitionExemptions() {
  yield takeLatest(
    tuitionActions.ADD_TUITION_EXEMPTION_REQUEST,
    addTuitionExemptions
  );
}
function* watchGetTuitionExemptions() {
  yield takeLatest(
    tuitionActions.GET_TUITION_EXEMPTIONS_REQUEST,
    getTuitionExemptions
  );
}
function* watchDeleteTuitionExemptions() {
  yield takeLatest(
    tuitionActions.DELETE_TUITION_EXEMPTION_REQUEST,
    deleteTuitionExemptions
  );
}

export default [
  fork(watchGetTuitionAmounts),
  fork(watchAddTuitionElements),
  fork(watchCreateTuitionAmounts),
  fork(watchDeleteTuitionAmounts),
  fork(watchUpdateTuitionAmounts),
  fork(watchDownloadTuitionTemplate),
  fork(watchUploadTuition),
  fork(watchAddTuitionExemptions),
  fork(watchGetTuitionExemptions),
  fork(watchDeleteTuitionExemptions),
];
