import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Result } from 'antd';
import { ReloadButton, SubmitButton } from '../../components/common';
import { authActions } from '../../config/actions';

function EmailVerificationRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    authUser,
    requesting,
    loggingOut,
    requestEmailSuccess,
    logoutData,
    requestEmailError,
    gettingAuthUser,
  } = useSelector((state) => state.auth);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (isEmpty(authUser)) navigate('/');
    if (authUser?.email_verified === true) navigate('/');
  }, []);

  useEffect(() => {
    if (
      !isEmpty(logoutData) &&
      isEmpty(authUser) &&
      logoutData.server.status === true
    ) {
      navigate('/');
    }
  }, [logoutData, authUser]);

  useEffect(() => {
    if (!isEmpty(requestEmailError) && requestEmailError.server)
      setErrorMessage(requestEmailError.server.message);

    if (!isEmpty(requestEmailSuccess) && requestEmailSuccess.server) {
      setSuccessMessage(requestEmailSuccess.server.message);
    }
  }, [requestEmailError, requestEmailSuccess]);

  const resendVerificationLink = () => {
    dispatch(
      authActions.requestEmailVerificationLink({ email: authUser.email })
    );
  };

  const logoutUser = () => {
    dispatch(authActions.logoutUser());
  };

  return (
    <div className="d-flex min-vh-100">
      <Helmet>
        <title>Verify Email</title>
      </Helmet>
      <div className="h-100 my-auto p-4 mx-auto text-center text-sm">
        {isEmpty(errorMessage) && isEmpty(successMessage) && (
          <>
            <Result
              status="error"
              title="Please verify your E-mail address to proceed"
              subTitle={
                <div>
                  <p>
                    Click on the link sent to your email
                    <strong>{` ${authUser.email}`}</strong> to activate your
                    account
                  </p>
                  <p>
                    If your link is EXPIRED or you DID NOT receive the email
                    click the BUTTON below to request again.
                  </p>
                </div>
              }
              extra={[
                <SubmitButton
                  loading={requesting}
                  htmlType="button"
                  onClick={resendVerificationLink}
                  text="Resend Verification Link"
                  loadingText="Sending Link..."
                />,
                <SubmitButton
                  className="mt-4 text-uppercase text-sm fw-bold"
                  htmlType="button"
                  danger
                  onClick={logoutUser}
                  loading={loggingOut}
                  text="Log out"
                  loadingText="Logging out..."
                />,
              ]}
            />
          </>
        )}
        {errorMessage && isEmpty(successMessage) && (
          <div className="text-danger">
            <i className="fa fa-close fa-3x mb-3" />
            <p>{errorMessage}</p>
          </div>
        )}
        {successMessage && (
          <Result
            status="success"
            title="VERIFICATION LINK SENT!"
            subTitle={successMessage}
            extra={[
              <SubmitButton
                loading={requesting}
                htmlType="button"
                onClick={resendVerificationLink}
                text="Resend Verification Link"
                loadingText="Sending Link..."
              />,
              <SubmitButton
                className="mt-4 text-uppercase text-sm fw-bold"
                htmlType="button"
                danger
                onClick={logoutUser}
                loading={loggingOut}
                text="Log out"
                loadingText="Logging out..."
              />,
            ]}
          />
        )}

        <ReloadButton
          loading={gettingAuthUser}
          text="Reload Profile"
          variant="primary"
          className="d-inline me-2 text-uppercase text-md fw-bold"
          onClick={() => dispatch(authActions.getAuthUser())}
        />
      </div>
    </div>
  );
}
export default EmailVerificationRequest;
