import update from 'immutability-helper';
import { flatten, map, uniqBy } from 'lodash';
import { authActions } from '../../actions';
import initialState from '../../initialState';

function auth(state = initialState.auth, actions) {
  switch (actions.type) {
    case authActions.LOGIN_REQUEST:
      return {
        ...state,
        loginError: {},
        loginIn: true,
        authUserError: {},
      };
    case authActions.LOGIN_SUCCESS:
      return {
        ...state,
        loginData: actions.data,
        loginError: {},
        loginIn: false,
      };
    case authActions.LOGIN_ERROR:
      return {
        ...state,
        loginError: actions.error,
        loginIn: false,
      };

    case authActions.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        loggingOut: true,
        logoutData: {},
        logoutError: {},
      };
    case authActions.LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        isAuthenticated: false,
        false: true,
        logoutData: actions.data,
      };
    case authActions.LOGOUT_ERROR:
      return {
        ...state,
        loggingOut: false,
        false: true,
        logoutError: actions.error,
      };

    case authActions.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changingPassword: true,
        changePasswordError: {},
      };
    case authActions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        changePasswordSuccess: actions.data,
      };
    case authActions.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changingPassword: false,
        changePasswordError: actions.error,
      };

    case authActions.CHANGE_DEFAULT_PASSWORD_REQUEST:
      return {
        ...state,
        changingDefaultPassword: true,
        changeDefaultPasswordError: {},
      };
    case authActions.CHANGE_DEFAULT_PASSWORD_SUCCESS:
      return {
        ...state,
        changingDefaultPassword: false,
        changeDefaultPasswordSuccess: actions.data,
      };
    case authActions.CHANGE_DEFAULT_PASSWORD_ERROR:
      return {
        ...state,
        changingDefaultPassword: false,
        changeDefaultPasswordError: actions.error,
      };

    case authActions.GET_SINGLE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        users: {},
        fetchUserError: {},
      };
    case authActions.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: actions.data.users,
      };
    case authActions.GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        fetchUserError: actions.error,
      };

    case authActions.GET_AUTH_USER_APPS_REQUEST:
      return {
        ...state,
        gettingUserApps: true,
        authUserRoleError: {},
      };
    case authActions.GET_AUTH_USER_APPS_SUCCESS:
      return {
        ...state,
        gettingUserApps: false,
        authUserRoles: actions.data,
        authUserApps: uniqBy(
          flatten(map(actions.data, 'role_apps')),
          'app_code'
        ),
      };
    case authActions.GET_AUTH_USER_APPS_ERROR:
      return {
        ...state,
        gettingUserApps: false,
        authUserRoleError: actions.error,
      };

    case authActions.GET_AUTH_USER_DEPARTMENTS_REQUEST:
      return {
        ...state,
        gettingUserDepartments: true,
        userDepartmentError: {},
      };
    case authActions.GET_AUTH_USER_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        gettingUserDepartments: false,
        userDepartments: actions.data,
      };
    case authActions.GET_AUTH_USER_DEPARTMENTS_ERROR:
      return {
        ...state,
        gettingUserDepartments: false,
        userDepartmentError: actions.error,
      };

    case authActions.GET_AUTH_USER_REQUEST:
      return {
        ...state,
        gettingAuthUser: true,
        authUserError: {},
      };
    case authActions.GET_AUTH_USER_SUCCESS:
      return {
        ...state,
        gettingAuthUser: false,
        authUser: actions.data,
      };
    case authActions.GET_AUTH_USER_ERROR:
      return {
        ...state,
        gettingAuthUser: false,
        authUserError: actions.error,
      };

    case authActions.SEND_OTP_REQUEST:
      return {
        ...state,
        sendingOTP: true,
        sendOTPError: {},
      };
    case authActions.SEND_OTP_SUCCESS:
      return {
        ...state,
        sendingOTP: false,
        sendOTPSuccess: actions.data,
      };
    case authActions.SEND_OTP_ERROR:
      return {
        ...state,
        sendingOTP: false,
        sendOTPError: actions.error,
      };

    case authActions.RESET_OTP_PASSWORD_REQUEST:
      return {
        ...state,
        resettingOTPPassword: true,
        resetOTPPasswordError: {},
      };
    case authActions.RESET_OTP_PASSWORD_SUCCESS:
      return {
        ...state,
        resettingOTPPassword: false,
        resetOTPPasswordSuccess: actions.data,
      };
    case authActions.RESET_OTP_PASSWORD_ERROR:
      return {
        ...state,
        resettingOTPPassword: false,
        resetOTPPasswordError: actions.error,
      };

    case authActions.EMAIL_VERIFICATION_LINK_REQUEST:
      return {
        ...state,
        requesting: true,
        requestEmailError: {},
        requestEmailSuccess: {},
      };
    case authActions.EMAIL_VERIFICATION_LINK_SUCCESS:
      return {
        ...state,
        requesting: false,
        requestEmailSuccess: actions.data,
      };
    case authActions.EMAIL_VERIFICATION_LINK_ERROR:
      return {
        ...state,
        requesting: false,
        requestEmailError: actions.error,
      };

    case authActions.VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        verifying: true,
        verifyEmailError: {},
        verifyEmailSuccess: {},
      };
    case authActions.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifying: false,
        verifyEmailSuccess: actions.data,
      };
    case authActions.VERIFY_EMAIL_ERROR:
      return {
        ...state,
        verifying: false,
        verifyEmailError: actions.error,
      };

    case authActions.GET_USER_ROLE_BOUND_REQUEST:
      return {
        ...state,
        gettingUserRoleBounds: true,
        getUserRoleBoundError: {},
      };
    case authActions.GET_USER_ROLE_BOUND_SUCCESS: {
      const { roleId, data } = actions;
      const { userRoleBounds } = state;

      const findIndex = userRoleBounds.findIndex(
        (role) => parseInt(role.roleId, 10) === parseInt(roleId, 10)
      );

      const newStateData = { roleId, data };

      let newState = update(state, {
        userRoleBounds: { $push: [newStateData] },
      });
      if (findIndex !== -1) {
        newState = update(state, {
          userRoleBounds: { [findIndex]: { $set: newStateData } },
        });
      }

      return {
        ...newState,
        gettingUserRoleBounds: false,
      };
    }
    case authActions.GET_USER_ROLE_BOUND_ERROR:
      return {
        ...state,
        gettingUserRoleBounds: false,
        getUserRoleBoundError: actions.error,
      };

    case authActions.SET_AUTH_USER:
      return {
        ...state,
        authUser: actions.user,
      };

    case authActions.REMOVE_AUTH_USER:
      return {
        ...state,
        authUser: {},
      };

    case authActions.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: actions.payload,
      };

    case authActions.SET_MY_APPS:
      return {
        ...state,
        myApps: actions.payload,
      };

    case authActions.SET_CURRENT_ROLE:
      return {
        ...state,
        currentRole: actions.payload,
      };

    case authActions.AUTHENTICATE_USER_ACTION_REQUEST:
      return {
        ...state,
        authenticatingUserAction: true,
        authActionError: {},
        authActionSuccess: {},
      };
    case authActions.AUTHENTICATE_USER_ACTION_SUCCESS:
      return {
        ...state,
        authenticatingUserAction: false,
        authActionSuccess: actions.data,
      };
    case authActions.AUTHENTICATE_USER_ACTION_ERROR:
      return {
        ...state,
        authenticatingUserAction: false,
        authActionError: actions.error,
      };

    default:
      return state;
  }
}

export default auth;
